import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import AppRole from "../../../authorization/appRoles";
import { ForRoles } from "../../../authorization/RolesContext";
import { FormTitle } from "../../../crossCutting/FormSectionTitle";
import {
  combineStates,
  success,
} from "../../../crossCutting/hooks/usePromiseStates";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import { useFetch2, useSendJson } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { Exhibition, Member, Organization } from "../apiTypes";
import OrganizationList from "../organization/OrganizationList";
import { memberEditPagePath } from "./routing";

export type MemberEditExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface RegistrationEditPageProps {
  registrationId?: number;
  exhibition: MemberEditExhibition;
}

const useStyles = makeStyles((theme) => ({
  radios: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));

const MemberEditPage: React.FunctionComponent<RegistrationEditPageProps> = (
  props
) => {
  const { memberId } = useParams<{ memberId?: string }>();
  const isNewMember = !memberId;

  const memberDefaultValues = {
    memberId: "",
    memberNumber: null as number | null,
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    zipCode: "",
    city: "",
    phone: "",
    email: "",
    ringNumber: "",
    yearOfBirth: null as number | null,
    salutation: "",
    language: "de",
    organizationIds: [] as string[],
  } as Member;

  const [existingMemberState] = useFetch2<Member>(
    isNewMember
      ? ""
      : `exhibitions/${props.exhibition.exhibitionId}/members/${memberId}`
  );
  const memberState = isNewMember
    ? success(memberDefaultValues)
    : existingMemberState;

  const [organizationsState] = useFetch2<{ organizations: Organization[] }>(
    `exhibitions/${props.exhibition.exhibitionId}/organizations`
  );

  const stateCombined = combineStates(
    memberState,
    organizationsState,
    (m, o) => ({
      member: m,
      allExhibitionOrganizations: o.organizations,
    })
  );

  return stateCombined.isRunning() ? (
    <LoadingIndicator />
  ) : stateCombined.isError() ? (
    <div>Error</div>
  ) : (
    <MemberEditPageExceptDataFetching
      exhibition={props.exhibition}
      {...stateCombined.data}
    />
  );
};

export default MemberEditPage;

const MemberEditPageExceptDataFetching: React.FunctionComponent<{
  exhibition: MemberEditExhibition;
  member: Omit<Member, "memberNumber" | "orders"> & {
    memberNumber: number | null;
    organizationIds: string[];
  };
  allExhibitionOrganizations: Organization[];
}> = ({ exhibition, member, allExhibitionOrganizations }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isNewMember = !member.memberNumber;
  const [editedMember, setEditedMember] = React.useState(member);
  const [memberNumber, setMemberNumber] = React.useState(
    member.memberNumber?.toString() ?? ""
  );
  const [organizations, setOrganizations] = React.useState(
    member.organizationIds.map(
      (id) =>
        allExhibitionOrganizations.find((eo) => eo.organizationId === id) ?? {
          organizationId: id,
          organizationName: "n/a",
          organizationNumber: null,
          organizationCode: "",
        }
    )
  );
  const classes = useStyles();

  const valid =
    editedMember.firstName &&
    editedMember.lastName &&
    !isNaN(parseInt(memberNumber)) &&
    (editedMember.yearOfBirth === null ||
      editedMember.yearOfBirth === undefined ||
      (typeof editedMember.yearOfBirth === "number" &&
        editedMember.yearOfBirth > 1900 &&
        editedMember.yearOfBirth < new Date().getFullYear()));

  const { sendJson: save, loading: saving } = useSendJson({
    success: t("memberSaveSuccess"),
    error: t("memberSaveError"),
  });
  const collectInputs = () => {
    return {
      ...editedMember,
      memberNumber: parseInt(memberNumber),
      address1: editedMember.address1?.trim() || null,
      address2: editedMember.address2?.trim() || null,
      zipCode: editedMember.zipCode?.trim() || null,
      city: editedMember.city?.trim() || null,
      phone: editedMember.phone?.trim() || null,
      email: editedMember.email?.trim() || null,
      ringNumber: editedMember.ringNumber?.trim() || null,
      yearOfBirth: editedMember.yearOfBirth || null,
      salutation: editedMember.salutation?.trim() || null,
      organizationIds: organizations.map((o) => o.organizationId),
    };
  };
  const handleSaveNew = () => {
    save({
      path: `exhibitions/${exhibition.exhibitionId}/members`,
      method: "POST",
      body: collectInputs(),
      onSuccess: ({ memberId }: { memberId: string }) => {
        history.replace(memberEditPagePath(exhibition.exhibitionId, memberId));
      },
    });
  };
  const handleSaveExisting = () => {
    save({
      path: `exhibitions/${exhibition.exhibitionId}/members/${member.memberId}`,
      method: "PUT",
      body: collectInputs(),
    });
  };
  return (
    <BottomBarPage
      title={exhibition.exhibitionName}
      subtitle={isNewMember ? t("newMember") : t("editMember")}
      bottomBarContent={
        isNewMember ? (
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid || saving}
            onClick={handleSaveNew}
          >
            {t("save")}
          </Button>
        ) : (
          <ForRoles roles={[AppRole.ExhibitionAdmin, "Organizer"]}>
            <Button
              color="secondary"
              variant="contained"
              disabled={!valid}
              onClick={handleSaveExisting}
            >
              {t("saveChanges")}
            </Button>
          </ForRoles>
        )
      }
    >
      <Grid container spacing={spacing3}>
        <Grid item xs={12}>
          <TextField
            label={t("memberNumber")}
            required
            autoFocus
            fullWidth
            value={editedMember.memberNumber}
            onChange={(e) => {
              setMemberNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("name")}
            required
            fullWidth
            value={editedMember.lastName}
            onChange={(e) => {
              const lastName = e.target.value;
              setEditedMember((m) => ({
                ...m,
                lastName,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("firstName")}
            required
            fullWidth
            value={editedMember.firstName}
            onChange={(e) => {
              const firstName = e.target.value;
              setEditedMember((m) => ({
                ...m,
                firstName,
              }));
            }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("salutation")}
            fullWidth
            value={editedMember.salutation}
            onChange={(e) => {
              const salutation = e.target.value;
              setEditedMember((m) => ({
                ...m,
                salutation,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("language")}</FormLabel>
            <RadioGroup
              className={classes.radios}
              aria-label={t("language")}
              name="language"
              value={editedMember.language}
              onChange={(e) => {
                const language = e.target.value;
                setEditedMember((m) => ({
                  ...m,
                  language: language,
                }));
              }}
            >
              <FormControlLabel
                value="de"
                control={<Radio />}
                label={t("german")}
              />
              <FormControlLabel
                value="fr"
                control={<Radio />}
                label={t("french")}
              />
              <FormControlLabel
                value="it"
                control={<Radio />}
                label={t("italian")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address1")}
            fullWidth
            value={editedMember.address1}
            onChange={(e) => {
              const address1 = e.target.value;
              setEditedMember((m) => ({
                ...m,
                address1,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("address2")}
            fullWidth
            value={editedMember.address2}
            onChange={(e) => {
              const address2 = e.target.value;
              setEditedMember((m) => ({
                ...m,
                address2,
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={t("postalCodeShort")}
            fullWidth
            value={editedMember.zipCode}
            onChange={(e) => {
              const zipCode = e.target.value;
              setEditedMember((m) => ({
                ...m,
                zipCode: zipCode,
              }));
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label={t("city")}
            fullWidth
            value={editedMember.city}
            onChange={(e) => {
              const city = e.target.value;
              setEditedMember((m) => ({
                ...m,
                city,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("email")}
            fullWidth
            type="email"
            value={editedMember.email}
            onChange={(e) => {
              const email = e.target.value;
              setEditedMember((m) => ({
                ...m,
                email,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("phoneNumber")}
            fullWidth
            type="tel"
            value={editedMember.phone}
            onChange={(e) => {
              const phone = e.target.value;
              setEditedMember((m) => ({
                ...m,
                phone,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("ringNumber")}
            fullWidth
            value={editedMember.ringNumber}
            onChange={(e) => {
              const ringNumber = e.target.value;
              setEditedMember((m) => ({
                ...m,
                ringNumber,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t("ageGroup")}
            fullWidth
            value={editedMember.yearOfBirth}
            onChange={(e) => {
              const yearOfBirth = parseInt(e.target.value);
              setEditedMember((m) => ({
                ...m,
                yearOfBirth: isNaN(yearOfBirth) ? null : yearOfBirth,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTitle>{t("organizations")}</FormTitle>
          <OrganizationList
            exhibitionId={exhibition.exhibitionId}
            value={organizations}
            onChange={setOrganizations}
          />
        </Grid>
      </Grid>
    </BottomBarPage>
  );
};
