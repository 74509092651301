import * as React from "react";

export default function usePromiseStates<
  F extends (...args: any[]) => Promise<R>,
  R
>(fn: F) {
  const [state, setState] = React.useState<State<R>>(new RunningState());

  const execute = React.useCallback(
    (...args: Parameters<F>) => {
      setState(RunningState.value as RunningState<R>);
      fn(...args)
        .then((data) => {
          setState(new SuccessState(data));
        })
        .catch((error) => setState(new ErrorState(error)));
    },
    [fn]
  );
  return {
    execute,
    state,
  };
}

export type State<T> = RunningState<T> | SuccessState<T> | ErrorState<T>;

function isSuccess<T>(state: State<T>): state is SuccessState<T> {
  return (state as SuccessState<T>).data !== undefined;
}

function isError<T>(state: State<T>): state is ErrorState<T> {
  return (state as ErrorState<T>).error !== undefined;
}

function isRunning<T>(state: State<T>): state is RunningState<T> {
  return (state as RunningState<T>).running === running;
}

const running = true;
class SuccessState<T> {
  constructor(public data: T) {}

  isSuccess(): this is SuccessState<T> {
    return isSuccess(this);
  }

  isError(): this is ErrorState<T> {
    return isError(this);
  }

  isRunning(): this is RunningState<T> {
    return isRunning(this);
  }
}
export function success<T>(data: T) {
  return new SuccessState(data) as State<T>;
}

class ErrorState<T> {
  constructor(public error: any) {}

  isSuccess(): this is SuccessState<T> {
    return isSuccess(this);
  }

  isError(): this is ErrorState<T> {
    return isError(this);
  }

  isRunning(): this is RunningState<T> {
    return isRunning(this);
  }
}

class RunningState<T> {
  readonly running = running;

  isSuccess(): this is SuccessState<T> {
    return isSuccess(this);
  }

  isError(): this is ErrorState<T> {
    return isError(this);
  }

  isRunning(): this is RunningState<T> {
    return isRunning(this);
  }

  static value: any = new RunningState();
}

export function combineStates<A, B, R>(
  a: State<A>,
  b: State<B>,
  combine: (a: A, b: B) => R
): State<R> {
  if (a.isError() && b.isError()) {
    return new ErrorState([a, b]);
  } else if (a.isError()) {
    return (a as any) as ErrorState<R>;
  } else if (b.isError()) {
    return (b as any) as ErrorState<R>;
  } else if (a.isRunning() || b.isRunning()) {
    return RunningState.value;
  } else {
    return new SuccessState(combine(a.data, b.data));
  }
}

export function mapState<I, O>(v: State<I>, fn: (data: I) => O): State<O> {
  if (v.isError()) {
    return v as any;
  } else if (v.isRunning()) {
    return v as any;
  } else {
    return success(fn(v.data));
  }
}
