import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import EditList from "../../../crossCutting/EditList";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { AddIcon } from "../../../crossCutting/icons";
import { JumpToLinkButton } from "../../../crossCutting/JumpToButton";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import Page from "../../../crossCutting/pages/Page";
import { useFetch2 } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { Exhibition } from "../apiTypes";
import { ClubCompetition } from "../clubCompetitionApiTypes";
import {
  clubCompetitionEditPagePath,
  LinkToNewClubCompetition,
} from "../clubCompetitionEditPage/routing";

export type ClubCompetitionsPageExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface CompetitionsPageProps {
  exhibition: ClubCompetitionsPageExhibition;
}

const CompetitionsPage: React.FunctionComponent<CompetitionsPageProps> = (
  props
) => {
  const history = useHistory();
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const { t } = useTranslation();

  const [clubCompetitionsStateNoType] = useFetch2(
    `exhibitions/${props.exhibition.exhibitionId}/clubcompetitions`
  );
  const clubCompetitionState = mapState(
    clubCompetitionsStateNoType as any,
    ({ clubCompetitions }: { clubCompetitions: ClubCompetition[] }) =>
      clubCompetitions
  );

  return (
    <Page
      title={props.exhibition.exhibitionName}
      subtitle={t("clubCompetitions")}
    >
      {clubCompetitionState.isRunning() ? (
        <LoadingIndicator />
      ) : clubCompetitionState.isError() ? (
        <>Error</>
      ) : (
        <EditList
          toolbarItems={
            <LinkToNewClubCompetition
              exhibitionId={props.exhibition.exhibitionId}
              button
              variant="contained"
              startIcon={<AddIcon />}
            >
              {t("newClubCompetition")}
            </LinkToNewClubCompetition>
          }
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("name")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clubCompetitionState.data.map((c) => (
                <TableRow
                  className={rowClass}
                  key={c.clubCompetitionId}
                  hover
                  onClick={() =>
                    history.push(
                      clubCompetitionEditPagePath(
                        props.exhibition.exhibitionId,
                        c.clubCompetitionId
                      )
                    )
                  }
                >
                  <TableCell>{c.clubCompetitionName}</TableCell>
                  <HoverActionCell>
                    <JumpToLinkButton
                      to={clubCompetitionEditPagePath(
                        props.exhibition.exhibitionId,
                        c.clubCompetitionId
                      )}
                    >
                      {t("goTo")}
                    </JumpToLinkButton>
                  </HoverActionCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </EditList>
      )}
    </Page>
  );
};

export default CompetitionsPage;
