import { Button, CircularProgress } from "@material-ui/core";
import * as React from "react";
import useFileDownload from "../../../crossCutting/hooks/useFileDownload";
import { ExcelIcon } from "../../../crossCutting/icons";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { ExhibitionId } from "../types";

export default function RegistrationsExcelDownload({
  exhibition,
}: {
  exhibition: { exhibitionId: ExhibitionId; exhibitionName: string };
}) {
  const { t } = useTranslation();
  const download = useFileDownload();
  const fetcher = useFetchClient();
  const [loading, setLoading] = React.useState(false);

  const handleDownloadClick = () => {
    const path = `exhibitions/${exhibition.exhibitionId}/registrations/excel`;
    const fileName = `${t("registrations")}_${exhibition.exhibitionName.replace(
      /[/\\.\s]/g,
      "_"
    )}.xlsx`;

    setLoading(true);
    fetcher(path, {
      method: "GET",
      raw: true,
      headers: {
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        download(blob, fileName);
        setLoading(false);
      });
  };
  return (
    <Button
      disabled={loading}
      onClick={handleDownloadClick}
      startIcon={
        loading ? (
          <CircularProgress style={{ width: 20, height: 20 }} />
        ) : (
          <ExcelIcon />
        )
      }
      title={t("downloadAllRegistrationsAsExcel")}
    >
      {t("download")}
    </Button>
  );
}
