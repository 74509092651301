export function dateString(date: Date) {
  // used to do date.toISOString().substr(0, 10) here but that's problematic
  // because it converts date that is likely local to UTC and the cuts of the
  // time which can result in a changed date.
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${leadingZero(month)}-${leadingZero(day)}`;
}

function leadingZero(input: number) {
  return input < 10 ? `0${input}` : input;
}

export function formatDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${leadingZero(day)}.${leadingZero(month)}.${year}`;
}

export function formatInterval(date1: Date, date2: Date) {
  return `${formatDate(date1)} - ${formatDate(date2)}`;
}

export function friendlyDateTime(date: Date) {
  return isToday(date)
    ? `Heute ${date.toLocaleTimeString()}`
    : date.toLocaleString();
}

function today() {
  return dateOnly(new Date());
}

function dateOnly(date: Date) {
  var copy = new Date(date.valueOf());
  copy.setHours(0);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);
  return copy;
}

function isToday(date: Date) {
  return areEqual(dateOnly(date), today());
}

function areEqual(date1: Date, date2: Date) {
  return date1.valueOf() === date2.valueOf();
}
