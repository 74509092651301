import Alert from "@material-ui/lab/Alert";
import React from "react";
import { Snackbar } from "@material-ui/core";

interface SnackbarProps {
  message: string;
  open: boolean;
  onClose?: () => void;
}

export const ErrorSnackbar = (props: SnackbarProps) => (
  <AlertSnackbar {...props} severity="error" />
);

export const SuccessSnackbar = (props: SnackbarProps) => (
  <AlertSnackbar {...props} severity="success" />
);

export function AlertSnackbar({
  message,
  open,
  onClose,
  severity,
}: {
  message: string;
  open: boolean;
  onClose?: () => void;
  severity: AlertSnackbarSeverity;
}) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}

export type AlertSnackbarSeverity =
  | "info"
  | "success"
  | "error"
  | "warning"
  | undefined;

type AlertFunction = (message: string, severity: AlertSnackbarSeverity) => void;
const noOp = () => {};
const AlertSnackbarContext = React.createContext<AlertFunction>(noOp);

export interface GlobalAlertSnackbarProviderProps {
  showGlobalAlertSnackbar: AlertFunction;
}

export const GlobalAlertSnackbarProvider: React.FunctionComponent<GlobalAlertSnackbarProviderProps> = (
  props
) => {
  return (
    <AlertSnackbarContext.Provider value={props.showGlobalAlertSnackbar}>
      {props.children}
    </AlertSnackbarContext.Provider>
  );
};

export function useGlobalAlertSnackbar() {
  return React.useContext(AlertSnackbarContext);
}
