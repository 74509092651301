export function recurseNodesUntil<T extends { children?: T[] | null }>(
  nodes: T[],
  until: (n: T) => boolean,
  doWithNode: (n: T, depth: number) => void,
  depth: number = 0
) {
  let node: T;
  let i = 0;
  for (; i < nodes.length; i++) {
    node = nodes[i];
    doWithNode(node, depth);
    if (
      until(node) ||
      (node.children &&
        recurseNodesUntil(node.children, until, doWithNode, depth + 1))
    ) {
      return true;
    }
  }
}

export function getAncestry<
  T extends { children?: T[] | null; nodeId: string }
>(nodes: T[], leafNodeId: string) {
  const ancestry: T[] = [];
  let found = false;
  recurseNodesUntil(
    nodes,
    (n) => {
      found = found || n.nodeId === leafNodeId;
      return found;
    },
    (n, depth) => {
      ancestry[depth] = n;
    }
  );
  return found ? ancestry : undefined;
}

export function getDepth<T extends { children: T[] | null }>(
  nodes: T[],
  node: T,
  depth: number = 0
): number | undefined {
  let i = 0;
  for (; i < nodes.length; i++) {
    if (nodes[i] === node) return depth;
    if (nodes[i].children) {
      const childrenDepth = getDepth(nodes[i].children!, node, depth + 1);
      if (childrenDepth !== undefined) return childrenDepth;
    }
  }
}

export function findSiblingsAndParent<T extends { children?: T[] | null }>(
  nodes: T[],
  node: T,
  parent?: T
): { parent: T | undefined; siblings: T[] } | undefined {
  let i = 0;
  for (; i < nodes.length; i++) {
    if (nodes[i] === node) return { siblings: nodes, parent };
    if (nodes[i].children) {
      const result = findSiblingsAndParent(nodes[i].children!, node, nodes[i]);
      if (result) return result;
    }
  }
}

export interface Node {
  nodeId: string;
  captions: { language: string; nodeName: string }[];
  children?: Node[] | null;
}

export const allNodeLevels = [
  { key: "breedSize", label: "Rassengrösse", plural: "Rassengrössen" },
  { key: "subGroup", label: "Untergruppe", plural: "Untergruppen" },
];

export function getApplicableNodeLevels(animalStandard: {
  hasBreedSizeLevel: boolean;
  hasSubGroupLevel: boolean;
}) {
  return allNodeLevels.filter(
    (l) =>
      (l.key === "breedSize" && animalStandard.hasBreedSizeLevel) ||
      (l.key === "subGroup" && animalStandard.hasSubGroupLevel)
  );
}
