import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
  makeStyles,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import * as React from "react";
import { FormTitle } from "../../../crossCutting/FormSectionTitle";
import CaptionEditor from "../CaptionEditor";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import { inDefaultLanguage } from "../../../language";

const useStyles = makeStyles((theme) => ({
  marginTop: { marginTop: theme.spacing(spacing3) },
}));

interface OtherColor {
  colorId: string;
  colorName: string;
}

export const insertAtHead = "head";
export default function ColorDialog<T extends FormColor>(props: {
  open: boolean;
  onCancel: () => void;
  onOk: (color: T) => void;
  color: T;
  otherColors: OtherColor[];
  animalStandard: { hasReferenceRating: boolean };
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [color, setColor] = React.useState(props.color);

  const handleChange = (color: T) => {
    setColor(color);
  };
  const hasDefaultLanguage = !!inDefaultLanguage(color.captions)?.colorName;
  const valid =
    hasDefaultLanguage &&
    (!props.animalStandard.hasReferenceRating || !!color.referenceRating) &&
    !!color.insertAfterColorId;
  const handleSave = () => {
    props.onOk(color);
  };
  const handleClose = () => {
    props.onCancel();
  };
  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Farbenschlag</DialogTitle>
      <DialogContent>
        <ColorForm
          color={color}
          animalStandard={props.animalStandard}
          onChange={handleChange}
          specifyPosition={!!props.otherColors.length}
          otherColors={props.otherColors}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!valid}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Color {
  colorId: string;
  captions: { language: string; colorName: string }[];
  referenceRating: number | null;
}

export type FormColor = Color & { insertAfterColorId: string };

function ColorForm<T extends FormColor>({
  color,
  animalStandard,
  ...props
}: {
  color: T;
  animalStandard: { hasReferenceRating: boolean };
  onChange: (color: T) => void;
  otherColors: { colorName: string; colorId: string }[];
  specifyPosition: boolean;
}) {
  const classes = useStyles();
  const captionFieldDescriptors = [
    {
      value: (caption: any) => caption?.colorName || "",
      onChange: (language: string, newValue: string) => {
        const old = color.captions;
        const itemForLanguage = old.find((i) => i.language === language);
        const captions = itemForLanguage
          ? [
              ...old.filter((i) => i.language !== language),
              { ...itemForLanguage, colorName: newValue },
            ]
          : [...old, { language, colorName: newValue }];
        props.onChange({ ...color, captions });
      },
    },
  ];

  const handleReferenceRatingChange = (e: any) => {
    const parsed = parseFloat(e.target.value);
    props.onChange({
      ...color,
      referenceRating: isNaN(parsed) ? null : parsed,
    });
  };

  const handlePositionChange = (e: any) => {
    props.onChange({ ...color, insertAfterColorId: e.target.value });
  };
  return (
    <>
      <div>
        <FormTitle>Name</FormTitle>
        <CaptionEditor
          autoFocus
          disableHeader
          captions={color.captions}
          fieldDescriptors={captionFieldDescriptors}
        />
      </div>
      {props.specifyPosition && (
        <div>
          <FormTitle>Einsortieren nach</FormTitle>
          <Select
            fullWidth
            onChange={handlePositionChange}
            value={color.insertAfterColorId}
            renderValue={(i: any) =>
              i === insertAtHead
                ? "Am Anfang"
                : props.otherColors.find((c) => c.colorId === i)?.colorName
            }
          >
            <MenuItem value={insertAtHead}>Am Anfang</MenuItem>
            <Divider />
            {props.otherColors.map((c) => (
              <MenuItem key={c.colorId} value={c.colorId}>
                {c.colorName}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {animalStandard.hasReferenceRating && (
        <div className={classes.marginTop}>
          <FormTitle>Richtpunktzahl *</FormTitle>
          <TextField
            required
            fullWidth
            type="number"
            value={color.referenceRating ?? ""}
            onChange={handleReferenceRatingChange}
          />
        </div>
      )}
    </>
  );
}
