import React from "react";
import { ConfirmationDialog } from "../Dialogs";

export default function useConfirmation(
  yesButtonText: string,
  noButtonText: string,
  action: () => void
) {
  const [open, setOpen] = React.useState(false);

  const handleConfirmationClose = (decision: boolean) => {
    setOpen(false);
    if (decision) {
      action();
    }
  };

  const Confirmation: React.FunctionComponent<{
    title?: string;
    text?: string;
  }> = (props) => (
    <ConfirmationDialog
      open={open}
      onClose={handleConfirmationClose}
      yesButtonText={yesButtonText}
      noButtonText={noButtonText}
      {...props}
    ></ConfirmationDialog>
  );

  return {
    Confirmation,
    executeWithConfirmation: () => {
      setOpen(true);
    },
  };
}
