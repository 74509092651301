import { makeStyles, TableCell } from "@material-ui/core";
import * as React from "react";
export interface HoverActionCellProps {}

const useStyles = makeStyles({
  row: {
    "&:hover": { cursor: "Pointer" },
    "&:hover $actionCell > *": {
      visibility: "visible",
    },
  },
  actionCell: {
    "& > *": { visibility: "hidden" },
    whiteSpace: "nowrap",
  },
});

const HoverActionCell: React.FunctionComponent<HoverActionCellProps> = (
  props
) => {
  const classes = useStyles();
  return <TableCell className={classes.actionCell}>{props.children}</TableCell>;
};

function useHoverActionCell() {
  const classes = useStyles();
  return { rowClass: classes.row, HoverActionCell };
}

export default useHoverActionCell;
