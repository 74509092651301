import * as React from "react";
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { masterDataLanguages, defaultLanguage } from "../../language";

interface Caption {
  language: string;
}

export interface CaptionEditorProps<T extends Caption> {
  captions: T[];
  fieldDescriptors: FieldDescriptor<T>[];
  disableHeader?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

export interface FieldDescriptor<T> {
  label?: string;
  value: (caption: T | undefined) => string;
  onChange: (language: string, value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  languageNameCell: {
    whiteSpace: "nowrap",
  },
}));

function CaptionEditor<T extends Caption>({
  captions,
  fieldDescriptors,
  disableHeader,
  autoFocus,
  disabled,
}: CaptionEditorProps<T>) {
  const classes = useStyles();
  return (
    <Table>
      {!disableHeader && (
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {fieldDescriptors.map((fd, i) => (
              <TableCell key={i}>{fd.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {masterDataLanguages.map((l, languageIndex) => {
          const caption = captions.find((c) => c.language === l);
          const isDefaultLanguage = l === defaultLanguage;
          return (
            <TableRow key={l}>
              <TableCell className={classes.languageNameCell}>
                {l + (isDefaultLanguage ? " *" : "")}
              </TableCell>
              {fieldDescriptors.map((fd, i) => (
                <TableCell key={i}>
                  <TextField
                    inputProps={{ lang: l }}
                    fullWidth
                    autoFocus={i === 0 && languageIndex === 0 && autoFocus}
                    required={isDefaultLanguage}
                    value={fd.value(caption)}
                    onChange={(e) => fd.onChange(l, e.target.value)}
                    size="small"
                    disabled={disabled}
                  />
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default CaptionEditor;
