export enum Medal {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
}

export const allMedals = [
  {
    sort: 0,
    medalName: "Gold",
    medal: Medal.Gold,
    isSelected: false,
    limit: 0,
    medalCode: "",
  },
  {
    sort: 1,
    medalName: "Silber",
    medal: Medal.Silver,
    isSelected: false,
    limit: 0,
    medalCode: "",
  },
  {
    sort: 2,
    medalName: "Bronze",
    medal: Medal.Bronze,
    isSelected: false,
    limit: 0,
    medalCode: "",
  },
];
