import { Maybe } from "../../../crossCutting/maybe";

export function sameOrganization<T extends { organizationId: string }>(
  a: Maybe<T>,
  b: Maybe<T>
) {
  return (a === b && (!!a || !!b)) || a?.organizationId === b?.organizationId;
}

export interface Organization {
  organizationId: string;
  organizationName: string;
  organizationNumber: number | null;
  organizationCode: string | null;
}
