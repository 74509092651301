import { ExhibitionId } from "../../exhibitions/types";

export function organizersApiPath(exhibitionId: ExhibitionId) {
  return `exhibitions/${exhibitionId}/organizers`;
}

export interface OrganizersResult {
  organizers: Organizer[];
}

export interface Organizer {
  userId: string;
  givenName: string;
  familyName: string;
  email: string;
  isExhibitionCreator: boolean;
}
