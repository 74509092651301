import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { DeleteIcon } from "../../../crossCutting/icons";
import { Maybe } from "../../../crossCutting/maybe";
import { useTranslation } from "../../../i18n/i18n";
import { defaultLanguage } from "../../../language";
import { Item } from "../../masterdata/ChangeOrderDialog";
import BreedPicker, { PickerBreed } from "./BreedPicker";
import ColorSelector, { ColorSelection } from "./ColorSelector";

export default function BreedColorList({ items, onChange, ...rest }: any) {
  const handleItemChange = (oldItem: BreedColor, newItem: BreedColor) => {
    onChange(items.map((bc: BreedColor) => (bc === oldItem ? newItem : bc)));
  };

  return (
    <div>
      {items.map((item: any, i: number) => (
        <ItemComponent
          key={i}
          onChange={(newItem: BreedColor) => handleItemChange(item, newItem)}
          item={item}
          {...rest}
          first={i === 0}
          onDelete={() => {
            onChange(
              items.map((item: Item, ii: number) =>
                i === ii ? { ...item, deleted: true } : item
              )
            );
          }}
        />
      ))}
    </div>
  );
}

const useItemStyles = makeStyles((theme) => ({
  notFirst: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey[500],
  },
  deleteContainer: {
    alignSelf: "flex-start",
  },
  breedTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

function ItemComponent({
  exhibition,
  standard,
  unit,
  item,
  showValidationErrorsIfAny,
  disabled,
  first,
  onChange,
  onDelete,
}: any) {
  const { t } = useTranslation();
  const classes = useItemStyles();

  const firstColorHasSameNameAsBreed =
    item.breed?.captions
      .find((c: any) => c.language === defaultLanguage)
      ?.breedName?.toLowerCase() ===
    item.breed?.colors?.[0]?.captions
      .find((c: any) => c.language === defaultLanguage)
      ?.colorName?.toLowerCase();

  const handleBreedChange = (breed: Maybe<PickerBreed>) => {
    onChange({
      ...item,
      breed,
      color: breed?.colors?.length === 1 ? breed.colors[0] : null,
      hasAdditionalBox: false,
    });
  };
  const handleColorChange = (color: ColorSelection) => {
    onChange({ ...item, color });
  };
  const handleHasAdditionalBoxChange = (e: any) => {
    onChange({ ...item, hasAdditionalBox: e.target.checked });
  };

  return (
    <Collapse in={!item.deleted} timeout={300} appear={!first}>
      <Box
        className={clsx(!first && classes.notFirst)}
        paddingTop={first ? 2 : 0}
      >
        <Box>
          <div className={classes.breedTitle}>
            <Typography variant="h6" component="h4">
              {t("breed") + " *"}
            </Typography>
            {!first && (
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <BreedPicker
            exhibitionId={exhibition.exhibitionId}
            animalStandardId={standard?.animalStandardId || ""}
            onChange={handleBreedChange}
            breed={item.breed}
            fullWidth
            error={showValidationErrorsIfAny && !item.breedValid}
            helperText={
              !showValidationErrorsIfAny || item.breedValid
                ? " "
                : t("registrationMissingBreed")
            }
            disabled={disabled}
          />
        </Box>
        {item.breed?.colors &&
          unit &&
          standard?.hasColorLevel &&
          (item.breed.colors.length > 1 || !firstColorHasSameNameAsBreed) && (
            <Box marginTop={2}>
              <Typography variant="h6" component="h4">
                {t("color") + " *"}
              </Typography>
              <ColorSelector
                isMixed={unit.isMixed}
                colors={item.breed.colors}
                value={item.color}
                onChange={handleColorChange}
                error={showValidationErrorsIfAny && !item.colorValid}
                helperText={
                  !showValidationErrorsIfAny || item.colorValid
                    ? " "
                    : t("registrationMissingColor")
                }
              />
            </Box>
          )}
        {!!item.breed?.hasAdditionalBoxes && (
          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.hasAdditionalBox}
                  onChange={handleHasAdditionalBoxChange}
                  name="hasAdditionalBox"
                  color="primary"
                />
              }
              label={t("animalsNeedAdditionalBox")}
            />
          </Box>
        )}
      </Box>
    </Collapse>
  );
}

export const emptyBreedColor = {
  breed: undefined as Maybe<PickerBreed>,
  color: null as ColorSelection,
  hasAdditionalBox: false,
  breedValid: false,
  colorValid: false,
  deleted: false,
};

export interface BreedColor {
  breed: Maybe<PickerBreed>;
  color: ColorSelection;
  hasAdditionalBox: boolean;
  breedValid: boolean;
  colorValid: boolean;
  deleted?: boolean;
}
