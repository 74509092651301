import { Button } from "@material-ui/core";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import EditTable from "../../../crossCutting/editTable/EditTable";
import InlineEditTextField from "../../../crossCutting/editTable/InlineEditTextField";
import { useColumn } from "../../../crossCutting/editTable/useColumn";
import useEdit, {
  EditExistingState,
} from "../../../crossCutting/editTable/useEdit";
import validation from "../../../crossCutting/editTable/validation";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import useDeleter from "../../../crossCutting/hooks/useDeleter";
import { AddIcon } from "../../../crossCutting/icons";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import Page from "../../../crossCutting/pages/Page";
import { ErrorSnackbar } from "../../../crossCutting/Snackbars";
import { useFetch, useSendJson } from "../../../fetching/fetchProvider";
import { Exhibition } from "../apiTypes";
import { Organization, sameOrganization } from "./organization";
import { useRefreshExhibition } from "../ExhibitionContext";
import { useTranslation } from "../../../i18n/i18n";

export type OrganizationsExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface OrganizationsPageProps {
  exhibition: OrganizationsExhibition;
}

const OrganizationsPage: React.FunctionComponent<OrganizationsPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { data, loading, error, refresh } = useFetch<{
    organizations: Organization[];
  }>(`exhibitions/${props.exhibition.exhibitionId}/organizations`);
  const listedOrganizations = data?.organizations;

  const { sendJson, loading: saving, error: modifyError } = useSendJson();

  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  const showError = (message: string) => showAlertSnackbar(message, "error");
  const showSuccess = (message: string) =>
    showAlertSnackbar(message, "success");

  const numberColumn = useColumn<Organization, string, number | null>({
    header: t("number"),
    columnKey: "number",
    defaultValue: undefined,
    extractValue: (organization) => organization.organizationNumber,
    view: (organization) => organization.organizationNumber,
    convert: (raw) => parseInt(raw) || null,
    convertBack: (value) => value?.toString() || "",
    validate: (value) => value !== 0,
    editorFactory: (editorProps) => (
      <InlineEditTextField {...editorProps} autoSelect />
    ),
  });

  const codeColumn = useColumn<Organization, string, string>({
    header: t("token"),
    columnKey: "code",
    defaultValue: "",
    extractValue: (organization) => organization.organizationCode || "",
    view: (organization) => organization.organizationCode,
    convert: (raw) => raw,
    convertBack: (value) => value || "",
    validate: (value) => true,
    editorFactory: (editorProps) => <InlineEditTextField {...editorProps} />,
  });

  const nameColumn = useColumn<Organization, string, string>({
    header: t("name"),
    columnKey: "name",
    defaultValue: "",
    extractValue: (organization) => organization.organizationName,
    view: (organization) => organization.organizationName,
    convert: (raw) => raw,
    convertBack: (value) => value || "",
    validate: (value) => !!value,
    editorFactory: (editorProps) => <InlineEditTextField {...editorProps} />,
  });
  const columns = [numberColumn, codeColumn, nameColumn];

  const { omitValidationErrors, validate } = validation(columns, showError);

  const { deleteConfirmation, deleteWithConfirmation } = useDeleter();

  const {
    handleAddNew,
    handleEditExisting,
    stopEditing,
    editState,
    tableEditState,
  } = useEdit(columns, omitValidationErrors, sameOrganization);

  const refreshGlobalExhibition = useRefreshExhibition();
  const handleSaveNew = () => {
    if (!validate()) return;

    sendJson({
      path: `exhibitions/${props.exhibition.exhibitionId}/organizations`,
      body: {
        organizationName: nameColumn.value,
        organizationNumber: numberColumn.value,
        organizationCode: codeColumn.value,
      },
      onSuccess: () => {
        stopEditing();
        refresh();
        refreshGlobalExhibition();
        showSuccess("Der Verein wurde erstellt.");
      },
    });
  };

  const handleSaveExisting = () => {
    if (!validate()) return;

    const changedOrganization = listedOrganizations?.find((c) =>
      sameOrganization(
        c,
        (editState as EditExistingState<Organization>).editedItem
      )
    );
    if (!changedOrganization) {
      stopEditing();
      return;
    }

    sendJson({
      path: `exhibitions/${props.exhibition.exhibitionId}/organizations/${changedOrganization.organizationId}`,
      method: "PUT",
      body: {
        organizationName: nameColumn.value,
        organizationNumber: numberColumn.value,
        organizationCode: codeColumn.value,
      },
      onSuccess: () => {
        stopEditing();
        refresh();
        refreshGlobalExhibition();
        showSuccess("Die Änderung wurde gespeichert.");
      },
    });
  };

  const handleDelete = (organization: Organization) => {
    deleteWithConfirmation(
      `Verein "${organization.organizationName}" löschen?`,

      () =>
        sendJson({
          path: `exhibitions/${props.exhibition.exhibitionId}/organizations/${organization.organizationId}`,
          method: "DELETE",
          onSuccess: () => {
            refresh();
            refreshGlobalExhibition();
            showSuccess("Der Verein wurde gelöscht.");
          },
        })
    );
  };

  return (
    <Page title={props.exhibition.exhibitionName} subtitle={t("organizations")}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <EditList
          toolbarItems={
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                disabled={editState !== "NotEditing"}
                onClick={handleAddNew}
              >
                {t("newOrganization")}
              </Button>
            </>
          }
        >
          <EditTable
            showHeader
            editState={tableEditState}
            onCancel={stopEditing}
            onEditExisting={handleEditExisting}
            onSaveNew={handleSaveNew}
            onSaveExisting={handleSaveExisting}
            onDelete={handleDelete}
            rowKey={(organization) => organization.organizationId}
            data={listedOrganizations || []}
            columns={columns.map((c) => c.tableColumn)}
            saving={saving}
          />
        </EditList>
      )}

      {alertSnackbar}
      <ErrorSnackbar
        message={(modifyError || error)?.friendlyMessage ?? ""}
        open={!!modifyError || !!error}
      />
      {deleteConfirmation}
    </Page>
  );
};

export default OrganizationsPage;
