import { Route } from "react-router-dom";
import React from "react";
import Page, { MemberImportPageExhibition } from "./MemberImportPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
import { ExhibitionId } from "../types";

export const memberImportPageRoute = (
  pathPrefix: string,
  exhibition: MemberImportPageExhibition
) => (
  <Route path={`${pathPrefix}/importmembers`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const memberImportPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/importmembers/`;

export const LinkToMemberImport: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={memberImportPagePath(exhibitionId)} />
);
