import React from "react";

const trueFn = () => true;

interface ColumnConfig<TItem, R, T> {
  defaultValue?: T;
  extractValue(item: TItem): T;
  convert(raw: R): T;
  convertBack(value: T | undefined): R;
  validate?: (value: T | undefined) => boolean;
  header: React.ReactNode;
  columnKey: string | number | undefined;
  view(item: TItem): React.ReactNode;
  disableUpdate?: boolean;
  editorFactory(editorProps: {
    value: R;
    onChange: (newValue: R) => void;
    error: boolean;
  }): React.ReactNode;
}

export function useColumn<TItem, R, T>(config: ColumnConfig<TItem, R, T>) {
  const [omitError, setOmitError] = React.useState(true);
  const [value, setValue] = React.useState<T>();

  const onChange = (newValue: R) => {
    setOmitError(false);
    setValue(config.convert(newValue));
  };
  const validate = config.validate || trueFn;
  return {
    grabValue: (item: TItem) => {
      setValue(config.extractValue(item));
    },
    resetToDefault: () => setValue(config.defaultValue),
    setValue,
    value,
    setOmitError,
    valid: validate(value),
    tableColumn: {
      header: config.header,
      columnKey: config.columnKey,
      disableUpdate: config.disableUpdate,
      view: config.view,
      editor: config.editorFactory({
        value: config.convertBack(value),
        onChange,
        error: !omitError && !validate(value),
      }),
    },
  };
}
