import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import PaperPage from "../../../crossCutting/pages/PaperPage";
import { useTranslation } from "../../../i18n/i18n";
import { LinkToRegistrationsPage } from "../registrationsPage/routing";
import { Exhibition } from "../types";

export type RegistrationDeletedExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface RegistrationDeletedPageProps {
  exhibition: RegistrationDeletedExhibition;
}

const RegistrationDeletedPage: React.FunctionComponent<RegistrationDeletedPageProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <PaperPage title={props.exhibition.exhibitionName} maxWidth="sm">
      <Typography variant="body1">{t("registrationHasBeenDeleted")}</Typography>
      <Box marginTop={spacing3}>
        <LinkToRegistrationsPage
          exhibitionId={props.exhibition.exhibitionId}
          variant="body1"
        >
          {t("goToRegistrations")}
        </LinkToRegistrationsPage>
      </Box>
    </PaperPage>
  );
};

export default RegistrationDeletedPage;
