import * as React from "react";
import { ExhibitionId } from "./types";
import { SelectorProps } from "../../crossCutting/selectorProps";
import { Member } from "./apiTypes";
import { useFetchClient } from "../../fetching/fetchProvider";
import AutocompletePicker from "../../crossCutting/AutocompletePicker";
import { AddIcon } from "../../crossCutting/icons";
import { formatName } from "../format";
import { useTranslation } from "../../i18n/i18n";

function MemberPicker(
  props: SelectorProps<Member> & {
    exhibitionId: ExhibitionId;
    variant?: "text" | "outlined" | "contained";
    color?: "primary" | "secondary" | "inherit";
    text?: string;
  }
) {
  const fetcher = useFetchClient();
  const search = React.useCallback(
    (searchText: string) =>
      fetcher(
        `exhibitions/${props.exhibitionId}/members?search=${encodeURIComponent(
          searchText
        )}`,
        { method: "GET" }
      ).then(({ members }) => members) as Promise<Member[]>,
    [fetcher, props.exhibitionId]
  );
  const { t } = useTranslation();

  return (
    <AutocompletePicker
      value={props.value}
      onChange={props.onChange as any}
      comparer={sameMember}
      fetchOptions={search}
      buttonLabel={props.text ?? t("registerSomeone")}
      getOptionLabel={(o) => formatName(o) + (o.city ? `, ${o.city}` : "")}
      noOptionsText={t("noMembersFound")}
      startIcon={<AddIcon />}
      placeholder={t("memberSearchboxPlaceholder")}
      variant={props.variant}
      color={props.color}
    />
  );
}
export default MemberPicker;

function sameMember(a: { memberId: string }, b: { memberId: string }) {
  return a.memberId === b.memberId;
}
