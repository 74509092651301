import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { State } from "../../../../crossCutting/hooks/usePromiseStates";
import {
  CollapseIcon,
  DeleteIcon,
  ExpandIcon,
} from "../../../../crossCutting/icons";
import { spacing3 } from "../../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../../crossCutting/LoadingIndicator";
import { byString } from "../../../../crossCutting/sorting";
import { useTranslation } from "../../../../i18n/i18n";
import { useTranslatorForUser } from "../../../masterdata/translation";
import { Organization } from "../../apiTypes";
import OrganizationPicker from "../../organization/OrganizationPicker";
import { ExhibitionId } from "../../types";
import BreedPicker, { Breed } from "./BreedPicker";
import { sameOrga } from "./organization";

export interface OrganizationListProps {
  exhibitionId: ExhibitionId;
  value: OrganizationConfig[];
  onChange: (value: OrganizationConfig[]) => void;
  allOrganizations: Organization[];
  allBreedsState: State<Breed[]>;
  animalStandardIds: string[];
}

export interface OrganizationConfig {
  organizationId: string;
  breedIds: string[];
}

const useStyles = makeStyles((theme) => ({
  emptyText: {
    marginTop: theme.spacing(spacing3),
  },
}));

function OrganizationList(props: OrganizationListProps) {
  const remove = (o: { organizationId: string }) => {
    props.onChange(props.value.filter((e) => !sameOrga(o, e)));
  };
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <OrganizationPicker
        value={props.allOrganizations.filter((o) =>
          props.value.some((vo) => vo.organizationId === o.organizationId)
        )}
        onChange={(c) =>
          props.onChange(
            c.map((cc) => ({
              ...cc,
              breedIds:
                props.value.find(
                  (pv) => pv.organizationId === cc.organizationId
                )?.breedIds ?? [],
            }))
          )
        }
        exhibitionId={props.exhibitionId}
        buttonLabel={t("addOrganization")}
      />
      {props.value.length === 0 ? (
        <Typography className={classes.emptyText}>
          {t("noOrganizationsSelected")}
        </Typography>
      ) : (
        <List disablePadding component="div">
          {props.allOrganizations
            .map((ao) => ({
              ...ao,
              config: props.value.find(
                (pv) => ao.organizationId === pv.organizationId
              ),
            }))
            .filter((ao) => ao.config)
            .sort(byString((o) => o.organizationName))
            .map((o: any) => (
              <OrgaItem
                key={o.organizationId}
                organization={o}
                onDelete={() => remove(o)}
                exhibitionId={props.exhibitionId}
                animalStandardIds={props.animalStandardIds}
                allBreeds={props.allBreedsState}
                onBreedsChanged={(breedIds) =>
                  props.onChange(
                    props.value.map((io) =>
                      o.organizationId === io.organizationId
                        ? { ...o, breedIds }
                        : io
                    )
                  )
                }
              />
            ))}
        </List>
      )}
    </>
  );
}

const useOrgaItemStyles = makeStyles((theme) => ({
  insideCollapse: {
    marginLeft: theme.spacing(spacing3),
  },
  divider: {
    marginTop: theme.spacing(spacing3),
  },
}));
function OrgaItem(props: {
  organization: Organization & { config: OrganizationConfig };
  onDelete: () => void;
  exhibitionId: ExhibitionId;
  animalStandardIds: string[];
  allBreeds: State<Breed[]>;
  onBreedsChanged: (breedIds: string[]) => void;
}) {
  const classes = useOrgaItemStyles();
  const o = props.organization;
  const { t } = useTranslation();
  const masterDataTranslator = useTranslatorForUser();
  const [open, setOpen] = React.useState(false);
  const handleBreedsSelected = (breedIds: string[]) => {
    props.onBreedsChanged(
      o.config.breedIds.filter((id) => !breedIds.includes(id)).concat(breedIds)
    );
  };
  const allBreeds = props.allBreeds.isSuccess() ? props.allBreeds.data : [];
  return (
    <>
      <ListItem
        button
        key={o.organizationId}
        disableGutters
        onClick={() => setOpen(!open)}
        ContainerComponent="div"
      >
        {open ? <ExpandIcon /> : <CollapseIcon />}
        <ListItemText>{o.organizationName}</ListItemText>
        <ListItemSecondaryAction>
          <Tooltip title={t("delete")}>
            <IconButton edge="end" aria-label="delete" onClick={props.onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.insideCollapse}>
          <Typography variant="h6" component="h4">
            {t("breeds")}
          </Typography>
          {props.allBreeds.isRunning() ? (
            <LoadingIndicator />
          ) : (
            <>
              <BreedPicker
                exhibitionId={props.exhibitionId}
                animalStandardIds={props.animalStandardIds}
                onSelect={handleBreedsSelected}
                breedIdsToExclude={props.organization.config.breedIds}
              />
              <List disablePadding>
                {o.config.breedIds
                  .map((breedId) =>
                    allBreeds.find((b) => b.breedId === breedId)
                  )
                  .filter((b) => b !== undefined)
                  .map((b: any) => (
                    <ListItem disableGutters>
                      <ListItemText>
                        {(masterDataTranslator(b.captions) as any)?.breedName}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Tooltip title={t("delete")}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              props.onBreedsChanged(
                                o.config.breedIds.filter(
                                  (id) => id !== b.breedId
                                )
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </>
          )}
        </div>
        <Divider className={classes.divider} />
      </Collapse>
    </>
  );
}

export default OrganizationList;
