import * as React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { ExhibitionId } from "../../exhibitions/types";
import {
  Node,
  CreateNodeInput,
  UpdateNodeInput,
  ChangeNodeOrderInput,
} from "./nodeTypes";
import { Maybe } from "../../../crossCutting/maybe";
import { makeUrl } from "../../../crossCutting/urls";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useNodes(
  animalStandardId: string,
  exhibitionId?: ExhibitionId
) {
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [nodes, setNodes] = React.useState([] as Node[]);
  const refresh = React.useCallback(() => {
    setLoading(true);
    return fetcher(
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/nodes`,
      {
        method: "GET",
      }
    )
      .then(({ nodes }) => setNodes(nodes))
      .finally(() => setLoading(false));
  }, [animalStandardId, urlPrefix, fetcher]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then(() =>
          refresh().then(() => {
            if (messages?.success) {
              showAlert(messages?.success, "success");
            }
          })
        )
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createNode = updater<CreateNodeInput>(
    () => `${urlPrefix}masterdata/animalstandards/${animalStandardId}/nodes`,
    POST,
    {
      error: "Fehler beim Speichern des Elements.",
      success: "Das Element wurde erstellt.",
    },
    (data) => data
  );

  const changeNodeOrder = updater<
    ChangeNodeOrderInput & { parentNodeId: Maybe<string> }
  >(
    (data) =>
      makeUrl`${urlPrefix}/masterdata/animalstandards/${animalStandardId}/nodes/${data.parentNodeId}/changeChildrenOrder`,
    PUT,
    { error: "Fehler beim Sortieren der Elemente." },
    (data) => ({
      nodeIds: data.nodeIds,
    })
  );

  const updateNode = updater<UpdateNodeInput & { nodeId: string }>(
    (data) =>
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/nodes/${data.nodeId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren des Elements.",
      success: "Die Änderungen wurden gespeichert.",
    },
    (data) => ({
      captions: data.captions,
    })
  );

  return {
    nodes,
    refresh,
    loading,
    createNode,
    updateNode,
    changeNodeOrder,
  };
}
