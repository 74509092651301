type Accessor<S, T> = (s: S) => T;
type SortFn<T> = (a: T, b: T) => number;
type StringExtractor<S> = Accessor<S, string>;
type NumberExtractor<S> = Accessor<S, number>;

function sorter<S, T>(accessor: Accessor<S, T>, sortFn: SortFn<T>) {
  return (a: S, b: S) => sortFn(accessor(a), accessor(b));
}

export function byString<S>(accessor: StringExtractor<S>) {
  return sorter(accessor, (a, b) => String.prototype.localeCompare.call(a, b));
}

export function byNumber<S>(accessor: NumberExtractor<S>) {
  return sorter(accessor, (a, b) => a - b);
}
