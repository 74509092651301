import * as React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { ExhibitionId } from "../../exhibitions/types";
import {
  BoxType,
  CreateBoxTypeInput,
  UpdateBoxTypeInput,
} from "./boxTypeTypes";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useBoxTypes(exhibitionId?: ExhibitionId) {
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [boxTypes, setBoxTypes] = React.useState([] as BoxType[]);
  const refresh = React.useCallback(() => {
    setLoading(true);
    return fetcher(`${urlPrefix}masterdata/boxtypes`, {
      method: "GET",
    })
      .then(({ boxTypes }) => setBoxTypes(boxTypes))
      .finally(() => setLoading(false));
  }, [urlPrefix, fetcher]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then(() =>
          refresh().then(() => {
            if (messages?.success) {
              showAlert(messages?.success, "success");
            }
          })
        )
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createBoxType = updater<CreateBoxTypeInput>(
    () => `${urlPrefix}masterdata/boxtypes`,
    POST,
    {
      error: "Fehler beim Speichern des Boxentypen.",
      success: "Der Boxentyp wurde erstellt.",
    },
    (data) => data
  );

  const updateBoxType = updater<UpdateBoxTypeInput & { boxTypeId: string }>(
    (data) => `${urlPrefix}masterdata/boxtypes/${data.boxTypeId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren des Boxentypen.",
      success: "Die Änderungen wurden gespeichert.",
    },
    ({ boxTypeId, ...rest }) => rest
  );

  return {
    boxTypes,
    refresh,
    loading,
    createBoxType,
    updateBoxType,
  };
}
