import * as React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { getAncestry, Node } from "./nodes";
import { useTranslatorForUser } from "./translation";

export default function NodeSelector(props: NodeSelectorProps) {
  const depth = props.depth ?? 0;

  const handleChildChange = (nodeId: string) => props.onChange(nodeId);
  const ancestry =
    props.selectedNodeId && props.nodes
      ? getAncestry(props.nodes, props.selectedNodeId)
      : undefined;
  const [selectedNodeId, setSelectedNodeId] = React.useState(
    ancestry?.[0]?.nodeId || ""
  );
  const selectedNode = selectedNodeId
    ? props.nodes.find((n) => n.nodeId === selectedNodeId)
    : undefined;

  const handleParentChange = (e: any) => {
    const selectedNode = props.nodes.find((n) => n.nodeId === e.target.value);
    setSelectedNodeId(selectedNode?.nodeId || "");
    if (selectedNode && !selectedNode.children?.length) {
      props.onChange(e.target.value);
    } else {
      props.onChange("");
    }
  };
  const oneLevelProps = {
    ...props,
    depth,
    selectedNodeId,
    onChange: handleParentChange,
  };
  const SelectContainer = props.selectContainer || React.Fragment;
  return (
    <>
      <SelectContainer {...props.selectContainerProps}>
        <OneLevel {...oneLevelProps} />
      </SelectContainer>
      {!!selectedNode?.children?.length && (
        <NodeSelector
          fullWidth={props.fullWidth}
          selectedNodeId={props.selectedNodeId}
          levelNames={props.levelNames?.slice(1)}
          selectContainer={props.selectContainer}
          selectContainerProps={props.selectContainerProps}
          nodes={selectedNode.children}
          depth={depth}
          onChange={handleChildChange}
        />
      )}
    </>
  );
}

function OneLevel(
  props: Pick<NodeSelectorProps, "depth" | "levelNames" | "nodes"> & {
    depth: number;
    selectedNodeId?: string;
    onChange: (e: any) => void;
  }
) {
  const labelId = `node-selector-label-${props.depth}`;
  const translator = useTranslatorForUser();
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{props.levelNames?.[props.depth]}</InputLabel>
      <Select
        labelId={labelId}
        fullWidth
        value={props.selectedNodeId || ""}
        onChange={props.onChange}
      >
        {props.nodes.map((n) => (
          <MenuItem key={n.nodeId} value={n.nodeId}>
            {translator(n.captions)?.nodeName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface NodeSelectorProps {
  nodes: Node[];
  selectedNodeId: string;
  onChange: (selectedNodeId: string) => void;
  fullWidth?: boolean;
  depth?: number;
  levelNames?: string[];
  selectContainer?: any;
  selectContainerProps?: any;
}
