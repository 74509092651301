import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { friendlyDateTime } from "../../../crossCutting/date";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import PaperPage from "../../../crossCutting/pages/PaperPage";
import { useSendJson } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { useMasterDataLock } from "../apiClient/lock";

interface MasterDataLockDto {
  lockedByUserName: string;
  isLockedByCurrentUser: boolean;
  lockedUtc: string;
}

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

const LockPage: React.FunctionComponent = () => {
  const { sendJson, loading } = useSendJson();
  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  const { masterDataLockInfo, refresh: refreshLockState } = useMasterDataLock();

  const { t } = useTranslation();

  const lockStatus =
    masterDataLockInfo === "NotLocked"
      ? "NotLocked"
      : masterDataLockInfo.isLockedByCurrentUser
      ? "LockedByCurrentUser"
      : masterDataLockInfo;

  const handleLock = async () => {
    sendJson({
      path: "masterdata/lock",
      onSuccess: () => {
        refreshLockState();
        showAlertSnackbar(t("globalMasterDataOnlyEditableByYou"), "success");
      },
    });
  };

  const handlePublish = async () => {
    sendJson({
      path: "masterdata/publish",
      onSuccess: () => {
        refreshLockState();
        showAlertSnackbar(t("yourChangesHaveBeenPublishedUnlocked"), "success");
      },
    });
  };

  const handleDiscard = async () => {
    sendJson({
      path: "masterdata/discard",
      onSuccess: () => {
        refreshLockState();
        showAlertSnackbar(t("yourChangesHaveBeenDiscardedUnlocked"), "success");
      },
    });
  };

  const handleUnlock = async () => {
    sendJson({
      path: "masterdata/unlock",
      onSuccess: () => {
        refreshLockState();
        showAlertSnackbar(t("unlockedMessage"), "success");
      },
    });
  };

  return (
    <PaperPage
      title={t("lock")}
      subtitle={t("lockGlobalMasterDataChanges")}
      maxWidth="sm"
    >
      {lockStatus === "NotLocked" ? (
        <NotLockedView onLock={handleLock} loading={loading} />
      ) : lockStatus === "LockedByCurrentUser" ? (
        <LockedByCurrentUserView
          onPublish={handlePublish}
          onDiscard={handleDiscard}
          onUnlock={handleUnlock}
          loading={loading}
        />
      ) : (
        <LockedBySomeoneElseView lockDto={lockStatus} />
      )}
      {alertSnackbar}
    </PaperPage>
  );
};

export default LockPage;

const NotLockedView: React.FunctionComponent<{
  onLock: () => void;
  loading: boolean;
}> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" component="h3">
        {t("statusNotLocked")}
      </Typography>
      <Typography className={classes.marginBottom}>
        {t("statusNotLockedHint")}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.onLock}
        disabled={props.loading}
      >
        {t("lockToStartChanges")}
      </Button>
    </Box>
  );
};

const LockedBySomeoneElseView: React.FunctionComponent<{
  lockDto: MasterDataLockDto;
}> = ({ lockDto }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>
        {t("statusLockedBy", {
          lockedByUserName: lockDto.lockedByUserName,
          dateTime: friendlyDateTime(new Date(lockDto.lockedUtc)),
        })}
      </Typography>
    </Box>
  );
};

const LockedByCurrentUserView: React.FunctionComponent<{
  onPublish: () => void;
  onDiscard: () => void;
  onUnlock: () => void;
  loading: boolean;
}> = (props) => {
  const [advancedOpen, setAdvancedOpen] = React.useState(false);
  const classes = useStyles();
  const handleAdvancedOpen = () => {
    setAdvancedOpen(true);
  };
  const { t } = useTranslation();
  return (
    <>
      <Box marginBottom={4}>
        <Typography variant="h6" component="h3">
          {t("statusLockedByYou")}
        </Typography>
        <Typography className={classes.marginBottom}>
          {t("statusLockedByYouHint")}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={props.onPublish}
          disabled={props.loading}
        >
          {t("publishChanges")}
        </Button>
      </Box>
      <Box>
        {!advancedOpen ? (
          <Button variant="text" onClick={handleAdvancedOpen} color="primary">
            {t("advanced")}
          </Button>
        ) : null}
        <Collapse in={advancedOpen}>
          <Box marginBottom={4}>
            <Typography variant="h6" component="h3">
              {t("discardChanges")}
            </Typography>
            <Typography className={classes.marginBottom}>
              {t("discardHint")}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={props.onDiscard}
              disabled={props.loading}
            >
              {t("discardChanges")}
            </Button>
          </Box>
          <Box>
            <Typography variant="h6" component="h3">
              {t("unlock")}
            </Typography>
            <Typography className={classes.marginBottom}>
              {t("unlockHint")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onUnlock}
              disabled={props.loading}
            >
              {t("unlock")}
            </Button>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
