import { Maybe } from "../../../crossCutting/maybe";

export function sameRoom<T extends { roomId: string }>(
  a: Maybe<T>,
  b: Maybe<T>
) {
  return (a === b && (!!a || !!b)) || a?.roomId === b?.roomId;
}

export interface Room {
  roomId: string;
  roomName: string;
}
