import * as React from "react";
import { useHistory } from "react-router-dom";
import MemberPicker from "./MemberPicker";
import { registrationNewPagePath } from "./registrationEditPage/routing";

export interface PickMemberAndRegisterProps {
  exhibitionId: string;
  variant?: "text" | "contained" | "outlined";
  color?: "primary" | "secondary";
  text?: string;
}

const PickMemberAndRegister: React.FunctionComponent<PickMemberAndRegisterProps> = (
  props
) => {
  const history = useHistory();
  const handleChange = (member: { memberId: string }) => {
    history.push(registrationNewPagePath(props.exhibitionId, member.memberId));
  };
  return (
    <MemberPicker
      exhibitionId={props.exhibitionId}
      onChange={handleChange}
      value={null as any}
      variant={props.variant}
      color={props.color}
      text={props.text}
    />
  );
};

export default PickMemberAndRegister;
