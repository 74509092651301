import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { exhibitionPagePath } from "../exhibitionPage/routing";
import JumpToButton from "../../../crossCutting/JumpToButton";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { Exhibition } from "../types";
import { useTranslation } from "../../../i18n/i18n";

const ExhibitionTable: React.FunctionComponent<ExhibitionTableProps> = ({
  exhibitions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { rowClass, HoverActionCell } = useHoverActionCell();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell>{t("city")}</TableCell>
          <TableCell>{t("year")}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exhibitions.map((e) => (
          <TableRow
            className={rowClass}
            key={e.exhibitionId}
            hover
            onClick={() => history.push(exhibitionPagePath(e.exhibitionId))}
          >
            <TableCell>{e.exhibitionName}</TableCell>
            <TableCell>{e.city}</TableCell>
            <TableCell>{e.startDate.getFullYear()}</TableCell>
            <HoverActionCell>
              <JumpToButton>{t("goTo")}</JumpToButton>
            </HoverActionCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ExhibitionTable;

export type ExhibitionInList = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName" | "city" | "startDate"
>;
interface ExhibitionTableProps {
  exhibitions: ExhibitionInList[];
}
