import { Button } from "@material-ui/core";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import EditTable from "../../../crossCutting/editTable/EditTable";
import InlineEditTextField from "../../../crossCutting/editTable/InlineEditTextField";
import { useColumn } from "../../../crossCutting/editTable/useColumn";
import useEdit, {
  EditExistingState,
} from "../../../crossCutting/editTable/useEdit";
import validation from "../../../crossCutting/editTable/validation";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import useDeleter from "../../../crossCutting/hooks/useDeleter";
import { AddIcon } from "../../../crossCutting/icons";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import Page from "../../../crossCutting/pages/Page";
import { ErrorSnackbar } from "../../../crossCutting/Snackbars";
import { useFetch, useSendJson } from "../../../fetching/fetchProvider";
import { Exhibition } from "../apiTypes";
import { Room, sameRoom } from "./room";
import { useRefreshExhibition } from "../ExhibitionContext";

export type RoomsExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface RoomsPageProps {
  exhibition: RoomsExhibition;
}

const RoomsPage: React.FunctionComponent<RoomsPageProps> = (props) => {
  const { data, loading, error, refresh } = useFetch<{
    rooms: Room[];
  }>(`exhibitions/${props.exhibition.exhibitionId}/rooms`);
  const listedRooms = data?.rooms;

  const { sendJson, loading: saving, error: modifyError } = useSendJson();

  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  const showError = (message: string) => showAlertSnackbar(message, "error");
  const showSuccess = (message: string) =>
    showAlertSnackbar(message, "success");

  const nameColumn = useColumn<Room, string, string>({
    header: "Name",
    columnKey: "name",
    defaultValue: "",
    extractValue: (room) => room.roomName,
    view: (room) => room.roomName,
    convert: (raw) => raw,
    convertBack: (value) => value || "",
    validate: (value) => !!value,
    editorFactory: (editorProps) => (
      <InlineEditTextField {...editorProps} autoSelect />
    ),
  });

  const columns = [nameColumn];

  const { omitValidationErrors, validate } = validation(columns, showError);

  const { deleteConfirmation, deleteWithConfirmation } = useDeleter();

  const {
    handleAddNew,
    handleEditExisting,
    stopEditing,
    editState,
    tableEditState,
  } = useEdit(columns, omitValidationErrors, sameRoom);

  const refreshGlobalExhibition = useRefreshExhibition();
  const handleSaveNew = () => {
    if (!validate()) return;

    sendJson({
      path: `exhibitions/${props.exhibition.exhibitionId}/rooms`,
      body: {
        roomName: nameColumn.value,
      },
      onSuccess: () => {
        stopEditing();
        refresh();
        refreshGlobalExhibition();
        showSuccess("Der Ausstellungsraum wurde erstellt.");
      },
    });
  };

  const handleSaveExisting = () => {
    if (!validate()) return;

    const changedRoom = listedRooms?.find((c) =>
      sameRoom(c, (editState as EditExistingState<Room>).editedItem)
    );
    if (!changedRoom) {
      stopEditing();
      return;
    }

    sendJson({
      path: `exhibitions/${props.exhibition.exhibitionId}/rooms/${changedRoom.roomId}`,
      method: "PUT",
      body: {
        roomName: nameColumn.value,
      },
      onSuccess: () => {
        stopEditing();
        refresh();
        refreshGlobalExhibition();
        showSuccess("Die Änderung wurde gespeichert.");
      },
    });
  };

  const handleDelete = (room: Room) => {
    deleteWithConfirmation(
      `Ausstellungsraum "${room.roomName}" löschen?`,

      () =>
        sendJson({
          path: `exhibitions/${props.exhibition.exhibitionId}/rooms/${room.roomId}`,
          method: "DELETE",
          onSuccess: () => {
            refresh();
            refreshGlobalExhibition();
            showSuccess("Der Ausstellungsraum wurde gelöscht.");
          },
        })
    );
  };

  return (
    <Page title={props.exhibition.exhibitionName} subtitle="Ausstellungsräume">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <EditList
          toolbarItems={
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                disabled={editState !== "NotEditing"}
                onClick={handleAddNew}
              >
                Neuer Ausstellungsraum
              </Button>
            </>
          }
        >
          <EditTable
            showHeader
            editState={tableEditState}
            onCancel={stopEditing}
            onEditExisting={handleEditExisting}
            onSaveNew={handleSaveNew}
            onSaveExisting={handleSaveExisting}
            onDelete={handleDelete}
            rowKey={(room) => room.roomId}
            data={listedRooms || []}
            columns={columns.map((c) => c.tableColumn)}
            saving={saving}
          />
        </EditList>
      )}

      {alertSnackbar}
      <ErrorSnackbar
        message={(modifyError || error)?.friendlyMessage ?? ""}
        open={!!modifyError || !!error}
      />
      {deleteConfirmation}
    </Page>
  );
};

export default RoomsPage;
