import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import * as React from "react";
import { formatDate, formatInterval } from "../../../crossCutting/date";
import GridCard from "../../../crossCutting/GridCard";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import Page from "../../../crossCutting/pages/Page";
import Pie from "../../../crossCutting/charts/Pie";
import ChartLegend from "../../../crossCutting/charts/ChartLegend";
import { ErrorSnackbar } from "../../../crossCutting/Snackbars";
import {
  useFetch,
  useSendJson,
  useFetch2,
} from "../../../fetching/fetchProvider";
import { einBlau, kleintiereGelb } from "../../../theme";
import PickMemberAndRegister from "../PickMemberAndRegister";
import { LinkToRegistrationsPage } from "../registrationsPage/routing";
import { Exhibition } from "../types";
import OrganizerChecklist from "./OrganizerChecklist";
import RegistrationList from "./RegistrationList";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { ForRoles } from "../../../authorization/RolesContext";
import AppRole from "../../../authorization/appRoles";
import { useTranslation } from "../../../i18n/i18n";
import { LinkToPublicationsPage } from "../publicationsPage/routing";
import { Checklist, checklistApiPath } from "../../api/exhibitions/checklist";
import { publishExhibitionApiPath } from "../../api/exhibitions/publication";
import { IfCanRegisterOthersInContext } from "../IfCanRegisterInContext";

export interface ExhibitionPageProps {
  exhibition: Exhibition;
}

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: theme.spacing(spacing3),
  },
  cardActionsPadding: {
    paddingLeft: theme.spacing(2),
  },
  statisticsCell: {
    display: "flex",
    flexDirection: "row",
  },
  pieLegend: {
    marginLeft: theme.spacing(spacing3),
  },
}));

const ExhibitionPage: React.FunctionComponent<ExhibitionPageProps> = ({
  exhibition,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: checklist, refresh: refreshChecklist } = useFetch<Checklist>(
    checklistApiPath(exhibition.exhibitionId)
  );
  const {
    sendJson: publishRemotely,
    error: publishError,
    loading: publishing,
  } = useSendJson();
  const handlePublish = () => {
    publishRemotely({
      path: publishExhibitionApiPath(exhibition.exhibitionId),
      method: "POST",
      onSuccess: () => {
        refreshChecklist();
        showPublishSuccessSnackbar(t("publishSuccess"), "success");
      },
    });
  };
  const [statistics] = useFetch2<{
    memberRegistrationStatistics: { category: string; count: number }[];
  }>(`exhibitions/${exhibition.exhibitionId}/statistics`);

  const slices = mapState(statistics, (s) => {
    const sum = s.memberRegistrationStatistics.reduce((s, i) => s + i.count, 0);
    if (sum === 0) return [];
    return s.memberRegistrationStatistics.map((i) => ({
      percent: i.count / sum,
      color: i.category === "HasRegistrations" ? kleintiereGelb : einBlau,
      label:
        i.category === "HasRegistrations"
          ? t("registered")
          : t("notRegistered"),
    }));
  });

  const {
    alertSnackbar: publishSuccessSnackbar,
    showAlertSnackbar: showPublishSuccessSnackbar,
  } = useAlertSnackbar();

  return (
    <Page
      title={exhibition.exhibitionName}
      subtitle={`${formatInterval(exhibition.startDate, exhibition.endDate)}, ${
        exhibition.city
      }`}
    >
      <Grid className={classes.grid} container spacing={spacing3}>
        <ForRoles roles={[AppRole.ExhibitionAdmin, "Organizer"]}>
          <GridCard xs={12} lg={8}>
            <CardHeader title={t("checklist")} />
            <CardContent>
              {checklist && (
                <OrganizerChecklist
                  exhibitionId={exhibition.exhibitionId}
                  membersDone={checklist.membersDone}
                  competitionsDone={checklist.competitionsDone}
                  publication={checklist.publication}
                  onPublish={handlePublish}
                  publishing={publishing}
                />
              )}
            </CardContent>
          </GridCard>
        </ForRoles>
        <ForRoles roles={["Member"]}>
          <GridCard xs={12} sm={6} lg={4}>
            <CardHeader title={t("yourRegistrations")} />
            <CardContent>
              <Box>
                <Typography variant="body1">
                  {`${t("deadLine")}: ${formatDate(exhibition.deadline)}${
                    exhibition.withinDeadline ? "" : ` (${t("expired")})`
                  }`}
                </Typography>
              </Box>
              <RegistrationList exhibitionId={exhibition.exhibitionId} />
            </CardContent>
          </GridCard>
        </ForRoles>
        <ForRoles exclude={"Member"}>
          <GridCard xs={12} sm={6} lg={4}>
            <CardHeader title={t("yourRegistrations")} />
            <CardContent>
              <Typography variant="body1" color="error">
                {t("registrationBlockedForMember")}
              </Typography>
            </CardContent>
          </GridCard>
        </ForRoles>
        <ForRoles roles={[AppRole.ExhibitionAdmin, "Organizer"]}>
          {slices.isSuccess() && slices.data.length ? (
            <GridCard xs={12} sm={6} lg={4}>
              <CardHeader title={t("registrationStatistics")} />
              <CardContent className={classes.statisticsCell}>
                <Pie slices={slices.data} />
                <ChartLegend
                  slices={slices.data}
                  className={classes.pieLegend}
                />
              </CardContent>
            </GridCard>
          ) : null}
        </ForRoles>

        <ForRoles roles="Member" exclude="Organizer">
          <GridCard xs={12} sm={6} lg={4}>
            <CardHeader title={t("registrationsForOthers")} />
            <CardContent>
              <IfCanRegisterOthersInContext>
                <PickMemberAndRegister
                  exhibitionId={exhibition.exhibitionId}
                  variant="outlined"
                  color="primary"
                />
              </IfCanRegisterOthersInContext>
            </CardContent>
            <CardActions>
              <LinkToRegistrationsPage
                exhibitionId={exhibition.exhibitionId}
                button
                color="primary"
              >
                {t("goToTheRegistrations")}
              </LinkToRegistrationsPage>
            </CardActions>
          </GridCard>
        </ForRoles>
        <ForRoles roles={[AppRole.ExhibitionAdmin, "Organizer"]}>
          <GridCard xs={12} sm={6} lg={4}>
            <CardHeader title={t("catalog")} />
            <CardActions>
              <LinkToPublicationsPage
                exhibitionId={exhibition.exhibitionId}
                button
                variant="text"
                color="primary"
              >
                {t("catalogPublication")}
              </LinkToPublicationsPage>
            </CardActions>
          </GridCard>
        </ForRoles>
        <ForRoles roles={["Organizer"]}>
          <GridCard xs={12} sm={6} lg={4}>
            <CardHeader title={t("downloadKlexpo")} />
            <CardContent>
              <Typography variant="body1">
                {t("downloadKlexpoLongText")}
              </Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" href="/download">
                {t("download")}
              </Button>
            </CardActions>
          </GridCard>{" "}
        </ForRoles>
      </Grid>
      {publishSuccessSnackbar}
      <ErrorSnackbar open={!!publishError} message={t("publishError")} />
    </Page>
  );
};

export default ExhibitionPage;
