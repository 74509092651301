import * as React from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import { spacing3 } from "../layoutConstants";
import clsx from "clsx";

export interface PageProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  noscroll?: boolean;
}
const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexShrink: 0,
  },
  subtitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexShrink: 0,
  },
  container: {
    paddingTop: theme.spacing(spacing3),
    paddingBottom: theme.spacing(spacing3),
  },
  scroll: {
    overflowY: "auto",
    overflowX: "hidden",
  },
}));
const Page: React.FunctionComponent<PageProps> = (props) => {
  const classes = useStyles();

  React.useEffect(() => {
    const titles = [props.title, props.subtitle].filter((x) => x);
    document.title = titles.length === 0 ? "Klexpo.ch" : titles.join(" - ");
  }, [props.title, props.subtitle]);

  return (
    // outer div is needed to have the scrollbar of <Container> at the window's
    // right edge
    <WrapperIfClass className={clsx(!props.noscroll && classes.scroll)}>
      <Container
        className={clsx(
          classes.container,
          props.className,
          !props.noscroll && classes.scroll
        )}
        maxWidth={props.maxWidth}
      >
        {props.title ? (
          <Typography variant="h3" component="h2" className={classes.title}>
            {props.title}
          </Typography>
        ) : null}
        {props.subtitle ? (
          <Typography variant="h5" component="p" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
        ) : null}
        {React.Children.toArray(props.children)}
      </Container>
    </WrapperIfClass>
  );
};

export default Page;

function WrapperIfClass(props: React.PropsWithChildren<{ className: string }>) {
  return props.className ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <>{props.children}</>
  );
}
