import React from "react";
import { Route } from "react-router-dom";
import { uuidRegex } from "../../../crossCutting/uuid";
import { TranslationFunc } from "../../../i18n/i18n";
import { useUnitSave } from "../apiClient/useUnits";
import Page from "./UnitEditPage";

export const globalUnitCreatePageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => {
  return (
    <Route
      path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/units/new`}
    >
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          getUnitPath: (animalStandardId: string, unitId: string) =>
            `masterdata/animalstandards/${animalStandardId}/units/${unitId}`,
          getAnimalStandardPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}`,
          makeUnitSaveDataHook: () => (animalStandardId: string) =>
            useUnitSave(animalStandardId),
          editUnitPath: (animalStandardId: string, unitId: string) =>
            globalUnitEditPagePath(animalStandardId, unitId),
        }}
      />
    </Route>
  );
};

export const globalUnitEditPageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => {
  return (
    <Route
      path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/units/:unitId(${uuidRegex})/edit`}
      exact
    >
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          getUnitPath: (animalStandardId: string, unitId: string) =>
            `masterdata/animalstandards/${animalStandardId}/units/${unitId}`,
          getAnimalStandardPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}`,
          makeUnitSaveDataHook: () => (animalStandardId: string) =>
            useUnitSave(animalStandardId),
          editUnitPath: (animalStandardId: string, unitId: string) =>
            globalUnitEditPagePath(animalStandardId, unitId),
        }}
      />
    </Route>
  );
};

export const globalUnitCreatePagePath = (animalStandardId: string) =>
  `/masterdata/standards/${animalStandardId}/units/new`;

export const globalUnitEditPagePath = (
  animalStandardId: string,
  unitId: string
) => `/masterdata/standards/${animalStandardId}/units/${unitId}/edit`;
