import * as React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { ExhibitionId } from "../../exhibitions/types";
import { Breed, BreedEditInput } from "./breedTypes";
import { buildQuery } from "../../../crossCutting/urls";

const PUT: FetchOptions = {
  method: "PUT",
};

const POST: FetchOptions = {
  method: "POST",
};

export function useBreeds(exhibitionId?: ExhibitionId) {
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [breeds, setBreeds] = React.useState([] as Breed[]);

  const refresh = React.useCallback(
    (animalStandardId: string, search?: string, ancestorNodeId?: string) => {
      setLoading(true);
      return fetcher(
        `${urlPrefix}masterdata/animalstandards/${animalStandardId}/breeds${buildQuery(
          {
            search: search || undefined,
            ancestorNodeId: ancestorNodeId || undefined,
          }
        )}`,
        {
          method: "GET",
        }
      )
        .then(({ breeds }) => setBreeds(breeds))
        .finally(() => setLoading(false));
    },
    [urlPrefix, fetcher]
  );

  const updateActivation = React.useCallback(
    (animalStandardId: string, breedIds: string[], isActive) => {
      setLoading(true);
      fetcher(
        `${urlPrefix}masterdata/animalstandards/${animalStandardId}/breeds/updateactivation`,
        {
          method: "PUT",
          body: JSON.stringify({ breedIds, isActive }),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then(() => refresh(animalStandardId).catch(() => {}))
        .then(() => {
          showAlert(
            `Die Rassen wurden ${isActive ? "aktiviert" : "deaktiviert"}`,
            "success"
          );
        })
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              `Fehler beim ${
                isActive ? "Aktivieren" : "Deaktivieren"
              } der Rassen`,
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [fetcher, refresh, urlPrefix, showAlert]
  );

  const changeOrder = React.useCallback(
    (animalStandardId: string, breedIds: string[]) => {
      setLoading(true);
      return fetcher(
        `${urlPrefix}masterdata/animalstandards/${animalStandardId}/breeds/changeorder`,
        {
          method: "PUT",
          body: JSON.stringify({ breedIds }),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then(() => refresh(animalStandardId).catch(() => {}))
        .then(() => {
          showAlert(`Die geänderte Reihenfolge wurde gespeichert`, "success");
        })
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              `Fehler beim Speichern der geänderten Reihenfolge`,
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [fetcher, refresh, urlPrefix, showAlert]
  );

  return {
    breeds,
    refresh,
    loading,
    updateActivation,
    changeOrder,
  };
}

export function useBreedSave(
  animalStandardId: string,
  exhibitionId?: ExhibitionId
) {
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T, image?: Blob) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("input", JSON.stringify(bodyFn ? bodyFn(data) : data));
      if (image) {
        formData.append("image", image);
      }
      return fetcher(pathFn(data), { ...options, body: formData })
        .then((result: any) => {
          if (messages?.success) {
            showAlert(messages?.success, "success");
          }
          return result;
        })
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createBreed = updater<BreedEditInput>(
    () => `${urlPrefix}masterdata/animalstandards/${animalStandardId}/breeds`,
    POST,
    {
      error: "Fehler beim Speichern der Rasse.",
      success: "Die Rasse wurde erstellt.",
    }
  );

  const updateBreed = updater<BreedEditInput & { breedId: string }>(
    (data) =>
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/breeds/${data.breedId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren der Rasse.",
      success: "Die Änderungen wurden gespeichert.",
    },
    ({ breedId, ...rest }) => rest
  );

  return { loading, createBreed, updateBreed };
}
