import { Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import { PersonAddIcon } from "../../../crossCutting/icons";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import Page from "../../../crossCutting/pages/Page";
import SearchBox from "../../../crossCutting/SearchBox";
import { ErrorSnackbar } from "../../../crossCutting/Snackbars";
import { useLazyFetch } from "../../../fetching/fetchProvider";
import { LinkToMemberNew } from "../memberEditPage/routing";
import { LinkToMemberImport } from "../memberImportPage/routing";
import { Exhibition } from "../types";
import MemberTable, { Member } from "./MemberTable";
import EditToolbar from "../../../crossCutting/EditToolbar";
import TableOrEmptyMessage from "../../../crossCutting/TableOrEmptyMessage";
import { buildQuery } from "../../../crossCutting/urls";
import { Pagination } from "../apiTypes";
import { useTranslation } from "../../../i18n/i18n";
export interface MembersPageProps {
  exhibition: MembersExhibition;
}

export type MembersExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;

const useStyles = makeStyles((theme) => ({
  search: {
    marginTop: theme.spacing(spacing3),
  },
  nothingFound: {
    textAlign: "center",
  },
  bottomSpacing: {
    marginBottom: theme.spacing(spacing3),
  },
  tableContainer: {
    paddingTop: theme.spacing(spacing3),
  },
  teaserButtonSpacing: {
    marginLeft: theme.spacing(1),
  },
}));

const MembersPage: React.FunctionComponent<MembersPageProps> = (props) => {
  const classes = useStyles();
  const { execute, state } = useLazyFetch<{
    members: Member[];
    pagination: Pagination;
  }>();
  const exhibitionId = props.exhibition.exhibitionId;
  const refresh = React.useCallback(
    (search: string, skip: number, take: number) =>
      execute(
        `exhibitions/${exhibitionId}/members${buildQuery({
          search,
          skip,
          take,
        })}`
      ),
    [execute, exhibitionId]
  );
  const initial = React.useRef(true);
  const [searchText, setSearchText] = React.useState("");
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(100);
  const handlePageChanged = (rowsPerPage: number, page: number) => {
    setSkip(page * rowsPerPage);
    setTake(rowsPerPage);
  };
  const handleSearch = (searchText: string) => {
    setSkip(0);
    setSearchText(searchText);
    initial.current = false;
  };
  const { t } = useTranslation();

  React.useEffect(() => {
    refresh(searchText, skip, take);
  }, [refresh, searchText, skip, take]);

  const [tableHeight, setTableHeight] = React.useState<number | undefined>();

  return (
    <Page
      title={props.exhibition.exhibitionName}
      subtitle={t("members")}
      noscroll
      maxWidth="xl"
    >
      {state.isRunning() ? (
        <LoadingIndicator />
      ) : state.isError() ? (
        <ErrorSnackbar message={state.error?.friendlyMessage} open={true} />
      ) : initial.current && state.data.members.length === 0 ? (
        <Box maxWidth="sm" className={classes.nothingFound}>
          <p className={classes.bottomSpacing}>{t("addMemberTeaser")}</p>
          <div>
            <LinkToMemberImport
              button
              color="secondary"
              variant="contained"
              exhibitionId={props.exhibition.exhibitionId}
            >
              {t("importCsvNow")}
            </LinkToMemberImport>
            <LinkToMemberNew
              button
              startIcon={<PersonAddIcon />}
              exhibition={props.exhibition}
              color="primary"
              className={classes.teaserButtonSpacing}
            >
              {t("enterMember")}
            </LinkToMemberNew>
          </div>
        </Box>
      ) : (
        <>
          <EditToolbar>
            <LinkToMemberImport
              button
              exhibitionId={props.exhibition.exhibitionId}
            >
              {t("uploadCsvFile")}
            </LinkToMemberImport>
            <LinkToMemberNew
              button
              startIcon={<PersonAddIcon />}
              exhibition={props.exhibition}
            >
              {t("enterMember")}
            </LinkToMemberNew>
          </EditToolbar>
          <div
            className={classes.search}
            ref={(r) => {
              if (!r) return;
              setTableHeight(
                document.body.clientHeight - r.getBoundingClientRect().bottom
              );
            }}
          >
            <SearchBox
              placeholder={t("memberSearchboxPlaceholder")}
              defaultValue={searchText}
              onSearch={handleSearch}
              autoFocus
            />
          </div>
          <TableOrEmptyMessage
            emptyMessage={t("noMembersFound")}
            data={state.data.members}
          >
            <div
              style={{ height: tableHeight }}
              className={classes.tableContainer}
            >
              <MemberTable
                exhibitionId={props.exhibition.exhibitionId}
                members={state.data.members}
                rowsPerPage={take}
                page={state.data.pagination.page}
                totalCount={state.data.pagination.totalCount}
                pageChanged={handlePageChanged}
              />
            </div>
          </TableOrEmptyMessage>
        </>
      )}
    </Page>
  );
};

export default MembersPage;
