import * as React from "react";
import { ForRoles } from "../../authorization/RolesContext";
import { AddIcon } from "../../crossCutting/icons";
import { useTranslation } from "../../i18n/i18n";
import { formatName } from "../format";
import { useCurrentUserMember } from "./ExhibitionUserProvider";
import PickMemberAndRegister from "./PickMemberAndRegister";
import { LinkToRegistrationNew } from "./registrationEditPage/routing";
import { ExhibitionId } from "./types";

export default function RegistrationCreatedButtons({
  ContainerComponent,
  containerComponentProps,
  exhibitionId,
  memberRegistered,
}: {
  ContainerComponent: any;
  containerComponentProps?: any;
  exhibitionId: ExhibitionId;
  memberRegistered: ButtonsMember;
}) {
  const { t } = useTranslation();
  const currentUser = useCurrentUserMember();
  return (
    <>
      <ForRoles roles="Member">
        <ContainerComponent {...containerComponentProps}>
          <LinkToRegistrationNew
            button
            color="primary"
            exhibitionId={exhibitionId}
            startIcon={<AddIcon />}
          >
            {t("addAnotherRegistrationForYou")}
          </LinkToRegistrationNew>
        </ContainerComponent>
      </ForRoles>
      {currentUser.memberId !== memberRegistered.memberId && (
        <ContainerComponent {...containerComponentProps}>
          <LinkToRegistrationNew
            button
            color="primary"
            exhibitionId={exhibitionId}
            startIcon={<AddIcon />}
            memberId={memberRegistered.memberId}
          >
            {t("addAnotherRegistrationForSomebodySpecificTemplate", {
              fullName: formatName(memberRegistered),
            })}
          </LinkToRegistrationNew>
        </ContainerComponent>
      )}
      <ContainerComponent {...containerComponentProps}>
        <PickMemberAndRegister
          exhibitionId={exhibitionId}
          color="primary"
          text={t("addAnotherRegistrationForSomebodyElse")}
        />
      </ContainerComponent>
    </>
  );
}

export interface ButtonsMember {
  firstName: string;
  lastName: string;
  memberId: string;
}
