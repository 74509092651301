import { Route } from "react-router-dom";
import React from "react";
import Page from "./UnitEditPage";
import { ExhibitionId, Exhibition } from "../../exhibitions/types";
import { uuidRegex } from "../../../crossCutting/uuid";
import { useUnitSave } from "../apiClient/useUnits";

type UnitEditExhibition = Pick<Exhibition, "exhibitionId" | "exhibitionName">;

export const exhibitionUnitCreatePageRoute = (
  pathPrefix: string,
  exhibition: UnitEditExhibition
) => (
  <Route
    path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/units/new`}
  >
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        getUnitPath: (animalStandardId: string, unitId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/units/${unitId}`,
        getAnimalStandardPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}`,
        makeUnitSaveDataHook: () => (animalStandardId: string) =>
          useUnitSave(animalStandardId, exhibition.exhibitionId),
        editUnitPath: (animalStandardId: string, unitId: string) =>
          exhibitionUnitEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            unitId
          ),
      }}
    />
  </Route>
);

export const exhibitionUnitEditPageRoute = (
  pathPrefix: string,
  exhibition: UnitEditExhibition
) => (
  <Route
    path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/units/:unitId(${uuidRegex})/edit`}
    exact
  >
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        getUnitPath: (animalStandardId: string, unitId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/units/${unitId}`,
        getAnimalStandardPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}`,
        makeUnitSaveDataHook: () => (animalStandardId: string) =>
          useUnitSave(animalStandardId, exhibition.exhibitionId),
        editUnitPath: (animalStandardId: string, unitId: string) =>
          exhibitionUnitEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            unitId
          ),
      }}
    />
  </Route>
);

export const exhibitionUnitCreatePagePath = (
  exhibitionId: ExhibitionId,
  animalStandardId: string
) =>
  `/exhibitions/${exhibitionId}/masterdata/standards/${animalStandardId}/units/new`;

export const exhibitionUnitEditPagePath = (
  exhibitionId: ExhibitionId,
  animalStandardId: string,
  unitId: string
) =>
  `/exhibitions/${exhibitionId}/masterdata/standards/${animalStandardId}/units/${unitId}/edit`;
