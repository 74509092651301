import { Route } from "react-router-dom";
import React from "react";
import PublicationsPage from "./PublicationsPage";
import PublicationPage from "./PublicationPage";
import { ExhibitionId } from "../types";
import { PublicationsExhibition } from "./PublicationsPage";
import { uuidRegex } from "../../../crossCutting/uuid";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";

const suffix = "publications";

export const publicationsPageRoute = (
  pathPrefix: string,
  exhibition: PublicationsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <PublicationsPage exhibition={exhibition} />
  </Route>
);

export const publicationsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;

export const LinkToPublicationsPage: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={publicationsPagePath(exhibitionId)} />
);

export const publicationPageRoute = (
  pathPrefix: string,
  exhibition: PublicationsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:publicationId(${uuidRegex})`}>
    <PublicationPage exhibition={exhibition} />
  </Route>
);

export const publicationPagePath = (
  exhibitionId: ExhibitionId,
  publicationId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${publicationId}`;

export const LinkToPublicationPage: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; publicationId: string }
> = ({ exhibitionId, publicationId, ...rest }) => (
  <AppLink {...rest} to={publicationPagePath(exhibitionId, publicationId)} />
);
