import { useFetch } from "../../fetching/fetchProvider";
import { ExhibitionId } from "./types";
import { AnimalStandard } from "./apiTypes";

export const useAnimalStandards = (exhibitionId: ExhibitionId) => {
  const x = useFetch<{ animalStandards: AnimalStandard[] }>(
    `/exhibitions/${exhibitionId}/masterdata/animalstandards`
  );
  return {
    animalStandards: x.data,
    animalStandardsLoading: x.loading,
    animalStandardsError: x.error,
  };
};
