import * as React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

/**
 * General purpose list of radio buttons.
 */
export default function Radios({
  items,
  label,
  onChange,
  fieldName,
  value,
  className,
}: {
  items: { label: string | React.ReactNode; value: string }[];
  label?: string;
  fieldName: string;
  onChange: (value: string) => void;
  value?: string | null;
  className?: string;
}) {
  return (
    <FormControl component="fieldset">
      {!!label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        className={className}
        aria-label={label}
        name={fieldName}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            label={item.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
