import { Log, User, UserManager } from "oidc-client";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import logger from "../crossCutting/logger";
import { parseJwt } from "../crossCutting/jwt";
import config from "../config";
import { mapMaybe } from "../crossCutting/maybe";
import { defaultLanguage } from "../language";
import AppRole from "../authorization/appRoles";

const AuthContext = React.createContext<AuthContextValue>({
  jwt: "",
  signin: noOp,
  signout: noOp,
  email: "",
  language: "",
  appRoles: [],
});

Log.logger = logger;

interface AuthContextValue {
  jwt: string;
  signin: Signin;
  signout: Signout;
  email: string;
  language: string;
  appRoles: AppRole[];
}

function noOp() {}

interface VoidFn {
  (): void;
}
type Signin = VoidFn;

type Signout = VoidFn;

export function Authenticated({
  children,
}: {
  children: (
    jwt: string,
    signin: Signin,
    language: string,
    appRoles: AppRole[]
  ) => React.ReactNode;
}) {
  return (
    <AuthContext.Consumer>
      {(v) => children(v.jwt, v.signin, v.language, v.appRoles)}
    </AuthContext.Consumer>
  );
}

const signedin = "signedin";

const userManager = new UserManager({
  authority: config.authAuthority,
  client_id: "spa",
  redirect_uri: `${window.location.origin}/${signedin}`,
  response_type: "code",
  scope: "openid catalog_api",
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/${signedin}`,
  loadUserInfo: true,
});

const signinPassingCurrentUrl = () =>
  userManager
    .signinRedirect({
      state: { path: window.location.pathname + window.location.search },
    })
    .catch((e) => {
      logger.error(e);
      throw e;
    });

export const AuthenticationProvider: React.FunctionComponent<{}> = ({
  children,
}) => {
  const signedInMatch = useRouteMatch(`/${signedin}`);
  const isSignedinRoute = !!signedInMatch;
  const history = useHistory();
  const [user, setUser] = useState<User>();
  const signout = React.useCallback(
    userManager.signoutRedirect.bind(userManager),
    [userManager]
  );
  useEffect(() => {
    logger.debug("AuthenticationProvider useEffect");
    if (isSignedinRoute) {
      logger.info("AuthenticationProvider ", signedin);
      userManager
        .signinRedirectCallback()
        .then((user) => {
          logger.info("AuthenticationProvider got user after sign in", user);
          setUser(user);
          history.replace(user.state?.path || "/");
        })
        .catch((e) => {
          logger.error("error after calling signinRedirectCallback()", e);
          if (e?.error === "login_required") {
            return userManager.signoutRedirect().then(() => {
              window.location.replace("/");
              // history.replace("/");
            });
          }
          throw e;
        });
      return;
    }
    userManager.getUser().then((user) => {
      if (user?.expired) {
        logger.debug("AuthenticationProvider has expired user");
        setUser(undefined);
        userManager.clearStaleState().finally(signinPassingCurrentUrl);
      } else if (user?.expired === false) {
        logger.debug("AuthenticationProvider has user that isn't expired");
        // logger.debug("", parseJwt(user.access_token));
        setUser(user);
      } else {
        logger.info("AuthenticationProvider has no user");
        setUser(undefined);
        userManager.clearStaleState().finally(signinPassingCurrentUrl);
      }
    });
  }, [history, isSignedinRoute]);

  return user ? (
    <AuthContext.Provider
      value={{
        jwt: user.access_token,
        signin: signinPassingCurrentUrl,
        signout,
        ...(mapMaybe(user.access_token, (jwt) => {
          const parsed = parseJwt(jwt) as JwtParsed;
          return {
            ...parsed,
            appRoles: extractAppRoles(parsed.role),
          };
        }) || { email: "", language: defaultLanguage, appRoles: [] }),
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : null;
};

export const useAuthentication = () => React.useContext(AuthContext);

function extractAppRoles(jwtRole: string | string[]) {
  const appRoles =
    typeof jwtRole === "string"
      ? [jwtRole]
      : Array.isArray(jwtRole)
      ? jwtRole
      : [];
  return appRoles as AppRole[];
}

interface JwtParsed {
  email: string;
  language: string;
  role: string | string[];
}
