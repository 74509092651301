import React from "react";
import { Route, Switch } from "react-router-dom";
import Shell from "../Shell";
import { exhibitionsPageRoute } from "./exhibitions/exhibitionsPage/routing";
import { homePageRoute } from "./homePage/routing";
import { newExhibitionPageRoute } from "./exhibitions/newExhibitionPage/routing";
import NoExhibitionMenu from "./NoExhibitionMenu";
import {
  globalBreedCreatePageRoute,
  globalBreedEditPageRoute,
} from "./masterdata/breedEditPage/routingGlobal";
import {
  globalUnitCreatePageRoute,
  globalUnitEditPageRoute,
} from "./masterdata/unitEditPage/routingGlobal";
import { globalStandardPageRoute } from "./masterdata/standardPage/routingGlobal";
import { globalBoxTypesPageRoute } from "./masterdata/boxTypesPage/routingGlobal";
import { useTranslation } from "../i18n/i18n";
import { globalStandardsManagePageRoute } from "./masterdata/standardsManagePage/routingGlobal";
import { lockPageRoute } from "./masterdata/lockPage/routing";
import { MasterDataLockProvider } from "./masterdata/apiClient/lock";

/**
 * Used when the current URL doesn't contain "/exhibition/:id" (i.e. we're
 * **not** inside an exhibition)
 * */
export default function NoExhibitionApp() {
  const { t } = useTranslation();
  const pathPrefix = "";
  return (
    <Shell
      drawerContent={(drawerActions) => (
        <NoExhibitionMenu drawerActions={drawerActions} />
      )}
      mainContent={
        <Switch>
          {newExhibitionPageRoute()}
          {exhibitionsPageRoute()}

          <Route path="/masterdata">
            <MasterDataLockProvider>
              <Switch>
                {lockPageRoute()}
                {globalBreedCreatePageRoute(pathPrefix, t)}
                {globalBreedEditPageRoute(pathPrefix, t)}
                {globalUnitCreatePageRoute(pathPrefix, t)}
                {globalUnitEditPageRoute(pathPrefix, t)}
                {globalStandardPageRoute(pathPrefix, t)}
                {globalStandardsManagePageRoute(pathPrefix, t)}
                {globalBoxTypesPageRoute(pathPrefix, t)}
              </Switch>
            </MasterDataLockProvider>
          </Route>
          {homePageRoute()}
        </Switch>
      }
    />
  );
}
