import { Divider } from "@material-ui/core";
import React from "react";
import { Switch, useParams, useRouteMatch } from "react-router-dom";
import {
  combineStates,
  mapState,
} from "../../crossCutting/hooks/usePromiseStates";
import { ErrorSnackbar } from "../../crossCutting/Snackbars";
import { useFetch2 } from "../../fetching/fetchProvider";
import Shell from "../../Shell";
import { AnimalStandard, Exhibition, ExhibitionDates } from "./apiTypes";
import { categoriesPageRoute } from "./categoriesPage/routing";
import {
  competitionEditPageRoute,
  competitionNewPageRoute,
} from "./competitionEditPage/routing";
import { competitionsPageRoute } from "./competitionsPage/routing";
import ExhibitionContext from "./ExhibitionContext";
import ExhibitionMenu from "./ExhibitionMenu";
import {
  exhibitionPageRoute,
  LinkToExhibition,
} from "./exhibitionPage/routing";
import { exhibitionSettingsPageRoute } from "./exhibitionSettingsPage/routing";
import {
  ExhibitionUserProvider,
  ExhibitionRolesProvider,
} from "./ExhibitionUserProvider";
import { judgesPageRoute } from "./judgesPage/routing";
import {
  memberEditPageRoute,
  memberNewPageRoute,
} from "./memberEditPage/routing";
import { memberImportPageRoute } from "./memberImportPage/routing";
import { membersPageRoute } from "./membersPage/routing";
import { exhibitionCreatedPageRoute } from "./newExhibitionPage/routing";
import NoExhibitionMenu from "../NoExhibitionMenu";
import {
  registrationNewPageRoute,
  registrationEditPageRoute,
} from "./registrationEditPage/routing";
import { registrationsPageRoute } from "./registrationsPage/routing";
import { roomsPageRoute } from "./roomsPage/routing";
import { ExhibitionId } from "./types";
import { exhibitionStandardPageRoute } from "../masterdata/standardPage/routingInExhibition";
import { exhibitionBoxTypesPageRoute } from "../masterdata/boxTypesPage/routingInExhibition";
import {
  exhibitionBreedCreatePageRoute,
  exhibitionBreedEditPageRoute,
} from "../masterdata/breedEditPage/routingInExhibition";
import {
  exhibitionUnitCreatePageRoute,
  exhibitionUnitEditPageRoute,
} from "../masterdata/unitEditPage/routingInExhibition";
import { useTranslatorForUser } from "../masterdata/translation";
import { clubCompetitionsPageRoute } from "./clubCompetitionsPage/routing";
import {
  clubCompetitionEditPageRoute,
  clubCompetitionNewPageRoute,
} from "./clubCompetitionEditPage/routing";
import { organizationsPageRoute } from "./organizationsPage/routing";
import {
  publicationsPageRoute,
  publicationPageRoute,
} from "./publicationsPage/routing";
import { exhibitionStandardsManagePageRoute } from "../masterdata/standardsManagePage/routingInExhibition";
import {
  registrationPageRoute,
  registrationCreatedPageRoute,
} from "./registrationPage/routing";
import { registrationDeletedPageRoute } from "./registrationDeletedPage/routing";

/**
 * Used when the current URL contains "/exhibition/:id" (i.e. we're inside an
 * exhibition)
 * */
export default function ExhibitionApp() {
  const { path } = useRouteMatch();
  const { exhibitionId } = useParams<{ exhibitionId: ExhibitionId }>();
  const [rawExhibitionState, refreshExhibition] = useFetch2<
    Exhibition & ExhibitionDates<string>
  >(`exhibitions/${exhibitionId}`);
  const exhibitionState = mapState(rawExhibitionState, parseExhibitionJson);
  const [animalStandardsState] = useFetch2<{
    animalStandards: (AnimalStandard & {
      captions: { language: string; animalStandardName: string }[];
    })[];
  }>(`/exhibitions/${exhibitionId}/masterdata/animalstandards`);
  const translator = useTranslatorForUser();
  const translatedStandardsState = mapState(
    animalStandardsState,
    ({ animalStandards }) =>
      animalStandards.map((s) => {
        return {
          ...s,
          ...(translator(s.captions) || { animalStandardName: "n/a" }),
        };
      })
  );
  const state = combineStates(
    exhibitionState,
    translatedStandardsState,
    (e, s) => ({
      ...e,
      animalStandards: s,
    })
  );
  return (
    <ExhibitionUserProvider exhibitionId={exhibitionId}>
      <ExhibitionRolesProvider>
        <Shell
          loading={state.isRunning()}
          location={
            state.isSuccess() ? (
              <LinkToExhibition exhibitionId={exhibitionId} disableLinkFormat>
                {state.data.exhibitionName}
              </LinkToExhibition>
            ) : null
          }
          drawerContent={(drawerActions) => (
            <>
              <ExhibitionMenu
                exhibitionId={exhibitionId}
                drawerActions={drawerActions}
              />
              <Divider />
              <NoExhibitionMenu />
            </>
          )}
          mainContent={
            state.isError() ? (
              <ErrorSnackbar
                open={true}
                message={state.error.friendlyMessage}
              />
            ) : state.isSuccess() ? (
              <ExhibitionContext.Provider
                value={{ exhibition: state.data, refresh: refreshExhibition }}
              >
                <Switch>
                  {exhibitionCreatedPageRoute(path, exhibitionId)}
                  {registrationNewPageRoute(path, state.data)}
                  {registrationEditPageRoute(path, state.data)}
                  {registrationCreatedPageRoute(path, state.data)}
                  {registrationDeletedPageRoute(path, state.data)}
                  {registrationPageRoute(path, state.data)}
                  {registrationsPageRoute(path, state.data)}
                  {competitionNewPageRoute(path, state.data)}
                  {competitionEditPageRoute(path, state.data)}
                  {competitionsPageRoute(path, state.data)}
                  {clubCompetitionNewPageRoute(path, state.data)}
                  {clubCompetitionEditPageRoute(path, state.data)}
                  {clubCompetitionsPageRoute(path, state.data)}
                  {exhibitionSettingsPageRoute(path, state.data)}
                  {roomsPageRoute(path, state.data)}
                  {categoriesPageRoute(path, state.data)}
                  {judgesPageRoute(path, state.data)}
                  {memberNewPageRoute(path, state.data)}
                  {memberEditPageRoute(path, state.data)}
                  {memberImportPageRoute(path, state.data)}
                  {membersPageRoute(path, state.data)}
                  {organizationsPageRoute(path, state.data)}
                  {publicationPageRoute(path, state.data)}
                  {publicationsPageRoute(path, state.data)}
                  {exhibitionBreedCreatePageRoute(path, state.data)}
                  {exhibitionBreedEditPageRoute(path, state.data)}
                  {exhibitionUnitCreatePageRoute(path, state.data)}
                  {exhibitionUnitEditPageRoute(path, state.data)}
                  {exhibitionStandardPageRoute(path, state.data)}
                  {exhibitionStandardsManagePageRoute(path, state.data)}
                  {exhibitionBoxTypesPageRoute(path, state.data)}
                  {exhibitionPageRoute(path, state.data)}
                </Switch>
              </ExhibitionContext.Provider>
            ) : null
          }
        />
      </ExhibitionRolesProvider>
    </ExhibitionUserProvider>
  );
}

type RawApiExhibition = Exhibition & ExhibitionDates<string>;
function parseExhibitionJson(data: RawApiExhibition) {
  return {
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
    deadline: new Date(data.deadline),
  };
}
