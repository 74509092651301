export enum PointCalculation {
  Relative = "Relative",
  Absolute = "Absolute",
}

export const pointCalculations = [
  {
    label: "Relativ zur Richtpunktzahl",
    value: PointCalculation.Relative,
  },
  { label: "Absolut (wie eingegeben)", value: PointCalculation.Absolute },
];

export const applicablePointCalculations = (animalStandard: {
  hasReferenceRating: boolean;
}) =>
  pointCalculations.filter(
    (pc) =>
      pc.value === PointCalculation.Absolute ||
      animalStandard.hasReferenceRating
  );
