import { useAuthentication } from "../../authentication/AuthContext";
import { inLanguageOrDefault } from "../../language";
type CaptionWithoutLanguage<T> = Pick<T, Exclude<keyof T, "language">>;

export function useTranslatorForUser() {
  const { language } = useAuthentication();
  return function <T extends { language: string }>(
    translations: T[]
  ): CaptionWithoutLanguage<T> | undefined {
    const translation = inLanguageOrDefault(language, translations);
    if (translation) {
      const { language: _, ...rest } = translation;
      return rest;
    } else {
      return undefined;
    }
  };
}
