import { Box, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "./layoutConstants";

export interface TableOrEmptyMessageProps {
  data: any[];
  emptyMessage: string;
}

const useStyles = makeStyles((theme) => ({
  nothingFound: {
    marginTop: theme.spacing(spacing3),
    textAlign: "center",
  },
}));

const TableOrEmptyMessage: React.FunctionComponent<TableOrEmptyMessageProps> = (
  props
) => {
  const classes = useStyles();
  return props.data.length ? (
    <>{props.children}</>
  ) : (
    <Box maxWidth="sm" className={classes.nothingFound}>
      <Typography variant="body1">{props.emptyMessage}</Typography>
    </Box>
  );
};

export default TableOrEmptyMessage;
