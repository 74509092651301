import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { ignoreAccents, escapeRegex } from "./strings";

const useStyles = makeStyles((theme) => ({
  mark: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

/** Takes a text and replaces all occurrences of toMark with <span>s containing toMark */
export default function MarkIt(props: { text: string; toMark: string }) {
  const classes = useStyles();
  return props.toMark ? (
    <>
      {props.text
        .split(
          new RegExp("(" + ignoreAccents(escapeRegex(props.toMark)) + ")", "i")
        )
        .map((s, i) => {
          const key = `${s}${i}`;
          if (i % 2 === 0) {
            return <React.Fragment key={key}>{s}</React.Fragment>;
          } else {
            return (
              <span key={key} className={classes.mark}>
                {s}
              </span>
            );
          }
        })}
    </>
  ) : (
    <>{props.text}</>
  );
}
