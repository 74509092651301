import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  DrawerMenuItem,
  useDrawerMenuItemStyles,
} from "../../crossCutting/DrawerMenu";
import { ExpandIcon, CollapseIcon } from "../../crossCutting/icons";
import { useTranslation } from "../../i18n/i18n";

interface MasterDataMenuStrategy {
  standardPagePath: () => string;
  boxTypesPagePath: () => string;
  rootItemTitle: string;
}
export default function MasterDataMenu(props: {
  strategy: MasterDataMenuStrategy;
  prefixComponent?: React.ReactNode;
}) {
  const drawerMenuItemClasses = useDrawerMenuItemStyles();
  const match = useRouteMatch({ path: props.strategy.standardPagePath() });

  const [expanded, setExpanded] = React.useState(false || !!match?.path);
  const handleExpandCollapse = () => {
    setExpanded((v) => !v);
  };
  const { t } = useTranslation();
  const prefixComponent = props.prefixComponent ?? null;
  return (
    <List component="div" disablePadding>
      <ListItem
        button
        onClick={handleExpandCollapse}
        className={drawerMenuItemClasses.listItem}
      >
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary={props.strategy.rootItemTitle} />
        {expanded ? <ExpandIcon /> : <CollapseIcon />}
      </ListItem>
      <List component="div" disablePadding>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {prefixComponent}
            <DrawerMenuItem
              text={t("standards")}
              path={props.strategy.standardPagePath()}
              notExact
            />
            <DrawerMenuItem
              text={t("boxTypes")}
              path={props.strategy.boxTypesPagePath()}
            />
          </List>
        </Collapse>
      </List>
    </List>
  );
}
