import * as React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  control: {
    marginBottom: theme.spacing(1),
  },
}));

export default function ChampionLevelItem<T extends ChampionLevelConfig>({
  config,
  onChange,
}: {
  config: T;
  onChange: (config: T) => void;
}) {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        className={classes.control}
        control={
          <Checkbox
            checked={config.isSelected}
            onChange={(e) =>
              onChange({ ...config, isSelected: e.target.checked })
            }
            name={config.levelName + "selected"}
          />
        }
        label={config.levelName}
      />
      <TextField
        className={classes.control}
        label="Mindestanzahl Tiere"
        variant="outlined"
        value={config.animalMin}
        size="small"
        inputProps={{ min: 0 }}
        onChange={(e) =>
          onChange({
            ...config,
            isSelected: true,
            animalMin: e.target.value,
          })
        }
      />
      <TextField
        className={classes.control}
        label="Kurzzeichen"
        variant="outlined"
        value={config.championCode}
        required={config.isSelected}
        size="small"
        onChange={(e) =>
          onChange({
            ...config,
            isSelected: true,
            championCode: e.target.value,
          })
        }
      />
    </FormGroup>
  );
}
export interface ChampionLevelConfig {
  levelName: string;
  isSelected: boolean;
  animalMin: string;
  championCode: string;
}
