import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { byString } from "../../../crossCutting/sorting";
import { useTranslatorForUser } from "../../masterdata/translation";

export default function Step2<T extends StandardItem>(props: {
  allStandards: T[];
  selectedStandards: T[];
  onChange: (selectedStandards: T[]) => void;
}) {
  const masterDataTranslator = useTranslatorForUser();
  return (
    <>
      <Typography variant="h6" component="h3">
        Ausgestellte Standards
      </Typography>

      <List>
        {props.allStandards
          .sort(byString((s) => s.animalStandardId))
          .map((item) => {
            const labelId = `checkbox-list-label-${item.animalStandardId}`;

            return (
              <ListItem
                key={item.animalStandardId}
                role={undefined}
                dense
                button
                onClick={toggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={props.selectedStandards.includes(item)}
                    tabIndex={-1}
                    disableRipple
                    onChange={toggle(item)}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    masterDataTranslator(item.captions)?.animalStandardName
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </>
  );

  function toggle<T extends StandardItem>(item: any) {
    return () => {
      const excluded = props.selectedStandards.filter((i) => i !== item);
      const newSelection =
        excluded.length === props.selectedStandards.length
          ? [...props.selectedStandards, item]
          : excluded;
      props.onChange(newSelection);
    };
  }
}

export interface StandardItem {
  animalStandardId: string;
  captions: { language: string; animalStandardName: string }[];
}
