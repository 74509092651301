import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import Page from "../../../crossCutting/pages/Page";
import { byNumber } from "../../../crossCutting/sorting";
import { useTranslation } from "../../../i18n/i18n";
import { AnimalStandard } from "../apiClient/animalStandardTypes";
import { useTranslatorForUser } from "../translation";
import TableToolbar from "../TableToolbar";
import useTableSelection from "../useTableSelection";
import { ShowIcon, HideIcon } from "../../../crossCutting/icons";
import clsx from "clsx";

export type MakeAnimalStandardsDataHook = () => () => {
  animalStandards: AnimalStandard[];
  loading: boolean;
  refresh: () => Promise<void>;
  updateActivation: (animalStandardIds: string[], active: boolean) => void;
};

interface AnimalStandardsPageStrategy {
  title: string;
  makeAnimalStandardsDataHook: MakeAnimalStandardsDataHook;
}

export interface AnimalStandardsPageProps {
  strategy: AnimalStandardsPageStrategy;
}

const useStyles = makeStyles((theme) => ({
  inactiveRow: {
    "& td": {
      color: theme.palette.text.secondary,
    },
  },
}));

const AnimalStandardsPage: React.FunctionComponent<AnimalStandardsPageProps> = (
  props
) => {
  const translator = useTranslatorForUser();
  const { t } = useTranslation();
  const useAnimalStandards = props.strategy.makeAnimalStandardsDataHook();
  const { animalStandards, loading, updateActivation } = useAnimalStandards();
  const {
    selectedIds,
    isSelected: isAnimalStandardSelected,
    selectionStatus,
    handleSelectAllChange,
    selectionCount,
    toggleSelection,
  } = useTableSelection(animalStandards, (s) => s.animalStandardId);
  const handleClick = (animalStandard: AnimalStandard) => {
    toggleSelection(animalStandard.animalStandardId);
  };
  const handleActivateSelected = () => {
    updateActivation(selectedIds, true);
  };
  const handleDeactivateSelected = () => {
    updateActivation(selectedIds, false);
  };
  const classes = useStyles();
  const handleCancelEdit = () => {};
  const handleSave = (animalStandard: AnimalStandard) => {};

  return (
    <Page title={props.strategy.title} subtitle={t("standards")}>
      <Paper>
        <TableToolbar
          selectionCount={selectionCount}
          titleWhenSelected={`${selectionCount} ${
            selectionCount === 1 ? " Standards " : " Standards "
          } ausgewählt`}
          contentWhenSelected={
            <>
              <Tooltip title="Ausgewählte aktivieren">
                <IconButton
                  aria-label="aktivieren"
                  onClick={handleActivateSelected}
                  color="primary"
                  disabled={loading}
                >
                  <ShowIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ausgewählte ausblenden">
                <IconButton
                  color="primary"
                  aria-label="ausblenden"
                  onClick={handleDeactivateSelected}
                  disabled={loading}
                >
                  <HideIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectionStatus === "all"}
                  indeterminate={selectionStatus === "indeterminate"}
                  inputProps={{ "aria-label": "alle auswählen" }}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Herkunft</TableCell>
              {/* <TableCell></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {animalStandards.sort(byNumber((s) => s.sort)).map((s) => {
              const isSelected = isAnimalStandardSelected(s);
              const labelId = `standard-table-checkbox-${s.animalStandardId}`;
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  selected={isSelected}
                  className={clsx(!s.isActive && classes.inactiveRow)}
                  key={s.animalStandardId}
                  onClick={() => handleClick(s)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell id={labelId}>
                    {translator(s.captions)?.animalStandardName}
                  </TableCell>
                  <TableCell>{s.origin ? "Ausstellung" : "Global"}</TableCell>
                  {/* <HoverActionCell>
                    <JumpToButton>Bearbeiten</JumpToButton>
                  </HoverActionCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Page>
  );
};

export default AnimalStandardsPage;
