import { Button, Container, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import EditList from "../../../../crossCutting/EditList";
import { AddIcon, ChangeOrderIcon } from "../../../../crossCutting/icons";
import NodeDialog from "./NodeDialog";
import NodeTree, { NodeCaption } from "./NodeTree";
import {
  Node,
  CreateNodeInput,
  UpdateNodeInput,
  ChangeNodeOrderInput,
} from "../../apiClient/nodeTypes";
import ChangeOrderDialog from "../../ChangeOrderDialog";
import {
  getDepth,
  findSiblingsAndParent,
  getApplicableNodeLevels,
} from "../../nodes";
import { Origin } from "../../apiClient/commonTypes";
import { useTranslatorForUser } from "../../translation";
import LoadingIndicator from "../../../../crossCutting/LoadingIndicator";
import { Maybe } from "../../../../crossCutting/maybe";

export type MakeNodesDataHook = () => (
  animalStandardId: string
) => {
  nodes: Node[];
  loading: boolean;
  refresh: () => Promise<void>;
  createNode: (input: CreateNodeInput) => Promise<void>;
  updateNode: (input: UpdateNodeInput & { nodeId: string }) => Promise<void>;
  changeNodeOrder: (
    input: ChangeNodeOrderInput & { parentNodeId: Maybe<string> }
  ) => Promise<void>;
};
export interface StructureTabPanelStrategy {
  makeNodesDataHook: MakeNodesDataHook;
}
export interface StructureTabPanelProps {
  strategy: StructureTabPanelStrategy;
  animalStandard: {
    animalStandardId: string;
    hasBreedSizeLevel: boolean;
    hasSubGroupLevel: boolean;
  };
}

const templateNode = {
  nodeId: "",
  captions: [] as NodeCaption[],
  active: true,
  origin: Origin.Exhibition,
  children: null,
  sort: 0,
};

type Destination = "inside" | "before" | "after";

const StructureTabPanel: React.FunctionComponent<StructureTabPanelProps> = (
  props
) => {
  const [anchorEl, setAnchorEl] = React.useState<any>();
  const translator = useTranslatorForUser();
  const levels = getApplicableNodeLevels(props.animalStandard);
  const useNodes = props.strategy.makeNodesDataHook();
  const { nodes, loading, createNode, updateNode, changeNodeOrder } = useNodes(
    props.animalStandard.animalStandardId
  );

  const handleEdit = (node: Node) => {
    setEditedNode(node);
    setEditedNodeLevel(getDepth(nodes, node));
  };
  const toggleActive = (node: Node) => {};
  const handleAdd = (anchorEl: any, node: Node) => {
    setNodeAddClickedOn(node);
    setAnchorEl(anchorEl);
  };
  const handleAddInside = () => {
    handleMenuClose();
    if (nodeAddClickedOnDepth === undefined) return;
    setEditedNode(templateNode);
    setEditedNodeLevel(nodeAddClickedOnDepth + 1);
    setDestination("inside");
  };
  const handleAddBefore = () => {
    handleMenuClose();
    if (nodeAddClickedOnDepth === undefined) return;
    setEditedNode(templateNode);
    setEditedNodeLevel(nodeAddClickedOnDepth);
    setDestination("before");
  };
  const handleAddAfter = () => {
    handleMenuClose();
    if (nodeAddClickedOnDepth === undefined) return;
    setEditedNode(templateNode);
    setEditedNodeLevel(nodeAddClickedOnDepth);
    setDestination("after");
  };
  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };
  const [nodeAddClickedOn, setNodeAddClickedOn] = React.useState<Node>();
  const nodeAddClickedOnDepth = nodeAddClickedOn
    ? getDepth(nodes, nodeAddClickedOn)
    : undefined;
  const [editedNode, setEditedNode] = React.useState<Node>();
  const [editedNodeLevel, setEditedNodeLevel] = React.useState<number>();
  const [destination, setDestination] = React.useState<Destination>();

  const stopEditing = () => {
    setEditedNode(undefined);
  };
  const handleCancelEdit = stopEditing;
  const [nodesToReorder, setNodesToReorder] = React.useState<Node[]>();
  const [parentOfNodesToReorder, setParentOfNodesToReorder] = React.useState<
    Node
  >();
  const handleRootOrderChange = () => {
    setParentOfNodesToReorder(undefined);
    setNodesToReorder(nodes);
  };
  const handleChangeChildrenOrder = (parent: Node) => {
    if (!parent.children) return;
    setParentOfNodesToReorder(parent);
    setNodesToReorder(parent.children);
  };
  const handleCancelChangeOrder = () => {
    setParentOfNodesToReorder(undefined);
    setNodesToReorder(undefined);
  };
  const handleSaveChangeOrder = (reordered: string[]) => {
    if (!nodesToReorder) return;

    changeNodeOrder({
      nodeIds: reordered,
      parentNodeId: parentOfNodesToReorder?.nodeId,
    }).then(() => {
      handleCancelChangeOrder();
    });
  };
  const handleSave = (node: Node) => {
    if (nodeAddClickedOn) {
      create(node, nodeAddClickedOn);
    } else if (editedNode) {
      updateNode({
        nodeId: editedNode?.nodeId,
        captions: node.captions,
      }).then(() => {
        stopEditing();
      });
    }
  };

  const create = (node: Node, nodeAddClickedOn: Node) => {
    if (destination === "inside") {
      createAndStopEditing({
        captions: node.captions,
        parentNodeId: nodeAddClickedOn.nodeId,
      });
    } else if (destination === "before") {
      const siblingsAndParent = findSiblingsAndParent(nodes, nodeAddClickedOn);
      if (!siblingsAndParent) return;
      const indexOfNodeBefore = siblingsAndParent.siblings.indexOf(
        nodeAddClickedOn
      );
      createAndStopEditing({
        captions: node.captions,
        parentNodeId: siblingsAndParent.parent?.nodeId,
        nodeIdBefore:
          indexOfNodeBefore === 0
            ? null
            : siblingsAndParent.siblings[indexOfNodeBefore - 1].nodeId,
      });
    } else if (destination === "after") {
      const siblingsAndParent = findSiblingsAndParent(nodes, nodeAddClickedOn);
      if (!siblingsAndParent) return;
      createAndStopEditing({
        captions: node.captions,
        parentNodeId: siblingsAndParent.parent?.nodeId,
        nodeIdBefore: nodeAddClickedOn.nodeId,
      });
    }
  };

  const createAndStopEditing = (input: CreateNodeInput) => {
    createNode(input).then(() => {
      stopEditing();
      setNodeAddClickedOn(undefined);
    });
  };

  return (
    <Container maxWidth="sm">
      <EditList
        toolbarItems={
          <>
            {levels.length && nodes.length === 0 && (
              <Button startIcon={<AddIcon />}>Neue {levels[0].label}</Button>
            )}
            <Button
              startIcon={<ChangeOrderIcon />}
              onClick={handleRootOrderChange}
            >
              Reihenfolge ändern
            </Button>
          </>
        }
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <NodeTree
            nodes={nodes}
            onEdit={handleEdit}
            onAdd={handleAdd}
            onToggleActive={toggleActive}
            onChangeChildrenOrder={handleChangeChildrenOrder}
          />
        )}
      </EditList>
      {nodeAddClickedOnDepth !== undefined && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
          {nodeAddClickedOnDepth < levels.length - 1 && (
            <MenuItem onClick={handleAddInside}>{`${
              levels[nodeAddClickedOnDepth + 1].label
            } innerhalb einfügen`}</MenuItem>
          )}
          <MenuItem
            onClick={handleAddBefore}
          >{`${levels[nodeAddClickedOnDepth].label} davor einfügen`}</MenuItem>
          <MenuItem
            onClick={handleAddAfter}
          >{`${levels[nodeAddClickedOnDepth].label} danach einfügen`}</MenuItem>
        </Menu>
      )}
      {editedNode && (
        <NodeDialog
          node={editedNode}
          open={!!editedNode}
          onCancel={handleCancelEdit}
          onSave={handleSave}
          loading={loading}
          title={
            editedNodeLevel === undefined
              ? ""
              : `${levels[editedNodeLevel]?.label} ${
                  editedNode.nodeId ? "bearbeiten" : "erstellen"
                }` ?? ""
          }
        />
      )}
      {nodesToReorder?.length && (
        <ChangeOrderDialog
          open={!!nodesToReorder}
          onCancel={handleCancelChangeOrder}
          onSave={handleSaveChangeOrder}
          title={`${
            levels[getDepth(nodes, nodesToReorder[0])!]?.plural
          }: Reihenfolge ändern`}
          items={nodesToReorder.map((n) => ({
            key: n.nodeId,
            orderable: translator(n.captions)?.nodeName,
          }))}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default StructureTabPanel;
