import React from "react";
import { Route } from "react-router-dom";
import { makeUrl } from "../../../crossCutting/urls";
import { TranslationFunc } from "../../../i18n/i18n";
import { useBoxTypes } from "../apiClient/useBoxTypes";
import Page from "./BoxTypesPage";

export const globalBoxTypesPageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => {
  return (
    <Route path={`${pathPrefix}/masterdata/boxtypes`}>
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          makeBoxTypesDataHook: () => () => useBoxTypes(),
        }}
      />
    </Route>
  );
};

export const globalBoxTypesPagePath = () => makeUrl`/masterdata/boxtypes/`;
