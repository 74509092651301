import { Route } from "react-router-dom";
import React from "react";
import Page, { MembersExhibition } from "./MembersPage";
import { ExhibitionId } from "../types";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";

const suffix = "members";

export const membersPageRoute = (
  pathPrefix: string,
  exhibition: MembersExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const membersPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;

export const LinkToMembersPage: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={membersPagePath(exhibitionId)} />
);
