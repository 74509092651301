import * as React from "react";

export default function useTableSelection<T>(
  items: T[],
  getId: (item: T) => string
) {
  const [selection, setSelection] = React.useState({} as SelectionMap);
  const toggleSelection = (id: string) => {
    setSelection((ids) => {
      if (ids[id]) {
        const { [id]: _, ...newMap } = ids;
        return newMap;
      } else {
        return { ...ids, ...{ [id]: true } };
      }
    });
  };
  const handleSelectAllChange = () => {
    if (selectionStatus === "all") {
      setSelection({});
    } else {
      setSelection(
        items.reduce(
          (a, item) => ({ ...a, ...{ [getId(item)]: true } }),
          {} as SelectionMap
        )
      );
    }
  };
  const selectedIds = Object.keys(selection);
  const selectionCount = selectedIds.length;
  const selectionStatus =
    selectionCount === items.length
      ? "all"
      : selectionCount === 0
      ? "none"
      : "indeterminate";

  const isSelected = (item: T) => !!selection[getId(item)];

  return {
    handleSelectAllChange,
    isSelected,
    selectedIds,
    selectionCount,
    selectionStatus,
    toggleSelection,
  };
}

interface SelectionMap {
  [id: string]: true;
}
