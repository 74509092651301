import { FetchClient } from "../../../../fetching/fetchClient";
import { ExhibitionId } from "../../types";

export interface Breed {
  breedId: string;
  captions: { language: string; breedName: string }[];
}

export function searchBreedsOfStandards(
  fetcher: FetchClient,
  exhibitionId: ExhibitionId,
  animalStandardIds: string[],
  searchText?: string
) {
  const st = searchText ?? "";
  return Promise.all(
    animalStandardIds.map((animalStandardId) =>
      fetcher(
        `exhibitions/${exhibitionId}/masterdata/animalstandards/${animalStandardId}/breeds?search=${encodeURIComponent(
          st
        )}`,
        {
          method: "GET",
        }
      )
    )
  ).then((breedResults: { breeds: Breed[] }[]) =>
    breedResults.map((b) => b.breeds).flat()
  );
}
