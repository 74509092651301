import { Button } from "@material-ui/core";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import EditTable from "../../../crossCutting/editTable/EditTable";
import InlineEditTextField from "../../../crossCutting/editTable/InlineEditTextField";
import InlineSelect from "../../../crossCutting/editTable/InlineSelect";
import { useColumn } from "../../../crossCutting/editTable/useColumn";
import useEdit, {
  EditExistingState,
} from "../../../crossCutting/editTable/useEdit";
import validation from "../../../crossCutting/editTable/validation";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import useDeleter from "../../../crossCutting/hooks/useDeleter";
import { AddIcon } from "../../../crossCutting/icons";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { Maybe } from "../../../crossCutting/maybe";
import Page from "../../../crossCutting/pages/Page";
import UpDown from "../../../crossCutting/UpDown";
import { Exhibition } from "../apiTypes";
import { useAnimalStandards } from "../standardsFetch";
import { useCategories } from "../useCategories";
import { Category, sameCategory } from "./category";
import { useTranslatorForUser } from "../../masterdata/translation";
export type CategoriesExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface CategoriesPageProps {
  exhibition: CategoriesExhibition;
}

const CategoriesPage: React.FunctionComponent<CategoriesPageProps> = (
  props
) => {
  const translator = useTranslatorForUser();

  const {
    animalStandards: animalStandardsResult,
    animalStandardsLoading,
  } = useAnimalStandards(props.exhibition.exhibitionId);

  const animalStandards = animalStandardsResult?.animalStandards?.map((s) => {
    return {
      ...s,
      ...(translator(s.captions) || { animalStandardName: "n/a" }),
    };
  });
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<
    Maybe<string>
  >();

  const {
    categories,
    loading: categoriesLoading,
    createCategory,
    moveCategory,
    updateCategory,
    deleteCategory,
  } = useCategories();

  // up/down
  const noneSelected = !categories?.some(
    (c) => c.categoryId === selectedCategoryId
  );
  const firstSelected = categories?.[0]?.categoryId === selectedCategoryId;
  const lastSelected =
    categories?.[categories.length - 1]?.categoryId === selectedCategoryId;

  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  const showError = (message: string) => showAlertSnackbar(message, "error");

  const nameColumn = useColumn<Category, string, string>({
    header: "Name",
    columnKey: "name",
    defaultValue: "",
    extractValue: (category) => category.categoryName,
    view: (category) => category.categoryName,
    convert: (raw) => raw,
    convertBack: (value) => value || "",
    validate: (value) => !!value,
    editorFactory: (editorProps) => (
      <InlineEditTextField
        {...editorProps}
        autoSelect
        disabled={categoriesLoading}
      />
    ),
  });

  const standardColumn = useColumn<Category, string, string>({
    header: "Standard",
    columnKey: "standard",
    extractValue: (category) => category.animalStandardId,
    view: (category) =>
      animalStandards?.find(
        (s) => s.animalStandardId === category.animalStandardId
      )?.animalStandardName,
    convert: (raw) => raw,
    convertBack: (value) => value || "",
    validate: (value) => !!value,
    disableUpdate: true,
    editorFactory: (editorProps) => (
      <InlineSelect
        {...editorProps}
        items={
          animalStandards?.filter(
            (s) => s.isActive || editorProps.value === s.animalStandardId
          ) ?? []
        }
        itemKey={(s) => s.animalStandardId}
        itemValue={(s) => s.animalStandardId}
        itemView={(s) => s.animalStandardName}
        disabled={categoriesLoading}
      />
    ),
  });

  const columns = [nameColumn, standardColumn];

  const { omitValidationErrors, validate } = validation(columns, showError);

  const { deleteConfirmation, deleteWithConfirmation } = useDeleter();

  const {
    handleAddNew,
    handleEditExisting,
    stopEditing,
    editState,
    tableEditState,
  } = useEdit(columns, omitValidationErrors, sameCategory);

  const handleSaveNew = () => {
    if (!validate()) return;

    createCategory({
      categoryName: nameColumn.value!,
      animalStandardId: standardColumn.value!,
    }).then(stopEditing);
  };

  const handleSaveExisting = () => {
    if (!validate()) return;

    const changedCategory = categories?.find((c) =>
      sameCategory(c, (editState as EditExistingState<Category>).editedItem)
    );
    if (!changedCategory) {
      stopEditing();
      return;
    }

    updateCategory({
      categoryId: changedCategory.categoryId,
      categoryName: nameColumn.value!,
    }).then(stopEditing);
  };

  const handleDelete = (category: Category) => {
    deleteWithConfirmation(
      `Kategorie "${category.categoryName}" löschen?`,
      () => deleteCategory(category)
    );
  };

  const handleUp = () => {
    if (!categories || noneSelected) return;
    var selectedIndex = categories?.findIndex(
      (category) => category.categoryId === selectedCategoryId
    );
    if (!selectedCategoryId || selectedIndex === 0) return;

    var idOfItemBefore = categories[selectedIndex - 1].categoryId;
    moveCategory({
      idOfCategoryToMove: selectedCategoryId,
      idOfNewPredecessor: idOfItemBefore,
    }).then(stopEditing);
  };

  const handleDown = () => {
    if (!categories || noneSelected) return;
    var selectedIndex = categories?.findIndex(
      (c) => c.categoryId === selectedCategoryId
    );
    if (!selectedCategoryId || selectedIndex + 1 === categories.length) return;

    var idOfTwoItemsAfter = categories[selectedIndex + 2]?.categoryId;

    moveCategory({
      idOfCategoryToMove: selectedCategoryId,
      idOfNewPredecessor: idOfTwoItemsAfter,
    }).then(stopEditing);
  };

  const handleSelectionChanged = (category: Maybe<Category>) => {
    setSelectedCategoryId(category?.categoryId);
  };

  const loading = categoriesLoading || animalStandardsLoading;
  return (
    <Page title={props.exhibition.exhibitionName} subtitle="Kategorien">
      <EditList
        toolbarItems={
          <>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              disabled={loading || editState !== "NotEditing"}
              onClick={handleAddNew}
            >
              Neue Kategorie
            </Button>
            <UpDown
              disabled={loading || editState !== "NotEditing"}
              upPossible={!(noneSelected || firstSelected)}
              downPossible={!(noneSelected || lastSelected)}
              onUp={handleUp}
              onDown={handleDown}
            />
          </>
        }
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <EditTable
            showHeader
            selectionMode="Single"
            isSelected={(category) =>
              category.categoryId === selectedCategoryId
            }
            selectionChanged={handleSelectionChanged}
            editState={tableEditState}
            onCancel={stopEditing}
            onEditExisting={handleEditExisting}
            onSaveNew={handleSaveNew}
            onSaveExisting={handleSaveExisting}
            onDelete={handleDelete}
            rowKey={(category) => category.categoryId}
            data={categories || []}
            columns={columns.map((c) => c.tableColumn)}
            saving={categoriesLoading}
          />
        )}
      </EditList>
      {deleteConfirmation}
      {alertSnackbar}
    </Page>
  );
};

export default CategoriesPage;
