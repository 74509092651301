import * as React from "react";

export default function useFileDownload() {
  let anchorRef = React.useRef<HTMLAnchorElement>();

  React.useEffect(() => {
    anchorRef.current = anchorRef.current || document.createElement("a");
    const anchor = anchorRef.current;
    anchor.style.opacity = "0";
    anchor.style.pointerEvents = "none";
    document.body.appendChild(anchor);

    return () => {
      document.body.removeChild(anchor);
    };
  }, []);

  return (blob: Blob, fileName: string) => {
    const anchor = anchorRef.current;
    if (!anchor) return;

    const objectUrl = window.URL.createObjectURL(blob);
    anchor.href = objectUrl;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  };
}
