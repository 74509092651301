import React from "react";
import config from "../config";
import logger from "../crossCutting/logger";
import { createFetchClient } from "./fetchClient";
import { FetchProvider } from "./fetchProvider";
import { useAuthentication } from "../authentication/AuthContext";
import { defaultLanguage } from "../language";

const KlexpoApiFetchProvider: React.FunctionComponent<{
  jwt: string;
  signin: () => void;
}> = (props) => {
  const { language } = useAuthentication();
  const fetchClient = React.useCallback(
    createFetchClient(
      config.apiBaseUrl,
      language,
      (req) => {
        req.headers.append("Authorization", `Bearer ${props.jwt}`);
        req.headers.append(
          "Accept-Language",
          `${language}${
            language !== defaultLanguage ? ", " + defaultLanguage + ";0.9" : ""
          }`
        );
        return req;
      },
      (res) => {
        if (res.status === 401) {
          logger.info("Got 401 response, signing in");
          props.signin();
          return true;
        }
        return false;
      }
    ),
    [props.jwt, props.signin, config.apiBaseUrl]
  );
  return (
    <FetchProvider fetchClient={fetchClient}>{props.children}</FetchProvider>
  );
};

export default KlexpoApiFetchProvider;
