import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import * as React from "react";
import {
  LinkToRegistrationNew,
  LinkToRegistrationEdit,
} from "../exhibitions/registrationEditPage/routing";
import { ExhibitionsWhereCurrentUserIsMember } from "./homePageTypes";
import ExhibitionListItemText from "./ExhibitionListItemText";
import IfCanRegisterSelf from "../exhibitions/IfCanRegisterSelf";

export default function RegistrationsByExhibition(props: {
  exhibitions: ExhibitionsWhereCurrentUserIsMember<Date>[];
}) {
  return (
    <List component="div" disablePadding>
      {props.exhibitions.map((d) => (
        <Group key={d.exhibitionId} {...d} />
      ))}
    </List>
  );
}

function Group(props: ExhibitionsWhereCurrentUserIsMember<Date>) {
  return (
    <>
      <ListItem ContainerComponent="div" disableGutters>
        <ExhibitionListItemText {...props} />
        <ListItemSecondaryAction>
          <IfCanRegisterSelf
            exhibition={props}
            user={{
              isMember: props.currentUserIsMember,
              isOrganizer: props.currentUserIsOrganizer,
            }}
          >
            <LinkToRegistrationNew
              exhibitionId={props.exhibitionId}
              button
              color="primary"
              startIcon={<PersonAddIcon />}
            >
              Anmelden
            </LinkToRegistrationNew>
          </IfCanRegisterSelf>
        </ListItemSecondaryAction>
      </ListItem>
      <List disablePadding>
        {[].map((r, i) => (
          <Item key={i} {...r} />
        ))}
      </List>
    </>
  );
}

function Item(props: { primary: string; secondary: string }) {
  return (
    <ListItem>
      <LinkToRegistrationEdit registrationId="" exhibitionId="33">
        <ListItemText primary={props.primary} secondary={props.secondary} />
      </LinkToRegistrationEdit>
    </ListItem>
  );
}
