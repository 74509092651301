import { CircularProgress, makeStyles } from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "./layoutConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    marginTop: theme.spacing(spacing3),
  },
}));

export default function LoadingIndicator() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress disableShrink />
      <div className={classes.text}>Wird geladen</div>
    </div>
  );
}
