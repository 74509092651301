import React from "react";
import { AlertSnackbar, AlertSnackbarSeverity } from "../Snackbars";

export default function useAlertSnackbar() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<AlertSnackbarSeverity>();
  const alertSnackbar = (
    <AlertSnackbar
      open={open}
      severity={severity}
      onClose={() => setOpen(false)}
      message={message}
    />
  );

  const showAlertSnackbar = React.useCallback(
    (message: string, severity: AlertSnackbarSeverity) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    },
    []
  );

  return {
    alertSnackbar,
    showAlertSnackbar,
  };
}
