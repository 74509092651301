export interface ClubCompetitionOrganization {
  breedIds: string[];
  organizationId: string;
}

export interface ClubCompetition {
  clubCompetitionId: string;
  clubCompetitionName: string;
  pointCalculation: number;
  rankingListSplitLimit: number;
  mustRestrictByBreed: boolean;
  minRatedAnimalCount: number;
  minBreederCount: number;
  fixAnimalCount: number;
  remainingAnimalsFraction: number;
  animalStandardId1: string;
  animalStandardId2: string;
  organizations: ClubCompetitionOrganization[];
  animalStatus: AnimalStatus[];
}

export enum AnimalStatus {
  Valid = "Valid",
  NotRated = "NotRated",
  NotPresent = "NotPresent",
  IncorrectClass = "IncorrectClass",
  IncorrectDelivery = "IncorrectDelivery",
}
