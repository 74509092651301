import { Route } from "react-router-dom";
import React from "react";
import Page from "./RoomsPage";
import { ExhibitionId } from "../types";
import { RoomsExhibition } from "./RoomsPage";

const suffix = "rooms";
export const roomsPageRoute = (
  pathPrefix: string,
  exhibition: RoomsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const roomsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
