import {
  Box,
  Button,
  Checkbox,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AppRole from "../../../authorization/appRoles";
import { ForRoles } from "../../../authorization/RolesContext";
import { getCookie } from "../../../crossCutting/cookies";
import DeleteButton from "../../../crossCutting/DeleteButton";
import {
  FormSectionTitle,
  FormTitle,
} from "../../../crossCutting/FormSectionTitle";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import useConfirmation from "../../../crossCutting/hooks/useConfirmation";
import {
  combineStates,
  mapState,
} from "../../../crossCutting/hooks/usePromiseStates";
import useSaveShortcut from "../../../crossCutting/hooks/useSaveShortcut";
import { AddIcon } from "../../../crossCutting/icons";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { Maybe } from "../../../crossCutting/maybe";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import Radios from "../../../crossCutting/Radios";
import { parseQuery } from "../../../crossCutting/urls";
import { useFetch2, useSendJson } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { formatName } from "../../format";
import { useTranslatorForUser } from "../../masterdata/translation";
import {
  ClubCompetition,
  Exhibition,
  Member,
  MemberRegistered,
  Registration,
} from "../apiTypes";
import { ExhibitionCached, useExhibitionCached } from "../ExhibitionContext";
import { exhibitionPagePath } from "../exhibitionPage/routing";
import { useCurrentUserMember } from "../ExhibitionUserProvider";
import { registrationDeletedPagePath } from "../registrationDeletedPage/routing";
import { registrationCreatedPagePath } from "../registrationPage/routing";
import { useCategories } from "../useCategories";
import BreedColorList, { BreedColor, emptyBreedColor } from "./BreedColorList";
import { PickerBreed } from "./BreedPicker";
import { ColorSelection } from "./ColorSelector";
import UnitSelect from "./UnitSelect";

export type RegistrationEditExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName" | "orderInfo"
>;
export interface RegistrationEditPageProps {
  registrationId?: number;
  exhibition: RegistrationEditExhibition;
}

const RegistrationEditPage: React.FunctionComponent<
  RegistrationEditPageProps
> = (props) => {
  const location = useLocation();
  const { registrationId } = useParams<{ registrationId?: string }>();
  const history = useHistory();

  const isNewRegistration = !registrationId;
  const [editedRegistrationState] = useFetch2<Registration>(
    isNewRegistration
      ? ""
      : `exhibitions/${props.exhibition.exhibitionId}/registrations/${registrationId}`
  );
  const { categories } = useCategories();
  const cachedExhibition = useExhibitionCached();
  const exhibition = { ...cachedExhibition, categories };
  const standard = editedRegistrationState.isSuccess()
    ? exhibition?.animalStandards.find((s) =>
        s.units.some(
          (u) => u.unitId === editedRegistrationState.data.unit.unitId
        )
      )
    : null;
  const [breedState] = useFetch2<any>(
    editedRegistrationState.isSuccess() && exhibition && standard
      ? `exhibitions/${props.exhibition.exhibitionId}/masterdata/animalstandards/${standard.animalStandardId}/breeds/${editedRegistrationState.data.breed.breedId}`
      : ""
  );

  const currentUserMember = useCurrentUserMember();
  const memberIdFromCurrenUser = currentUserMember?.memberId;
  const memberId =
    parseQuery(location.search).memberId ?? memberIdFromCurrenUser;

  if (isNewRegistration && !memberId) {
    history.replace(exhibitionPagePath(props.exhibition.exhibitionId));
  }
  const [memberState] = useFetch2<Member>(
    memberId
      ? `exhibitions/${props.exhibition.exhibitionId}/members/${memberId}`
      : ""
  );

  const registrationState = isNewRegistration
    ? mapState(memberState, (m) => ({
        registration: {
          competitionIds: [],
          clubCompetitionIds: [],
          memberRegistered: m,
          hasAdditionalBox: false,
        } as RegistrationValues,
        breed: undefined,
      }))
    : combineStates(editedRegistrationState, breedState, (r, breed) => ({
        registration: {
          ...r,
          roomId: r.room?.roomId,
          categoryId: r.category?.categoryId,
          ageClassId: r.ageClass?.ageClassId,
          unitId: r.unit.unitId,
          colorId: r.color?.colorId,
        } as RegistrationValues,
        breed: breed,
      }));

  return exhibition ? (
    registrationState.isRunning() ? (
      <LoadingIndicator />
    ) : registrationState.isError() ? (
      <div>Error</div>
    ) : (
      <RegistrationEditPageExceptDataFetching
        exhibition={exhibition}
        registration={registrationState.data.registration}
        defaultBreed={registrationState.data.breed}
      />
    )
  ) : null;
};

export default RegistrationEditPage;

function MemberInfo({
  member,
}: {
  member: {
    memberNumber: number;
    firstName: string;
    lastName: string;
    email: Maybe<string>;
    address1: Maybe<string>;
    address2: Maybe<string>;
    zipCode: Maybe<string>;
    city: Maybe<string>;
  };
}) {
  const addressLines = [
    member.address1,
    member.address2,
    `${member.zipCode ?? ""} ${member.city}`.trim(),
  ].filter((i) => !!i);
  return (
    <>
      <Typography variant="body1">{formatName(member)}</Typography>
      <Typography variant="body2">{member.memberNumber}</Typography>
      {member.email && <Typography variant="body2">{member.email}</Typography>}
      <br />
      {addressLines.map((l) => (
        <Typography variant="body2">{l}</Typography>
      ))}
    </>
  );
}

const RegistrationEditPageExceptDataFetching: React.FunctionComponent<{
  registration: RegistrationValues;
  exhibition: ExhibitionCached & {
    categories: {
      categoryId: string;
      categoryName: string;
      animalStandardId: string;
    }[];
  };
  defaultBreed: Maybe<PickerBreed>;
}> = ({ registration, exhibition, defaultBreed }) => {
  const mt = useTranslatorForUser();
  const { t } = useTranslation();
  useSaveShortcut(() => {
    if (!valid) return;
    if (isNewRegistration) handleSaveNew();
    else handleSaveExisting();
  });
  const history = useHistory();
  const { Confirmation, executeWithConfirmation } = useConfirmation(
    t("delete"),
    t("dontDelete"),
    () => {
      deleteRegistration({
        path: `exhibitions/${exhibition.exhibitionId}/registrations/${registration.registrationId}`,
        method: "DELETE",
        onSuccess: () => {
          history.replace(registrationDeletedPagePath(exhibition.exhibitionId));
        },
      });
    }
  );
  const isNewRegistration = !registration.registrationId;

  const unitsUsedInAnyCompetition = exhibition.competitions
    .flatMap((c) => c.competitionUnits)
    .map((cu) => cu.unitId);
  const unitSelectorItems = exhibition.animalStandards
    .map((s) => ({
      ...s,
      units: s.units.filter(
        (u) => unitsUsedInAnyCompetition.indexOf(u.unitId) !== -1
      ),
    }))
    .filter((s) => s.units.length);
  const unitSelectorItemsFlat = unitSelectorItems.flatMap((s) => s.units);
  const hasOnlyOneUnit = unitSelectorItemsFlat.length === 1;
  const selectedUnitId =
    registration.unitId ||
    (firstIfJustOne(unitSelectorItemsFlat)?.unitId ?? "");
  const [unitId, setUnitId] = React.useState(selectedUnitId);
  const [categoryId, setCategoryId] = React.useState(
    registration.categoryId || ""
  );
  const [roomId, setRoomId] = React.useState(registration.roomId || "");
  const [ageClassId, setAgeClassId] = React.useState(
    registration.ageClassId || ""
  );
  const [organizationNumber, setOrganizationNumber] = React.useState(
    registration.organizationNumber?.toString() ?? ""
  );

  const standard = exhibition.animalStandards.find((s) =>
    s.units.some((u) => u.unitId === unitId)
  );
  const unit = standard?.units?.find((u) => u.unitId === unitId);

  const [breedColors, setBreedColors] = React.useState<BreedColor[]>(
    isNewRegistration
      ? [emptyBreedColor]
      : [
          {
            breed: defaultBreed,
            color: (registration.colorId
              ? { colorId: registration.colorId }
              : unit?.isMixed
              ? "mixed"
              : null) as ColorSelection,
            hasAdditionalBox: registration.hasAdditionalBox,
            breedValid: true,
            colorValid: true,
          },
        ]
  );

  const breedColorRef = React.createRef<any>();
  const updateBreedColorValidationFlags = (items: BreedColor[]) =>
    items.map(getBreedColorWithValidationFlags);
  const getBreedColorWithValidationFlags = (breedColor: BreedColor) => {
    const breedValid = !!breedColor.breed;
    const colorValid =
      !standard?.hasColorLevel ||
      (breedColor.color === "mixed" && !!breedColor.breed?.colors?.length) ||
      (breedColor.color !== "mixed" && !!breedColor.color);
    return { ...breedColor, breedValid, colorValid };
  };
  const handleAddBreedColor = () => {
    const newItems = updateBreedColorValidationFlags(breedColors);
    if (
      !newItems.some(
        (breedColor) => !(breedColor.breedValid && breedColor.colorValid)
      )
    ) {
      newItems.push({ ...newItems[newItems.length - 1], deleted: false });
    }
    setBreedColors(newItems);
  };
  const handleBreedColorsChange = (newItems: BreedColor[]) => {
    setBreedColors(updateBreedColorValidationFlags(newItems));
  };
  const breedColorsNotDeleted = breedColors.filter((bc) => !bc.deleted);
  const breedColorsValid = !breedColorsNotDeleted.some(
    (r) => !(r.breedValid && r.colorValid)
  );

  const competitions = competitionsMatchingUnit(
    exhibition.competitions,
    unitId
  );
  const clubCompetitions = standard
    ? clubCompetitionsMatchingUnit(
        exhibition.clubCompetitions,
        standard.animalStandardId
      )
    : ([] as ClubCompetition[]);
  const [competitionIds, setCompetitionIds] = React.useState(
    isNewRegistration && competitions.length === 1
      ? competitions.map((c) => c.competitionId)
      : registration.competitionIds
  );
  const [clubCompetitionIds, setClubCompetitionIds] = React.useState(
    isNewRegistration && clubCompetitions.length === 1
      ? clubCompetitions.map((c) => c.clubCompetitionId)
      : registration.clubCompetitionIds
  );

  const categories =
    exhibition.categories?.filter(
      (c) => c.animalStandardId === standard?.animalStandardId
    ) || [];
  const [email, setEmail] = React.useState(registration.memberRegistered.email);
  const [catalogByEmail, setCatalogByEmail] = React.useState(
    registration.memberRegistered.orders?.catalogByEmail ?? false
  );
  const [souvenirMedalOrderCount, setSouvenirMedalOrderCount] = React.useState(
    registration.memberRegistered.orders?.souvenirMedalOrderCount?.toString() ??
      "0"
  );
  const [catalogOrderCount, setCatalogOrderCount] = React.useState(
    registration.memberRegistered.orders?.catalogOrderCount?.toString() ?? "0"
  );

  const handleUnitChange = (unitId: string) => {
    setUnitId(unitId);
    setCategoryId("");
    setBreedColors([emptyBreedColor]);
    setAgeClassId("");
    const competitions = competitionsMatchingUnit(
      exhibition.competitions,
      unitId
    );
    setCompetitionIds(
      competitions.length === 1 ? competitions.map((c) => c.competitionId) : []
    );
  };

  const registrationInfoCookieKey = `registrationInfoShown${exhibition.exhibitionId}`;

  const shouldShowRegistrationInfoDialog =
    !!exhibition.registrationInfo && !getCookie(registrationInfoCookieKey);
  const [registrationInfoDialogOpen, setRegistrationInfoDialogOpen] =
    React.useState(shouldShowRegistrationInfoDialog);
  const handleRegistrationInfoDialogClose = () => {
    document.cookie = `${registrationInfoCookieKey}=true`;
    setRegistrationInfoDialogOpen(false);
  };
  const [singleAnimalGender, setSingleAnimalGender] = React.useState(
    registration.singleAnimalGender
  );

  const [saveClicked, setSaveClicked] = React.useState(false);
  const showValidationErrorsIfAny = saveClicked;

  const unitSelected = !!unit;
  const unitRef = React.createRef<HTMLDivElement>();

  const ageClassSelectedIfAny =
    !standard?.hasAgeClass || !standard?.ageClasses?.length || ageClassId;
  const ageClassRef = React.createRef<HTMLDivElement>();

  const exhibitionRoomSelectedIfAny = !exhibition.rooms.length || roomId;
  const exhibitionRoomRef = React.createRef<HTMLDivElement>();

  const categorySelectedIfAny = !categories.length || categoryId;
  const categoryRef = React.createRef<HTMLDivElement>();

  const hasAtLeastOneCompetition = !!competitionIds.length;
  const competitionRef = React.createRef<HTMLDivElement>();

  const hasEmailIfNeeded = !catalogByEmail || email;
  const emailRef = React.createRef<HTMLDivElement>();

  const catalogOrderCountValid = !isNaN(
    parseInt(catalogOrderCount.trim() || "0")
  );
  const catalogOrderCountRef = React.createRef<HTMLDivElement>();

  const souvenirMedalOrderCountValid = !isNaN(
    parseInt(souvenirMedalOrderCount.trim() || "0")
  );
  const souvenirMedalOrderCountRef = React.createRef<HTMLDivElement>();

  const organizationNumberValid = !isNaN(
    parseInt(organizationNumber.trim() || "0")
  );
  const organizationNumberRef = React.createRef<HTMLDivElement>();

  // The order of the items in this array must correspond to the (visual) order
  // of the respective components on the page.
  const validatedComponentsByFlag = [
    { flag: unitSelected, ref: unitRef },
    { flag: breedColorsValid, ref: breedColorRef },
    { flag: ageClassSelectedIfAny, ref: ageClassRef },
    { flag: exhibitionRoomSelectedIfAny, ref: exhibitionRoomRef },
    { flag: categorySelectedIfAny, ref: categoryRef },
    { flag: hasAtLeastOneCompetition, ref: competitionRef },
    { flag: hasEmailIfNeeded, ref: emailRef },
    { flag: catalogOrderCountValid, ref: catalogOrderCountRef },
    { flag: souvenirMedalOrderCountValid, ref: souvenirMedalOrderCountRef },
    { flag: organizationNumberValid, ref: organizationNumberRef },
  ];

  const valid =
    unitSelected &&
    breedColorsValid &&
    hasAtLeastOneCompetition &&
    hasEmailIfNeeded &&
    categorySelectedIfAny &&
    exhibitionRoomSelectedIfAny &&
    ageClassSelectedIfAny &&
    catalogOrderCountValid &&
    souvenirMedalOrderCountValid &&
    organizationNumberValid;
  const {
    alertSnackbar: validationSnackbar,
    showAlertSnackbar: showValidationSnackbar,
  } = useAlertSnackbar();

  const { sendJson: save, loading: saving } = useSendJson({
    success: t("registrationSaveSuccess"),
    error: t("registrationSaveError"),
  });
  const { sendJson: deleteRegistration, loading: deleting } = useSendJson({
    success: t("registrationDeleteSuccess"),
    error: t("registrationDeleteError"),
  });
  const collectCommonInputs = () => {
    return {
      unitId: unitId,
      competitionIds,
      clubCompetitionIds,
      roomId: roomId || null,
      categoryId: categoryId || null,
      ageClassId: ageClassId || null,
      memberInfo: {
        memberId: registration.memberRegistered.memberId,
        email: email,
        orders: {
          catalogByEmail,
          souvenirMedalOrderCount: parseInt(souvenirMedalOrderCount) ?? 0,
          catalogOrderCount: parseInt(catalogOrderCount) ?? 0,
        },
      },
      singleAnimalGender: singleAnimalGender || null,
      organizationNumber: parseInt(organizationNumber) || null,
    };
  };

  const scrollToTopMostValidationError = () => {
    const topMostComponentWithError = validatedComponentsByFlag.find(
      (pair) => !pair.flag
    )?.ref?.current;
    if (topMostComponentWithError) {
      topMostComponentWithError.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const validate = () => {
    setSaveClicked(true);
    if (valid) return true;

    showValidationSnackbar(t("registrationValidationError"), "error");
    scrollToTopMostValidationError();
    return false;
  };
  const breedColorToPostData = (breedColor: BreedColor) => ({
    breedId: breedColor.breed?.breedId,
    colorId: breedColor.color === "mixed" ? null : breedColor.color?.colorId,
    hasAdditionalBox: breedColor.hasAdditionalBox,
  });
  const handleSaveNew = () => {
    if (!validate()) return;
    save({
      path: `exhibitions/${exhibition.exhibitionId}/registrations/`,
      method: "POST",
      body: {
        breedColors: breedColorsNotDeleted.map(breedColorToPostData),
        ...collectCommonInputs(),
      },
      onSuccess: ({ registrationIds }: { registrationIds: string[] }) => {
        history.replace(
          registrationCreatedPagePath(
            exhibition.exhibitionId,
            registrationIds[0],
            registrationIds.length
          )
        );
      },
    });
  };
  const handleSaveExisting = () => {
    if (!validate()) return;
    save({
      path: `exhibitions/${exhibition.exhibitionId}/registrations/${registration.registrationId}`,
      method: "PUT",
      body: {
        ...breedColorToPostData(breedColors[0]),
        ...collectCommonInputs(),
      },
    });
  };

  return (
    <BottomBarPage
      title={exhibition.exhibitionName}
      subtitle={
        isNewRegistration
          ? t("newRegistration")
          : t("editRegistrationWithNumber", {
              registrationNumber: registration.registrationNumber,
            })
      }
      bottomBarContent={
        isNewRegistration ? (
          <Button
            color="secondary"
            variant="contained"
            disabled={saving || deleting}
            onClick={handleSaveNew}
          >
            {t("registerDefinitely")}
          </Button>
        ) : (
          <ForRoles roles={[AppRole.ExhibitionAdmin, "Organizer"]}>
            <Button
              color="secondary"
              variant="contained"
              disabled={saving || deleting}
              onClick={handleSaveExisting}
            >
              {t("saveChanges")}
            </Button>
            <DeleteButton
              color="primary"
              variant="text"
              disabled={saving || deleting}
              onClick={executeWithConfirmation}
            >
              {t("deleteRegistration")}
            </DeleteButton>
          </ForRoles>
        )
      }
    >
      <Grid container spacing={spacing3}>
        <Grid item xs={12}>
          <FormSectionTitle title={t("exhibitor")} />
          <MemberInfo member={registration.memberRegistered} />
        </Grid>
        {isNewRegistration && !hasOnlyOneUnit ? (
          <Grid item xs={12} ref={unitRef}>
            <FormSectionTitle title={t("unit") + " *"} />
            <UnitSelect
              standards={unitSelectorItems}
              fullWidth
              value={unitId}
              onChange={handleUnitChange}
              error={showValidationErrorsIfAny && !unitSelected}
              helperText={
                showValidationErrorsIfAny && !unitSelected
                  ? t("registrationMissingUnit")
                  : " "
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <FormSectionTitle title={t("unit")} />
            <Typography>
              {
                mt(
                  exhibition.animalStandards
                    .flatMap((s) => s.units)
                    .find((u) => u.unitId === unitId)?.captions || []
                )?.unitName
              }
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} ref={breedColorRef}>
          <FormTitle>
            {isNewRegistration ? t("breedsAndColors") : t("breedAndColor")}
            {breedColorsNotDeleted.length > 1
              ? ` (${breedColorsNotDeleted.length})`
              : ""}
          </FormTitle>
          <Box
            border={1}
            borderColor={colors.grey[500]}
            padding={spacing3}
            paddingTop={0}
          >
            <BreedColorList
              {...{
                exhibition,
                standard,
                unit,
                showValidationErrorsIfAny,
                onChange: handleBreedColorsChange,
                items: breedColors.map((x) => ({
                  ...x,
                  breed: x.breed
                    ? {
                        ...x.breed,
                        // don't display inactive colors unless it's the one that's already
                        // assigned to the registration
                        colors: x.breed.colors?.filter(
                          (c) =>
                            c.isActive ||
                            (!isNewRegistration &&
                              registration.colorId === c.colorId)
                        ),
                      }
                    : undefined,
                })),
                disabled: !unit,
              }}
            />
            {isNewRegistration && (
              <Box marginTop={2}>
                <Button
                  startIcon={<AddIcon />}
                  color="primary"
                  size="small"
                  onClick={handleAddBreedColor}
                  disabled={
                    !breedColors.some((bc) => {
                      const { breedValid, colorValid } =
                        getBreedColorWithValidationFlags(bc);
                      return breedValid && colorValid;
                    })
                  }
                >
                  {t("addBreedColorTemplate", {
                    unitName: mt(unit?.captions ?? [])?.unitName ?? "",
                  })}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
        {!!standard?.hasAgeClass && !!standard.ageClasses?.length && (
          <Grid item xs={12} ref={ageClassRef}>
            <FormSectionTitle title={t("ageClass") + " *"} />
            <TextField
              select
              fullWidth
              value={ageClassId}
              onChange={(e) => setAgeClassId(e.target.value as string)}
              error={showValidationErrorsIfAny && !ageClassSelectedIfAny}
              helperText={
                showValidationErrorsIfAny && !ageClassSelectedIfAny
                  ? t("registrationMissingAgeClass")
                  : " "
              }
            >
              {standard.ageClasses.map((c) => (
                <MenuItem key={c.ageClassId} value={c.ageClassId}>
                  {mt(c.captions)?.ageClassName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {!!exhibition.rooms?.length && (
          <Grid item xs={12} ref={exhibitionRoomRef}>
            <FormSectionTitle title={t("exhibitionRoom") + " *"} />
            <TextField
              select
              fullWidth
              value={roomId}
              onChange={(e) => setRoomId(e.target.value as string)}
              error={showValidationErrorsIfAny && !exhibitionRoomSelectedIfAny}
              helperText={
                showValidationErrorsIfAny && !exhibitionRoomSelectedIfAny
                  ? t("registrationMissingRoom")
                  : " "
              }
            >
              {exhibition.rooms.map((c) => (
                <MenuItem key={c.roomId} value={c.roomId}>
                  {c.roomName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {!!categories.length && (
          <Grid item xs={12} ref={categoryRef}>
            <FormSectionTitle title={t("category") + " *"} />
            <TextField
              select
              fullWidth
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value as string)}
              error={showValidationErrorsIfAny && !categorySelectedIfAny}
              helperText={
                showValidationErrorsIfAny && !categorySelectedIfAny
                  ? t("registrationMissingCategory")
                  : " "
              }
            >
              {categories.map((c) => (
                <MenuItem key={c.categoryId} value={c.categoryId}>
                  {c.categoryName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {unitId && competitions.length !== 1 && (
          <Grid item xs={12} ref={competitionRef}>
            <FormSectionTitle title={t("competitions") + " *"} />
            <FormControl
              component="fieldset"
              error={showValidationErrorsIfAny && !hasAtLeastOneCompetition}
            >
              <FormGroup>
                {competitions.map((competition) => (
                  <FormControlLabel
                    key={competition.competitionId}
                    control={
                      <Checkbox
                        checked={competitionIds.includes(
                          competition.competitionId
                        )}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setCompetitionIds((old) =>
                            checked
                              ? [...old, competition.competitionId]
                              : old.filter(
                                  (i) => i !== competition.competitionId
                                )
                          );
                        }}
                        name=""
                      />
                    }
                    label={competition.competitionName}
                  />
                ))}
              </FormGroup>
              <FormHelperText>
                {showValidationErrorsIfAny && !hasAtLeastOneCompetition
                  ? t("registrationMissingCompetition")
                  : " "}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        {unitId && !!clubCompetitions.length && (
          <Grid item xs={12}>
            <FormSectionTitle title={t("clubCompetitions")} />
            <FormControl component="fieldset">
              <FormGroup>
                {clubCompetitions.map((cc) => (
                  <FormControlLabel
                    key={cc.clubCompetitionId}
                    control={
                      <Checkbox
                        checked={clubCompetitionIds.includes(
                          cc.clubCompetitionId
                        )}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setClubCompetitionIds((old) =>
                            checked
                              ? [...old, cc.clubCompetitionId]
                              : old.filter((i) => i !== cc.clubCompetitionId)
                          );
                        }}
                        name=""
                      />
                    }
                    label={cc.clubCompetitionName}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {unit?.animalCount === 1 &&
          competitions.some(
            (c) =>
              competitionIds.includes(c.competitionId) &&
              c.competitionUnits.some((cu) => cu.isGenderMandatory)
          ) && (
            <Grid item xs={12}>
              <FormSectionTitle title={t("gender")} />
              <Radios
                items={[
                  { label: "1.0", value: "m" },
                  { label: "0.1", value: "f" },
                ]}
                value={singleAnimalGender}
                fieldName="sex"
                onChange={(v) => setSingleAnimalGender(v)}
              />
            </Grid>
          )}

        <FormSectionTitle title={t("orders")} />
        {exhibition.orderInfo && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary">
              {exhibition.orderInfo}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={catalogByEmail}
                onChange={(e) => {
                  setCatalogByEmail(e.target.checked);
                }}
                name="catalogPdf"
              />
            }
            label={t("getsCatalogByEmail")}
          />
        </Grid>
        <Grid item xs={12} ref={emailRef}>
          <TextField
            label={t("email")}
            type="email"
            fullWidth
            required={catalogByEmail}
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            error={showValidationErrorsIfAny && !hasEmailIfNeeded}
            helperText={
              showValidationErrorsIfAny && !hasEmailIfNeeded
                ? t("registrationMissingEmail")
                : " "
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} ref={catalogOrderCountRef}>
          <TextField
            label={t("catalogOrderLabel")}
            fullWidth
            value={catalogOrderCount}
            onChange={(e) => {
              setCatalogOrderCount(e.target.value);
            }}
            error={!catalogOrderCountValid}
            helperText={
              catalogOrderCountValid
                ? ""
                : t("registrationInvalidCatalogOrderCount")
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} ref={souvenirMedalOrderCountRef}>
          <TextField
            label={t("ordersMedal")}
            fullWidth
            value={souvenirMedalOrderCount}
            onChange={(e) => {
              setSouvenirMedalOrderCount(e.target.value);
            }}
            error={!souvenirMedalOrderCountValid}
            helperText={
              souvenirMedalOrderCountValid
                ? ""
                : t("registrationInvalidSouvenirMedalOrderCount")
            }
          />
        </Grid>
        <Grid item xs={12} ref={organizationNumberRef}>
          <FormSectionTitle title={t("registrationByOrganization")} />
          <Box mb={2}>
            <Typography>{t("registrationOrganizationNumberHint")}</Typography>
          </Box>
          <TextField
            fullWidth
            label={t("organizationNumber")}
            value={organizationNumber}
            onChange={(e) => {
              setOrganizationNumber(e.target.value);
            }}
            error={!organizationNumberValid}
            helperText={
              organizationNumberValid
                ? ""
                : t("registrationInvalidOrganizationNumber")
            }
          />
        </Grid>
      </Grid>
      <Confirmation title={t("confirmDeleteRegistration")} />
      <Dialog
        open={registrationInfoDialogOpen}
        onClose={handleRegistrationInfoDialogClose}
        maxWidth="sm"
      >
        <DialogTitle>{exhibition.exhibitionName}</DialogTitle>
        <DialogContent>
          <DialogContentText>{exhibition.registrationInfo}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleRegistrationInfoDialogClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {validationSnackbar}
    </BottomBarPage>
  );
};

interface RegistrationValues {
  registrationId?: string;
  registrationNumber?: number;
  memberRegistered: MemberRegistered;
  unitId?: string;
  competitionIds: string[];
  clubCompetitionIds: string[];
  ageClassId?: string;
  colorId?: string;
  categoryId?: string;
  roomId?: string;
  hasAdditionalBox: boolean;
  singleAnimalGender?: string | null;
  organizationNumber?: number | undefined;
}

function firstIfJustOne<T>(arr: T[]) {
  return arr.length === 1 ? arr[0] : undefined;
}

function competitionsMatchingUnit<
  T extends { competitionUnits: { unitId: string }[] }
>(allCompetitions: T[], unitId: string) {
  return allCompetitions?.filter((c) =>
    c.competitionUnits.some((cu) => cu.unitId === unitId)
  );
}

function clubCompetitionsMatchingUnit<
  T extends { animalStandardId1: string; animalStandardId2: string | null }
>(allCompetitions: T[], animalStandardId: string) {
  return allCompetitions?.filter(
    (c) =>
      c.animalStandardId1 === animalStandardId ||
      c.animalStandardId2 === animalStandardId
  );
}
