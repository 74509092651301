import { TextField, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "../../../i18n/i18n";

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(1) },
}));

export default function Step4orderInfo(props: {
  orderInfo: string;
  onChange: (orderInfo: string) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" component="h3">
        {t("orderInfoTextNewExhibition")}
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.marginBottom}
      >
        Wird während der Anmeldung angezeigt und erläutert, was Aussteller
        bestellen können.
      </Typography>
      <TextField
        multiline
        autoFocus
        value={props.orderInfo}
        rows={4}
        fullWidth
        placeholder='Beispiel: "Es können Kataloge aber keine Erinnerungsmedaillen bestellt werden."'
        onChange={(e) => props.onChange(e.target.value)}
        className={classes.marginBottom}
      />
      <Typography variant="body2" color="textSecondary">
        Du kannst diesen Text später in den Ausstellungseinstellungen ändern.
      </Typography>
    </>
  );
}
