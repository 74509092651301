import { debounce, InputBase, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import { Exhibition } from "./exhibition";
import { SearchIcon } from "../../../crossCutting/icons";
import { LinkToExhibition } from "../exhibitionPage/routing";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";

export interface ExhibitionPickerProps {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  jumpToButton: {
    flex: "0 0 auto",
    display: "none",
    lineHeight: 1,
  },
  option: {
    "&:hover": {
      "& $jumpToButton": {
        display: "block",
      },
    },
  },

  optionLabel: {
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
  },

  input: {
    backgroundColor: theme.palette.primary.light,
    color: "black",
    paddingLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  searchIcon: {
    position: "absolute",
    color: theme.palette.primary.main,
    left: theme.spacing(0.5),
  },
}));
const ExhibitionPicker: React.FunctionComponent<ExhibitionPickerProps> = (
  props
) => {
  const [options, setOptions] = React.useState([] as Exhibition[]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const fetchClient = useFetchClient();
  const fetchDebounced = React.useMemo(
    () =>
      debounce(
        (
          searchText: string,
          callback: (error: any, o?: Exhibition[]) => void
        ) => {
          fetchClient(`exhibitions?search=${encodeURIComponent(searchText)}`, {
            method: "GET",
          })
            .then((r) => callback(null, r.exhibitions))
            .catch(callback);
        }
      ),
    [fetchClient]
  );
  React.useEffect(() => {
    setLoading(true);
    fetchDebounced(inputValue, (err, options) => {
      setLoading(false);
      if (err || !Array.isArray(options)) return;

      setOptions(options);
    });
  }, [inputValue, fetchDebounced]);
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Autocomplete
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          startAdornment={<SearchIcon className={classes.searchIcon} />}
          fullWidth
          placeholder={t("jumpToExhibition")}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onChange={() => {
        setInputValue("");
      }}
      noOptionsText={inputValue === "" ? "" : "Keine Ausstellung gefunden"}
      loading={loading}
      options={options}
      inputValue={inputValue}
      className={props.className}
      clearOnEscape
      blurOnSelect
      clearOnBlur
      renderOption={(option) => (
        <>
          <LinkToExhibition
            exhibitionId={option.exhibitionId}
            className={classes.optionLabel}
            disableLinkFormat
          >
            {option.exhibitionName}
          </LinkToExhibition>
          <LinkToExhibition
            button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.jumpToButton}
            exhibitionId={option.exhibitionId}
          >
            Gehe zu
          </LinkToExhibition>
        </>
      )}
      getOptionLabel={(option) => option.exhibitionName}
      filterOptions={(x) => x}
      onOpen={() => {
        setOpen(true);
      }}
      classes={{
        input: classes.input,
        option: classes.option,
      }}
    />
  );
};

export default ExhibitionPicker;
