export function parseFloatWithDefault<T>(value: any, dflt: T) {
  // the difference of this function to just using `parseFloat(value) || dflt` is the different behavior when `value==="0"`
  var parsed = parseFloat(value);
  return isNaN(parsed) ? dflt : parsed;
}

export function parseIntWithDefault<T>(value: any, dflt: T) {
  // the difference of this function to just using `parseInt(value) || dflt` is the different behavior when `value==="0"`
  var parsed = parseInt(value);
  return isNaN(parsed) ? dflt : parsed;
}
