import { Route } from "react-router-dom";
import React from "react";
import Page, { ExhibitionSettingsExhibition } from "./ExhibitionSettingsPage";
import { ExhibitionId } from "../types";

const suffix = "settings";

export const exhibitionSettingsPageRoute = (
  pathPrefix: string,
  exhibition: ExhibitionSettingsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const exhibitionSettingsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
