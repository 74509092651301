import * as React from "react";
import {
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
  Button,
  Checkbox,
  useTheme,
} from "@material-ui/core";

export interface ColorCombinationDialogProps {
  open: boolean;
  onClose: () => void;
  colors: { colorId: string; colorName: string }[];
  colorCombination: string[];
  onOk: (colorCombination: string[]) => void;
}

const ColorCombinationDialog: React.FunctionComponent<ColorCombinationDialogProps> = (
  props
) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const handleSave = () => {
    props.onOk(colorCombination);
  };
  const [colorCombination, setColorCombination] = React.useState(
    props.colorCombination
  );
  const handleChange = (colorId: string) => {
    setColorCombination((old) =>
      old.includes(colorId)
        ? old.filter((id) => id !== colorId)
        : [...old, colorId]
    );
  };
  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Zusammenstellung</DialogTitle>
      <DialogContent>
        <List disablePadding>
          {props.colors.map((c) => {
            const selected = colorCombination.includes(c.colorId);
            const labelId = `colorcombinationlistitemlabel-${c.colorId}`;
            return (
              <ListItem
                key={c.colorId}
                onClick={() => handleChange(c.colorId)}
                dense
                button
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={c.colorName} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorCombinationDialog;
