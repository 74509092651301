import * as React from "react";
import { useColumn } from "../../../crossCutting/editTable/useColumn";
import InlineEditTextField from "../../../crossCutting/editTable/InlineEditTextField";
import validation from "../../../crossCutting/editTable/validation";
import EditTable from "../../../crossCutting/editTable/EditTable";
import useAlertSnackbar from "../../../crossCutting/hooks/useAlertSnackbar";
import useEdit, {
  EditExistingState,
} from "../../../crossCutting/editTable/useEdit";
import EditList from "../../../crossCutting/EditList";
import { Button } from "@material-ui/core";
import { AddIcon } from "../../../crossCutting/icons";

export interface GenderCodeTableProps {
  genderCodes: GenderCodeItem[];
  onChange: (genderCodes: GenderCodeItem[]) => void;
  disabled?: boolean;
  animalCount: number;
}
export interface GenderCodeItem {
  maleCount: number;
  femaleCount: number;
}
const GenderCodeTable: React.FunctionComponent<GenderCodeTableProps> = (
  props
) => {
  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  const showError = (message: string) => showAlertSnackbar(message, "error");

  const maleColumn = useColumn<GenderCodeItem, string, number>({
    header: "Männlich",
    columnKey: "maleCount",
    defaultValue: 0,
    extractValue: (codeItem) => codeItem.maleCount,
    view: (codeItem) => codeItem.maleCount,
    convert: (raw) => {
      const parsed = parseInt(raw);
      return isNaN(parsed) ? 0 : parsed;
    },
    convertBack: (value) => value?.toString() || "0",
    validate: (value) => value !== undefined && value !== null,
    editorFactory: (editorProps) => (
      <InlineEditTextField {...editorProps} autoSelect />
    ),
  });
  const femaleColumn = useColumn<GenderCodeItem, string, number>({
    header: "Weiblich",
    columnKey: "femaleCount",
    defaultValue: 0,
    extractValue: (codeItem) => codeItem.femaleCount,
    view: (codeItem) => codeItem.femaleCount,
    convert: (raw) => {
      const parsed = parseInt(raw);
      return isNaN(parsed) ? 0 : parsed;
    },
    convertBack: (value) => value?.toString() || "0",
    validate: (value) => value !== undefined && value !== null,
    editorFactory: (editorProps) => <InlineEditTextField {...editorProps} />,
  });

  const columns = [maleColumn, femaleColumn];

  const { omitValidationErrors, validate } = validation(
    columns,
    showError,
    () =>
      (maleColumn.value ?? 0) + (femaleColumn.value ?? 0) === props.animalCount
  );
  const handleSaveNew = () => {
    if (
      !validate() ||
      maleColumn.value === undefined ||
      femaleColumn.value === undefined
    )
      return;
    props.onChange([
      ...props.genderCodes,
      { maleCount: maleColumn.value!, femaleCount: femaleColumn.value! },
    ]);
    stopEditing();
  };
  const handleSaveExisting = () => {
    if (
      !validate() ||
      maleColumn.value === undefined ||
      femaleColumn.value === undefined
    )
      return;
    const editedItem = (editState as EditExistingState<GenderCodeItem>)
      .editedItem;
    props.onChange(
      props.genderCodes.map((i) =>
        i === editedItem
          ? {
              maleCount: maleColumn.value!,
              femaleCount: femaleColumn.value!,
            }
          : i
      )
    );
    stopEditing();
  };
  const handleDelete = (item: GenderCodeItem) => {
    props.onChange(props.genderCodes.filter((i) => i !== item));
  };
  const {
    handleAddNew,
    handleEditExisting,
    stopEditing,
    editState,
    tableEditState,
  } = useEdit(columns, omitValidationErrors, (a, b) => a === b);
  return (
    <EditList
      toolbarItems={
        <>
          <Button
            startIcon={<AddIcon />}
            disabled={props.disabled || editState !== "NotEditing"}
            onClick={handleAddNew}
          >
            Neue Kombination
          </Button>
        </>
      }
    >
      <EditTable
        showHeader
        disabled={props.disabled}
        editState={tableEditState}
        onCancel={stopEditing}
        onEditExisting={handleEditExisting}
        onSaveNew={handleSaveNew}
        onSaveExisting={handleSaveExisting}
        onDelete={handleDelete}
        rowKey={(codeItem) => props.genderCodes.indexOf(codeItem)}
        data={props.genderCodes}
        columns={columns.map((c) => c.tableColumn)}
      />
      {alertSnackbar}
    </EditList>
  );
};

export default GenderCodeTable;
