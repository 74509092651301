import React from "react";
import { DeleteConfirmation } from "../Dialogs";

export default function useDeleter() {
  const [message, setMessage] = React.useState("");
  const [deleteFn, setDeleteFn] = React.useState<() => void>();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(
    false
  );

  const handleDeleteConfirmationClose = (decision: boolean) => {
    const fn = deleteFn;
    setDeleteFn(undefined);
    setDeleteConfirmationOpen(false);
    if (decision && fn) {
      fn();
    }
  };

  const deleteConfirmation = (
    <DeleteConfirmation
      open={deleteConfirmationOpen}
      onClose={handleDeleteConfirmationClose}
      message={message}
    />
  );

  return {
    deleteConfirmation,
    deleteWithConfirmation: (message: string, deleteFn: () => void) => {
      setMessage(message);
      setDeleteFn(() => deleteFn);
      setDeleteConfirmationOpen(true);
    },
  };
}
