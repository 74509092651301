import {
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import {
  AddIcon,
  ChangeOrderIcon,
  HideIcon,
  ShowIcon,
} from "../../../crossCutting/icons";
import JumpToButton from "../../../crossCutting/JumpToButton";
import { Origin } from "../apiClient/commonTypes";
import ChangeOrderDialog from "../ChangeOrderDialog";
import TableToolbar from "../TableToolbar";
import { useTranslatorForUser } from "../translation";
import useTableSelection from "../useTableSelection";

const useStyles = makeStyles((theme) => ({
  numericCell: {
    textAlign: "right",
  },
  inactiveRow: {
    "& td": {
      color: theme.palette.text.secondary,
    },
  },
}));

export interface Color {
  colorId: string;
  referenceRating: number | null;
  isActive: boolean;
  captions: {
    language: string;
    colorName: string;
  }[];
  origin: Origin;
}
export default function ColorTable<T extends Color>(props: {
  animalStandard: { hasReferenceRating: boolean };
  colors: T[];
  onEdit: (color?: T) => void;
  onToggleActivation: (active: boolean, colorIds: string[]) => void;
  onOrderChange: (reorderedColorIds: string[]) => void;
}) {
  const classes = useStyles();
  const { rowClass, HoverActionCell } = useHoverActionCell();

  const {
    selectedIds,
    isSelected: isColorSelected,
    selectionStatus,
    handleSelectAllChange,
    selectionCount,
    toggleSelection,
  } = useTableSelection(props.colors, (c) => c.colorId);
  const handleRowClick = (colorId: string) => {
    toggleSelection(colorId);
  };
  const handleEditClick = (e: any, color: T) => {
    e.stopPropagation();
    props.onEdit(color);
  };
  const handleNewClick = () => {
    props.onEdit();
  };
  const handleActivateSelected = () => {
    props.onToggleActivation(true, selectedIds);
  };
  const handleDeactivateSelected = () => {
    props.onToggleActivation(false, selectedIds);
  };

  const [changeOrderDialogOpen, setChangeOrderDialogOpen] = React.useState(
    false
  );
  const handleCancelChangeOrder = () => setChangeOrderDialogOpen(false);
  const handleSaveChangedOrder = (reorderedColorIds: string[]) => {
    props.onOrderChange(reorderedColorIds);
    setChangeOrderDialogOpen(false);
  };
  const handleChangeOrderClick = () => {
    setChangeOrderDialogOpen(true);
  };
  const translator = useTranslatorForUser();
  return (
    <EditList
      toolbarItems={
        <>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewClick}
          >
            Neuer Farbenschlag
          </Button>
          <Button
            startIcon={<ChangeOrderIcon />}
            onClick={handleChangeOrderClick}
          >
            Reihenfolge ändern
          </Button>
        </>
      }
    >
      <div>
        <TableToolbar
          selectionCount={selectionCount}
          titleWhenSelected={`${selectionCount} ${
            selectionCount === 1 ? " Farbenschlag " : " Farbenschläge "
          } ausgewählt`}
          contentWhenSelected={
            <>
              <Tooltip title="Ausgewählte aktivieren">
                <IconButton
                  aria-label="aktivieren"
                  onClick={handleActivateSelected}
                  color="primary"
                >
                  <ShowIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ausgewählte ausblenden">
                <IconButton
                  color="primary"
                  aria-label="ausblenden"
                  onClick={handleDeactivateSelected}
                >
                  <HideIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectionStatus === "all"}
                  indeterminate={selectionStatus === "indeterminate"}
                  inputProps={{ "aria-label": "alle auswählen" }}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              {props.animalStandard.hasReferenceRating && (
                <TableCell className={classes.numericCell}>
                  Richtpunktzahl
                </TableCell>
              )}
              <TableCell>Herkunft</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.colors.map((c) => {
              const translatedCaption = translator(c.captions);
              const isSelected = isColorSelected(c);
              const labelId = `color-table-checkbox-${c.colorId}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  selected={isSelected}
                  className={clsx(rowClass, !c.isActive && classes.inactiveRow)}
                  key={c.colorId}
                  onClick={() => handleRowClick(c.colorId)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell>{translatedCaption?.colorName}</TableCell>
                  {props.animalStandard.hasReferenceRating && (
                    <TableCell className={classes.numericCell}>
                      {c.referenceRating?.toFixed(2)}
                    </TableCell>
                  )}
                  <TableCell>{c.origin ? "Ausstellung" : "Global"}</TableCell>
                  <TableCell>
                    {c.isActive ? (
                      <ShowIcon titleAccess="Aktiv" />
                    ) : (
                      <HideIcon titleAccess="Inaktiv" />
                    )}
                  </TableCell>
                  <HoverActionCell>
                    <JumpToButton onClick={(e) => handleEditClick(e, c)}>
                      Bearbeiten
                    </JumpToButton>
                  </HoverActionCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <ChangeOrderDialog
        open={changeOrderDialogOpen}
        onCancel={handleCancelChangeOrder}
        onSave={handleSaveChangedOrder}
        title={"Reihenfolge ändern"}
        saveButtonTitle="OK"
        items={props.colors.map((c) => ({
          key: c.colorId,
          orderable: translator(c.captions)?.colorName,
        }))}
      />
    </EditList>
  );
}
