import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import { inDefaultLanguage } from "../../../../language";
import CaptionEditor from "../../CaptionEditor";

export default function AgeClassDialog<
  T extends { captions: { language: string; ageClassName: string }[] }
>(props: {
  open: boolean;
  onCancel: () => void;
  ageClass: T;
  onSave: (captions: T) => void;
  loading?: boolean;
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const [ageClass, setAgeClass] = React.useState(props.ageClass);
  const hasDefaultLanguage = !!inDefaultLanguage(ageClass.captions)
    ?.ageClassName;
  const captionFieldDescriptors = [
    {
      value: (caption: any) => caption?.ageClassName || "",
      onChange: (language: string, newValue: string) => {
        const old = ageClass.captions;
        const itemForLanguage = old.find((i) => i.language === language);
        const captions = itemForLanguage
          ? [
              ...old.filter((i) => i.language !== language),
              { ...itemForLanguage, ageClassName: newValue },
            ]
          : [...old, { language, ageClassName: newValue }];
        setAgeClass({ ...ageClass, captions });
      },
    },
  ];
  const valid = hasDefaultLanguage;
  const handleSave = () => {
    props.onSave(ageClass);
  };
  const handleClose = () => {
    props.onCancel();
  };
  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Alterskategorie</DialogTitle>
      <DialogContent>
        <CaptionEditor
          autoFocus
          captions={ageClass.captions}
          disableHeader
          fieldDescriptors={captionFieldDescriptors}
          disabled={props.loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={props.loading}>
          Abbrechen
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={!valid || props.loading}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
