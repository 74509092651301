import { Route } from "react-router-dom";
import React from "react";
import Page from "./HomePage";
import AppLink, { AppLinkProps } from "../../crossCutting/AppLink";

const path = "/";
export const homePageRoute = () => (
  <Route path={path}>
    <Page />
  </Route>
);

export const homePagePath = () => path;

export const LinkToHome: React.FunctionComponent<AppLinkProps> = (props) => (
  <AppLink {...props} to={path} />
);
