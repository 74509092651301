import { createMuiTheme, Theme } from "@material-ui/core";
import { frFR, deDE } from "@material-ui/core/locale";
import { defaultLanguage, french } from "./language";

export const kleintiereGelb = "#fdce2a";
export const einBlau = "#2a58fd";

function localizationByLanguage(language: string) {
  return language === french ? frFR : deDE;
}
export default function createKlexpoTheme(language: string = defaultLanguage) {
  return (baseTheme: Theme) =>
    createMuiTheme(
      {
        ...baseTheme,
        palette: {
          type: "light",
          primary: { main: einBlau },
          secondary: { main: kleintiereGelb },
        },

        props: {
          MuiRadio: { color: "primary" },
          MuiCheckbox: { color: "primary" },
          MuiSelect: { variant: "outlined" },
          MuiChip: { variant: "outlined" },
          MuiFormControl: { variant: "outlined" },
          MuiTextField: { variant: "outlined" },
          MuiSwitch: { color: "primary" },
          MuiTabs: { textColor: "primary", indicatorColor: "primary" },
        },

        overrides: {
          // Set the minHeight of each typography component to its fontSize.
          // If this is not done, the minHeight is auto which can lead to
          // text being clipped especially if the typography component is a
          // flex child. It can be observed with the title of RegistrationsPage
          // if the window is very short.
          MuiTypography: {
            h1: { minHeight: baseTheme.typography.h1.fontSize },
            h2: { minHeight: baseTheme.typography.h2.fontSize },
            h3: { minHeight: baseTheme.typography.h3.fontSize },
            h4: { minHeight: baseTheme.typography.h4.fontSize },
            h5: { minHeight: baseTheme.typography.h5.fontSize },
            h6: { minHeight: baseTheme.typography.h6.fontSize },
            subtitle1: { minHeight: baseTheme.typography.subtitle1.fontSize },
            subtitle2: { minHeight: baseTheme.typography.subtitle2.fontSize },
            body1: { minHeight: baseTheme.typography.body1.fontSize },
            body2: { minHeight: baseTheme.typography.body2.fontSize },
            caption: { minHeight: baseTheme.typography.caption.fontSize },
            button: { minHeight: baseTheme.typography.button.fontSize },
            overline: { minHeight: baseTheme.typography.overline.fontSize },
          },
          MuiButtonBase: { root: { textAlign: "center" } },
        },
      },
      localizationByLanguage(language)
    );
}
