import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import AppLink from "./AppLink";

const useStyles = makeStyles({
  jumpToButton: {
    whiteSpace: "nowrap",
  },
});

const JumpToButton: React.FunctionComponent<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      size="small"
      className={classes.jumpToButton}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const JumpToLinkButton: React.FunctionComponent<{
  to: string;
}> = (props) => {
  const classes = useStyles();
  return (
    <AppLink
      color="primary"
      size="small"
      button
      to={props.to}
      className={classes.jumpToButton}
    >
      {props.children}
    </AppLink>
  );
};

export default JumpToButton;
