import {
  Box,
  List,
  ListItem,
  ListItemText,
  PropTypes,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import { useFetch2 } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import {
  RegistrationInList,
  registrationsApiPath,
} from "../../api/exhibitions/registrations";
import { useCurrentUserMember } from "../ExhibitionUserProvider";
import { IfCanRegisterSelfInContext } from "../IfCanRegisterInContext";
import { LinkToRegistrationNew } from "../registrationEditPage/routing";
import { LinkToRegistration } from "../registrationPage/routing";
import { LinkToRegistrationsPage } from "../registrationsPage/routing";
import { ExhibitionId } from "../types";

export default function RegistrationList({
  exhibitionId,
}: {
  exhibitionId: ExhibitionId;
}) {
  const { t } = useTranslation();
  const member = useCurrentUserMember();
  const [currentUsersRegistrations] = useFetch2<{
    registrations: RegistrationInList[];
  }>(
    member?.memberId
      ? registrationsApiPath(exhibitionId, {
          memberIdRegistered: member.memberId,
        })
      : ""
  );

  const howManyToDisplay = 3;
  const top3Registrations = mapState(currentUsersRegistrations, (r) =>
    topN(r.registrations, howManyToDisplay)
  );
  return top3Registrations.isSuccess() && member?.memberId ? (
    top3Registrations.data.items.length ? (
      <>
        <List component="div" disablePadding>
          {top3Registrations.data.items.map((r) => (
            <ListItem disableGutters key={r.registrationId}>
              <LinkToRegistration
                exhibitionId={exhibitionId}
                registrationId={r.registrationId}
              >
                <ListItemText
                  primary={r.breed.breedName}
                  secondary={r.unit.unitName}
                />
              </LinkToRegistration>
            </ListItem>
          ))}
        </List>
        {top3Registrations.data.bottomCount ? (
          <Box marginBottom={spacing3}>
            <LinkToRegistrationsPage
              exhibitionId={exhibitionId}
              memberIdRegistered={member.memberId}
            >
              {t("moreRegistrationsTemplate", {
                registrationCount: top3Registrations.data.bottomCount,
              })}
            </LinkToRegistrationsPage>
          </Box>
        ) : null}
        <RegisterButton
          exhibitionId={exhibitionId}
          text={t("registerAdditionalAnimals")}
          color="primary"
        />
      </>
    ) : (
      <Box mt={spacing3}>
        <Typography>{t("noAnimalsRegisteredForExhibition")}</Typography>
        <Box paddingTop={spacing3}>
          <RegisterButton exhibitionId={exhibitionId} text={t("registerNow")} />
        </Box>
      </Box>
    )
  ) : null;
}

function topN<T>(items: T[], count: number) {
  const bottomCount = items.length - count;
  return {
    items: items.slice(0, count),
    bottomCount: bottomCount > 0 ? bottomCount : 0,
  };
}

function RegisterButton(props: {
  exhibitionId: ExhibitionId;
  text: string;
  color?: PropTypes.Color;
}) {
  const color = props.color || "secondary";
  return (
    <IfCanRegisterSelfInContext>
      <LinkToRegistrationNew
        exhibitionId={props.exhibitionId}
        button
        variant="contained"
        color={color}
      >
        {props.text}
      </LinkToRegistrationNew>
    </IfCanRegisterSelfInContext>
  );
}
