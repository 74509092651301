import * as React from "react";
import EditToolbar from "./EditToolbar";

export interface EditListProps {
  toolbarItems: React.ReactNode;
}

const EditList: React.FunctionComponent<EditListProps> = (props) => {
  return (
    <>
      <EditToolbar>{props.toolbarItems}</EditToolbar>
      {props.children}
    </>
  );
};

export default EditList;
