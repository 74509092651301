import { Maybe } from "./maybe";

const multipleSlashesExceptAfterColon = /(^|[^:])\/{2,}/g;

export function makeUrl(
  templateParts: TemplateStringsArray,
  ...tokens: Maybe<string>[]
) {
  return templateParts
    .map((p, i) => p + (tokens[i] ?? ""))
    .join("")
    .replace(multipleSlashesExceptAfterColon, "$1/");
}

export function parseQuery(query: string) {
  return (
    query
      ?.replace(/^\?/, "")
      .split("&")
      .reduce((prev, cur) => {
        const [k, v] = cur.split("=");
        prev[k] = v;
        return prev;
      }, {} as { [key: string]: string }) ?? {}
  );
}

/** Retunrs a query string starting with ? or an empty string. */
export function buildQuery(parts: {
  [key: string]: string | number | undefined | null;
}) {
  var query = Object.keys(parts)
    .map((key) => {
      let value = parts[key];
      if (typeof value === "string") value = encodeURIComponent(value);
      return [key, value] as [string, string];
    })
    .filter((i) => i[1] !== null && i[1] !== undefined && i[1] !== "")
    .map((i) => i.join("="))
    .join("&");
  return query ? "?" + query : "";
}
