import { Item } from "./ChangeOrderDialog";
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import UpDown from "../../crossCutting/UpDown";

interface ChangeOrderListProps {
  items: Item[];
  onUp: (key: string) => void;
  onDown: (key: string) => void;
}

const useOrderListStyles = makeStyles((theme) => ({
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export default function ChangeOrderList(props: ChangeOrderListProps) {
  const [selectedKey, setSelectedKey] = React.useState<string>();
  const selectedIndex = props.items.findIndex((i) => i.key === selectedKey);
  const handleUp = () =>
    selectedIndex !== -1 &&
    selectedKey !== undefined &&
    props.onUp(selectedKey);
  const handleDown = () =>
    selectedIndex !== -1 &&
    selectedKey !== undefined &&
    props.onDown(selectedKey);
  const toggleSelection = (key: string) => {
    setSelectedKey((old) => (old === key ? undefined : key));
  };
  const classes = useOrderListStyles();
  return (
    <>
      <UpDown
        upPossible={selectedIndex > 0}
        downPossible={selectedIndex !== props.items.length - 1}
        disabled={selectedIndex === -1}
        onUp={handleUp}
        onDown={handleDown}
      />
      <List disablePadding>
        {props.items.map((item) => {
          const selected = item.key === selectedKey;
          return (
            <ListItem
              key={item.key}
              selected={selected}
              onClick={() => toggleSelection(item.key)}
              className={classes.row}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected}
                  tabIndex={-1}
                  disableRipple
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    toggleSelection(item.key);
                  }}
                />
              </ListItemIcon>
              {item.orderable}
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
