import CategoryIcon from "@material-ui/icons/Category";
import ContactsIcon from "@material-ui/icons/Contacts";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import GavelIcon from "@material-ui/icons/Gavel";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import TuneIcon from "@material-ui/icons/Tune";
import GroupIcon from "@material-ui/icons/Group";
import * as React from "react";
import AppRole from "../../authorization/appRoles";
import { ForRoles } from "../../authorization/RolesContext";
import DrawerMenu, {
  DrawerActions,
  DrawerMenuItem,
} from "../../crossCutting/DrawerMenu";
import { categoriesPagePath } from "./categoriesPage/routing";
import { competitionsPagePath } from "./competitionsPage/routing";
import { clubCompetitionsPagePath } from "./clubCompetitionsPage/routing";
import { exhibitionSettingsPagePath } from "./exhibitionSettingsPage/routing";
import { Role } from "./ExhibitionUserProvider";
import { judgesPagePath } from "./judgesPage/routing";
import { membersPagePath } from "./membersPage/routing";
import { registrationsPagePath } from "./registrationsPage/routing";
import { roomsPagePath } from "./roomsPage/routing";
import { ExhibitionId } from "./types";
import MasterDataMenu from "../masterdata/MasterDataMenu";
import { exhibitionStandardPagePath } from "../masterdata/standardPage/routingInExhibition";
import { exhibitionBoxTypesPagePath } from "../masterdata/boxTypesPage/routingInExhibition";
import { useTranslation } from "../../i18n/i18n";
import { organizationsPagePath } from "./organizationsPage/routing";

export default function ExhibitionMenu({
  exhibitionId,
  drawerActions,
}: {
  exhibitionId: ExhibitionId;
  drawerActions?: DrawerActions;
}) {
  const handleItemClick = () => {
    drawerActions?.setDrawerOpen(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <DrawerMenu>
        {([
          {
            text: t("registrations"),
            path: registrationsPagePath(exhibitionId),
            icon: <HowToRegIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer", "Member"],
          },
          {
            text: t("members"),
            path: membersPagePath(exhibitionId),
            icon: <ContactsIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("organizations"),
            path: organizationsPagePath(exhibitionId),
            icon: <GroupIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("competitions"),
            path: competitionsPagePath(exhibitionId),
            icon: <EmojiEventsIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("clubCompetitions"),
            path: clubCompetitionsPagePath(exhibitionId),
            icon: <TuneIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("judges"),
            path: judgesPagePath(exhibitionId),
            icon: <GavelIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("exhibitionRooms"),
            path: roomsPagePath(exhibitionId),
            icon: <ViewQuiltIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("categories"),
            path: categoriesPagePath(exhibitionId),
            icon: <CategoryIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
          },
          {
            text: t("configuration"),
            path: exhibitionSettingsPagePath(exhibitionId),
            icon: <SettingsIcon />,
            roles: [AppRole.ExhibitionAdmin, "Organizer"],
            notExact: true,
          },
        ] as Item[]).map((item) =>
          item.roles ? (
            <ForRoles roles={item.roles} key={item.text}>
              <DrawerMenuItem {...item} onClick={handleItemClick} />
            </ForRoles>
          ) : (
            <DrawerMenuItem key={item.text} {...item} />
          )
        )}
      </DrawerMenu>
      <MasterDataMenu
        strategy={{
          rootItemTitle: t("masterData"),
          standardPagePath: () => exhibitionStandardPagePath(exhibitionId),
          boxTypesPagePath: () => exhibitionBoxTypesPagePath(exhibitionId),
        }}
      />
    </>
  );
}

interface Item {
  text: string;
  path: string;
  icon: React.ReactNode;
  roles?: Role[];
}
