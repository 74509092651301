import * as React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { ExhibitionId } from "../../exhibitions/types";
import {
  AgeClass,
  CreateAgeClassInput,
  UpdateAgeClassInput,
  ChangeAgeClassOrderInput,
} from "./ageClassTypes";
import { makeUrl } from "../../../crossCutting/urls";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useAgeClasses(
  animalStandardId: string,
  exhibitionId?: ExhibitionId
) {
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [ageClasses, setAgeClasses] = React.useState([] as AgeClass[]);
  const refresh = React.useCallback(() => {
    setLoading(true);
    return fetcher(
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}`,
      {
        method: "GET",
      }
    )
      .then(({ ageClasses }) => setAgeClasses(ageClasses))
      .finally(() => setLoading(false));
  }, [animalStandardId, urlPrefix, fetcher]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then(() =>
          refresh().then(() => {
            if (messages?.success) {
              showAlert(messages?.success, "success");
            }
          })
        )
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createAgeClass = updater<CreateAgeClassInput>(
    () =>
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/ageclasses`,
    POST,
    {
      error: "Fehler beim Speichern der Alterskategorie.",
      success: "Die Alterskategorie wurde erstellt.",
    },
    (data) => data
  );

  const changeAgeClassOrder = updater<ChangeAgeClassOrderInput>(
    (data) =>
      makeUrl`${urlPrefix}/masterdata/animalstandards/${animalStandardId}/ageclasses/changeOrder`,
    PUT,
    { error: "Fehler beim Sortieren der Alterskategorien." },
    (data) => ({
      ageClassIds: data.ageClassIds,
    })
  );

  const updateAgeClass = updater<UpdateAgeClassInput & { ageClassId: string }>(
    (data) =>
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/ageclasses/${data.ageClassId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren der Alterskategorie.",
      success: "Die Änderungen wurden gespeichert.",
    },
    (data) => ({
      captions: data.captions,
    })
  );

  return {
    ageClasses,
    refresh,
    loading,
    createAgeClass,
    updateAgeClass,
    changeAgeClassOrder,
  };
}
