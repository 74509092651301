import { Button, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { dateString } from "../../../crossCutting/date";
import { BackIcon, NextIcon } from "../../../crossCutting/icons";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import PaperPage from "../../../crossCutting/pages/PaperPage";
import { ErrorSnackbar } from "../../../crossCutting/Snackbars";
import { useFetch, useSendJson } from "../../../fetching/fetchProvider";
import { exhibitionCreatedPath } from "./routing";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3RegistrationInfo from "./Step3RegistrationInfo";
import Step4OrderInfo from "./Step4OrderInfo";
import { NewExhibition } from "./types";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { AnimalStandard } from "../../masterdata/apiClient/animalStandardTypes";

export interface NewExhibitionPageProps {}

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(spacing3),
    display: "flex",
    justifyContent: "center",
  },
  component: {},
  marginLeft: {
    marginLeft: theme.spacing(spacing3),
  },
}));

const NewExhibitionPage: React.FunctionComponent<NewExhibitionPageProps> = () => {
  const {
    loading: standardsLoading,
    error,
    data: standardsWrapper,
  } = useFetch<{
    animalStandards: AnimalStandard[];
  }>("masterdata/animalstandards");
  const [stepIndex, setStepIndex] = React.useState(0);
  const [exhibition, setExhibition] = React.useState(defaultExhibition());
  const [selectedStandards, setSelectedStandards] = React.useState(
    [] as AnimalStandard[]
  );
  const classes = useStyles();
  const history = useHistory();
  const { sendJson, loading } = useSendJson();
  const exhibitionValid =
    !!exhibition?.city.trim() &&
    !!exhibition?.exhibitionName.trim() &&
    !!exhibition?.deadline &&
    !!exhibition?.startDate &&
    !!exhibition?.endDate;

  const handleNext = () => {
    setStepIndex((old) => ++old);
  };
  const handleBack = () => setStepIndex((old) => --old);
  const handleCreate = () => {
    sendJson({
      body: {
        ...exhibition,
        exhibitionName: exhibition.exhibitionName.trim(),
        city: exhibition.city.trim(),
        orderInfo: exhibition.orderInfo?.trim(),
        registrationInfo: exhibition.registrationInfo?.trim(),
        animalStandardIds: selectedStandards.map((s) => s.animalStandardId),
      },
      path: "exhibitions",
      onSuccess: (data) => {
        history.push(exhibitionCreatedPath(data.exhibitionId));
      },
    });
  };

  const standards = standardsWrapper?.animalStandards;
  const steps = [
    {
      component: <Step1 value={exhibition} onChange={setExhibition} />,
      buttons: (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          endIcon={<NextIcon />}
          disabled={!exhibitionValid}
        >
          Standards wählen
        </Button>
      ),
    },
    {
      component:
        standardsLoading || !standards ? (
          <LoadingIndicator />
        ) : (
          <Step2
            onChange={setSelectedStandards}
            allStandards={standards}
            selectedStandards={selectedStandards}
          />
        ),
      buttons: (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBack}
            startIcon={<BackIcon />}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.marginLeft}
            onClick={handleNext}
            endIcon={<NextIcon />}
            disabled={!exhibitionValid}
          >
            Anmeldungs-Info eingeben
          </Button>
        </>
      ),
    },
    {
      component: (
        <Step3RegistrationInfo
          registrationInfo={exhibition.registrationInfo || ""}
          onChange={(registrationInfo) => {
            setExhibition((e) => ({
              ...e,
              registrationInfo,
            }));
          }}
        />
      ),
      buttons: (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBack}
            startIcon={<BackIcon />}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.marginLeft}
            onClick={handleNext}
            endIcon={<NextIcon />}
            disabled={loading}
          >
            Bestell-Info eingeben
          </Button>
        </>
      ),
    },
    {
      component: (
        <Step4OrderInfo
          orderInfo={exhibition.orderInfo || ""}
          onChange={(orderInfo) => {
            setExhibition((e) => ({
              ...e,
              orderInfo,
            }));
          }}
        />
      ),
      buttons: (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBack}
            startIcon={<BackIcon />}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.marginLeft}
            onClick={handleCreate}
            disabled={loading}
          >
            Ausstellung Erstellen
          </Button>
        </>
      ),
    },
  ];

  const step = steps[stepIndex];

  return (
    <PaperPage maxWidth="sm" title="Neue Ausstellung">
      <div className={classes.component}>{step.component}</div>
      <div className={classes.buttons}>{step.buttons}</div>
      <ErrorSnackbar open={!!error} message={error?.friendlyMessage || ""} />
    </PaperPage>
  );
};

export default NewExhibitionPage;

const defaultExhibition = () =>
  ({
    exhibitionName: "",
    startDate: dateString(new Date()),
    endDate: dateString(new Date()),
    city: "",
    deadline: "",
    orderInfo: "",
    registrationInfo: "",
  } as NewExhibition);
