import { Route } from "react-router-dom";
import React from "react";
import Page from "./AnimalStandardsPage";
import { ExhibitionId } from "../../exhibitions/types";
import { makeUrl } from "../../../crossCutting/urls";
import { useAnimalStandards } from "../apiClient/useAnimalStandards";

export const exhibitionStandardsManagePageRoute = (
  pathPrefix: string,
  exhibition: { exhibitionName: string; exhibitionId: ExhibitionId }
) => (
  <Route path={`${pathPrefix}/masterdata/animalstandards`}>
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        makeAnimalStandardsDataHook: () => () =>
          useAnimalStandards(exhibition.exhibitionId),
      }}
    />
  </Route>
);

export const exhibitionStandardsPagePath = (exhibitionId: ExhibitionId) =>
  makeUrl`/exhibitions/${exhibitionId}/masterdata/animalstandards/`;
