import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { useFetch2, useSendJson } from "../../../fetching/fetchProvider";
import {
  Organizer,
  organizersApiPath,
  OrganizersResult,
} from "../../api/exhibitions/organizers";
import { ExhibitionId } from "../types";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  addButton: {
    alignSelf: "center",
    marginTop: theme.spacing(1),
  },
}));

export default function OrganizersTabPanel(props: {
  exhibitionId: ExhibitionId;
}) {
  const [email, setEmail] = React.useState("");
  const [organizersState, refreshOrganizers] = useFetch2<OrganizersResult>(
    organizersApiPath(props.exhibitionId)
  );
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    addOrganizer();
  };
  const {
    sendJson: postNewOrganizer,
    loading: postingNewOrganizer,
  } = useSendJson({
    error: "Organisator konnte nicht hinzugefügt werden.",
  });
  const addOrganizer = () => {
    postNewOrganizer({
      path: organizersApiPath(props.exhibitionId),
      body: { email },
      onSuccess: () => {
        refreshOrganizers();
        setEmail("");
      },
    });
  };
  const classes = useStyles();
  return (
    <>
      <Box>
        <form onSubmit={handleFormSubmit} className={classes.form}>
          <TextField
            helperText="E-Mail bzw. Benutzername eines bestehenden Klexpo-Benutzers"
            label="Organisator hinzufügen"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className={classes.addButton}
            variant="outlined"
            color="primary"
            onClick={addOrganizer}
            disabled={postingNewOrganizer || !email}
            size="small"
          >
            Hinzufügen
          </Button>
        </form>
      </Box>
      {organizersState.isSuccess() ? (
        <Box marginTop={spacing3}>
          <Typography variant="h5" component="h3">
            Organisatoren
          </Typography>
          <OrganizerTable organizers={organizersState.data.organizers} />
        </Box>
      ) : organizersState.isRunning() ? (
        <LoadingIndicator />
      ) : (
        <div>Error</div>
      )}
    </>
  );
}

function OrganizerTable({ organizers }: { organizers: Organizer[] }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>E-Mail</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {organizers.map((o) => (
          <TableRow key={o.userId}>
            <TableCell>{fullNameReversed(o.givenName, o.familyName)}</TableCell>
            <TableCell>{o.email}</TableCell>
            <TableCell>{o.isExhibitionCreator ? "Ersteller" : ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function fullNameReversed(firstName: string, lastName: string) {
  return firstName ? (lastName ? `${lastName} ${firstName}` : firstName) : "";
}
