import { Route } from "react-router-dom";
import React from "react";
import { ExhibitionId } from "../types";
import RegistrationDeletedPage, {
  RegistrationDeletedExhibition,
} from "./RegistrationDeletedPage";

const suffix = "registrations";

export const registrationDeletedPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}/deleted`;

export const registrationDeletedPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationDeletedExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/deleted`}>
    <RegistrationDeletedPage exhibition={exhibition} />
  </Route>
);
