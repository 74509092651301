import { Route } from "react-router-dom";
import React from "react";
import Page, { CompetitionEditExhibition } from "./CompetitionEditPage";
import { ExhibitionId } from "../types";
import { uuidRegex } from "../../../crossCutting/uuid";

const suffix = "competitions";

export const competitionNewPageRoute = (
  pathPrefix: string,
  exhibition: CompetitionEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/new`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const competitionNewPagePath = (
  exhibitionId: ExhibitionId,
  animalStandardId: string
) =>
  `/exhibitions/${exhibitionId}/${suffix}/new?animalStandardId=${animalStandardId}`;

export const competitionEditPageRoute = (
  pathPrefix: string,
  exhibition: CompetitionEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:competitionId(${uuidRegex})/edit`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const competitionEditPagePath = (
  exhibitionId: ExhibitionId,
  competitionId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${competitionId}/edit`;
