import React from "react";
import { TextField } from "@material-ui/core";

export default function InlineEditTextField({
  value,
  error,
  onChange,
  placeholder,
  autoSelect,
  disabled,
}: {
  value: string;
  error?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  autoSelect?: boolean;
  disabled?: boolean;
}) {
  const inputRef: any = React.useRef();
  React.useEffect(() => {
    if (autoSelect) inputRef.current?.select();
  }, [autoSelect]);

  return (
    <TextField
      inputRef={inputRef}
      value={value}
      error={error}
      placeholder={placeholder}
      fullWidth
      variant="outlined"
      size="small"
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
}
