import * as React from "react";
import german from "./translations/de.json";
import french from "./translations/fr.json";
import { escapeRegex } from "../crossCutting/strings";

// A value in a translation file (e.g. in "de.json") can be just a string or a
// string containing variables enclosed in {{}} (e.g. "Registration
// {{registrationNumber}}"). In the latter case, an object with fields
// resembling the variables must be supplied to the translation function (e.g.
// {registrationNumber: 1234}).

const translations: Translations = {
  de: german,
  fr: french,
};

const I18nContext = React.createContext({ translation: german });

export function I18nProvider({
  language,
  children,
}: React.PropsWithChildren<{ language: string }>) {
  const translation = getTranslation(language);
  return (
    <I18nContext.Provider value={{ translation }}>
      {children}
    </I18nContext.Provider>
  );
}

export function useTranslation() {
  const { translation } = React.useContext(I18nContext);
  const getString = React.useCallback(
    (key: StringKeys, variables?: any) => {
      const template = translation[key];
      return variables ? replaceVariables(template, variables) : template;
    },
    [translation]
  );

  return { t: getString };
}

function getTranslation(language: string) {
  return translations[language];
}

interface Translations {
  [language: string]: TranslationDict;
}

type StringKeys = keyof typeof german;
type TranslationDict = { [key in StringKeys]: string };

export type TranslationFunc = (key: StringKeys, variables?: any) => string;

function replaceVariables(template: string, variables: any) {
  const variablesMap = Object.keys(variables).reduce((p, c) => {
    p[`{{${c}}}`] = variables[c];
    return p;
  }, {} as { [key: string]: any });
  return template.replace(
    new RegExp(
      Object.keys(variablesMap)
        .map((k) => escapeRegex(k))
        .join("|"),
      "gi"
    ),
    (matched) => variablesMap[matched]
  );
}
