export enum MedalDistribution {
  ByPercent = "ByPercent",
  ByMedalCount = "ByMedalCount",
  ByRank = "ByRank",
}
export const medalDistributions = [
  {
    value: MedalDistribution.ByRank,
    label: "Nach Rang",
    valueLabel: "Bis Rang",
  },
  {
    value: MedalDistribution.ByMedalCount,
    label: "Anzahl Medaillen",
    valueLabel: "Medaillen",
  },
  {
    value: MedalDistribution.ByPercent,
    label: "Nach Prozent der Teilnehmer",
    valueLabel: "%",
  },
];
