import { Route } from "react-router-dom";
import React from "react";
import Page from "./OrganizationsPage";
import { ExhibitionId } from "../types";
import { OrganizationsExhibition } from "./OrganizationsPage";

const suffix = "organizations";

export const organizationsPageRoute = (
  pathPrefix: string,
  exhibition: OrganizationsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const organizationsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
