export const defaultLanguage = "de";

export const masterDataLanguages = ["de", "fr", "it"];

export const french = "fr";

type Language = string;

interface Caption {
  language: Language;
}

export function inLanguageOrDefault<T extends Caption>(
  language: Language,
  captions: T[] | undefined
) {
  return (
    inLanguage(language, captions) || inLanguage(defaultLanguage, captions)
  );
}

export function inLanguage<T extends Caption>(
  language: Language,
  captions: T[] | undefined
) {
  return captions?.find((c) => c.language === language);
}

export function inDefaultLanguage<T extends Caption>(
  captions: T[] | undefined
) {
  return inLanguage(defaultLanguage, captions);
}

export function languagesPrio(prio: Language) {
  return [prio, ...masterDataLanguages.filter((l) => l !== prio)];
}
