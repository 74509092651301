import { ListItemText } from "@material-ui/core";
import * as React from "react";
import { LinkToExhibition } from "../exhibitions/exhibitionPage/routing";
import { ExhibitionId } from "../exhibitions/types";

export interface ExhibitionListItemTextProps {
  exhibitionId: ExhibitionId;
  exhibitionName: string;
  city: string;
  startDate: Date;
}

const ExhibitionListItemText: React.FunctionComponent<ExhibitionListItemTextProps> = (
  props
) => {
  return (
    <LinkToExhibition exhibitionId={props.exhibitionId}>
      <ListItemText
        primary={props.exhibitionName}
        secondary={`${props.city}, ${props.startDate.getFullYear()}`}
      />
    </LinkToExhibition>
  );
};

export default ExhibitionListItemText;
