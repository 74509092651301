import {
  useTheme,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { CheckIcon, CloseIcon } from "../icons";

export default function EditingButtons({
  onSave,
  onCancel,
  saving,
}: {
  onSave: () => void;
  onCancel?: () => void;
  saving?: boolean;
}) {
  const theme = useTheme();
  saving = saving || false;
  return (
    <>
      <Tooltip title="Speichern">
        <IconButton
          disabled={saving}
          edge="end"
          aria-label="speichern"
          onClick={(e) => {
            onSave();
            e.stopPropagation();
          }}
        >
          {saving ? (
            <CircularProgress size="sm" />
          ) : (
            <CheckIcon htmlColor={theme.palette.success.main} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Abbrechen">
        <IconButton
          disabled={saving}
          edge="end"
          aria-label="abbrechen"
          onClick={(e) => {
            onCancel && onCancel();
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
