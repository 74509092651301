import { Maybe } from "../../../crossCutting/maybe";

export function sameJudge<T extends { judgeId: string }>(
  a: Maybe<T>,
  b: Maybe<T>
) {
  return (a === b && (!!a || !!b)) || a?.judgeId === b?.judgeId;
}

export interface Judge {
  judgeId: string;
  fullName: string;
  carrierName: Maybe<string>;
}
