import * as React from "react";
import Page from "../../../crossCutting/pages/Page";
import { useTranslation } from "../../../i18n/i18n";
import { Exhibition } from "../apiTypes";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@material-ui/core";
import { useFetch2 } from "../../../fetching/fetchProvider";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { friendlyDateTime } from "../../../crossCutting/date";
import { Publication } from "./publicationApiTypes";
import { LinkToPublicationPage } from "./routing";

export type PublicationsExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface PublicationsPageProps {
  exhibition: PublicationsExhibition;
}

const PublicationsPage: React.FunctionComponent<PublicationsPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const [publicationState] = useFetch2<{ publications: Publication[] }>(
    `exhibitions/${props.exhibition.exhibitionId}/publications`
  );

  return publicationState.isRunning() ? (
    <LoadingIndicator />
  ) : publicationState.isError() ? (
    <>Error</>
  ) : (
    <Page
      title={props.exhibition.exhibitionName}
      subtitle={t("catalogPublication")}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("publicationDate")}</TableCell>
            <TableCell>{t("publishedBy")}</TableCell>
            <TableCell>{t("recipients")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {publicationState.data.publications.map((p) => (
            <TableRow key={p.publicationId}>
              <TableCell>
                {friendlyDateTime(new Date(p.fileUploadedUtc))}
              </TableCell>
              <TableCell>{p.uploaderFullName}</TableCell>
              <TableCell>
                <LinkToPublicationPage
                  exhibitionId={props.exhibition.exhibitionId}
                  publicationId={p.publicationId}
                >
                  {`${p.recipients.length} ${t("recipients")}`}
                </LinkToPublicationPage>
              </TableCell>
              <TableCell>
                <Link href={p.url} target="_blank">
                  {t("download")}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Page>
  );
};

export default PublicationsPage;
