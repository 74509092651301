import * as React from "react";
import {
  Popper,
  makeStyles,
  Button,
  debounce,
  TextField,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@material-ui/lab/Autocomplete";
import { SelectorProps } from "./selectorProps";
import { CloseIcon, SearchIcon } from "./icons";
import { useTranslation } from "../i18n/i18n";

const useStyles = makeStyles((theme) => ({
  popper: {
    width: 300,
    backgroundColor: "white",
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    /* above:
     * - the label that is displayed above a textbox (1)
     * - a sticky table header (2)
     */
    zIndex: 3,
  },
  popperDisablePortal: {
    position: "relative",
  },
  paper: {
    boxShadow: "none",
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  startAdornment: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  endAdornment: {
    marginRight: theme.spacing(1),
  },
  checkbox: { paddingTop: 0, paddingBottom: 0, paddingLeft: 0 },
}));

export default function AutocompletePicker<T>({
  value,
  onChange,
  buttonLabel,
  multiple,
  comparer,
  getOptionLabel,
  renderOption,
  fetchOptions,
  noOptionsText,
  startIcon,
  variant,
  color,
  placeholder,
  size,
}: AutocompletePickerProps<T>) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [popperAnchor, setPopperAnchor] = React.useState<HTMLElement>();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([] as T[]);
  const [pendingSelection, setPendingSelection] = React.useState(
    multiple ? ([] as T[]) : (null as T | null)
  );
  const [loading, setLoading] = React.useState(false);
  const handleClose = (
    event: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "toggleInput" && event.type === "keydown") {
      // enables navigating inside the input field with the keyboard (e.g using
      // the arrow keys)
      return;
    }

    if (multiple && reason !== "escape") {
      onChange(pendingSelection as any);
    }
    setOpen(false);
    if (popperAnchor) {
      popperAnchor.focus();
    }
    setPopperAnchor(undefined);
  };
  const fetchDebounced = React.useMemo(
    () =>
      debounce(
        (searchText: string, callback: (error: any, o?: T[]) => void) => {
          fetchOptions(searchText)
            .then((o) => callback(null, o))
            .catch(callback);
        }
      ),
    [fetchOptions]
  );
  React.useEffect(() => {
    if (inputValue === "") return;
    setLoading(true);
    fetchDebounced(inputValue, (err, options) => {
      setLoading(false);
      if (err || !Array.isArray(options)) return;

      setOptions(options);
    });
  }, [inputValue, fetchDebounced]);

  const { t } = useTranslation();
  return (
    <>
      <Button
        variant={variant}
        color={color}
        startIcon={startIcon}
        disabled={open}
        size={size}
        onClick={(e) => {
          if (open) return;

          setOptions([]);
          setOpen(true);
          setPendingSelection(value as any);
          setPopperAnchor(e.currentTarget);
        }}
      >
        {buttonLabel}
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={popperAnchor}
        placement="bottom-start"
      >
        <Autocomplete
          open
          disablePortal
          multiple={multiple as any}
          disableCloseOnSelect={multiple}
          onClose={handleClose}
          disableClearable
          onChange={(_: any, newValue: any) => {
            if (multiple) setPendingSelection(newValue);
            else onChange(newValue);
          }}
          options={options}
          filterOptions={(o) => o}
          getOptionLabel={getOptionLabel}
          renderOption={
            multiple
              ? (o, { selected }) => (
                  <>
                    <Checkbox
                      checked={selected}
                      classes={{ root: classes.checkbox }}
                    />
                    {renderOption
                      ? renderOption(o)
                      : getOptionLabel
                      ? getOptionLabel(o)
                      : "missing renderOption or getOption"}
                  </>
                )
              : renderOption
              ? renderOption
              : getOptionLabel
          }
          value={pendingSelection as any}
          getOptionSelected={comparer}
          loading={loading}
          renderTags={() => null}
          onInputChange={(e, v) => setInputValue(v)}
          popupIcon={<CloseIcon />}
          noOptionsText={inputValue ? noOptionsText : t("enterSearchTerm")}
          classes={{
            popperDisablePortal: classes.popperDisablePortal,
            paper: classes.paper,
            input: classes.input,
            endAdornment: classes.endAdornment,
          }}
          renderInput={(params) => {
            const params2 = {
              ...params,
              InputProps: {
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.startAdornment}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            };
            return (
              <TextField {...params2} autoFocus placeholder={placeholder} />
            );
          }}
        />
      </Popper>
    </>
  );
}

// NOTE that the order of AutocompletePickerMultipleProps and
// AutocompletePickerSingleProps matters here to make the type inference work
// nice.
type AutocompletePickerProps<T> =
  | AutocompletePickerMultipleProps<T>
  | AutocompletePickerSingleProps<T>;

interface AutocompletePickerMultipleProps<T>
  extends AutocompletePickerCommonProps<T>,
    SelectorProps<T[]> {
  multiple: true;
}

interface AutocompletePickerSingleProps<T>
  extends AutocompletePickerCommonProps<T>,
    SelectorProps<T | null> {
  multiple?: false;
}

interface AutocompletePickerCommonProps<T> {
  buttonLabel: string;
  comparer: (a: T, b: T) => boolean;
  getOptionLabel?: (o: T) => string;
  renderOption?: (o: T) => React.ReactNode;
  fetchOptions: (searchText: string) => Promise<T[]>;
  noOptionsText: string;
  startIcon?: React.ReactNode;
  placeholder?: string;
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "inherit";
  size?: "small" | "medium" | "large";
}
