import { Route } from "react-router-dom";
import React from "react";
import Page from "./LockPage";

const path = "/masterdata/lock";
export const lockPageRoute = () => (
  <Route path={path}>
    <Page />
  </Route>
);

export const lockPagePath = () => path;
