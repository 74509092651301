import {
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useHistory } from "react-router-dom";
import EditList from "../../../../crossCutting/EditList";
import useHoverActionCell from "../../../../crossCutting/hooks/useHoverActionCell";
import {
  AddIcon,
  HideIcon,
  ShowIcon,
  ChangeOrderIcon,
} from "../../../../crossCutting/icons";
import { JumpToLinkButton } from "../../../../crossCutting/JumpToButton";
import SearchBox from "../../../../crossCutting/SearchBox";
import { byNumber } from "../../../../crossCutting/sorting";
import TableToolbar from "../../TableToolbar";
import useTableSelection from "../../useTableSelection";
import ChangeBreedOrderDialog from "./ChangeBreedOrderDialog";
import { Breed } from "../../apiClient/breedTypes";
import { useTranslatorForUser } from "../../translation";
import LoadingIndicator from "../../../../crossCutting/LoadingIndicator";
import { Node } from "../../nodes";

export type MakeBreedsDataHook = () => () => {
  breeds: Breed[];
  loading: boolean;
  refresh: (
    animalStandardId: string,
    search?: string,
    ancestorNodeId?: string
  ) => Promise<void>;
  updateActivation: (
    animalStandardId: string,
    breedIds: string[],
    isActive: boolean
  ) => void;
  changeOrder: (animalStandardId: string, breedIds: string[]) => Promise<void>;
};

export type MakeNodesDataHook = () => () => {
  nodes: Node[];
  loading: boolean;
};
interface BreedsTabPanelStrategy {
  editPageUrl: (breedId: string) => string;
  createPageUrl: () => string;
  makeBreedsDataHook: MakeBreedsDataHook;
  makeNodesDataHook: MakeNodesDataHook;
}

export interface BreedsTabPanelProps {
  animalStandard: {
    hasBreedSizeLevel: boolean;
    hasSubGroupLevel: boolean;
    animalStandardId: string;
  };
  strategy: BreedsTabPanelStrategy;
}

const useStyles = makeStyles((theme) => ({
  inactiveRow: {
    "& td": {
      color: theme.palette.text.secondary,
    },
  },
}));

const BreedsTabPanel: React.FunctionComponent<BreedsTabPanelProps> = (
  props
) => {
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const history = useHistory();
  const classes = useStyles();
  const useBreeds = props.strategy.makeBreedsDataHook();
  const {
    breeds,
    loading,
    refresh,
    updateActivation,
    changeOrder,
  } = useBreeds();
  const translator = useTranslatorForUser();

  const {
    selectedIds,
    isSelected: isBreedSelected,
    selectionStatus,
    handleSelectAllChange,
    selectionCount,
    toggleSelection,
  } = useTableSelection(breeds, (b) => b.breedId);
  const handleSearch = (searchText: string) => {
    refresh(props.animalStandard.animalStandardId, searchText);
  };

  const handleNewClick = () => {
    history.push(props.strategy.createPageUrl());
  };

  const handleActivateSelected = () => {
    updateActivation(animalStandardId, selectedIds, true);
  };
  const handleDeactivateSelected = () => {
    updateActivation(animalStandardId, selectedIds, false);
  };
  const handleRowClick = (breedId: string) => {
    toggleSelection(breedId);
  };

  const [changeOrderDialogOpen, setChangeOrderDialogOpen] = React.useState(
    false
  );
  const handleCancelChangeOrder = () => setChangeOrderDialogOpen(false);
  const handleSaveChangeOrder = (reorderedBreedIds: string[]) => {
    changeOrder(animalStandardId, reorderedBreedIds).then(() => {
      setChangeOrderDialogOpen(false);
    });
  };
  const handleChangeOrderClick = () => {
    setChangeOrderDialogOpen(true);
  };

  const animalStandardId = props.animalStandard.animalStandardId;
  React.useEffect(() => {
    refresh(animalStandardId);
  }, [refresh, animalStandardId]);
  return (
    <EditList
      toolbarItems={
        <>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewClick}
          >
            Neue Rasse
          </Button>
          <Button
            startIcon={<ChangeOrderIcon />}
            onClick={handleChangeOrderClick}
          >
            Reihenfolge ändern
          </Button>
        </>
      }
    >
      <Paper>
        <TableToolbar
          selectionCount={selectionCount}
          titleWhenSelected={`${selectionCount} ${
            selectionCount === 1 ? " Rasse " : " Rassen "
          } ausgewählt`}
          contentWhenSelected={
            <>
              <Tooltip title="Ausgewählte aktivieren">
                <IconButton
                  aria-label="aktivieren"
                  onClick={handleActivateSelected}
                  color="primary"
                  disabled={loading}
                >
                  <ShowIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ausgewählte ausblenden">
                <IconButton
                  color="primary"
                  aria-label="ausblenden"
                  onClick={handleDeactivateSelected}
                  disabled={loading}
                >
                  <HideIcon />
                </IconButton>
              </Tooltip>
            </>
          }
          contentWhenNothingSelected={
            <SearchBox onSearch={handleSearch} placeholder="Name" />
          }
        />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectionStatus === "all"}
                    indeterminate={selectionStatus === "indeterminate"}
                    inputProps={{ "aria-label": "alle auswählen" }}
                    onChange={handleSelectAllChange}
                  />
                </TableCell>
                <TableCell>Rasse</TableCell>
                {props.animalStandard.hasBreedSizeLevel && (
                  <TableCell>Rassengrösse</TableCell>
                )}
                {props.animalStandard.hasSubGroupLevel && (
                  <TableCell>Untergruppe</TableCell>
                )}
                <TableCell>Herkunft</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {breeds.sort(byNumber((b) => b.sort)).map((b) => {
                const isSelected = isBreedSelected(b);
                const labelId = `breed-table-checkbox-${b.breedId}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    selected={isSelected}
                    className={clsx(
                      rowClass,
                      !b.isActive && classes.inactiveRow
                    )}
                    key={b.breedId}
                    onClick={() => handleRowClick(b.breedId)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell id={labelId}>
                      {translator(b.captions)?.breedName}
                    </TableCell>
                    {props.animalStandard.hasBreedSizeLevel && (
                      <TableCell>
                        {translator(b.captions)?.breedSizeName}
                      </TableCell>
                    )}
                    {props.animalStandard.hasSubGroupLevel && (
                      <TableCell>
                        {translator(b.captions)?.subGroupName}
                      </TableCell>
                    )}
                    <TableCell>{b.origin ? "Ausstellung" : "Global"}</TableCell>
                    <TableCell>
                      {b.isActive ? (
                        <ShowIcon titleAccess="Aktiv" />
                      ) : (
                        <HideIcon titleAccess="Inaktiv" />
                      )}
                    </TableCell>
                    <HoverActionCell>
                      <JumpToLinkButton
                        to={props.strategy.editPageUrl(b.breedId)}
                      >
                        Bearbeiten
                      </JumpToLinkButton>
                    </HoverActionCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Paper>
      <ChangeBreedOrderDialog
        open={changeOrderDialogOpen}
        onCancel={handleCancelChangeOrder}
        onSave={handleSaveChangeOrder}
        title={"Reihenfolge ändern"}
        makeBreedsDataHook={props.strategy.makeBreedsDataHook}
        makeNodesDataHook={props.strategy.makeNodesDataHook}
        animalStandard={props.animalStandard}
      />
    </EditList>
  );
};

export default BreedsTabPanel;
