import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";

const DrawerMenu: React.FunctionComponent<{}> = (props) => (
  <List component="div">{React.Children.toArray(props.children)}</List>
);

export const useDrawerMenuItemStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));
export default DrawerMenu;

export function DrawerMenuItem(props: {
  text: string;
  path: string;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
  notExact?: boolean;
}) {
  const classes = useDrawerMenuItemStyles();

  return (
    <ListItem
      button
      component={RouterLink}
      to={props.path}
      className={clsx(classes.listItem, props.className)}
      exact={!props.notExact}
      activeClassName="Mui-selected"
      onClick={props.onClick}
    >
      <ListItemIcon>{props.icon && props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
}

export interface SetDrawerOpen {
  (open: boolean): void;
}
export interface DrawerActions {
  setDrawerOpen: SetDrawerOpen;
}
