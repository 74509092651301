import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useLocation, useParams } from "react-router-dom";
import AppRole from "../../../authorization/appRoles";
import { ForRoles } from "../../../authorization/RolesContext";
import { combineStates } from "../../../crossCutting/hooks/usePromiseStates";
import { EditIcon } from "../../../crossCutting/icons";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { Maybe } from "../../../crossCutting/maybe";
import PaperPage from "../../../crossCutting/pages/PaperPage";
import { trimSlashes } from "../../../crossCutting/strings";
import { parseQuery } from "../../../crossCutting/urls";
import { useFetch2 } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { defaultLanguage } from "../../../language";
import { formatName } from "../../format";
import { useTranslatorForUser } from "../../masterdata/translation";
import { Exhibition, MemberRegistered, Registration } from "../apiTypes";
import { ExhibitionCached, useExhibitionCached } from "../ExhibitionContext";
import { formatGender, Gender } from "../genders";
import RegistrationCreatedButtons from "../RegistrationCreatedButtons";
import { PickerBreed } from "../registrationEditPage/BreedPicker";
import { LinkToRegistrationEdit } from "../registrationEditPage/routing";
import { useCategories } from "../useCategories";

export type RegistrationDetailsExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName" | "orderInfo"
>;
export interface RegistrationPageProps {
  registrationId?: number;
  exhibition: RegistrationDetailsExhibition;
}

const RegistrationPage: React.FunctionComponent<RegistrationPageProps> = (
  props
) => {
  const { registrationId } = useParams<{ registrationId: string }>();

  const [editedRegistrationState] = useFetch2<Registration>(
    `exhibitions/${props.exhibition.exhibitionId}/registrations/${registrationId}`
  );
  const { categories } = useCategories();
  const cachedExhibition = useExhibitionCached();
  const exhibition = { ...cachedExhibition, categories };
  const standard = editedRegistrationState.isSuccess()
    ? exhibition?.animalStandards.find((s) =>
        s.units.some(
          (u) => u.unitId === editedRegistrationState.data.unit.unitId
        )
      )
    : null;
  const [breedState] = useFetch2<any>(
    editedRegistrationState.isSuccess() && exhibition && standard
      ? `exhibitions/${props.exhibition.exhibitionId}/masterdata/animalstandards/${standard.animalStandardId}/breeds/${editedRegistrationState.data.breed.breedId}`
      : ""
  );

  const registrationState = combineStates(
    editedRegistrationState,
    breedState,
    (r, breed) => ({
      registration: {
        ...r,
        roomId: r.room?.roomId,
        categoryId: r.category?.categoryId,
        ageClassId: r.ageClass?.ageClassId,
        unitId: r.unit.unitId,
        colorId: r.color?.colorId,
      } as RegistrationValues,
      breed: breed,
    })
  );

  return exhibition ? (
    registrationState.isRunning() ? (
      <LoadingIndicator />
    ) : registrationState.isError() ? (
      <div>Error</div>
    ) : (
      <RegistrationPageExceptDataFetching
        exhibition={exhibition}
        registration={registrationState.data.registration}
        defaultBreed={registrationState.data.breed}
      />
    )
  ) : null;
};

export default RegistrationPage;

const useStyles = makeStyles((theme) => ({
  competitionList: {
    paddingLeft: "1rem",
    margin: 0,
  },
}));
const RegistrationPageExceptDataFetching: React.FunctionComponent<{
  registration: RegistrationValues;
  exhibition: ExhibitionCached & {
    categories: {
      categoryId: string;
      categoryName: string;
      animalStandardId: string;
    }[];
  };
  defaultBreed: PickerBreed;
}> = ({ registration, exhibition, defaultBreed }) => {
  const translator = useTranslatorForUser();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const unitId = registration.unitId;
  const standard = exhibition.animalStandards.find((s) =>
    s.units.some((u) => u.unitId === unitId)
  );
  const unit = standard?.units?.find((u) => u.unitId === unitId);
  if (!standard || !unit) {
    return <>Error</>;
  }
  const breed = defaultBreed;
  const categoryId = registration.categoryId;
  const roomId = registration.roomId;
  const hasAdditionalBox = registration.hasAdditionalBox;

  const ageClass = standard.ageClasses?.find(
    (ac) => ac.ageClassId === registration.ageClassId
  );

  const color = breed.colors?.find(
    (color) => color.colorId === registration.colorId
  );
  const isMixed =
    unit.isMixed && standard.hasColorLevel ? !registration.colorId : false;

  const competitions = competitionsMatchingUnit(
    exhibition.competitions,
    unitId
  );
  const clubCompetitions = clubCompetitionsMatchingUnit(
    exhibition.clubCompetitions,
    standard.animalStandardId
  );

  const competitionIds = registration.competitionIds;
  const clubCompetitionIds = registration.clubCompetitionIds;

  const categories =
    exhibition.categories?.filter(
      (c) => c.animalStandardId === standard?.animalStandardId
    ) || [];
  const catalogByEmail = registration.memberRegistered.orders?.catalogByEmail;
  const souvenirMedalOrderCount =
    registration.memberRegistered.orders?.souvenirMedalOrderCount;
  const catalogOrderCount =
    registration.memberRegistered.orders?.catalogOrderCount;
  const singleAnimalGender = registration.singleAnimalGender;

  const firstColorHasSameNameAsBreed =
    breed.captions
      .find((c) => c.language === defaultLanguage)
      ?.breedName?.toLowerCase() ===
    breed.colors?.[0]?.captions
      .find((c) => c.language === defaultLanguage)
      ?.colorName?.toLowerCase();

  const organizationNumber = registration.organizationNumber;
  const isOnCreatedPage = trimSlashes(location.pathname).endsWith("created");
  const registrationCount =
    parseInt(parseQuery(location.search).registrationCount) || 1;
  console.log(registrationCount);
  return (
    !!standard && (
      <PaperPage
        title={exhibition.exhibitionName}
        subtitle={
          isOnCreatedPage
            ? registrationCount === 1
              ? t("registrationCreated", {
                  registrationNumber: registration.registrationNumber,
                })
              : t("registrationsCreatedTemplate", { registrationCount })
            : t("registrationWithNumber", {
                registrationNumber: registration.registrationNumber,
              })
        }
        maxWidth="sm"
      >
        <Grid container spacing={1}>
          {registrationCount === 1 && (
            <>
              <Grid item xs={12}>
                <MutedTitle>{t("exhibitor")}</MutedTitle>
                <MemberInfo member={registration.memberRegistered} />
              </Grid>
              <Grid item xs={12}>
                <MutedTitle>{t("unit")}</MutedTitle>
                <Typography>
                  {
                    translator(
                      exhibition.animalStandards
                        .flatMap((s) => s.units)
                        .find((u) => u.unitId === unitId)?.captions || []
                    )?.unitName
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MutedTitle>{t("breed")}</MutedTitle>
                <Typography>{translator(breed.captions)?.breedName}</Typography>
              </Grid>
              {!(firstColorHasSameNameAsBreed && breed.colors?.length === 1) &&
                (color || isMixed) && (
                  <Grid item xs={12}>
                    <MutedTitle>{t("color")}</MutedTitle>
                    <Typography>
                      {isMixed
                        ? t("mixed")
                        : color
                        ? translator(color.captions)?.colorName
                        : null}
                    </Typography>
                  </Grid>
                )}
              {breed.hasAdditionalBoxes && (
                <Grid item xs={12}>
                  <MutedTitle>{t("additionalBox")}</MutedTitle>
                  {hasAdditionalBox ? t("yes") : t("no")}
                </Grid>
              )}
              {!!ageClass && (
                <Grid item xs={12}>
                  <MutedTitle>{t("ageClass")}</MutedTitle>
                  {translator(ageClass.captions)?.ageClassName}
                </Grid>
              )}
              {!!exhibition.rooms?.length && (
                <Grid item xs={12}>
                  <MutedTitle>{t("exhibitionRoom")}</MutedTitle>
                  {exhibition.rooms.find((r) => r.roomId === roomId)?.roomName}
                </Grid>
              )}
              {!!categories.length && (
                <Grid item xs={12}>
                  <MutedTitle>{t("category")}</MutedTitle>
                  {
                    categories.find((c) => c.categoryId === categoryId)
                      ?.categoryName
                  }
                </Grid>
              )}
              {unit?.animalCount === 1 &&
                competitions.some(
                  (c) =>
                    competitionIds.includes(c.competitionId) &&
                    c.competitionUnits.some((cu) => cu.isGenderMandatory)
                ) && (
                  <Grid item xs={12}>
                    <MutedTitle>{t("gender")}</MutedTitle>
                    {singleAnimalGender ? formatGender(singleAnimalGender) : ""}
                  </Grid>
                )}
              <Grid item xs={12}>
                <MutedTitle>{t("competitions")}</MutedTitle>
                <ul className={classes.competitionList}>
                  {competitionIds.map((id) => (
                    <li key={id}>
                      {
                        competitions.find((c) => c.competitionId === id)
                          ?.competitionName
                      }
                    </li>
                  ))}
                </ul>
              </Grid>
              {!!clubCompetitionIds.length && (
                <Grid item xs={12}>
                  <MutedTitle>{t("clubCompetitions")}</MutedTitle>
                  <ul className={classes.competitionList}>
                    {clubCompetitionIds.map((id) => (
                      <li key={id}>
                        {
                          clubCompetitions.find(
                            (c) => c.clubCompetitionId === id
                          )?.clubCompetitionName
                        }
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}
              <Grid item xs={12}>
                <MutedTitle>{t("getsCatalogByEmail")}</MutedTitle>
                {catalogByEmail ? t("yes") : t("no")}
              </Grid>
              <Grid item xs={12}>
                <MutedTitle>{t("catalogOrderLabel")}</MutedTitle>
                {catalogOrderCount}
              </Grid>
              <Grid item xs={12}>
                <MutedTitle>{t("ordersMedal")}</MutedTitle>
                {souvenirMedalOrderCount}
              </Grid>
              {organizationNumber !== undefined && organizationNumber != null && (
                <Grid item xs={12}>
                  <MutedTitle>{t("organizationNumber")}</MutedTitle>
                  {organizationNumber}
                </Grid>
              )}
              <ForRoles roles={["Organizer", AppRole.ExhibitionAdmin]}>
                <Grid item xs={12}>
                  <LinkToRegistrationEdit
                    button
                    color="primary"
                    registrationId={registration.registrationId}
                    exhibitionId={exhibition.exhibitionId}
                    startIcon={<EditIcon />}
                  >
                    {t("editRegistration")}
                  </LinkToRegistrationEdit>
                </Grid>
              </ForRoles>
            </>
          )}
          {isOnCreatedPage && (
            <RegistrationCreatedButtons
              exhibitionId={exhibition.exhibitionId}
              ContainerComponent={Grid}
              containerComponentProps={{ item: true, xs: 12 }}
              memberRegistered={registration.memberRegistered}
            />
          )}
        </Grid>
      </PaperPage>
    )
  );
};

interface RegistrationValues {
  registrationId: string;
  registrationNumber?: number;
  memberRegistered: MemberRegistered;
  unitId: string;
  competitionIds: string[];
  clubCompetitionIds: string[];
  ageClassId?: string;
  colorId?: string;
  categoryId?: string;
  roomId?: string;
  hasAdditionalBox: boolean;
  singleAnimalGender?: Gender | undefined;
  organizationNumber: number | undefined;
}

function competitionsMatchingUnit<
  T extends { competitionUnits: { unitId: string }[] }
>(allCompetitions: T[], unitId: string) {
  return allCompetitions?.filter((c) =>
    c.competitionUnits.some((cu) => cu.unitId === unitId)
  );
}

function clubCompetitionsMatchingUnit<
  T extends { animalStandardId1: string; animalStandardId2: string | null }
>(allCompetitions: T[], animalStandardId: string) {
  return allCompetitions?.filter(
    (c) =>
      c.animalStandardId1 === animalStandardId ||
      c.animalStandardId2 === animalStandardId
  );
}

function MemberInfo({
  member,
}: {
  member: {
    memberNumber: number;
    firstName: string;
    lastName: string;
    email: Maybe<string>;
  };
}) {
  return (
    <>
      <Typography variant="body1">{formatName(member)}</Typography>
      <Typography variant="body2">{member.memberNumber}</Typography>
      {member.email && <Typography variant="body2">{member.email}</Typography>}
    </>
  );
}

function MutedTitle(props: PropsWithChildren<{}>) {
  return <Typography color="textSecondary">{props.children}</Typography>;
}
