export default function validation(
  columns: { setOmitError: (omit: boolean) => void; valid: boolean }[],
  showError: (message: string) => void,
  validateMultiple?: () => boolean
) {
  const toggleOmitValidationErrors = (omit: boolean) =>
    columns.forEach((c) => c.setOmitError(omit));

  const validate = () => {
    toggleOmitValidationErrors(false);
    const valid =
      !columns.some((c) => !c.valid) &&
      (!validateMultiple || validateMultiple());
    if (!valid) {
      showError("Bitte überprüfe deine Eingaben.");
    }
    return valid;
  };

  return {
    omitValidationErrors: () => toggleOmitValidationErrors(true),
    validate,
  };
}
