import { Route } from "react-router-dom";
import React from "react";
import Page, { CompetitionsPageExhibition } from "./CompetitionsPage";
import { ExhibitionId } from "../types";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";

const suffix = "competitions";

export const competitionsPageRoute = (
  pathPrefix: string,
  exhibition: CompetitionsPageExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const competitionsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;

export const LinkToCompetitionsPage: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={competitionsPagePath(exhibitionId)} />
);
