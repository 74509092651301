import * as React from "react";
import { AnimalStandard, Exhibition } from "./apiTypes";

export type ExhibitionCached = Omit<Exhibition, "animalStandardIds"> & {
  animalStandards: AnimalStandard[];
};

type ExhibitionContextValue = {
  exhibition: ExhibitionCached;
  refresh: () => void;
};

const ExhibitionContext = React.createContext<ExhibitionContextValue>(
  null as any
);

export default ExhibitionContext;

export const useExhibitionCached = () =>
  React.useContext(ExhibitionContext).exhibition;

export const useRefreshExhibition = () =>
  React.useContext(ExhibitionContext).refresh;
