import { Route } from "react-router-dom";
import React from "react";
import Page, { StandardPageTabKey } from "./StandardPage";
import { uuidRegex } from "../../../crossCutting/uuid";
import { ExhibitionId, Exhibition } from "../../exhibitions/types";
import { makeUrl } from "../../../crossCutting/urls";
import {
  exhibitionBreedEditPagePath,
  exhibitionBreedCreatePagePath,
} from "../breedEditPage/routingInExhibition";
import {
  exhibitionUnitEditPagePath,
  exhibitionUnitCreatePagePath,
} from "../unitEditPage/routingInExhibition";
import { useAnimalStandards } from "../apiClient/useAnimalStandards";
import { useBreeds } from "../apiClient/useBreeds";
import { useNodes } from "../apiClient/useNodes";
import { useAgeClasses } from "../apiClient/useAgeClasses";
import { exhibitionStandardsPagePath } from "../standardsManagePage/routingInExhibition";

export const exhibitionStandardPageRoute = (
  prefix: string,
  exhibition: Pick<Exhibition, "exhibitionId" | "exhibitionName">
) => (
  <Route
    path={`${prefix}/masterdata/standards/:animalStandardId(${uuidRegex})?/:tab?`}
  >
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        makeStandardsDataHook: () => () =>
          useAnimalStandards(exhibition.exhibitionId),
        makeNodesDataHook: () => (animalStandardId: string) =>
          useNodes(animalStandardId, exhibition.exhibitionId),
        makeAgeClassesDataHook: () => (animalStandardId: string) =>
          useAgeClasses(animalStandardId, exhibition.exhibitionId),
        manageUrl: () => exhibitionStandardsPagePath(exhibition.exhibitionId),
        standardUrl: (animalStandardId, tabKey) =>
          exhibitionStandardPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            tabKey
          ),
        unitCreateUrl: (animalStandardId: string) =>
          exhibitionUnitCreatePagePath(
            exhibition.exhibitionId,
            animalStandardId
          ),
        unitEditUrl: (animalStandardId: string, unitId: string) =>
          exhibitionUnitEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            unitId
          ),
        breedEditUrl: (animalStandardId: string, breedId: string) =>
          exhibitionBreedEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            breedId
          ),
        breedCreateUrl: (animalStandardId: string) =>
          exhibitionBreedCreatePagePath(
            exhibition.exhibitionId,
            animalStandardId
          ),
        makeBreedsDataHook: () => () => useBreeds(exhibition.exhibitionId),
      }}
    />
  </Route>
);

export const exhibitionStandardPagePath = (
  exhibitionId: ExhibitionId,
  standardId?: string,
  tab?: StandardPageTabKey
) =>
  makeUrl`/exhibitions/${exhibitionId}/masterdata/standards/${standardId}/${
    standardId ? tab || "breeds" : ""
  }`;
