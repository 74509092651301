import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  color: {
    display: "inline-block",
    height: "1rem",
    width: "1rem",
    marginRight: theme.spacing(1),
  },
  item: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));
export default function ChartLegend<
  T extends { color: string; label: string }
>(props: { slices: T[]; className?: string }) {
  const classes = useStyles();
  return (
    <ul className={clsx(classes.root, props.className)}>
      {props.slices.map((slice) => (
        <li className={classes.item} key={slice.label}>
          <span
            className={classes.color}
            style={{ backgroundColor: slice.color }}
          ></span>
          <Typography variant="body1" noWrap>
            {slice.label}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
