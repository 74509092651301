import {
  Button,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import { NavLink as RouterLink, useHistory, useParams } from "react-router-dom";
import { AddIcon } from "../../../crossCutting/icons";
import Page from "../../../crossCutting/pages/Page";
import StandardsMenu from "./StandardsMenu";
import UnitsTabPanel from "./UnitsTabPanel";
import BreedsTabPanel, {
  MakeBreedsDataHook,
} from "./breedsTabPanel/BreedsTabPanel";
import StructureTabPanel, {
  MakeNodesDataHook,
} from "./structureTabPanel/StructureTabPanel";
import AgeClassesTabPanel, {
  MakeAgeClassesDataHook,
} from "./ageClassesTabPanel/AgeClassesTabPanel";
import { AnimalStandard } from "../apiClient/animalStandardTypes";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";

export interface StandardPageProps {
  strategy: StandardPageStrategy;
  tab?: StandardPageTabKey;
}

export type StandardPageTabKey =
  | "breeds"
  | "units"
  | "structure"
  | "ageClasses";

type MakeStandardsDataHook = () => () => {
  animalStandards: AnimalStandard[];
  loading: boolean;
};
interface StandardPageStrategy {
  title: string;
  breedCreateUrl: (animalStandardId: string) => string;
  breedEditUrl: (animalStandardId: string, breedId: string) => string;
  makeBreedsDataHook: MakeBreedsDataHook;
  makeNodesDataHook: MakeNodesDataHook;
  makeAgeClassesDataHook: MakeAgeClassesDataHook;
  manageUrl: () => string;
  standardUrl: (
    animalStandardId: string,
    tabKey?: StandardPageTabKey
  ) => string;
  makeStandardsDataHook: MakeStandardsDataHook;
  unitCreateUrl: (animalStandardId: string) => string;
  unitEditUrl: (animalStandardId: string, unitId: string) => string;
}

const StandardPage: React.FunctionComponent<StandardPageProps> = (props) => {
  const useAnimalStandards = props.strategy.makeStandardsDataHook();
  const { animalStandards, loading } = useAnimalStandards();
  const history = useHistory();
  const { animalStandardId, tab } = useParams<{
    animalStandardId: string;
    tab: StandardPageTabKey;
  }>();

  const Wrapper = ({ children }: any) => (
    <Page title={props.strategy.title} subtitle="Standards">
      {children}
    </Page>
  );

  if (loading) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  const handleManage = () => {
    history.push(props.strategy.manageUrl());
  };

  const activeStandards = animalStandards.filter((s) => s.isActive);

  if (activeStandards.length === 0) {
    return (
      <Wrapper>
        <NoActiveStandards handleManage={handleManage} />
      </Wrapper>
    );
  }

  const standardToSelect = activeStandards.find(
    (s) => s.animalStandardId === animalStandardId
  );
  if (!standardToSelect) {
    history.push(
      props.strategy.standardUrl(activeStandards[0].animalStandardId)
    );
    return null;
  }

  const handleStandardChange = (animalStandardId: string) => {
    if (animalStandardId !== standardToSelect?.animalStandardId) {
      history.push(props.strategy.standardUrl(animalStandardId));
    }
  };

  const animalStandard = standardToSelect!;
  return (
    <Wrapper>
      <>
        <StandardsMenu
          animalStandards={activeStandards}
          selectedAnimalStandardId={animalStandard.animalStandardId}
          onChange={handleStandardChange}
          onManage={handleManage}
        />
        <StandardPageTabs
          tab={tab}
          animalStandard={animalStandard}
          standardUrl={props.strategy.standardUrl}
        />
        {tab === "units" ? (
          <UnitsTabPanel
            units={animalStandard.units}
            strategy={{
              createPageUrl: () =>
                props.strategy.unitCreateUrl(animalStandardId),
              editPageUrl: (unitId: string) =>
                props.strategy.unitEditUrl(animalStandardId, unitId),
            }}
          />
        ) : tab === "structure" ? (
          <StructureTabPanel
            animalStandard={animalStandard}
            strategy={{
              makeNodesDataHook: props.strategy.makeNodesDataHook,
            }}
          />
        ) : tab === "breeds" ? (
          <BreedsTabPanel
            animalStandard={animalStandard}
            strategy={{
              createPageUrl: () =>
                props.strategy.breedCreateUrl(animalStandardId),
              editPageUrl: (breedId: string) =>
                props.strategy.breedEditUrl(animalStandardId, breedId),
              makeBreedsDataHook: props.strategy.makeBreedsDataHook,
              makeNodesDataHook: () => () =>
                props.strategy.makeNodesDataHook()(
                  animalStandard.animalStandardId
                ),
            }}
          />
        ) : tab === "ageClasses" ? (
          <AgeClassesTabPanel
            animalStandard={animalStandard}
            strategy={{
              makeAgeClassesDataHook: props.strategy.makeAgeClassesDataHook,
            }}
          />
        ) : null}
      </>
    </Wrapper>
  );
};

export default StandardPage;

const allTabs: { label: string; key: StandardPageTabKey }[] = [
  { label: "Rassen", key: "breeds" },
  { label: "Einheiten", key: "units" },
  { label: "Struktur", key: "structure" },
  { label: "Alterskategorien", key: "ageClasses" },
];

function StandardPageTabs({
  tab,
  animalStandard,
  standardUrl,
}: {
  tab: StandardPageTabKey;
  animalStandard: AnimalStandard;
  standardUrl: {
    (animalStandardId: string, tabKey: StandardPageTabKey): string;
  };
}) {
  const theme = useTheme();
  const largeOrBigger = useMediaQuery(theme.breakpoints.up("lg"));
  const tabs = allTabs.filter(
    (t) => t.key !== "ageClasses" || animalStandard?.hasAgeClass
  );
  return (
    <Tabs
      value={tab}
      centered={largeOrBigger}
      variant={largeOrBigger ? "standard" : "fullWidth"}
    >
      {tabs.map((t) => (
        <Tab
          {...t}
          value={t.key}
          component={RouterLink}
          // to={props.strategy.standardUrl(animalStandardId, t.key)}
          to={standardUrl(animalStandard.animalStandardId, t.key)}
        />
      ))}
    </Tabs>
  );
}

function NoActiveStandards({ handleManage }: { handleManage: { (): void } }) {
  return (
    <>
      <Typography>Keine Standards vorhanden</Typography>
      <Button
        color="secondary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleManage}
      >
        Standard hinzufügen
      </Button>
    </>
  );
}
