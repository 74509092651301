import {
  AppBar,
  Backdrop,
  CircularProgress,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import React, { useState } from "react";
import { CloseIcon, MenuIcon } from "./crossCutting/icons";
import { spacing3 } from "./crossCutting/layoutConstants";
import CurrentUser from "./CurrentUser";
import ExhibitionPicker from "./domain/exhibitions/exhibitionPicker/ExhibitionPicker";
import { LinkToHome } from "./domain/homePage/routing";
import { DrawerActions } from "./crossCutting/DrawerMenu";

const drawerAlwaysVisibleUpFrom = "md";
const drawerWidth = 260;

const usePrintStyles = makeStyles(
  () => ({
    noprint: { display: "none" },
  }),
  { media: "print" }
);
const useStyles = makeStyles((theme) => ({
  topOffset: theme.mixins.toolbar,
  root: {
    display: "flex",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  drawerOffset: {},
  "@media screen": {
    drawerOffset: {
      [theme.breakpoints.up(drawerAlwaysVisibleUpFrom)]: {
        marginLeft: `${drawerWidth}px`,
      },
    },
    main: {
      height: "100vh",
      // force horizontal scrolling *inside* of content (e.g. scroll individual tables)
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  drawerHeader: {
    display: "flex",
    paddingLeft: theme.spacing(spacing3),
    alignItems: "center",
  },
  drawerTitle: {
    flexGrow: 1,
  },
  drawerContainer: {
    overflowY: "auto",
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  titleAndLocation: {
    flexGrow: 3,
    display: "flex",
    alignItems: "baseline",
    overflowX: "hidden",
    minWidth: 0,
  },
  location: {
    marginLeft: theme.spacing(spacing3),
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toolbarSearch: {
    flexGrow: 2,
    minWidth: 300,
  },
  loading: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function Shell({
  mainContent,
  drawerContent,
  loading,
  location,
}: {
  mainContent: React.ReactNode;
  drawerContent: (drawerActions: DrawerActions) => React.ReactNode;
  loading?: boolean;
  location?: React.ReactNode;
}) {
  const theme = useTheme();
  const widerThanSmall = useMediaQuery(
    theme.breakpoints.up(drawerAlwaysVisibleUpFrom)
  );
  const classes = useStyles();
  const { noprint } = usePrintStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleLinkToHomeClick = () => {
    setDrawerOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, noprint)}>
        <Toolbar>
          {widerThanSmall ? null : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.titleAndLocation}>
            <Typography variant="h6" component="h1">
              <LinkToHome disableLinkFormat>Klexpo</LinkToHome>
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              className={classes.location}
            >
              {location}
            </Typography>
          </div>
          <Hidden xsDown>
            <ExhibitionPicker className={classes.toolbarSearch} />
          </Hidden>
          <CurrentUser />
        </Toolbar>
      </AppBar>

      <Drawer
        className={noprint}
        PaperProps={{ component: "nav" }}
        classes={{ paper: classes.drawerPaper }}
        variant={widerThanSmall ? "permanent" : "temporary"}
        open={drawerOpen || widerThanSmall}
        onClose={() => setDrawerOpen(false)}
      >
        <div className={clsx(classes.topOffset, classes.drawerHeader)}>
          <LinkToHome
            underline="none"
            variant="h5"
            title="Startseite"
            className={classes.drawerTitle}
            onClick={handleLinkToHomeClick}
          >
            Klexpo
          </LinkToHome>
          <IconButton
            color="inherit"
            aria-label="close menu"
            onClick={() => setDrawerOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.drawerContainer}>
          {drawerContent({ setDrawerOpen })}
        </div>
      </Drawer>

      <main className={clsx(classes.main, classes.drawerOffset)}>
        <div className={clsx(classes.topOffset, noprint)}></div>
        {mainContent}
      </main>
      <Backdrop open={loading || false} className={classes.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Shell;
