import * as React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  control: {
    marginBottom: theme.spacing(1),
  },
}));

export default function MedalItem<T extends MedalItemConfig>({
  config,
  onChange,
}: {
  config: T;
  onChange: (config: T) => void;
}) {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        className={classes.control}
        control={
          <Checkbox
            checked={config.isSelected}
            onChange={(e) =>
              onChange({ ...config, isSelected: e.target.checked })
            }
            name={config.medalName + "selected"}
          />
        }
        label={config.medalName}
      />
      <TextField
        className={classes.control}
        label={config.valueLabel}
        variant="outlined"
        value={config.limit}
        size="small"
        inputProps={{ min: 0 }}
        onChange={(e) =>
          onChange({
            ...config,
            isSelected: true,
            limit: e.target.value,
          })
        }
      />
      <TextField
        className={classes.control}
        label="Kurzzeichen"
        variant="outlined"
        value={config.medalCode}
        required={config.isSelected}
        size="small"
        onChange={(e) =>
          onChange({
            ...config,
            isSelected: true,
            medalCode: e.target.value,
          })
        }
      />
    </FormGroup>
  );
}
interface MedalItemConfig {
  medalName: string;
  isSelected: boolean;
  limit: string;
  valueLabel: string;
  medalCode: string;
}
