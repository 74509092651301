import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { AddIcon } from "../../../crossCutting/icons";
import JumpToButton from "../../../crossCutting/JumpToButton";
import Page from "../../../crossCutting/pages/Page";
import { byString } from "../../../crossCutting/sorting";
import BoxTypeDialog from "./BoxTypeDialog";
import {
  BoxType as ApiBoxType,
  CreateBoxTypeInput,
  UpdateBoxTypeInput,
} from "../apiClient/boxTypeTypes";
import { useTranslatorForUser } from "../translation";

export type MakeBoxTypesDataHook = () => () => {
  boxTypes: ApiBoxType[];
  loading: boolean;
  refresh: () => Promise<void>;
  createBoxType: (input: CreateBoxTypeInput) => Promise<void>;
  updateBoxType: (
    input: UpdateBoxTypeInput & { boxTypeId: string }
  ) => Promise<void>;
};

interface BoxTypesPageStrategy {
  title: string;
  makeBoxTypesDataHook: MakeBoxTypesDataHook;
}

export interface BoxTypesPageProps {
  strategy: BoxTypesPageStrategy;
}

const BoxTypesPage: React.FunctionComponent<BoxTypesPageProps> = (props) => {
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const translator = useTranslatorForUser();
  const [editedBoxType, setEditedBoxType] = React.useState<BoxType>();
  const useBoxTypes = props.strategy.makeBoxTypesDataHook();
  const { boxTypes, createBoxType, updateBoxType } = useBoxTypes();
  const handleNewClick = () => {
    setEditedBoxType({
      boxTypeId: "",
      captions: [],
      length: null,
      width: null,
      height: null,
      additionalDimension: null,
    } as BoxType);
  };
  const handleClick = (boxType: BoxType) => {
    setEditedBoxType(boxType);
  };

  const handleCancelEdit = () => {
    setEditedBoxType(undefined);
  };
  const handleSave = (boxType: BoxType) => {
    const toBeSaved = { ...editedBoxType, ...boxType };
    if (toBeSaved.boxTypeId) {
      updateBoxType({ ...toBeSaved, ...boxType });
    } else {
      createBoxType(boxType);
    }
    Promise.resolve().then(() => setEditedBoxType(undefined));
  };

  return (
    <Page title={props.strategy.title} subtitle={"Boxentypen"}>
      <EditList
        toolbarItems={
          <>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleNewClick}
            >
              Neuer Boxentyp
            </Button>
          </>
        }
      >
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Herkunft</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boxTypes
                .sort(
                  byString((b) => translator(b.captions)?.boxTypeName ?? "")
                )
                .map((b) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      className={rowClass}
                      key={b.boxTypeId}
                      onClick={() => handleClick(b)}
                    >
                      <TableCell>
                        {translator(b.captions)?.boxTypeName}
                      </TableCell>
                      <TableCell>
                        {b.origin ? "Ausstellung" : "Global"}
                      </TableCell>
                      <HoverActionCell>
                        <JumpToButton>Bearbeiten</JumpToButton>
                      </HoverActionCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </EditList>
      {editedBoxType && (
        <BoxTypeDialog
          boxType={editedBoxType}
          open={!!editedBoxType}
          onCancel={handleCancelEdit}
          onSave={handleSave}
        />
      )}
    </Page>
  );
};

export default BoxTypesPage;

type Measure = number | null;
interface BoxType {
  boxTypeId: string;
  captions: {
    language: string;
    boxTypeName: string;
    advice: string;
  }[];
  length: Measure;
  width: Measure;
  height: Measure;
  additionalDimension: string | null;
}
