import {
  TableRow,
  TableCell,
  Checkbox,
  Switch,
  IconButton,
  Dialog,
  DialogContent,
  FormControlLabel,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { CompetitionUnit } from "./unitTypes";
import React from "react";
import { MoreIcon } from "../../../../crossCutting/icons";

const useStyles = makeStyles({
  nameCell: {
    cursor: "pointer",
  },
});

export function Row<T extends CompetitionUnit>({
  competitionUnit,
  labelId,
  onChange,
}: RowProps<T>) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const changeSelected = (newValue: boolean) => {
    onChange({ ...competitionUnit, isSelected: newValue });
  };
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          checked={competitionUnit.isSelected}
          onChange={(e) => changeSelected(e.target.checked)}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell
        className={classes.nameCell}
        id={labelId}
        onClick={() => changeSelected(!competitionUnit.isSelected)}
      >
        {competitionUnit.unit.unitName}
      </TableCell>
      <TableCell align="center">
        <Switch
          checked={competitionUnit.isGenderMandatory}
          onChange={(e) => {
            onChange({
              ...competitionUnit,
              isGenderMandatory: e.target.checked,
              isSelected: true,
            });
          }}
        />
      </TableCell>
      <TableCell align="center">
        {competitionUnit.unit.isStrikeResultPossible ? (
          <>
            <Switch
              checked={!!competitionUnit.strikeResult}
              onChange={(e) => {
                onChange({
                  ...competitionUnit,
                  strikeResult: e.target.checked
                    ? {
                        hasAtLeastOneMale: false,
                        hasFollowingStrikeResult: false,
                      }
                    : null,
                  isSelected: true,
                });
                if (e.target.checked) {
                  setDialogOpen(true);
                }
              }}
            />
            <IconButton
              onClick={() => setDialogOpen(true)}
              disabled={!competitionUnit.strikeResult}
            >
              <MoreIcon />
            </IconButton>
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              title="Streichresultat"
            >
              <DialogContent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        competitionUnit.strikeResult?.hasFollowingStrikeResult
                      }
                      onChange={(e) =>
                        onChange({
                          ...competitionUnit,
                          strikeResult: {
                            ...competitionUnit.strikeResult,
                            hasFollowingStrikeResult: e.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label="Streichresultat bei Punktegleichheit beachten"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={competitionUnit.strikeResult?.hasAtLeastOneMale}
                      onChange={(e) =>
                        onChange({
                          ...competitionUnit,
                          strikeResult: {
                            ...competitionUnit.strikeResult,
                            hasAtLeastOneMale: e.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label="Mindestens ein 1.0 muss vorhanden sein"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)} color="primary">
                  Schliessen
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
}

interface RowProps<T extends CompetitionUnit> {
  competitionUnit: T;
  labelId: string;
  onChange: (data: T) => void;
}
