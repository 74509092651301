import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { sequenceEqual } from "../../../crossCutting/arrays";
import {
  FormSectionTitle,
  FormTitle,
} from "../../../crossCutting/FormSectionTitle";
import usePromiseStates, {
  combineStates,
  success,
  State,
} from "../../../crossCutting/hooks/usePromiseStates";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import { byString } from "../../../crossCutting/sorting";
import {
  useFetch2,
  useSendJson,
  useFetchClient,
} from "../../../fetching/fetchProvider";
import { TranslationFunc, useTranslation } from "../../../i18n/i18n";
import { useTranslatorForUser } from "../../masterdata/translation";
import { AnimalStandard } from "../apiTypes";
import {
  AnimalStatus,
  ClubCompetition as ApiClubCompetition,
} from "../clubCompetitionApiTypes";
import {
  useExhibitionCached,
  useRefreshExhibition,
} from "../ExhibitionContext";
import PointCalculationRadios from "../PointCalculationRadios";
import {
  applicablePointCalculations,
  PointCalculation,
} from "../pointCalcultation";
import { Exhibition } from "../types";
import OrganizationList, {
  OrganizationConfig,
} from "./organization/OrganizationList";
import { clubCompetitionEditPagePath } from "./routing";
import { searchBreedsOfStandards, Breed } from "./organization/breedApi";

export type ClubCompetitionEditExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;

const none = "none";

interface ClubCompetitionEditPageProps {
  exhibition: ClubCompetitionEditExhibition;
  clubCompetition: ClubCompetition;
  organizations: ApiOrganization[];
  animalStandards: AnimalStandard[];
}

interface ClubCompetition {
  clubCompetitionId: string;
  clubCompetitionName: string;
  animalStandardId1: string;
  animalStandardId2: string | null;
  pointCalculation?: PointCalculation;
  animalStatus: AnimalStatus[];
  minRatedAnimalCount?: number;
  minBreederCount?: number;
  fixAnimalCount?: number;
  remainingAnimalsFraction?: number;
  rankingListSplitLimit?: number;
  organizations?: OrganizationConfig[];
  mustRestrictByBreed?: boolean;
}

const useStyles = makeStyles((theme) => ({
  slider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(5),
  },
}));

const ClubCompetitionEditPage: React.FunctionComponent<ClubCompetitionEditPageProps> = (
  props
) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const masterDataTranslator = useTranslatorForUser();
  const refreshGlobalExhibition = useRefreshExhibition();

  const isNewCompetition = !props.clubCompetition.clubCompetitionId;
  const [clubCompetitionName, setClubCompetitionName] = React.useState(
    props.clubCompetition.clubCompetitionName
  );
  const [animalStandardId1, setAnimalStandardId1] = React.useState(
    props.clubCompetition.animalStandardId1 || ""
  );
  const [animalStandardId2, setAnimalStandardId2] = React.useState(
    props.clubCompetition.animalStandardId2 || none
  );

  const fetchClient = useFetchClient();
  const searchBreeds = React.useCallback(
    (animalStandardIds: string[]) =>
      searchBreedsOfStandards(
        fetchClient,
        props.exhibition.exhibitionId,
        animalStandardIds
      ),
    [props.exhibition.exhibitionId, fetchClient]
  );
  const animalStandardIds = [animalStandardId1, animalStandardId2].filter(
    (i) => i && i !== none
  );
  const animalStandardIdsJson = JSON.stringify(animalStandardIds);
  const { execute, state: allBreedsState } = usePromiseStates(searchBreeds);
  React.useEffect(() => {
    execute(JSON.parse(animalStandardIdsJson));
  }, [animalStandardIdsJson, execute]);

  const selectablePointCalculations = (
    animalStandardId1: string,
    animalStandardId2: string
  ) => {
    const animalStandard1 = props.animalStandards.find(
      (s) => s.animalStandardId === animalStandardId1
    );
    const animalStandard2 = props.animalStandards.find(
      (s) => s.animalStandardId === animalStandardId2
    );
    return (
      (animalStandard1 &&
        applicablePointCalculations(animalStandard1).filter(
          (pc) =>
            !animalStandard2 ||
            applicablePointCalculations(animalStandard2).some(
              (pc2) => pc2.value === pc.value
            )
        )) ??
      []
    );
  };
  const defaultPointCalculation = (
    animalStandardId1: string,
    animalStandardId2: string
  ) =>
    selectablePointCalculations(animalStandardId1, animalStandardId2)[0]
      ?.value ?? "";

  const [pointCalculation, setPointCalculation] = React.useState(
    props.clubCompetition.pointCalculation ||
      defaultPointCalculation(
        props.clubCompetition.animalStandardId1,
        props.clubCompetition.animalStandardId2 ?? none
      )
  );

  const handleStandard1Change = (e: any) => {
    setAnimalStandardId1(e.target.value);
    setPointCalculation(
      defaultPointCalculation(e.target.value, animalStandardId2)
    );
  };

  const handleStandard2Change = (e: any) => {
    setAnimalStandardId2(e.target.value);
    setPointCalculation(
      defaultPointCalculation(animalStandardId1, e.target.value)
    );
  };

  const animalStatusPresets = getAnimalStatusPresets(t);
  const findPreset = (statusList: AnimalStatus[]) =>
    animalStatusPresets.find((p) => {
      const list = p.statusList ?? ([] as AnimalStatus[]);
      return (
        statusList.length === list.length &&
        sequenceEqual(
          statusList.sort(byString((i) => i)),
          list.sort(byString((i) => i))
        )
      );
    }) ?? animalStatusPresets.find((s) => s.userDefined);

  const [animalStatusPreset, setAnimalStatusPreset] = React.useState(
    findPreset(props.clubCompetition.animalStatus)?.label
  );

  const handleAnimalStatusPresetChange = (e: any) => {
    const selectedPreset = animalStatusPresets.find(
      (s) => s.label === e.target.value
    );
    setAnimalStatusPreset(selectedPreset?.label ?? "");
    setSelectedAnimalStatus(selectedPreset?.statusList ?? [AnimalStatus.Valid]);
  };
  const [selectedAnimalStatus, setSelectedAnimalStatus] = React.useState(
    props.clubCompetition.animalStatus
  );
  const allStatus = getAllStatus(t);

  const [minRatedAnimalCount, setMinRatedAnimalCount] = React.useState(
    props.clubCompetition.minRatedAnimalCount?.toString() ?? "0"
  );
  const [minBreederCount, setMinBreederCount] = React.useState(
    props.clubCompetition.minBreederCount?.toString() ?? "0"
  );
  const [fixAnimalCount, setFixAnimalCount] = React.useState(
    props.clubCompetition.fixAnimalCount ?? 0
  );
  const [rankingListSplitLimit, setRankingListSplitLimit] = React.useState(
    props.clubCompetition.rankingListSplitLimit
  );
  const [
    remainingAnimalsFraction,
    setRemainingAnimalsFraction,
  ] = React.useState(
    (props.clubCompetition.remainingAnimalsFraction ?? 0) * 100
  );
  const [mustRestrictByBreed, setMustRestrictByBreed] = React.useState(
    props.clubCompetition.mustRestrictByBreed || false
  );
  const [organizations, setOrganizations] = React.useState(
    props.clubCompetition.organizations ?? ([] as OrganizationConfig[])
  );

  const { sendJson: save, loading: saving } = useSendJson({
    success: t("clubCompetitionSaveSuccess"),
    error: t("clubCompetitionSaveError"),
  });

  const valid = clubCompetitionName.trim() && animalStandardId1;

  const collectInputs = () => {
    return {
      clubCompetitionName,
      pointCalculation,
      rankingListSplitLimit: hasStandard2 ? rankingListSplitLimit : null,
      mustRestrictByBreed,
      minRatedAnimalCount: parseInt(minRatedAnimalCount),
      minBreederCount: parseInt(minBreederCount),
      fixAnimalCount,
      remainingAnimalsFraction: remainingAnimalsFraction / 100,
      animalStandardId1,
      animalStandardId2: animalStandardId2 === none ? null : animalStandardId2,
      organizations,
      animalStatus: selectedAnimalStatus,
    };
  };

  const handleSaveNew = () => {
    save({
      path: `exhibitions/${props.exhibition.exhibitionId}/clubcompetitions/`,
      method: "POST",
      body: collectInputs(),
      onSuccess: ({ clubCompetitionId }: { clubCompetitionId: string }) => {
        refreshGlobalExhibition();
        history.replace(
          clubCompetitionEditPagePath(
            props.exhibition.exhibitionId,
            clubCompetitionId
          )
        );
      },
    });
  };
  const handleSaveExisting = () => {
    save({
      path: `exhibitions/${props.exhibition.exhibitionId}/clubcompetitions/${props.clubCompetition.clubCompetitionId}`,
      method: "PUT",
      body: collectInputs(),
      onSuccess: refreshGlobalExhibition,
    });
  };
  const hasStandard2 = animalStandardId2 && animalStandardId2 !== none;
  return (
    <BottomBarPage
      title={props.exhibition.exhibitionName}
      subtitle={
        isNewCompetition ? t("newClubCompetition") : t("editClubCompetition")
      }
      bottomBarContent={
        isNewCompetition ? (
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid || saving || allBreedsState.isRunning()}
            onClick={handleSaveNew}
          >
            {t("save")}
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid || saving || allBreedsState.isRunning()}
            onClick={handleSaveExisting}
          >
            {t("saveChanges")}
          </Button>
        )
      }
    >
      <Grid container spacing={spacing3}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            id="competitionName"
            name="competitionName"
            label={t("clubCompetitionName")}
            value={clubCompetitionName}
            onChange={(e) => setClubCompetitionName(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSectionTitle title={t("standard1")} />
          <Select
            fullWidth
            value={animalStandardId1}
            onChange={handleStandard1Change}
          >
            {props.animalStandards
              .filter(
                (s) =>
                  s.isActive ||
                  props.clubCompetition.animalStandardId1 === s.animalStandardId
              )
              .map((s) => (
                <MenuItem key={s.animalStandardId} value={s.animalStandardId}>
                  {masterDataTranslator(s.captions)?.animalStandardName}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSectionTitle title={t("standard2")} />
          <Select
            fullWidth
            value={animalStandardId2}
            onChange={handleStandard2Change}
          >
            {[<MenuItem value={none}>{t("withoutStandard2")}</MenuItem>].concat(
              props.animalStandards
                .filter(
                  (s) =>
                    s.isActive ||
                    props.clubCompetition.animalStandardId2 ===
                      s.animalStandardId
                )
                .filter((s) => s.animalStandardId !== animalStandardId1)
                .map((s) => (
                  <MenuItem key={s.animalStandardId} value={s.animalStandardId}>
                    {masterDataTranslator(s.captions)?.animalStandardName}
                  </MenuItem>
                ))
            )}
          </Select>
        </Grid>
        {selectablePointCalculations(animalStandardId1, animalStandardId2)
          .length > 1 ? (
          <Grid item xs={12} sm={6}>
            <FormSectionTitle title={t("points")} />
            <PointCalculationRadios
              value={pointCalculation as PointCalculation}
              onChange={setPointCalculation}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormSectionTitle title={t("includedAnimals")} />
          <Select
            fullWidth
            value={animalStatusPreset}
            onChange={handleAnimalStatusPresetChange}
          >
            {animalStatusPresets.map((p) => (
              <MenuItem key={p.label} value={p.label}>
                {p.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("status")}</FormLabel>
            <FormGroup>
              {allStatus.map((s) => (
                <FormControlLabel
                  key={s.animalStatus}
                  control={
                    <Checkbox
                      checked={selectedAnimalStatus.some(
                        (ss) => ss === s.animalStatus
                      )}
                      disabled={
                        !animalStatusPresets.find(
                          (p) => p.label === animalStatusPreset
                        )?.userDefined || s.animalStatus === AnimalStatus.Valid
                      }
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSelectedAnimalStatus((old) =>
                          checked
                            ? [...old, s.animalStatus]
                            : old.filter((o) => o !== s.animalStatus)
                        );
                      }}
                    />
                  }
                  label={s.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title={t("minimalRatedAnimals")} />
          <TextField
            fullWidth
            value={minRatedAnimalCount}
            onChange={(e) => setMinRatedAnimalCount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title={t("minimalBreederLabel")} />
          <TextField
            fullWidth
            value={minBreederCount}
            onChange={(e) => setMinBreederCount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title={t("fixCountedAnimal")} />
          <Slider
            className={classes.slider}
            value={fixAnimalCount}
            min={0}
            max={parseInt(minRatedAnimalCount) || 0}
            onChange={(_, newValue) => setFixAnimalCount(newValue as number)}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title={t("percentOfCountedAnimals")} />
          <Slider
            className={classes.slider}
            value={remainingAnimalsFraction}
            min={0}
            max={100}
            onChange={(_, newValue) =>
              setRemainingAnimalsFraction(newValue as number)
            }
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle
            title={t("splitRankingList")}
            disabled={!hasStandard2}
          />
          <FormControlLabel
            disabled={!hasStandard2}
            control={
              <Checkbox
                checked={
                  rankingListSplitLimit !== null &&
                  rankingListSplitLimit !== undefined
                }
                onChange={(e) => {
                  setRankingListSplitLimit(e.target.checked ? 50 : undefined);
                }}
              />
            }
            label={t("splitIn2RankingLists")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t("limitValue")}</FormLabel>
            <FormGroup>
              <Slider
                disabled={
                  !hasStandard2 ||
                  rankingListSplitLimit === null ||
                  rankingListSplitLimit === undefined
                }
                className={classes.slider}
                value={rankingListSplitLimit || 0}
                min={0}
                max={100}
                onChange={(_, newValue) =>
                  setRankingListSplitLimit(newValue as number)
                }
                valueLabelDisplay="on"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormTitle>{t("organizations")}</FormTitle>
          <OrganizationList
            exhibitionId={props.exhibition.exhibitionId}
            animalStandardIds={animalStandardIds}
            value={organizations}
            onChange={setOrganizations}
            allOrganizations={props.organizations}
            allBreedsState={allBreedsState as State<Breed[]>}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mustRestrictByBreed}
                onChange={(e) => {
                  setMustRestrictByBreed(e.target.checked);
                }}
              />
            }
            label={t("mustRestrictByBreed")}
          />
        </Grid>
      </Grid>
    </BottomBarPage>
  );
};

const SupplyData = (props: { exhibition: ClubCompetitionEditExhibition }) => {
  const { clubCompetitionId } = useParams<{ clubCompetitionId?: string }>();
  const isNewClubCompetition = !clubCompetitionId;
  const cachedExhibition = useExhibitionCached();

  const [organizationState] = useFetch2<{ organizations: ApiOrganization[] }>(
    `exhibitions/${props.exhibition.exhibitionId}/organizations`
  );

  const [editClubCompetitionState] = useFetch2<ApiClubCompetition>(
    isNewClubCompetition
      ? ""
      : `exhibitions/${props.exhibition.exhibitionId}/clubcompetitions/${clubCompetitionId}`
  );

  const newClubCompetitionState = success({
    clubCompetitionId: "",
    clubCompetitionName: "",
    animalStandardId1: "",
    animalStandardId2: "",
    animalStatus: [AnimalStatus.Valid],
  });

  const clubCompetitionState = isNewClubCompetition
    ? newClubCompetitionState
    : editClubCompetitionState;

  const stateProps = combineStates(
    clubCompetitionState,
    organizationState,
    (c, o) => ({
      clubCompetition: c,
      organizations: o.organizations,
      animalStandards: cachedExhibition.animalStandards,
    })
  );

  return stateProps.isRunning() ? (
    <LoadingIndicator />
  ) : stateProps.isError() ? (
    <>"Error"</>
  ) : (
    <ClubCompetitionEditPage
      exhibition={cachedExhibition}
      {...stateProps.data}
    />
  );
};
export default SupplyData;

interface ApiOrganization {
  organizationId: string;
  organizationName: string;
  organizationNumber: number;
  organizationCode: string;
}

const getAnimalStatusPresets = (translator: TranslationFunc) => [
  {
    label: translator("includeRatedAnimals"),
    statusList: [AnimalStatus.Valid],
  },
  {
    label: translator("includeRegisteredAnimals"),
    statusList: [
      AnimalStatus.Valid,
      AnimalStatus.NotRated,
      AnimalStatus.NotPresent,
      AnimalStatus.IncorrectClass,
      AnimalStatus.IncorrectDelivery,
    ],
  },
  {
    label: translator("includeAdmitedAnimals"),
    statusList: [
      AnimalStatus.Valid,
      AnimalStatus.NotRated,
      AnimalStatus.IncorrectClass,
      AnimalStatus.IncorrectDelivery,
    ],
  },
  { userDefined: true, label: translator("includeUserDefined") },
];

function getAllStatus(t: TranslationFunc) {
  return [
    { animalStatus: AnimalStatus.Valid, label: t("valid") },
    { animalStatus: AnimalStatus.NotRated, label: t("notRated") },
    { animalStatus: AnimalStatus.NotPresent, label: t("notPresent") },
    { animalStatus: AnimalStatus.IncorrectClass, label: t("incorrectClass") },
    {
      animalStatus: AnimalStatus.IncorrectDelivery,
      label: t("incorrectDelivery"),
    },
  ];
}
