import {
  ListSubheader,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { SelectorProps } from "../../../crossCutting/selectorProps";
import { useTranslatorForUser } from "../../masterdata/translation";

export interface UnitSelectProps<T extends Unit> extends SelectorProps<string> {
  standards: Standard<T>[];
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
}

interface Standard<T> {
  captions: { language: string; animalStandardName: string }[];
  units: T[];
}

interface Unit {
  unitId: string;
  captions: { language: string; unitName: string }[];
}

const useStyles = makeStyles((theme) => ({
  subheader: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
  },
}));
const UnitSelect = <T extends Unit>(props: UnitSelectProps<T>) => {
  const translator = useTranslatorForUser();
  const units = props.standards.flatMap((s) => s.units);
  const handleChange = (e: any) => {
    props.onChange(e.target.value);
  };
  const shouldRenderSubheader = props.standards.length > 1;
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      value={props.value ?? ""}
      onChange={handleChange}
      fullWidth={props.fullWidth}
      error={props.error}
      helperText={props.helperText}
      select
      SelectProps={{
        renderValue: (unitId: any) => {
          const unit = units.find((u) => u.unitId === unitId);
          const standard = props.standards.find((s) =>
            s.units.some((u) => u === unit)
          );
          return `${translator(unit?.captions ?? [])?.unitName}: ${
            translator(standard?.captions ?? [])?.animalStandardName
          }`;
        },
      }}
    >
      {props.standards
        .map((s) => {
          const unitItems = s.units.map((u) => (
            <MenuItem key={u.unitId} value={u.unitId}>
              {translator(u.captions)?.unitName}
            </MenuItem>
          ));
          return shouldRenderSubheader
            ? [
                <ListSubheader
                  key={translator(s.captions)?.animalStandardName}
                  className={classes.subheader}
                >
                  {translator(s.captions)?.animalStandardName}
                </ListSubheader>,
                ...unitItems,
              ]
            : unitItems;
        })
        .flat()}
    </TextField>
  );
};

export default UnitSelect;
