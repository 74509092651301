import * as React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { CompetitionUnit } from "./unitTypes";
import { Row } from "./UnitTableRow";

export interface UnitTableProps<T extends CompetitionUnit> {
  units: T[];
  onChange: (units: T[]) => void;
}

function UnitTable<T extends CompetitionUnit>({
  units,
  onChange,
}: UnitTableProps<T>) {
  function onRowChange(changedRow: T) {
    onChange([
      changedRow,
      ...units.filter(({ unit }) => unit.unitId !== changedRow.unit.unitId),
    ]);
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Name</TableCell>
          <TableCell align="center">Geschlechter erfassen</TableCell>
          <TableCell align="center">Streichresultat</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {units.map((u, i) => {
          const rowProps = {
            competitionUnit: u,
            onChange: onRowChange,
            labelId: `unit-table-checkbox-${i}`,
          };
          return <Row key={u.unit.unitId} {...rowProps} />;
        })}
      </TableBody>
    </Table>
  );
}

export default UnitTable;
