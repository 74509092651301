export enum ChampionLevelKey {
  BreedChampion = "BreedChampion",
  ColorChampion = "ColorChampion",
}

export const allChampionLevels = [
  {
    level: ChampionLevelKey.BreedChampion,
    levelName: "Rasse",
    isSelected: false,
    animalMin: "0",
    championCode: "",
  },
  {
    level: ChampionLevelKey.ColorChampion,
    levelName: "Farbe",
    isSelected: false,
    animalMin: "0",
    championCode: "",
  },
];
