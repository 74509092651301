import * as React from "react";
import DrawerMenu, {
  DrawerMenuItem,
  DrawerActions,
} from "../crossCutting/DrawerMenu";
import { exhibitionsPagePath } from "./exhibitions/exhibitionsPage/routing";
import { newExhibitionPagePath } from "./exhibitions/newExhibitionPage/routing";
import { AddIcon, } from "../crossCutting/icons";
import { ForRoles } from "../authorization/RolesContext";
import AppRole from "../authorization/appRoles";
import { useTranslation } from "../i18n/i18n";
import MasterDataMenu from "./masterdata/MasterDataMenu";
import { globalStandardPagePath } from "./masterdata/standardPage/routingGlobal";
import { globalBoxTypesPagePath } from "./masterdata/boxTypesPage/routingGlobal";
import { lockPagePath } from "./masterdata/lockPage/routing";

/**
 * Menu that appears in the drawer when there is no current exhibition.
 */
export default function NoExhibitionMenu(props: {
  drawerActions?: DrawerActions;
}) {
  const handleItemClick = () => {
    props.drawerActions?.setDrawerOpen(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <DrawerMenu>
        {[
          { text: t("allExhibitions"), path: exhibitionsPagePath() },
          {
            text: t("newExhibition"),
            path: newExhibitionPagePath(),
            icon: <AddIcon />,
          },
        ].map((item) => (
          <ForRoles key={item.text} roles={(item as any).roles}>
            <DrawerMenuItem {...item} onClick={handleItemClick} />
          </ForRoles>
        ))}
      </DrawerMenu>
      <ForRoles roles={AppRole.MasterDataAdmin}>
        <MasterDataMenu
          strategy={{
            rootItemTitle: t("globalMasterData"),
            standardPagePath: () => globalStandardPagePath(),
            boxTypesPagePath: () => globalBoxTypesPagePath(),
          }}
          prefixComponent={
            <DrawerMenuItem
              text={"Sperrung"}
              path={lockPagePath()}
              notExact
            />
          }
        />
      </ForRoles>
    </>
  );
}
