import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import { sequenceEqual } from "../../../../crossCutting/arrays";
import ChangeOrderList from "../../ChangeOrderList";
import { Node, getApplicableNodeLevels } from "../../nodes";
import NodeSelector from "../../NodeSelector";
import { spacing3 } from "../../../../crossCutting/layoutConstants";
import { useTranslatorForUser } from "../../translation";
import { byNumber } from "../../../../crossCutting/sorting";

const useStyles = makeStyles((theme) => ({
  belowNodeSelector: {
    marginTop: theme.spacing(spacing3),
  },
}));

export type MakeBreedsDataHook = () => () => {
  breeds: {
    breedId: string;
    captions: { language: string; breedName: string }[];
    sort: number;
  }[];
  loading: boolean;
  refresh: (
    animalStandardId: string,
    search?: string,
    ancestorNodeId?: string
  ) => Promise<void>;
};

export type MakeNodesDataHook = () => () => {
  nodes: Node[];
  loading: boolean;
};

export default function ChangeBreedOrderDialog(props: {
  open: boolean;
  onCancel: () => void;
  onSave: (orderedKeys: string[]) => void;
  title: string;
  makeBreedsDataHook: MakeBreedsDataHook;
  makeNodesDataHook: MakeNodesDataHook;
  animalStandard: {
    animalStandardId: string;
    hasBreedSizeLevel: boolean;
    hasSubGroupLevel: boolean;
  };
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const translator = useTranslatorForUser();
  const useNodes = props.makeNodesDataHook();
  const { nodes, loading: nodesLoading } = useNodes();
  const useBreeds = props.makeBreedsDataHook();
  const { breeds, loading: breedsLoading, refresh: refresBreeds } = useBreeds();
  const [selectedNodeId, setSelectedNodeId] = React.useState("");
  const animalStandardId = props.animalStandard.animalStandardId;

  const levels = getApplicableNodeLevels(props.animalStandard);
  const needsParentNode = levels.length !== 0;

  React.useEffect(() => {
    if (needsParentNode && !selectedNodeId) return;
    refresBreeds(animalStandardId, undefined, selectedNodeId);
  }, [refresBreeds, selectedNodeId, animalStandardId, needsParentNode]);
  React.useEffect(() => {
    setOrdered(breeds.sort(byNumber((b) => b.sort)).map((b) => b.breedId));
  }, [breeds]);

  const classes = useStyles();
  const handleSave = () => {
    props.onSave(ordered);
  };
  const handleClose = () => {
    props.onCancel();
  };
  const [ordered, setOrdered] = React.useState([] as string[]);
  const handleUp = (key: string) => {
    setOrdered((old) => {
      const copy = [...old];
      const index = copy.indexOf(key);
      [copy[index], copy[index - 1]] = [copy[index - 1], copy[index]];
      return copy;
    });
  };
  const handleDown = (key: string) => {
    setOrdered((old) => {
      const copy = [...old];
      const index = copy.indexOf(key);
      [copy[index + 1], copy[index]] = [copy[index], copy[index + 1]];
      return copy;
    });
  };
  const changed = !sequenceEqual(
    breeds.sort(byNumber((b) => b.sort)).map((i) => i.breedId),
    ordered
  );

  const handleNodeChange = (selectedNodeId: string) => {
    setSelectedNodeId(selectedNodeId);
  };

  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        {needsParentNode && (
          <Grid container spacing={spacing3}>
            <NodeSelector
              nodes={nodes}
              selectedNodeId={selectedNodeId}
              onChange={handleNodeChange}
              levelNames={levels.map((l) => l.label)}
              selectContainer={Grid}
              selectContainerProps={{ xs: 6, item: true }}
              fullWidth
            />
          </Grid>
        )}
        {(selectedNodeId || !needsParentNode) && (
          <div className={classes.belowNodeSelector}>
            <DialogContentText>
              Selektiere eine Zeile und drücke die Pfeil-Knöpfe um die Zeile
              nach oben oder unten zu verschieben.
            </DialogContentText>
            <ChangeOrderList
              items={ordered.map((breedId) => {
                const breed = breeds.find((b) => b.breedId === breedId);
                return {
                  key: breedId,
                  orderable: breed
                    ? translator(breed.captions)?.breedName
                    : "n/a",
                };
              })}
              onUp={handleUp}
              onDown={handleDown}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!changed}>
          Reihenfolge speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
