import { Route } from "react-router-dom";
import React from "react";
import Page, { RegistrationEditExhibition } from "./RegistrationEditPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
import { ExhibitionId } from "../types";
import { uuidRegex } from "../../../crossCutting/uuid";

const suffix = "registrations";

export const registrationEditPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:registrationId(${uuidRegex})/edit`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const registrationEditPagePath = (
  exhibitionId: ExhibitionId,
  registrationId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${registrationId}/edit`;

export const LinkToRegistrationEdit: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; registrationId: string }
> = ({ exhibitionId, registrationId, ...rest }) => (
  <AppLink
    {...rest}
    to={registrationEditPagePath(exhibitionId, registrationId)}
  />
);

export const registrationNewPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/new`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const registrationNewPagePath = (
  exhibitionId: ExhibitionId,
  memberId?: string
) =>
  `/exhibitions/${exhibitionId}/${suffix}/new` +
  (memberId ? `?memberId=${memberId}` : ``);

export const LinkToRegistrationNew: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; memberId?: string }
> = ({ exhibitionId, memberId, ...rest }) => (
  <AppLink {...rest} to={registrationNewPagePath(exhibitionId, memberId)} />
);
