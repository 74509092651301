import * as React from "react";
import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import config from "./config";
import { withRouter } from "react-router-dom";

const AzureMonitor: React.FunctionComponent<React.PropsWithChildren<{}>> = (
  props
) =>
  config.appinsightsInstrumentationKey ? (
    <WithKeyAndRouter
      appinsightsInstrumentationKey={config.appinsightsInstrumentationKey}
    >
      {props.children}
    </WithKeyAndRouter>
  ) : (
    <>{props.children}</>
  );

function WithKey(
  props: React.PropsWithChildren<{ appinsightsInstrumentationKey: string }>
) {
  const browserHistory = createBrowserHistory({ basename: "" });
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.appinsightsInstrumentationKey,
      distributedTracingMode: DistributedTracingModes.W3C,
      disableFetchTracking: false,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();

  return <>{props.children}</>;
}

const WithKeyAndRouter: any = withRouter(WithKey as any);

export default AzureMonitor;
