import { Route } from "react-router-dom";
import React from "react";
import Page, { StandardPageTabKey } from "./StandardPage";
import { uuidRegex } from "../../../crossCutting/uuid";
import { makeUrl } from "../../../crossCutting/urls";
import {
  globalBreedEditPagePath,
  globalBreedCreatePagePath,
} from "../breedEditPage/routingGlobal";
import {
  globalUnitEditPagePath,
  globalUnitCreatePagePath,
} from "../unitEditPage/routingGlobal";
import { useAnimalStandards } from "../apiClient/useAnimalStandards";
import { useBreeds } from "../apiClient/useBreeds";
import { useNodes } from "../apiClient/useNodes";
import { useAgeClasses } from "../apiClient/useAgeClasses";
import { TranslationFunc } from "../../../i18n/i18n";
import { globalStandardsPagePath } from "../standardsManagePage/routingGlobal";

export const globalStandardPageRoute = (prefix: string, t: TranslationFunc) => {
  return (
    <Route
      path={`${prefix}/masterdata/standards/:animalStandardId(${uuidRegex})?/:tab?`}
    >
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          makeStandardsDataHook: () => () => useAnimalStandards(),
          makeNodesDataHook: () => (animalStandardId: string) =>
            useNodes(animalStandardId),
          makeAgeClassesDataHook: () => (animalStandardId: string) =>
            useAgeClasses(animalStandardId),
          manageUrl: globalStandardsPagePath,
          standardUrl: (animalStandardId, tabKey) =>
            globalStandardPagePath(animalStandardId, tabKey),
          unitCreateUrl: (animalStandardId: string) =>
            globalUnitCreatePagePath(animalStandardId),
          unitEditUrl: (animalStandardId: string, unitId: string) =>
            globalUnitEditPagePath(animalStandardId, unitId),
          breedEditUrl: (animalStandardId: string, breedId: string) =>
            globalBreedEditPagePath(animalStandardId, breedId),
          breedCreateUrl: (animalStandardId: string) =>
            globalBreedCreatePagePath(animalStandardId),
          makeBreedsDataHook: () => () => useBreeds(),
        }}
      />
    </Route>
  );
};

export const globalStandardPagePath = (
  standardId?: string,
  tab?: StandardPageTabKey
) =>
  makeUrl`/masterdata/standards/${standardId}/${
    standardId ? tab || "breeds" : ""
  }`;
