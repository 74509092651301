import React from "react";

export default function useEdit<T>(
  columns: { resetToDefault: () => void; grabValue: (item: T) => void }[],
  omitValidationErrors: () => void,
  sameItem: (a: T, b: T) => boolean
) {
  const [editState, setEditState] = React.useState<EditState<T>>("NotEditing");
  const stopEditing = () => setEditState("NotEditing");

  const handleAddNew = () => {
    columns.forEach((c) => c.resetToDefault());
    omitValidationErrors();
    setEditState("AddNew");
  };

  const handleEditExisting = (item: T) => {
    columns.forEach((c) => c.grabValue(item));
    omitValidationErrors();
    setEditState({ editedItem: item });
  };

  const tableEditState =
    editState === "NotEditing" || editState === "AddNew"
      ? editState
      : (item: T) =>
          sameItem((editState as EditExistingState<T>).editedItem, item);

  return {
    stopEditing,
    handleAddNew,
    editState,
    handleEditExisting,
    tableEditState,
  };
}

export interface EditExistingState<T> {
  editedItem: T;
}

type EditState<T> = "NotEditing" | "AddNew" | EditExistingState<T>;
