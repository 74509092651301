import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import { inDefaultLanguage } from "../../../language";
import CaptionEditor from "../CaptionEditor";
import { spacing3 } from "../../../crossCutting/layoutConstants";

export type Measure = number | null;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(spacing3),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dimensionBox: {
    width: "4rem",
  },
  additionalDimensionBox: {
    flexGrow: 1,
  },
  dimensionsLabel: {
    marginBottom: theme.spacing(2),
  },
}));

export default function BoxTypeDialog<
  T extends {
    captions: {
      language: string;
      boxTypeName: string;
      advice: string;
    }[];
    length: Measure;
    width: Measure;
    height: Measure;
    additionalDimension: string | null;
  }
>(props: {
  open: boolean;
  onCancel: () => void;
  boxType: T;
  onSave: (captions: T) => void;
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [boxType, setBoxType] = React.useState(props.boxType);
  const hasDefaultLanguage = !!inDefaultLanguage(boxType.captions)?.boxTypeName;
  const captionFieldDescriptors = [
    {
      value: (caption: any) => caption?.boxTypeName || "",
      label: "Name",
      onChange: (language: string, newValue: string) => {
        const old = boxType.captions;
        const itemForLanguage = old.find((i) => i.language === language);
        const captions = itemForLanguage
          ? [
              ...old.filter((i) => i.language !== language),
              { ...itemForLanguage, boxTypeName: newValue },
            ]
          : [...old, { language, boxTypeName: newValue }];
        setBoxType({ ...boxType, captions });
      },
    },
    {
      value: (caption: any) => caption?.advice || "",
      label: "Hinweis",
      onChange: (language: string, newValue: string) => {
        const old = boxType.captions;
        const itemForLanguage = old.find((i) => i.language === language);
        const captions = itemForLanguage
          ? [
              ...old.filter((i) => i.language !== language),
              { ...itemForLanguage, advice: newValue },
            ]
          : [...old, { language, advice: newValue }];
        setBoxType({ ...boxType, captions });
      },
    },
  ];
  const valid = hasDefaultLanguage;
  const handleSave = () => {
    props.onSave(boxType);
  };
  const handleClose = () => {
    props.onCancel();
  };
  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Boxentyp</DialogTitle>
      <DialogContent>
        <CaptionEditor
          autoFocus
          captions={boxType.captions}
          fieldDescriptors={captionFieldDescriptors}
        />
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.dimensionsLabel}>
            Masse (cm)
          </FormLabel>
          <TextField
            className={classes.dimensionBox}
            size="small"
            label="L"
            value={boxType.length ?? ""}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setBoxType((old) => ({
                ...old,
                length: isNaN(parsed) ? null : parsed,
              }));
            }}
          />
          <TextField
            className={classes.dimensionBox}
            size="small"
            label="B"
            value={boxType.width ?? ""}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setBoxType((old) => ({
                ...old,
                width: isNaN(parsed) ? null : parsed,
              }));
            }}
          />
          <TextField
            className={classes.dimensionBox}
            size="small"
            label="H"
            value={boxType.height ?? ""}
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setBoxType((old) => ({
                ...old,
                height: isNaN(parsed) ? null : parsed,
              }));
            }}
          />
          {" / "}
          <TextField
            className={classes.additionalDimensionBox}
            size="small"
            label="Zusatz"
            value={boxType.additionalDimension ?? ""}
            onChange={(e) => {
              var text = e.target.value;
              setBoxType((old) => ({
                ...old,
                additionalDimension: text,
              }));
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!valid}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
