import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import EditList from "../../../crossCutting/EditList";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { AddIcon } from "../../../crossCutting/icons";
import { JumpToLinkButton } from "../../../crossCutting/JumpToButton";
import { byNumber } from "../../../crossCutting/sorting";
import { Unit } from "../apiClient/unitTypes";
import { useTranslatorForUser } from "../translation";

export type MakeUnitsDataHook = () => () => {
  units: Unit[];
  loading: boolean;
  refresh: (animalStandardId: string) => Promise<void>;
};
interface UnitsTabPanelStrategy {
  createPageUrl: () => string;
  editPageUrl: (unitId: string) => string;
}

export interface UnitsTabPanelProps {
  strategy: UnitsTabPanelStrategy;
  units: Unit[];
}

const UnitsTabPanel: React.FunctionComponent<UnitsTabPanelProps> = (props) => {
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const history = useHistory();
  const translator = useTranslatorForUser();
  const handleNewUnitClick = () => {
    history.push(props.strategy.createPageUrl());
  };
  return (
    <EditList
      toolbarItems={
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleNewUnitClick}
        >
          Neue Einheit
        </Button>
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Herkunft</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.units.sort(byNumber((u) => u.sort)).map((u) => (
            <TableRow
              className={rowClass}
              key={u.unitId}
              hover
              onClick={() => history.push(props.strategy.editPageUrl(u.unitId))}
            >
              <TableCell>{translator(u.captions)?.unitName}</TableCell>
              <TableCell>{u.origin ? "Ausstellung" : "Global"}</TableCell>
              <HoverActionCell>
                <JumpToLinkButton to={props.strategy.editPageUrl(u.unitId)}>
                  Bearbeiten
                </JumpToLinkButton>
              </HoverActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </EditList>
  );
};

export default UnitsTabPanel;
