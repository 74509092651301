import { IconButton, Tooltip } from "@material-ui/core";

import * as React from "react";
import { UpIcon, DownIcon } from "./icons";

/**
 * Shows an up and a down button.
 */
export default function UpDown({
  upPossible,
  downPossible,
  onUp,
  onDown,
  disabled,
}: {
  upPossible: boolean;
  downPossible: boolean;
  onUp: () => void;
  onDown: () => void;
  disabled?: boolean;
}) {
  return (
    <>
      {!disabled && upPossible ? (
        <Tooltip title="Nach oben">
          <IconButton onClick={onUp}>
            <UpIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <UpIcon />
        </IconButton>
      )}
      {!disabled && downPossible ? (
        <Tooltip title="Nach unten">
          <IconButton onClick={onDown}>
            <DownIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton disabled>
          <DownIcon />
        </IconButton>
      )}
    </>
  );
}
