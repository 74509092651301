import React from "react";
import { Route } from "react-router-dom";
import { makeUrl } from "../../../crossCutting/urls";
import { TranslationFunc } from "../../../i18n/i18n";
import { useAnimalStandards } from "../apiClient/useAnimalStandards";
import Page from "./AnimalStandardsPage";

export const globalStandardsManagePageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => (
  <Route path={`${pathPrefix}/masterdata/animalstandards`}>
    <Page
      strategy={{
        title: t("globalMasterDataPageTitle"),
        makeAnimalStandardsDataHook: () => () => useAnimalStandards(),
      }}
    />
  </Route>
);

export const globalStandardsPagePath = () =>
  makeUrl`/masterdata/animalstandards/`;
