import * as React from "react";

export enum RankingMode {
  OneTwoTwoThree = "OneTwoTwoThree",
  OneTwoTwoFour = "OneTwoTwoFour",
}

export const rankingModes = [
  {
    label: (
      <>
        1, 2, 2, <strong>3</strong>, 4
      </>
    ),
    value: RankingMode.OneTwoTwoThree,
  },
  {
    label: (
      <>
        1, 2, 2, <strong>4</strong>, 5
      </>
    ),
    value: RankingMode.OneTwoTwoFour,
  },
];
