import { Route } from "react-router-dom";
import React from "react";
import Page from "./BoxTypesPage";
import { ExhibitionId } from "../../exhibitions/types";
import { makeUrl } from "../../../crossCutting/urls";
import { useBoxTypes } from "../apiClient/useBoxTypes";

export const exhibitionBoxTypesPageRoute = (
  pathPrefix: string,
  exhibition: { exhibitionName: string; exhibitionId: ExhibitionId }
) => (
  <Route path={`${pathPrefix}/masterdata/boxtypes`}>
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        makeBoxTypesDataHook: () => () => useBoxTypes(exhibition.exhibitionId),
      }}
    />
  </Route>
);

export const exhibitionBoxTypesPagePath = (exhibitionId: ExhibitionId) =>
  makeUrl`/exhibitions/${exhibitionId}/masterdata/boxtypes/`;
