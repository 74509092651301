import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import { sequenceEqual } from "../../crossCutting/arrays";
import ChangeOrderList from "./ChangeOrderList";

export interface Item {
  orderable: any;
  key: string;
}

export default function ChangeOrderDialog(props: {
  open: boolean;
  onCancel: () => void;
  onSave: (orderedKeys: string[]) => void;
  title: string;
  items: Item[];
  loading?: boolean;
  saveButtonTitle?: string;
}) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const handleSave = () => {
    props.onSave(ordered.map((o) => o.key));
  };
  const handleClose = () => {
    props.onCancel();
  };
  const [ordered, setOrdered] = React.useState(props.items);

  const handleUp = (key: string) => {
    setOrdered((old) => {
      const copy = [...old];
      const index = copy.findIndex((item) => item.key === key);
      [copy[index], copy[index - 1]] = [copy[index - 1], copy[index]];
      return copy;
    });
  };
  const handleDown = (key: string) => {
    setOrdered((old) => {
      const copy = [...old];
      const index = copy.findIndex((item) => item.key === key);
      [copy[index + 1], copy[index]] = [copy[index], copy[index + 1]];
      return copy;
    });
  };
  const changed = !sequenceEqual(
    props.items.map((i) => i.key),
    ordered.map((i) => i.key)
  );

  const saveButtonTitle = props.saveButtonTitle ?? "Reihenfolge speichern";
  return (
    <Dialog
      fullScreen={xs}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selektiere eine Zeile und drücke die Pfeil-Knöpfe um die Zeile nach
          oben oder unten zu verschieben.
        </DialogContentText>
        <ChangeOrderList items={ordered} onUp={handleUp} onDown={handleDown} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={props.loading}>
          Abbrechen
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={!changed || props.loading}
        >
          {saveButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
