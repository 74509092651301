import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import PaperPage from "../../../crossCutting/pages/PaperPage";
import { AlertSnackbar } from "../../../crossCutting/Snackbars";
import UploadButton from "../../../crossCutting/UploadButton";
import { LinkToMembersPage } from "../membersPage/routing";
import { Exhibition } from "../types";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";

export interface ExhibitionPageProps {
  exhibition: MemberImportPageExhibition;
}

export type MemberImportPageExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(spacing3),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const MemberImportPage: React.FunctionComponent<ExhibitionPageProps> = ({
  exhibition,
}) => {
  const classes = useStyles();
  const upload = useFetchClient();
  const [loading, setLoading] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<
    "error" | "success"
  >();
  const [error, setError] = React.useState<MemberImportError>();
  const { t } = useTranslation();
  const handleFile = (file: File) => {
    const formData = new FormData();
    formData.append("csv", file);
    setLoading(true);
    setError(undefined);
    upload(`exhibitions/${exhibition.exhibitionId}/importmembers`, {
      method: "POST",
      body: formData,
    })
      .then((d: any) => {
        setSnackbarMessage(t("rowsImportedTemplate", d));
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((e) => {
        setSnackbarMessage(t("fileImportError"));
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        if (Array.isArray(e.errorFromServer?.errors)) {
          setError(e.errorFromServer);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <PaperPage
      maxWidth={"sm"}
      title={exhibition.exhibitionName}
      subtitle="Mitglieder importieren"
    >
      <Typography className={classes.bottomSpacing}>
        Lade hier eine CSV-Datei mit Mitgliedern hoch damit sich diese anmelden
        können.
      </Typography>
      <Typography className={classes.bottomSpacing}>
        Die Datei erhältst du aus dem Statistikprogramm von Kleintiere Schweiz.
      </Typography>
      <div className={clsx(classes.buttons, classes.bottomSpacing)}>
        <UploadButton onFile={handleFile} disabled={loading}>
          CSV Datei hochladen
        </UploadButton>
        <LinkToMembersPage
          exhibitionId={exhibition.exhibitionId}
          button
          color="primary"
        >
          Vorhandene Mitglieder ansehen
        </LinkToMembersPage>
      </div>
      <Typography color="textSecondary">
        Du kannst mehrere Dateien hintereinander hochladen. Ist ein Mitglied in
        mehreren Dateien vorhanden, überschreiben die Informationen von später
        hochgeladenen Dateien diejenigen von früheren.
      </Typography>
      {error && (
        <Box bgcolor="error.main" color="error.contrastText" p={2}>
          <Typography>Fehlerdetails:</Typography>
          <ul>
            {error.errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </Box>
      )}

      <AlertSnackbar
        open={snackbarOpen}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </PaperPage>
  );
};

export default MemberImportPage;

interface MemberImportError {
  errors: string[];
}
