import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    "&.MuiGrid-root": {
      paddingBottom: 0,
    },
  },
}));

export function FormSectionTitle({
  title,
  disabled,
}: {
  title: string;
  disabled?: boolean;
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.sectionTitle}>
      <FormTitle disabled={disabled}>{title}</FormTitle>
    </Grid>
  );
}

const useFormTitleStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
}));
export const FormTitle: React.FunctionComponent<{ disabled?: boolean }> = (
  props
) => {
  const classes = useFormTitleStyles();
  return (
    <Typography
      component="h3"
      variant="h5"
      className={props.disabled ? classes.disabled : undefined}
    >
      {props.children}
    </Typography>
  );
};
