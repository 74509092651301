import * as React from "react";
import { Tooltip, IconButton, Menu, MenuItem, Link } from "@material-ui/core";
import { PersonIcon } from "./crossCutting/icons";
import { useAuthentication } from "./authentication/AuthContext";
import config from "./config";
import { makeUrl } from "./crossCutting/urls";
import { useTranslation } from "./i18n/i18n";

export interface CurrentUserProps {}

const CurrentUser: React.FunctionComponent<CurrentUserProps> = () => {
  const { signout, email } = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSignout = () => {
    setAnchorEl(null);
    signout();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("userProfile")}>
        <IconButton color="inherit" aria-label="profile" onClick={handleClick}>
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem disabled>{email}</MenuItem>
        <MenuItem>
          <Link
            underline="none"
            color="inherit"
            href={makeUrl`${config.authAuthority}/Identity/Account/Manage`}
          >
            {t("userProfile")}
          </Link>
        </MenuItem>
        <MenuItem onClick={handleSignout}>{t("logout")}</MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUser;
