import * as React from "react";
import OrganizationPicker from "./OrganizationPicker";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { sameOrga } from "./organization";
import { byString } from "../../../crossCutting/sorting";
import { DeleteIcon } from "../../../crossCutting/icons";
import { ExhibitionId } from "../types";
import { Organization } from "../apiTypes";
import { useTranslation } from "../../../i18n/i18n";

export interface OrganizationListProps {
  exhibitionId: ExhibitionId;
  value: Organization[];
  onChange: (value: Organization[]) => void;
}

function OrganizationList({
  exhibitionId,
  value,
  onChange,
}: OrganizationListProps) {
  const remove = (o: { organizationId: string }) => {
    onChange(value.filter((e) => !sameOrga(o, e)));
  };
  const { t } = useTranslation();

  return (
    <>
      <OrganizationPicker
        value={value}
        onChange={onChange}
        exhibitionId={exhibitionId}
        buttonLabel={t("choose")}
      />
      {value.length === 0 ? (
        <div>{t("noOrganizationsSelected")}</div>
      ) : (
        <List disablePadding>
          {value.sort(byString((o) => o.organizationName)).map((o) => (
            <ListItem key={o.organizationId} disableGutters>
              <ListItemText>{o.organizationName}</ListItemText>
              <ListItemSecondaryAction>
                <Tooltip title={t("delete")}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => remove(o)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}

export default OrganizationList;
