import { Tooltip, IconButton } from "@material-ui/core";
import React from "react";
import { EditIcon } from "../icons";

export default function EditButton({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <Tooltip title="Bearbeiten">
      <IconButton
        edge="end"
        aria-label="bearbeiten"
        disabled={disabled}
        onClick={(e) => {
          onClick();
          e.stopPropagation();
        }}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}
