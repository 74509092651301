import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  makeStyles,
  LinkProps,
  Button,
  ExtendButtonBaseTypeMap,
  ButtonTypeMap,
} from "@material-ui/core";
import clsx from "clsx";
import { OverrideProps } from "@material-ui/core/OverridableComponent";

interface JustALinkProps extends LinkProps<"a"> {
  /** Don't underline, no special color */
  disableLinkFormat?: boolean;
}

const useStyles = makeStyles((theme) => ({
  disableLinkFormat: {
    color: "inherit",
  },
}));

const JustALink: React.FunctionComponent<JustALinkProps & WithTo> = ({
  disableLinkFormat,
  underline,
  className,
  ...rest
}) => {
  const formatAsLink = disableLinkFormat !== true;
  const classes = useStyles();
  return (
    <Link
      onClick={(e: any) => e.stopPropagation()}
      component={RouterLink}
      {...rest}
      underline={underline || (formatAsLink ? "hover" : "none")}
      className={clsx(
        className,
        disableLinkFormat && classes.disableLinkFormat
      )}
    />
  );
};

type JustAButtonProps = OverrideProps<
  ExtendButtonBaseTypeMap<ButtonTypeMap>,
  "a"
>;

const JustAButton: React.FunctionComponent<JustAButtonProps & WithTo> = (
  props
) => {
  return (
    <Button
      {...props}
      component={RouterLink}
      onClick={(e: any) => e.stopPropagation()}
    />
  );
};

const AppLink: React.FunctionComponent<AppLinkProps & WithTo> = ({
  button,
  ...rest
}) =>
  button ? (
    <JustAButton {...(rest as any)} />
  ) : (
    <JustALink {...(rest as any)} />
  );

export type AppLinkProps =
  | (JustAButtonProps & { button: true })
  | (JustALinkProps & { button?: false });

type WithTo = { to: string };

export default AppLink;
