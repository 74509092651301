import { Route } from "react-router-dom";
import React from "react";
import Page, { JudgesExhibition } from "./JudgesPage";
import { ExhibitionId } from "../types";

const suffix = "judges";
export const judgesPageRoute = (
  pathPrefix: string,
  exhibition: JudgesExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const judgesPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
