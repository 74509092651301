import {
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import GridCard from "../../crossCutting/GridCard";
import { spacing3 } from "../../crossCutting/layoutConstants";
import Page from "../../crossCutting/pages/Page";
import { useFetchWithTransform } from "../../fetching/fetchProvider";
import { LinkToNewExhibition } from "../exhibitions/newExhibitionPage/routing";
import { ExhibitionHomeData } from "./homePageTypes";
import OrganizerExhibitionsBox from "./OrganizerExhibitionsBox";
import RegistrationsByExhibition from "./RegistrationsByExhibition";
import { useTranslation } from "../../i18n/i18n";
import { AddIcon } from "../../crossCutting/icons";

export interface HomePageProps {}

const HomePage: React.FunctionComponent<HomePageProps> = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useFetchWithTransform<
    ExhibitionHomeData<Date>
  >("/exhibitions/home", transformExhibitionData);
  return (
    <Page>
      <Grid container spacing={spacing3}>
        {!!data?.exhibitionsWhereCurrentUserIsMember?.length && (
          <GridCard xs={12} sm={6}>
            <CardHeader title={t("exhibitionsForYou")} />
            <CardContent>
              <RegistrationsByExhibition
                exhibitions={data.exhibitionsWhereCurrentUserIsMember}
              />
            </CardContent>
          </GridCard>
        )}
        <GridCard xs={12} sm={6}>
          <CardHeader title={t("organizedByYou")} />
          <CardContent>
            {data?.exhibitionsWhereCurrentUserIsOrganizer && (
              <OrganizerExhibitionsBox
                exhibitions={data.exhibitionsWhereCurrentUserIsOrganizer}
              />
            )}
          </CardContent>
          <CardActions>
            <LinkToNewExhibition color="primary" button startIcon={<AddIcon />}>
              {t("newExhibition")}
            </LinkToNewExhibition>
          </CardActions>
        </GridCard>
        <GridCard xs={12} sm={6}>
          <CardHeader title={t("downloadKlexpo")} />
          <CardContent>
            <Typography variant="body1">
              {t("downloadKlexpoLongText")}
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="primary" href="/download">
              {t("download")}
            </Button>
          </CardActions>
        </GridCard>
      </Grid>
    </Page>
  );
};

export default HomePage;

function transformExhibitionData(raw: ExhibitionHomeData<string>) {
  return {
    exhibitionsWhereCurrentUserIsMember: raw.exhibitionsWhereCurrentUserIsMember.map(
      (i) => ({
        ...i,
        startDate: new Date(i.startDate),
        endDate: new Date(i.endDate),
        deadline: new Date(i.deadline),
      })
    ),
    exhibitionsWhereCurrentUserIsOrganizer: raw.exhibitionsWhereCurrentUserIsOrganizer.map(
      (i) => ({
        ...i,
        startDate: new Date(i.startDate),
        endDate: new Date(i.endDate),
      })
    ),
  };
}
