export function trimSlashes(input: string) {
  return removeTrailingSlash(removeLeadingSlash(input));
}
function removeLeadingSlash(input: string) {
  return input.startsWith("/") ? input.slice(1) : input;
}

function removeTrailingSlash(input: string) {
  return input.endsWith("/") ? input.slice(0, -1) : input;
}

/** Regex-esxape all regex special chars in string */
export function escapeRegex(input: string) {
  return input.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function ignoreAccents(input: string) {
  input = input.toLowerCase();
  var output = "";
  for (var i = 0; i < input.length; i++) {
    if (input.charAt(i) === "a") output = output + "[aàáâãäåæ]";
    else if (input.charAt(i) === "c") output = output + "[cç]";
    else if (input.charAt(i) === "e") output = output + "[eèéêëæ]";
    else if (input.charAt(i) === "i") output = output + "[iìíîï]";
    else if (input.charAt(i) === "n") output = output + "[nñ]";
    else if (input.charAt(i) === "o") output = output + "[oòóôõöø]";
    else if (input.charAt(i) === "s") output = output + "[sß]";
    else if (input.charAt(i) === "u") output = output + "[uùúûü]";
    else if (input.charAt(i) === "y") output = output + "[yÿ]";
    else output = output + input.charAt(i);
  }
  return output;
}
