import { SelectorProps } from "../../../crossCutting/selectorProps";
import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import DateField from "../../../crossCutting/dataInput/DateField";
import { NewExhibition } from "./types";
import { useTranslation } from "../../../i18n/i18n";

export default function Step1({
  value,
  onChange,
}: SelectorProps<NewExhibition>) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={spacing3}>
      <Grid item xs={12}>
        <TextField
          label={t("nameOfTheExhibition")}
          required
          fullWidth
          autoFocus
          value={value.exhibitionName}
          onChange={(e) =>
            onChange({
              ...value,
              exhibitionName: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Ort"
          required
          fullWidth
          value={value.city}
          onChange={(e) => onChange({ ...value, city: e.target.value })}
        />
      </Grid>
      <Grid item container spacing={spacing3}>
        <Grid item xs={6}>
          <DateField
            label="Von"
            value={value.startDate}
            onChange={(d) =>
              onChange({
                ...value,
                startDate: d,
                endDate: value.endDate < d ? d : value.endDate,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DateField
            label="Bis"
            value={value.endDate}
            onChange={(d) => {
              onChange({ ...value, endDate: d });
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <DateField
          label="Anmeldefrist"
          value={value.deadline}
          onChange={(d) => onChange({ ...value, deadline: d })}
        />
      </Grid>
    </Grid>
  );
}
