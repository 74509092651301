export function copyArrayExcludingElementAt(array: any[], index: number) {
  if (index > array.length - 1) {
    return [];
  } else if (index === 0) {
    return array.slice(1);
  } else if (index === array.length - 1) {
    return array.slice(0, index);
  } else {
    return array.slice(0, index).concat(array.slice(index + 1, array.length));
  }
}

export function sequenceEqual<T>(a: T[], b: T[]) {
  // Check if the arrays are the same length
  if (a.length !== b.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  // Otherwise, return true
  return true;
}

export function distinct<T>(arr: T[], equals: (a: T, b: T) => boolean) {
  return arr.reduce(
    (prev, curr) =>
      prev.some((p) => equals(p, curr)) ? prev : [...prev, curr],
    [] as T[]
  );
}
