import { Route } from "react-router-dom";
import React from "react";
import Page, { CategoriesExhibition } from "./CategoriesPage";
import { ExhibitionId } from "../types";

const suffix = "categories";
export const categoriesPageRoute = (
  pathPrefix: string,
  exhibition: CategoriesExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const categoriesPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
