import {
  debounce,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import { useAuthentication } from "../../../authentication/AuthContext";
import MarkIt from "../../../crossCutting/MarkIt";
import { Maybe } from "../../../crossCutting/maybe";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { inLanguageOrDefault, languagesPrio } from "../../../language";
import { ExhibitionId } from "../types";
import NameCell from "./NameCell";

const minSearchTextLength = 2;

const useStyles = makeStyles((theme) => ({
  option: {
    display: "table-row",
  },
  listboxInner: {
    display: "table",
    width: "100%",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
}));

export default function BreedPicker(props: {
  animalStandardId: string;
  exhibitionId: ExhibitionId;
  fullWidth?: boolean;
  disabled?: boolean;
  breed: Maybe<PickerBreed>;
  onChange: (breed: Maybe<PickerBreed>) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { language } = useAuthentication();
  const [options, setOptions] = React.useState([] as PickerBreed[]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [inputValueResultsAreFor, setInputValueResultsAreFor] =
    React.useState("");
  const fetchClient = useFetchClient();
  const fetchDebounced = React.useCallback(
    debounce(
      (
        searchText: string,
        callback: (error: any, o?: PickerBreed[]) => void
      ) => {
        fetchClient(
          `exhibitions/${props.exhibitionId}/masterdata/animalstandards/${
            props.animalStandardId
          }/breeds?onlyActive=true&search=${encodeURIComponent(searchText)}`,
          {
            method: "GET",
          }
        )
          .then((r) => callback(null, r.breeds))
          .catch(callback);
      },
      300
    ),
    [fetchClient, props.animalStandardId, props.exhibitionId]
  );
  React.useEffect(() => {
    if (!inputValue) {
      setOptions([]);
      setInputValueResultsAreFor("");
      return;
    }
    if (inputValue.length < minSearchTextLength) return;
    setLoading(true);
    fetchDebounced(inputValue, (err, options) => {
      setLoading(false);
      if (err || !Array.isArray(options)) return;

      setOptions(options);
      setInputValueResultsAreFor(inputValue);
    });
  }, [inputValue, fetchDebounced]);

  const languages = languagesPrio(language);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleBreedChange = (_: any, breed: Maybe<PickerBreed>) => {
    if (breed) {
      fetchClient(
        `exhibitions/${props.exhibitionId}/masterdata/animalstandards/${props.animalStandardId}/breeds/${breed.breedId}`,
        {
          method: "GET",
        }
      )
        .then((breed: PickerBreed) => {
          props.onChange(breed);
          setInputValue("");
        })
        .catch(() => {});
    }
  };
  return (
    <>
      <Autocomplete
        renderInput={(params) => (
          <OutlinedInput
            error={props.error}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            fullWidth
            placeholder={t("searchBreed")}
            disabled={props.disabled}
            onChange={(e) => {
              setOptions([]);
              setInputValue(e.target.value);
            }}
          />
        )}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        onChange={handleBreedChange}
        noOptionsText={
          inputValue === ""
            ? ""
            : inputValue.length < minSearchTextLength
            ? t("atLeastNumberOfCharsTemplate", {
                minLength: minSearchTextLength,
              })
            : t("noBreedsFound")
        }
        loading={loading}
        options={options}
        autoHighlight
        classes={{
          option: classes.option,
        }}
        selectOnFocus={true}
        ListboxProps={{
          ulClassName: classes.listboxInner,
        }}
        ListboxComponent={TableListbox}
        renderOption={(option) =>
          languages.map((l) => {
            const caption = option.captions.find((c) => c.language === l);
            return (
              <NameCell key={l} language={l}>
                {caption ? (
                  <>
                    {(caption.breedSizeName || caption.subGroupName) && (
                      <Ancestors
                        caption={caption}
                        inputValue={inputValueResultsAreFor}
                      />
                    )}
                    <div>
                      <MarkIt
                        text={caption.breedName}
                        toMark={inputValueResultsAreFor}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </NameCell>
            );
          })
        }
        value={props.breed}
        getOptionLabel={(option) =>
          inLanguageOrDefault(language, option.captions)?.breedName || ""
        }
        getOptionSelected={(o, v) => o?.breedId === v?.breedId}
        filterOptions={(x) => x}
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </>
  );
}

const Ancestors = ({
  caption,
  inputValue,
}: {
  caption: AncestorNames;
  inputValue: string;
}) => {
  const stringsJoined = [caption.breedSizeName, caption.subGroupName]
    .filter((x) => x)
    .join(">");
  return (
    <Typography variant="body2" color="textSecondary">
      <MarkIt text={stringsJoined} toMark={inputValue} />
    </Typography>
  );
};

const TableListbox: any = React.forwardRef(
  ({ children, ulClassName, ...rest }: any, ref: any) => (
    <div {...rest} ref={ref}>
      <ul className={ulClassName}>{children}</ul>
    </div>
  )
);

interface AncestorNames {
  breedSizeName: string | null;
  subGroupName: string | null;
}

export interface PickerBreed {
  breedId: string;
  animalStandardId: string;
  hasAdditionalBoxes: boolean;
  captions: ({
    language: string;
    breedName: string;
  } & AncestorNames)[];
  colors: Maybe<PickerBreedColor[]>;
}

export interface PickerBreedColor {
  colorId: string;
  isActive: boolean;
  captions: {
    language: string;
    colorName: string;
  }[];
}
