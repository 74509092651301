import { Route } from "react-router-dom";
import React from "react";
import Page, { RegistrationsListExhibition } from "./RegistrationsPage";
import { ExhibitionId } from "../types";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";

const suffix = "registrations";

export const registrationsPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationsListExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const registrationsPagePath = (
  exhibitionId: ExhibitionId,
  memberIdRegistered?: string
) =>
  `/exhibitions/${exhibitionId}/${suffix}${
    memberIdRegistered ? "?memberIdRegistered=" + memberIdRegistered : ""
  }`;

export const LinkToRegistrationsPage: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; memberIdRegistered?: string }
> = ({ exhibitionId, memberIdRegistered, ...rest }) => (
  <AppLink
    {...rest}
    to={registrationsPagePath(exhibitionId, memberIdRegistered)}
  />
);
