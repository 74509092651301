import { Route } from "react-router-dom";
import React from "react";
import Page, { ClubCompetitionEditExhibition } from "./ClubCompetitionEditPage";
import { ExhibitionId } from "../types";
import { uuidRegex } from "../../../crossCutting/uuid";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
const suffix = "clubcompetitions";

export const clubCompetitionNewPageRoute = (
  pathPrefix: string,
  exhibition: ClubCompetitionEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/new`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const clubCompetitionNewPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}/new`;

export const clubCompetitionEditPageRoute = (
  pathPrefix: string,
  exhibition: ClubCompetitionEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:clubCompetitionId(${uuidRegex})/edit`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const clubCompetitionEditPagePath = (
  exhibitionId: ExhibitionId,
  clubCompetitionId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${clubCompetitionId}/edit`;

export const LinkToNewClubCompetition: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={clubCompetitionNewPagePath(exhibitionId)} />
);
