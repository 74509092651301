import {
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import GridCard from "../../../crossCutting/GridCard";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import Page from "../../../crossCutting/pages/Page";
import { LinkToMemberImport } from "../memberImportPage/routing";
import { ExhibitionId } from "../types";
import { LinkToCompetitionsPage } from "../competitionsPage/routing";
import { LinkToExhibition } from "../exhibitionPage/routing";

export interface NewExhibitionCreatedPageProps {
  exhibitionId: ExhibitionId;
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(spacing3),
  },
  centerButton: {
    justifyContent: "center",
  },
}));

const ExhibitionCreatedPage: React.SFC<NewExhibitionCreatedPageProps> = ({
  exhibitionId,
}) => {
  const classes = useStyles();
  return (
    <Page
      title="Ausstellung erstellt!"
      subtitle="Nächste Schritte"
      maxWidth="sm"
    >
      <Grid container spacing={spacing3} className={classes.grid}>
        <GridCard xs={12}>
          <CardHeader title="Wertungen erstellen" />
          <CardContent>
            <Typography variant="body1" color="textSecondary">
              Wertungen definieren die Ausstellungseinheiten der Tiere in dieser
              Ausstellung. Deshalb müssen die Wertungen vorhanden sein, bevor
              Aussteller angemeldet werden können.
            </Typography>
          </CardContent>
          <CardActions className={classes.centerButton}>
            <LinkToCompetitionsPage
              button
              variant="contained"
              color="primary"
              exhibitionId={exhibitionId}
            >
              Jetzt Wertung erstellen
            </LinkToCompetitionsPage>
          </CardActions>
        </GridCard>
        <GridCard xs={12}>
          <CardHeader title="Aussteller freischalten" />
          <CardContent>
            <Typography variant="body1" color="textSecondary">
              Damit sich Aussteller anmelden können, müssen sie zuerst
              freigeschaltet werden. Du kannst dazu eine Tabelle mit den
              Mitgliedernummern der Aussteller hochladen.
            </Typography>
          </CardContent>
          <CardActions className={classes.centerButton}>
            <LinkToMemberImport
              button
              variant="contained"
              color="primary"
              exhibitionId={exhibitionId}
            >
              Jetzt Aussteller freischalten
            </LinkToMemberImport>
          </CardActions>
        </GridCard>
        <Grid item xs={12}>
          <CardActions className={classes.centerButton}>
            <LinkToExhibition
              exhibitionId={exhibitionId}
              button
              // variant="outlined"
              color="primary"
            >
              Zur Ausstellung gehen
            </LinkToExhibition>
          </CardActions>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ExhibitionCreatedPage;
