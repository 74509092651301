import React from "react";
import { Route } from "react-router-dom";
import { uuidRegex } from "../../../crossCutting/uuid";
import { TranslationFunc } from "../../../i18n/i18n";
import { useBreedSave } from "../apiClient/useBreeds";
import Page from "./BreedEditPage";

export const globalBreedEditPageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => {
  return (
    <Route
      path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/breeds/:breedId(${uuidRegex})/edit`}
    >
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          getBreedPath: (animalStandardId: string, breedId: string) =>
            `masterdata/animalstandards/${animalStandardId}/breeds/${breedId}`,
          getAnimalStandardPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}`,
          getNodesPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}/nodes`,
          getBoxTypesPath: () => `masterdata/boxtypes/`,
          getBreedsPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}/breeds`,
          makeBreedSaveDataHook: () => (animalStandardId: string) =>
            useBreedSave(animalStandardId),
          editBreedPath: (animalStandardId: string, breedId: string) =>
            globalBreedEditPagePath(animalStandardId, breedId),
        }}
      />
    </Route>
  );
};

export const globalBreedCreatePageRoute = (
  pathPrefix: string,
  t: TranslationFunc
) => {
  return (
    <Route
      path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/breeds/new`}
    >
      <Page
        strategy={{
          title: t("globalMasterDataPageTitle"),
          getAnimalStandardPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}`,
          getNodesPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}/nodes`,
          getBoxTypesPath: () => `masterdata/boxtypes/`,
          getBreedsPath: (animalStandardId: string) =>
            `masterdata/animalstandards/${animalStandardId}/breeds`,
          makeBreedSaveDataHook: () => (animalStandardId: string) =>
            useBreedSave(animalStandardId),
          editBreedPath: (animalStandardId: string, breedId: string) =>
            globalBreedEditPagePath(animalStandardId, breedId),
        }}
      />
    </Route>
  );
};

export const globalBreedEditPagePath = (
  animalStandardId: string,
  breedId: string
) => `/masterdata/standards/${animalStandardId}/breeds/${breedId}/edit`;

export const globalBreedCreatePagePath = (animalStandardId: string) =>
  `/masterdata/standards/${animalStandardId}/breeds/new`;
