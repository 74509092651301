import { PropsWithChildren } from "react";
import * as React from "react";

export default function IfCanRegisterSelf(
  props: PropsWithChildren<{
    exhibition: { withinDeadline: boolean };
    user: { isMember: boolean; isOrganizer: boolean };
  }>
) {
  return canRegisterSelf(props.exhibition, props.user) ? (
    <>{props.children}</>
  ) : null;
}

function canRegisterSelf(
  exhibition: { withinDeadline: boolean },
  user: { isMember: boolean; isOrganizer: boolean }
) {
  return user.isMember && (exhibition.withinDeadline || user.isOrganizer);
}
