import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { DownIcon, SettingsIcon } from "../../../crossCutting/icons";
import { useTranslatorForUser } from "../translation";

export default function StandardsMenu(props: {
  animalStandards: {
    animalStandardId: string;
    captions: { language: string; animalStandardName: string }[];
  }[];
  selectedAnimalStandardId: string;
  onChange: (selectedAnimalStandardId: string) => void;
  onManage: () => void;
}) {
  const masterDataTranslator = useTranslatorForUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (animalStandardId: string) => {
    props.onChange(animalStandardId);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleManage = () => {
    props.onManage();
    handleClose();
  };
  const selectedStandard = props.animalStandards.find(
    (s) => s.animalStandardId === props.selectedAnimalStandardId
  );
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<DownIcon />}
        color="primary"
      >
        {selectedStandard
          ? masterDataTranslator(selectedStandard.captions)?.animalStandardName
          : ""}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.animalStandards.map((s) => (
          <MenuItem
            key={s.animalStandardId}
            onClick={() => handleItemClick(s.animalStandardId)}
          >
            {masterDataTranslator(s.captions)?.animalStandardName}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleManage}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Standards verwalten" />
        </MenuItem>
      </Menu>
    </div>
  );
}
