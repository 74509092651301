import { Maybe } from "../../../crossCutting/maybe";

export function sameCategory<T extends { categoryId: string }>(
  a: Maybe<T>,
  b: Maybe<T>
) {
  return a?.categoryId === b?.categoryId;
}

export interface Category {
  categoryId: string;
  categoryName: string;
  sort: number;
  animalStandardId: string;
}
