import * as React from "react";
import Page from "../../../crossCutting/pages/Page";
import { useTranslation } from "../../../i18n/i18n";
import { Exhibition } from "../apiTypes";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useFetch2 } from "../../../fetching/fetchProvider";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { friendlyDateTime } from "../../../crossCutting/date";
import { Publication } from "./publicationApiTypes";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { useParams } from "react-router-dom";
import { spacing3 } from "../../../crossCutting/layoutConstants";

export type PublicationExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface PublicationPageProps {
  exhibition: PublicationExhibition;
}

const useStyles = makeStyles((theme) => ({
  attributes: theme.typography.body1,
  downloadLink: {
    marginTop: theme.spacing(spacing3),
    marginBottom: theme.spacing(spacing3),
  },
}));

const PublicationPage: React.FunctionComponent<PublicationPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { publicationId } = useParams<{ publicationId?: string }>();
  const [publicationState] = useFetch2<{ publications: Publication[] }>(
    `exhibitions/${props.exhibition.exhibitionId}/publications`
  );
  const requestedPublication = mapState(publicationState, (s) =>
    s.publications.find((p) => p.publicationId === publicationId)
  );

  if (requestedPublication.isRunning()) {
    return <LoadingIndicator />;
  } else if (requestedPublication.isError()) {
    return <>Error</>;
  } else if (!requestedPublication.data) {
    return <Typography>Nicht gefunden</Typography>;
  }

  const publication = requestedPublication.data;
  return (
    <Page
      title={props.exhibition.exhibitionName}
      subtitle={t("catalogPublication")}
    >
      <div>
        <dl className={classes.attributes}>
          <dt>{t("publicationDate")}</dt>
          <dd>{friendlyDateTime(new Date(publication.fileUploadedUtc))}</dd>
          <dt>{t("publishedBy")}</dt>
          <dd>{publication.uploaderFullName}</dd>
        </dl>
      </div>
      <div className={classes.downloadLink}>
        <Link href={publication.url} target="_blank" variant="body1">
          {t("downloadCatalog")}
        </Link>
      </div>
      <Typography variant="h6" component="h3">
        {t("recipients")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("email")}</TableCell>
            <TableCell>{t("lastName")}</TableCell>
            <TableCell>{t("firstName")}</TableCell>
            <TableCell>{t("status")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {publication.recipients.map((r) => (
            <TableRow key={r.email}>
              <TableCell>{r.email}</TableCell>
              <TableCell>{r.familyName}</TableCell>
              <TableCell>{r.givenName}</TableCell>
              <TableCell>
                {r.deliveryStatus === "delivered" ? "OK" : r.deliveryStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Page>
  );
};

export default PublicationPage;
