import { Route } from "react-router-dom";
import React from "react";
import Page, { ClubCompetitionsPageExhibition } from "./ClubCompetitionsPage";
import { ExhibitionId } from "../types";

const suffix = "clubcompetitions";

export const clubCompetitionsPageRoute = (
  pathPrefix: string,
  exhibition: ClubCompetitionsPageExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const clubCompetitionsPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}`;
