import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import EditList from "../../../crossCutting/EditList";
import {
  FormSectionTitle,
  FormTitle,
} from "../../../crossCutting/FormSectionTitle";
import {
  success,
  combineStates,
} from "../../../crossCutting/hooks/usePromiseStates";
import { AddIcon, DeleteIcon, EditIcon } from "../../../crossCutting/icons";
import ImageEditor from "../../../crossCutting/ImageEditor";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import { parseQuery } from "../../../crossCutting/urls";
import { inDefaultLanguage } from "../../../language";
import CaptionEditor from "../CaptionEditor";
import NodeSelector from "../NodeSelector";
import { Node } from "../nodes";
import ColorCombinationDialog from "./ColorCombinationDialog";
import ColorDialog, { FormColor, insertAtHead } from "./ColorDialog";
import ColorTable, { Color } from "./ColorTable";
import { useFetch2 } from "../../../fetching/fetchProvider";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { parseFloatWithDefault } from "../../../crossCutting/numbers";
import { AnimalStandard as ApiAnimalStandard } from "../apiClient/animalStandardTypes";
import { Node as ApiNode } from "../apiClient/nodeTypes";
import { Breed as ApiBreed, BreedEditInput } from "../apiClient/breedTypes";
import { BoxType as ApiBoxType } from "../apiClient/boxTypeTypes";
import { Origin } from "../apiClient/commonTypes";
import { useTranslatorForUser } from "../translation";
import { byString, byNumber } from "../../../crossCutting/sorting";
import { v1 as uuidv1 } from "uuid";
import { distinct, sequenceEqual } from "../../../crossCutting/arrays";

export type MakeBreedSaveDataHook = () => (
  animalStandardId: string
) => {
  loading: boolean;
  createBreed: (
    input: BreedEditInput,
    image?: any
  ) => Promise<{ breedId: string }>;
  updateBreed: (
    input: BreedEditInput & { breedId: string },
    image?: any
  ) => Promise<any>;
};

export interface BreedEditPageStrategy {
  title: string;
  makeBreedSaveDataHook: MakeBreedSaveDataHook;
  editBreedPath: (animalStandardId: string, breedId: string) => string;
}
export interface BreedEditPageProps {
  strategy: BreedEditPageStrategy;
  animalStandard: AnimalStandard;
  breed: Breed;
  nodes: Node[];
  boxTypes: BoxType[];
  refreshAllBreeds: () => void;
  otherBreeds: {
    breedId: string;
    parentNodeId: string | null;
    sort: number;
    captions: { language: string; breedName: string }[];
  }[];
}

interface BoxType {
  boxTypeId: string;
  captions: { language: string; boxTypeName: string }[];
}

interface Breed {
  breedId: string;
  captions: BreedCaption[];
  hasAdditionalBoxes: boolean;
  isWeighingNeeded: boolean;
  godfather: string | null;
  colors: Color[];
  boxTypeId: string | null;
  parentNodeId: string | null;
  colorCombinations: ColorCombination[];
  imageUrl: string | null;
  ringSize: number | null;
}

type ColorId = string;

type ColorCombination = ColorId[];

interface BreedCaption {
  language: string;
  breedName: string;
}

interface AnimalStandard {
  hasBreedSizeLevel: boolean;
  hasSubGroupLevel: boolean;
  hasColorLevel: boolean;
  hasReferenceRating: boolean;
  hasRingSize: boolean;
}

const useStyles = makeStyles((theme) => ({
  imageGridCell: {
    display: "flex",
    flexDirection: "column",
  },
  imageEditor: {
    flexGrow: 1,
    minHeight: 200,
    maxHeight: 400,
  },
}));

const BreedEditPage: React.FunctionComponent<BreedEditPageProps> = ({
  animalStandard,
  ...props
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const translator = useTranslatorForUser();
  const useBreedSave = props.strategy.makeBreedSaveDataHook();
  const { animalStandardId } = useParams<{ animalStandardId: string }>();
  const { createBreed, updateBreed, loading } = useBreedSave(animalStandardId);
  const { preselectionNodeId } = parseQuery(search);
  const classes = useStyles();
  const [captions, setCaptions] = React.useState(props.breed.captions);
  const captionFieldDescriptors = [
    {
      value: (caption: any) => caption?.breedName || "",
      onChange: (language: string, newValue: string) => {
        setCaptions((old) => {
          const itemForLanguage = old.find((i) => i.language === language);
          return itemForLanguage
            ? [
                ...old.filter((i) => i.language !== language),
                { ...itemForLanguage, breedName: newValue },
              ]
            : [...old, { language, breedName: newValue }];
        });
      },
    },
  ];
  const needsParent =
    animalStandard.hasBreedSizeLevel || animalStandard.hasSubGroupLevel;
  const siblingBreeds = (parentNodeId: string) =>
    needsParent
      ? props.otherBreeds.filter((b) => b.parentNodeId === parentNodeId)
      : props.otherBreeds;
  const boxTypes = props.boxTypes;
  const isNewBreed = !props.breed.breedId;
  const [isWeighingNeeded, setIsWeighingNeeded] = React.useState(
    props.breed.isWeighingNeeded
  );
  const [hasAdditionalBoxes, setHasAdditionalBoxes] = React.useState(
    props.breed.hasAdditionalBoxes
  );
  const [godfather, setGodfather] = React.useState(props.breed.godfather || "");
  const [boxTypeId, setBoxTypeId] = React.useState(props.breed.boxTypeId || "");
  const [parentNodeId, setParentNodeId] = React.useState(
    props.breed.parentNodeId || ""
  );
  const findInitialBreedIdBefore = () => {
    const siblings = siblingBreeds(props.breed.parentNodeId || "").sort(
      byNumber((b) => b.sort)
    );
    const indexOfEditedBreed = siblings.findIndex(
      (b) => b.breedId === props.breed.breedId
    );
    if (indexOfEditedBreed === 0) {
      return insertAtHead;
    } else if (indexOfEditedBreed === -1) {
      return "";
    } else {
      return siblings[indexOfEditedBreed - 1].breedId;
    }
  };
  const [breedIdBefore, setBreedIdBefore] = React.useState(
    props.breed.breedId ? findInitialBreedIdBefore() : ""
  );
  const [colors, setColors] = React.useState(props.breed.colors);
  const [colorCombinations, setColorCombinations] = React.useState(
    filterByColors(
      props.breed.colorCombinations,
      props.breed.colors.map((c) => c.colorId)
    )
  );
  const [ringSize, setRingSize] = React.useState<string | undefined>(
    props.breed.ringSize?.toFixed(1) || undefined
  );
  const handleRingSizeChange = (e: any) => {
    setRingSize(e.target.value);
  };
  const handleAdditionalBoxChange = (e: any) => {
    setHasAdditionalBoxes(e.target.checked);
  };
  const handleWeighingNeededChange = (e: any) => {
    setIsWeighingNeeded(e.target.checked);
  };
  const handleGodfatherChange = (e: any) => {
    setGodfather(e.target.value);
  };
  const handleBoxTypeChange = (e: any) => {
    setBoxTypeId(e.target.value);
  };
  const handleParentChange = (nodeId: string) => {
    setParentNodeId(nodeId);
    setBreedIdBefore("");
  };

  const hasDefaultLanguage = !!inDefaultLanguage(captions)?.breedName;

  const [editedColor, setEditedColor] = React.useState<Color | undefined>();
  const handleColorEdit = (color?: Color) => {
    setEditedColor(color || newColorTemplate);
  };
  const colorForDialog = (color: Color) => {
    const index = colors.indexOf(color);
    console.log(index);
    return {
      ...color,
      insertAfterColorId:
        colors.length === 0
          ? insertAtHead
          : index === -1
          ? ""
          : index === 0
          ? insertAtHead
          : colors[index - 1].colorId,
    };
  };
  const handleColorDialogCancel = () => {
    setEditedColor(undefined);
  };
  const handleColorDialogOk = (color: FormColor & Color) => {
    updateColorsWithEditedColor(color);
    setEditedColor(undefined);
  };
  const updateColorsWithEditedColor = (edits: FormColor & Color) => {
    const updated =
      colors.length === 0 ||
      (colors.length === 1 && colors[0].colorId === edits.colorId)
        ? [edits]
        : colors
            .filter((c) => c !== editedColor)
            .map((c, i) =>
              i === 0 && edits.insertAfterColorId === insertAtHead
                ? [edits, c]
                : c.colorId === edits.insertAfterColorId
                ? [c, edits]
                : c
            )
            .flat();
    setColors(updated);
    setColorCombinations(
      filterByColors(
        colorCombinations,
        updated.map((c) => c.colorId)
      )
    );
  };

  const newColorTemplate = () => ({
    colorId: uuidv1(),
    captions: [] as any[],
    referenceRating: null,
    isActive: true,
    origin: Origin.Exhibition,
  });
  const handleToggleColorActivation = (active: boolean, colorIds: string[]) =>
    setColors((old) =>
      old.map((c) =>
        colorIds.some((id) => c.colorId === id) ? { ...c, isActive: active } : c
      )
    );

  const handleColorOrderChange = (reorderedColorIds: string[]) => {
    setColors((old) =>
      reorderedColorIds.map((id) => old.find((o) => o.colorId === id)!)
    );
  };
  const handleBreedIdBeforeChange = (e: any) => {
    setBreedIdBefore(e.target.value);
  };

  const handleEditColorCombination = (cc: ColorCombination) => {
    setEditedColorCombination(cc);
  };
  const handleDeleteColorCombination = (cc: ColorCombination) => {
    setColorCombinations((old) => old.filter((o) => o !== cc));
  };
  const handleAddColorCombination = () => {
    setEditedColorCombination([]);
  };
  const handleColorCombinationDialogOk = (cc: ColorCombination) => {
    setColorCombinations(
      editedColorCombination?.length
        ? colorCombinations.map((ecc) =>
            ecc === editedColorCombination ? cc : ecc
          )
        : [...colorCombinations, cc]
    );
    setEditedColorCombination(undefined);
  };
  const [editedColorCombination, setEditedColorCombination] = React.useState<
    ColorCombination
  >();

  const otherBreeds = siblingBreeds(parentNodeId).filter(
    (b) => b.breedId !== props.breed.breedId
  );
  const [image, setImage] = React.useState<any>();
  const [mustImageBeSaved, setMustImageBeSaved] = React.useState(false);
  const handleImageChange = (image: any) => {
    setImage(image);
    if (image) {
      setMustImageBeSaved(true);
    }
  };

  const valid =
    (!needsParent || parentNodeId) &&
    hasDefaultLanguage &&
    !!breedIdBefore &&
    (!ringSize || !isNaN(parseFloat(ringSize)));

  const handleSaveNew = () => {
    const input = collectInput();
    createBreed(input, mustImageBeSaved ? image : undefined).then(
      ({ breedId }) => {
        history.replace(
          props.strategy.editBreedPath(animalStandardId, breedId)
        );
        props.refreshAllBreeds();
        setMustImageBeSaved(false);
      }
    );
  };
  const handleSaveExisting = () => {
    const input = collectInput();
    updateBreed(
      { ...input, breedId: props.breed.breedId },
      mustImageBeSaved ? image : undefined
    ).then(() => {
      setMustImageBeSaved(false);
    });
  };

  const collectInput = () => {
    console.log(colorCombinations);
    return {
      parentNodeId: parentNodeId || null,
      breedIdBefore: breedIdBefore === insertAtHead ? null : breedIdBefore,
      captions,
      boxTypeId: boxTypeId || null,
      hasAdditionalBoxes,
      isWeighingNeeded,
      godfather: godfather.trim() || null,
      ringSize: parseFloatWithDefault(ringSize, null),
      colors: colors.map((c) => ({
        colorId: c.colorId,
        captions: c.captions,
        referenceRating: c.referenceRating,
        isActive: c.isActive,
      })),
      colorCombinations: distinct(
        colorCombinations.map((cc) => cc.sort(byString((c) => c))),
        sequenceEqual
      ).filter((cc) => cc.length > 1),
    };
  };

  return (
    <BottomBarPage
      title={props.strategy.title}
      subtitle="Rasse bearbeiten"
      bottomBarContent={
        isNewBreed ? (
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid || loading}
            onClick={handleSaveNew}
          >
            Speichern
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid || loading}
            onClick={handleSaveExisting}
          >
            Änderungen Speichern
          </Button>
        )
      }
    >
      <Grid container spacing={spacing3}>
        <Grid item xs={12} sm={6}>
          <FormSectionTitle title="Name" />
          <CaptionEditor
            disableHeader
            captions={captions}
            fieldDescriptors={captionFieldDescriptors}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.imageGridCell}>
          <FormTitle>Bild</FormTitle>
          <ImageEditor
            className={classes.imageEditor}
            onImageChange={handleImageChange}
            image={image || props.breed.imageUrl}
          />
        </Grid>
        {needsParent && (
          <Grid item xs={12}>
            <FormSectionTitle title="Zugehörigkeit" />
            <NodeSelector
              nodes={props.nodes}
              selectedNodeId={parentNodeId || preselectionNodeId || ""}
              fullWidth
              onChange={handleParentChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormSectionTitle title="Einsortieren nach" />
          <Select
            fullWidth
            value={breedIdBefore}
            onChange={handleBreedIdBeforeChange}
          >
            <MenuItem value={insertAtHead}>Am Anfang</MenuItem>
            <Divider />
            {otherBreeds.sort(byNumber((b) => b.sort)).map((b) => (
              <MenuItem key={b.breedId} value={b.breedId}>
                {translator(b.captions)?.breedName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Boxentyp" />
          <Select fullWidth value={boxTypeId} onChange={handleBoxTypeChange}>
            {boxTypes
              .sort(
                byString((bt) => translator(bt.captions)?.boxTypeName ?? "")
              )
              .map((bt) => (
                <MenuItem key={bt.boxTypeId} value={bt.boxTypeId}>
                  {translator(bt.captions)?.boxTypeName}
                </MenuItem>
              ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasAdditionalBoxes}
                onChange={handleAdditionalBoxChange}
                name="Zusatzbox"
              />
            }
            label="Zusatzboxen möglich"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Wägen" />
          <FormControlLabel
            control={
              <Checkbox
                checked={isWeighingNeeded}
                onChange={handleWeighingNeededChange}
                name="Wägen"
              />
            }
            label="Zu wägende Rasse"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Götti/Gotti" />
          <TextField
            placeholder="Muster Max"
            fullWidth
            value={godfather}
            onChange={handleGodfatherChange}
          />
        </Grid>
        {animalStandard.hasRingSize && (
          <Grid item xs={12}>
            <FormSectionTitle title="Ringgrösse (mm)" />
            <TextField
              placeholder="4.2"
              fullWidth
              value={ringSize}
              onChange={handleRingSizeChange}
            />
          </Grid>
        )}
        {animalStandard.hasColorLevel && (
          <>
            <FormSectionTitle title="Farbenschläge" />
            <Grid item xs={12}>
              <>
                <ColorTable
                  colors={colors}
                  onEdit={handleColorEdit}
                  onToggleActivation={handleToggleColorActivation}
                  animalStandard={animalStandard}
                  onOrderChange={handleColorOrderChange}
                />
                {editedColor && (
                  <ColorDialog
                    open={!!editedColor}
                    onCancel={handleColorDialogCancel}
                    onOk={handleColorDialogOk}
                    color={colorForDialog(editedColor)}
                    animalStandard={animalStandard}
                    otherColors={colors
                      .filter((c) => c !== editedColor)
                      .map((c) => ({
                        colorId: c.colorId,
                        colorName: translator(c.captions)?.colorName ?? "",
                      }))}
                  />
                )}
              </>
            </Grid>
            <FormSectionTitle title="Zulässige Zusammenstellung" />
            <Grid item xs={12}>
              <Typography color="textSecondary">
                Für Einheiten bei denen gemischte Anmeldungen bestimmter
                Zusammenstellungen erlaubt sind.
              </Typography>
              <EditList
                toolbarItems={
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddColorCombination}
                  >
                    Neue Zusammenstellung
                  </Button>
                }
              >
                <List disablePadding>
                  {colorCombinations.map((cc) => {
                    return (
                      <ListItem key={cc.join()}>
                        <ListItemText>
                          {cc
                            .map(
                              (colorId) =>
                                translator(
                                  colors.find((c) => c.colorId === colorId)
                                    ?.captions ?? []
                                )?.colorName
                            )
                            .join(", ")}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Tooltip title="Bearbeiten">
                            <IconButton
                              onClick={() => handleEditColorCombination(cc)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Löschen">
                            <IconButton
                              onClick={() => handleDeleteColorCombination(cc)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </EditList>
              {editedColorCombination && (
                <ColorCombinationDialog
                  colorCombination={editedColorCombination}
                  onOk={handleColorCombinationDialogOk}
                  onClose={() => setEditedColorCombination(undefined)}
                  open={!!editedColorCombination}
                  colors={colors.map((c) => ({
                    ...c,
                    colorName: translator(c.captions)?.colorName ?? "",
                  }))}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </BottomBarPage>
  );
};

const SupplyData = (props: {
  strategy: {
    getAnimalStandardPath: (animalStandardId: string) => string;
    getNodesPath: (animalStandardId: string) => string;
    getBoxTypesPath: () => string;
    getBreedsPath: (animalStandardId: string) => string;
    getBreedPath?: (animalStandardId: string, breedId: string) => string;
  } & BreedEditPageStrategy;
}) => {
  const { animalStandardId, breedId } = useParams<{
    animalStandardId: string;
    breedId?: string;
  }>();
  const [animalStandardState] = useFetch2<ApiAnimalStandard>(
    props.strategy.getAnimalStandardPath(animalStandardId)
  );
  const [nodesState] = useFetch2<{ nodes: ApiNode[] }>(
    props.strategy.getNodesPath(animalStandardId)
  );
  const isNewBreed = !props.strategy.getBreedPath;
  const [existingBreedState] = useFetch2<ApiBreed>(
    isNewBreed ? "" : props.strategy.getBreedPath!(animalStandardId, breedId!)
  );
  const [boxTypesState] = useFetch2<{ boxTypes: ApiBoxType[] }>(
    props.strategy.getBoxTypesPath()
  );
  const [allBreedsState, refreshAllBreeds] = useFetch2<{ breeds: ApiBreed[] }>(
    props.strategy.getBreedsPath(animalStandardId)
  );

  const newOrExistingBreedState = isNewBreed
    ? success(templateBreed)
    : existingBreedState;

  const pageProps = combineStates(
    allBreedsState,
    combineStates(
      boxTypesState,
      combineStates(
        newOrExistingBreedState,
        combineStates(
          animalStandardState,
          nodesState,
          (animalStandard, { nodes }) => ({ animalStandard, nodes })
        ),
        (breed, sn) => ({ breed, ...sn })
      ),
      ({ boxTypes }, bsn) => ({ boxTypes, ...bsn })
    ),
    ({ breeds }, bsnb) => ({ otherBreeds: breeds, ...bsnb })
  );
  return pageProps.isRunning() ? (
    <LoadingIndicator />
  ) : pageProps.isError() ? (
    <>Fehler</>
  ) : (
    <BreedEditPage
      {...pageProps.data}
      strategy={props.strategy}
      refreshAllBreeds={refreshAllBreeds}
    />
  );
};

export default SupplyData;

function filterByColors(
  colorCombinations: ColorCombination[],
  colorIds: string[]
) {
  return colorCombinations
    .map((cc) => cc.filter((colorId) => colorIds.includes(colorId)))
    .filter((cc) => cc.length);
}

const templateBreed = {
  breedId: "",
  captions: [],
  hasAdditionalBoxes: false,
  isWeighingNeeded: false,
  godfather: null,
  boxTypeId: "",
  parentNodeId: "",
  colors: [],
  colorCombinations: [],
  imageUrl: null,
  ringSize: null,
} as Breed;
