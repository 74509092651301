import * as React from "react";
import { makeStyles, lighten, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolbar: React.FunctionComponent<{
  selectionCount: number;
  titleWhenSelected: string;
  contentWhenSelected?: React.ReactNode;
  contentWhenNothingSelected?: React.ReactNode;
}> = ({ selectionCount, ...props }) => {
  const classes = useStyles();
  const anySelected = selectionCount !== 0;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: anySelected,
      })}
    >
      {anySelected ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {props.titleWhenSelected}
        </Typography>
      ) : null}

      {anySelected
        ? props.contentWhenSelected || null
        : props.contentWhenNothingSelected || null}
    </Toolbar>
  );
};

export default TableToolbar;
