import React from "react";
import { PropsWithChildren } from "react";
import AppRole from "../../authorization/appRoles";
import { useExhibitionCached } from "./ExhibitionContext";
import { useRolesCombined } from "./ExhibitionUserProvider";
import IfCanRegisterSelf from "./IfCanRegisterSelf";

export function IfCanRegisterSelfInContext(props: PropsWithChildren<{}>) {
  const exhibition = useExhibitionCached();
  const { roles } = useRolesCombined();
  return (
    <IfCanRegisterSelf
      exhibition={exhibition}
      user={{
        isMember: roles.includes("Member"),
        isOrganizer:
          roles.includes("Organizer") ||
          roles.includes(AppRole.ExhibitionAdmin),
      }}
    >
      {props.children}
    </IfCanRegisterSelf>
  );
}

export function IfCanRegisterOthersInContext(props: PropsWithChildren<{}>) {
  const exhibition = useExhibitionCached();
  const { roles } = useRolesCombined();

  return canRegisterOthers(exhibition, {
    isMember: roles.includes("Member"),
    isOrganizer: roles.includes("Organizer"),
  }) || roles.includes(AppRole.ExhibitionAdmin) ? (
    <>{props.children}</>
  ) : null;
}

function canRegisterOthers(
  exhibition: { withinDeadline: boolean },
  user: { isMember: boolean; isOrganizer: boolean }
) {
  return user.isOrganizer || (user.isMember && exhibition.withinDeadline);
}
