import * as React from "react";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { ExhibitionId } from "../../exhibitions/types";
import { UnitCreateInput, UnitUpdateInput } from "./unitTypes";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useUnitSave(
  animalStandardId: string,
  exhibitionId?: ExhibitionId
) {
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then((result: any) => {
          if (messages?.success) {
            showAlert(messages?.success, "success");
          }
          return result;
        })
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createUnit = updater<UnitCreateInput>(
    () => `${urlPrefix}masterdata/animalstandards/${animalStandardId}/units`,
    POST,
    {
      error: "Fehler beim Speichern der Einheit.",
      success: "Die Einheit wurde erstellt.",
    },
    (data) => data
  );

  const updateUnit = updater<UnitUpdateInput & { unitId: string }>(
    (data) =>
      `${urlPrefix}masterdata/animalstandards/${animalStandardId}/units/${data.unitId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren der Einheit.",
      success: "Die Änderungen wurden gespeichert.",
    },
    ({ unitId, ...rest }) => rest
  );

  return { loading, createUnit, updateUnit };
}
