import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Authenticated,
  AuthenticationProvider,
} from "./authentication/AuthContext";
import { RolesContext } from "./authorization/RolesContext";
import { uuidRegex } from "./crossCutting/uuid";
import ExhibitionApp from "./domain/exhibitions/ExhibitionApp";
import NoExhibitionApp from "./domain/NoExhibitionApp";
import KlexpoApiFetchProvider from "./fetching/KlexpoApiFetchProvider";
import createKlexpoTheme from "./theme";
import { GlobalAlertSnackbarProvider } from "./crossCutting/Snackbars";
import useAlertSnackbar from "./crossCutting/hooks/useAlertSnackbar";
import { I18nProvider } from "./i18n/i18n";
import AzureMonitor from "./AzureMonitor";

function App() {
  const { alertSnackbar, showAlertSnackbar } = useAlertSnackbar();
  return (
    <AzureMonitor>
      <ThemeProvider theme={createMuiTheme()}>
        <ThemeProvider theme={createKlexpoTheme()}>
          <GlobalAlertSnackbarProvider
            showGlobalAlertSnackbar={showAlertSnackbar}
          >
            <AuthenticationProvider>
              <Authenticated>
                {(jwt, signin, language, appRoles) => (
                  <I18nProvider language={language}>
                    <ThemeProvider theme={createKlexpoTheme(language)}>
                      <KlexpoApiFetchProvider jwt={jwt} signin={signin}>
                        <RolesContext.Provider value={{ roles: appRoles }}>
                          <Switch>
                            <Route
                              path={`/exhibitions/:exhibitionId(${uuidRegex})`}
                            >
                              <ExhibitionApp />
                            </Route>
                            <Route>
                              <NoExhibitionApp />
                            </Route>
                          </Switch>
                        </RolesContext.Provider>
                      </KlexpoApiFetchProvider>
                    </ThemeProvider>
                  </I18nProvider>
                )}
              </Authenticated>
            </AuthenticationProvider>
          </GlobalAlertSnackbarProvider>
          {alertSnackbar}
        </ThemeProvider>
      </ThemeProvider>
    </AzureMonitor>
  );
}

export default App;
