import { TextField, MenuItem } from "@material-ui/core";
import React from "react";

export default function InlineSelect<T>({
  value,
  onChange,
  items,
  itemKey,
  itemView,
  itemValue,
  error,
  disabled,
}: {
  value: string;
  onChange(value: string): void;
  items: T[];
  itemKey(item: T): string | number | undefined;
  itemView(item: T): React.ReactNode;
  itemValue(item: T): string | number;
  error?: boolean;
  disabled?: boolean;
}) {
  return (
    <TextField
      value={value}
      error={error}
      fullWidth
      size="small"
      select
      variant="outlined"
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      {items?.map((s) => (
        <MenuItem key={itemKey(s)} value={itemValue(s)}>
          {itemView(s)}
        </MenuItem>
      ))}
    </TextField>
  );
}
