export enum RankingLevelKey {
  Standard = "Standard",
  BreedSize = "BreedSize",
  Breed = "Breed",
  Color = "Color",
  Category = "Category",
  AgeClass = "AgeClass",
  Gender = "Gender",
}

export const allRankingLevels = [
  { label: "Standard", value: RankingLevelKey.Standard },
  { label: "Rassengrösse", value: RankingLevelKey.BreedSize },
  { label: "Rasse", value: RankingLevelKey.Breed },
  { label: "Farbe", value: RankingLevelKey.Color },
  { label: "Kategorie", value: RankingLevelKey.Category },
  { label: "Alterskategorie", value: RankingLevelKey.AgeClass },
  { label: "Geschlecht", value: RankingLevelKey.Gender },
];
