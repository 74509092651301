import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import { Theme, withStyles } from "@material-ui/core/styles";

const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    color: red[500],
  },
}))(Button);

export default DeleteButton;
