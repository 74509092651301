import * as React from "react";
import logger from "../crossCutting/logger";
import { FetchClient, FetchOptions, FetchError } from "./fetchClient";
import usePromiseStates, {
  State,
} from "../crossCutting/hooks/usePromiseStates";
import { useGlobalAlertSnackbar } from "../crossCutting/Snackbars";

const FetchContext = React.createContext<FetchClient>(
  (path: string, options: FetchOptions) =>
    Promise.reject(
      "Client not configured. Wrap your components in FetchProvider to do so."
    )
);

export const FetchProvider: React.FunctionComponent<{
  fetchClient: FetchClient;
}> = (props) => (
  <FetchContext.Provider value={props.fetchClient}>
    {props.children}
  </FetchContext.Provider>
);

export const useFetchClient = () => React.useContext(FetchContext);

export const useFetch = <T,>(
  path: string,
  onSuccess?: (data: T) => void,
  messages?: Messages
) => {
  const fetchClient = React.useContext(FetchContext);
  const [data, setData] = React.useState<T>();
  const [error, setError] = React.useState<FetchError>();
  const [loading, setLoading] = React.useState(false);
  const [triggerRefreshValue, setTriggerRefreshvalue] = React.useState(0);
  const [pathUsed, setPathUsed] = React.useState<string>();
  const { successMessage, errorMessage } = useFetchMessages(messages);
  React.useEffect(() => {
    logger.debug("fetch", path);

    setLoading(true);
    setError(undefined);
    fetchClient(path, {
      method: "GET",
    })
      .then((d) => {
        setData(d);
        onSuccess && onSuccess(d);
        successMessage();
      })
      .catch((e) => {
        setError(e);
        errorMessage();
      })
      .finally(() => {
        setPathUsed(path);
        setLoading(false);
      });
  }, [
    path,
    triggerRefreshValue,
    fetchClient,
    successMessage,
    errorMessage,
    onSuccess,
  ]);

  const refresh = () => {
    setError(undefined);
    setTriggerRefreshvalue(newTriggerRefreshValue());
  };
  return {
    data,
    error,
    loading,
    refresh,
    path: pathUsed,
  };
};

export function useFetchWithTransform<T>(
  path: string,
  transform: (src: any) => T | undefined
) {
  const result = useFetch<any>(path);
  return {
    ...result,
    data: result.data ? transform(result.data) : undefined,
  };
}

export function useLazyFetch<T>() {
  const fetchClient = React.useContext(FetchContext);
  const fetch = React.useCallback(
    (path: string) => fetchClient(path, { method: "GET" }),
    [fetchClient]
  );
  return usePromiseStates(fetch) as {
    execute: (path: string) => void;
    state: State<T>;
  };
}

export function useFetch2<T>(path: string) {
  const fetchClient = React.useContext(FetchContext);
  const fetch = React.useCallback(
    (path: string) => fetchClient(path, { method: "GET" }),
    [fetchClient]
  );

  const { execute, state } = usePromiseStates(fetch);
  const refresh = React.useCallback(() => {
    if (!path) return;
    execute(path);
  }, [path, execute]);

  React.useEffect(() => refresh(), [refresh]);

  return [state, refresh] as [State<T>, () => void];
}

export const useSendJson = (messages?: Messages) => {
  const fetchClient = React.useContext(FetchContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<FetchError>();
  const [data, setData] = React.useState();
  const { successMessage, errorMessage } = useFetchMessages(messages);
  return {
    sendJson: (options: {
      path: string;
      body?: any;
      onSuccess?: (data: any) => void;
      method?: "POST" | "PUT" | "DELETE";
    }) => {
      logger.debug("sendJson", options.path);

      setLoading(true);
      setError(undefined);
      fetchClient(options.path, {
        method: options.method || "POST",
        body: options.body && JSON.stringify(options.body),
        headers: { "Content-Type": "application/json" },
      })
        .then((data: any) => {
          setData(data);
          if (options.onSuccess) {
            options.onSuccess(data);
          }
          successMessage();
        })
        .catch((e) => {
          setError(e);
          errorMessage();
        })
        .finally(() => setLoading(false));
    },
    data,
    loading,
    error,
  };
};

function newTriggerRefreshValue() {
  return new Date().valueOf();
}

export interface Messages {
  success?: string;
  error?: string;
}

function useFetchMessages(messages?: Messages) {
  const show = useGlobalAlertSnackbar();
  const { success, error } = messages || {};
  const successMessage = React.useCallback(
    () => (success ? show(success, "success") : null),
    [success, show]
  );
  const errorMessage = React.useCallback(
    () => (error ? show(error, "error") : null),
    [error, show]
  );

  return {
    successMessage,
    errorMessage,
  };
}
