import {
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import EditList from "../../../crossCutting/EditList";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { AddIcon } from "../../../crossCutting/icons";
import { JumpToLinkButton } from "../../../crossCutting/JumpToButton";
import Page from "../../../crossCutting/pages/Page";
import { useTranslatorForUser } from "../../masterdata/translation";
import { AnimalStandard, Exhibition } from "../apiTypes";
import {
  competitionEditPagePath,
  competitionNewPagePath,
} from "../competitionEditPage/routing";
import { useExhibitionCached } from "../ExhibitionContext";
import { ExhibitionId } from "../types";

export type CompetitionsPageExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;
export interface CompetitionsPageProps {
  exhibition: CompetitionsPageExhibition;
}

const CompetitionsPage: React.FunctionComponent<CompetitionsPageProps> = (
  props
) => {
  const history = useHistory();
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const exhibition = useExhibitionCached();

  const translator = useTranslatorForUser();
  const translatedStandards = exhibition.animalStandards.map((s) => {
    return {
      ...s,
      ...(translator(s.captions) || { animalStandardName: "n/a" }),
    };
  });

  const competitions = exhibition.competitions.map((c) => ({
    competitionId: c.competitionId,
    competitionName: c.competitionName,
    standardName: translatedStandards.find(
      (s) => s.animalStandardId === c.animalStandardId
    )?.animalStandardName,
  }));

  return (
    <Page title={props.exhibition.exhibitionName} subtitle="Wertungen">
      <EditList
        toolbarItems={
          <AnimalsStandardMenuButton
            animalStandards={exhibition.animalStandards.filter(
              (s) => s.isActive
            )}
            exhibitionId={props.exhibition.exhibitionId}
          />
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competitions.map((c) => (
              <TableRow
                className={rowClass}
                key={c.competitionId}
                hover
                onClick={() =>
                  history.push(
                    competitionEditPagePath(
                      props.exhibition.exhibitionId,
                      c.competitionId
                    )
                  )
                }
              >
                <TableCell>{c.competitionName}</TableCell>
                <TableCell>{c.standardName}</TableCell>
                <HoverActionCell>
                  <JumpToLinkButton
                    to={competitionEditPagePath(
                      props.exhibition.exhibitionId,
                      c.competitionId
                    )}
                  >
                    Gehe zu
                  </JumpToLinkButton>
                </HoverActionCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </EditList>
    </Page>
  );
};

export default CompetitionsPage;

function AnimalsStandardMenuButton(props: {
  animalStandards: AnimalStandard[] | undefined;
  exhibitionId: ExhibitionId;
}) {
  const translator = useTranslatorForUser();

  const [anchorEl, setAnchorEl] = React.useState<any>();
  const handleNewCompetitionClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(e.target);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleMenuItemClick = () => setAnchorEl(undefined);
  return (
    <>
      <Button
        variant="contained"
        onClick={handleNewCompetitionClick}
        startIcon={<AddIcon />}
      >
        Neue Wertung
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem disabled>Standard für Wertung wählen</MenuItem>
        {props.animalStandards?.map((s) => (
          <MenuItem
            key={s.animalStandardId}
            component={RouterLink}
            onClick={handleMenuItemClick}
            to={competitionNewPagePath(props.exhibitionId, s.animalStandardId)}
          >
            {translator(s.captions)?.animalStandardName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
