import { buildQuery } from "../../../crossCutting/urls";
import { Gender } from "../../exhibitions/genders";
import { ExhibitionId } from "../../exhibitions/types";

export function registrationsApiPath(
  exhibitionId: ExhibitionId,
  query?: {
    memberIdRegistered?: string;
    search?: string;
    skip?: number;
    take?: number;
  }
) {
  return `exhibitions/${exhibitionId}/registrations${
    query ? buildQuery(query) : ""
  }`;
}

export interface RegistrationInList {
  registrationId: string;
  registrationNumber: number;
  memberRegistered: {
    memberNumber: number;
    firstName: string;
    lastName: string;
    city: string;
  };
  unit: {
    unitName: string;
  };
  breed: {
    breedName: string | undefined;
  };
  color:
    | {
        colorName: string;
      }
    | undefined;
  ageClass: { ageClassName: string } | undefined;
  singleAnimalGender: Gender | undefined;
  selfRegistration: boolean;
  createdUtc: string;
  organizationNumber: number | undefined;
}
