import { Route } from "react-router-dom";
import React from "react";
import Page from "./NewExhibitionPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
import ExhibitionCreatedPage from "./ExhibitionCreatedPage";
import { ExhibitionId } from "../types";

const path = "/exhibitions/new";
export const newExhibitionPageRoute = () => (
  <Route path={path}>
    <Page />
  </Route>
);

export const newExhibitionPagePath = () => path;

export const LinkToNewExhibition: React.FunctionComponent<AppLinkProps> = (
  props
) => <AppLink {...props} to={newExhibitionPagePath()} />;

export const exhibitionCreatedPath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/created`;

export const exhibitionCreatedPageRoute = (
  pathPrefix: string,
  exhibitionId: ExhibitionId
) => {
  return (
    <Route path={`${pathPrefix}/created`}>
      <ExhibitionCreatedPage exhibitionId={exhibitionId} />
    </Route>
  );
};
