import { makeStyles } from "@material-ui/core";
import * as React from "react";

export interface NameCellProps {
  language: string;
}

const useStyles = makeStyles((theme) => ({
  nameCell: {
    display: "table-cell",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const NameCell: React.FunctionComponent<NameCellProps> = (props) => {
  const classes = useStyles();
  return (
    <div lang={props.language} className={classes.nameCell}>
      {props.children}
    </div>
  );
};

export default NameCell;
