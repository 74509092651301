import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import EditList from "../../../crossCutting/EditList";
import { AddIcon } from "../../../crossCutting/icons";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import Page from "../../../crossCutting/pages/Page";
import SearchBox from "../../../crossCutting/SearchBox";
import { useExhibitionList } from "./exhibitionFetch";
import { LinkToNewExhibition } from "../newExhibitionPage/routing";
import ExhibitionTable from "./ExhibitionTable";
import TableOrEmptyMessage from "../../../crossCutting/TableOrEmptyMessage";
import { useTranslation } from "../../../i18n/i18n";

export interface ExhibitionsPageProps {}

const useStyles = makeStyles((theme) => ({
  search: {
    marginTop: theme.spacing(spacing3),
  },
  tableTitle: {
    marginTop: theme.spacing(2),
  },
}));

const ExhibitionsPage: React.FunctionComponent<ExhibitionsPageProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState("");
  const { exhibitions, search } = useExhibitionList(searchText);

  return (
    <Page title={t("exhibitions")}>
      <EditList
        toolbarItems={
          <LinkToNewExhibition button startIcon={<AddIcon />}>
            {t("newExhibition")}
          </LinkToNewExhibition>
        }
      >
        <div className={classes.search}>
          <SearchBox
            placeholder={t("exhibitionSearchBoxPlaceholder")}
            onSearch={setSearchText}
            autoFocus
          />
        </div>
        {exhibitions && (
          <TableOrEmptyMessage
            data={exhibitions}
            emptyMessage={t("noExhibitionsFound")}
          >
            {!search && (
              <Typography
                variant="h6"
                component="h3"
                className={classes.tableTitle}
              >
                {t("recentAndFutureExhibitions")}
              </Typography>
            )}
            <ExhibitionTable exhibitions={exhibitions} />
          </TableOrEmptyMessage>
        )}
      </EditList>
    </Page>
  );
};

export default ExhibitionsPage;
