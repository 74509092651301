import { List, ListItem, Typography } from "@material-ui/core";
import * as React from "react";
import ExhibitionListItemText from "./ExhibitionListItemText";
import { ExhibitionsWhereCurrentUserIsOrganizer } from "./homePageTypes";
import { useTranslation } from "../../i18n/i18n";

export default function OrganizerExhibitionsBox(props: {
  exhibitions: ExhibitionsWhereCurrentUserIsOrganizer<Date>[];
}) {
  const { t } = useTranslation();
  return (
    <List disablePadding>
      {props.exhibitions.length ? (
        props.exhibitions.map((d) => <Item key={d.exhibitionId} {...d} />)
      ) : (
        <Typography variant="body1" color="textSecondary">
          {t("noExhibitionsOrganizedLately")}
        </Typography>
      )}
    </List>
  );
}

function Item(props: ExhibitionsWhereCurrentUserIsOrganizer<Date>) {
  return (
    <ListItem disableGutters>
      <ExhibitionListItemText {...props} />
    </ListItem>
  );
}
