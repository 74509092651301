import { SelectorProps } from "../selectorProps";
import { TextField } from "@material-ui/core";
import React from "react";

export default function DateField({
  label,
  value,
  onChange,
}: { label: string } & SelectorProps<string>) {
  return (
    //   setting shrink: true because otherwise the label is initially displayed
    //   as placeholder which then overlaps with the browser's builtin display
    //   of the date format.
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      required
      type="date"
      InputLabelProps={{ shrink: true }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
