import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormSectionTitle } from "../../../crossCutting/FormSectionTitle";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import BottomBarPage from "../../../crossCutting/pages/BottomBarPage";
import { byNumber } from "../../../crossCutting/sorting";
import { useFetch2 } from "../../../fetching/fetchProvider";
import { inDefaultLanguage } from "../../../language";
import { AnimalStandard } from "../apiClient/animalStandardTypes";
import { UnitCreateInput, UnitUpdateInput } from "../apiClient/unitTypes";
import CaptionEditor from "../CaptionEditor";
import { useTranslatorForUser } from "../translation";
import GenderCodeTable, { GenderCodeItem as UnitCode } from "./GenderCodeTable";

interface UnitCaption {
  language: string;
  unitName: string;
}
interface Unit {
  unitId: string;
  captions: UnitCaption[];
  animalCount: number;
  animalBoxCount: number;
  hasHarmonyPoints: boolean;
  mixed: {
    isAnyColorCombinationAllowed: boolean;
  } | null;
  isMaleFirst: boolean;
  unitCodes: UnitCode[];
  isStrikeResultPossible: boolean;
}

export type MakeUnitSaveDataHook = () => (
  animalStandardId: string
) => {
  loading: boolean;
  createUnit: (input: UnitCreateInput) => Promise<{ unitId: string }>;
  updateUnit: (input: UnitUpdateInput & { unitId: string }) => Promise<any>;
};

export interface UnitEditPageStrategy {
  title: string;
  makeUnitSaveDataHook: MakeUnitSaveDataHook;
  editUnitPath: (animalStandardId: string, unitId: string) => string;
}

export interface UnitEditPageProps {
  strategy: UnitEditPageStrategy;
  unit: Unit;
  refreshAnimalStandard: () => void;
  animalStandard: {
    hasColorLevel: boolean;
    units: {
      unitId: string;
      sort: number;
      captions: { language: string; unitName: string }[];
    }[];
  };
}

const insertAtHead = "insertAtHead";
type SpecificOrAny = "any" | "specific";
const UnitEditPage: React.FunctionComponent<UnitEditPageProps> = (props) => {
  const { animalStandardId } = useParams<{ animalStandardId: string }>();
  const history = useHistory();
  const useUnits = props.strategy.makeUnitSaveDataHook();
  const { loading, updateUnit, createUnit } = useUnits(animalStandardId);
  const translator = useTranslatorForUser();
  const isNewUnit = !props.unit.unitId;
  const handleSaveNew = () => {
    const input = collectInputs();
    createUnit(input).then(({ unitId }) => {
      props.refreshAnimalStandard();
      history.replace(props.strategy.editUnitPath(animalStandardId, unitId));
    });
  };
  const handleSaveExisting = () => {
    const input = collectInputs();
    updateUnit(input);
  };
  const [captions, setCaptions] = React.useState(props.unit.captions);
  const findInitialUnitIdBefore = () => {
    const siblings = props.animalStandard.units.sort(byNumber((u) => u.sort));
    const indexOfEditedUnit = siblings.findIndex(
      (u) => u.unitId === props.unit.unitId
    );
    if (indexOfEditedUnit === 0) {
      return insertAtHead;
    } else if (indexOfEditedUnit === -1) {
      return "";
    } else {
      return siblings[indexOfEditedUnit - 1].unitId;
    }
  };
  const [unitIdBefore, setUnitIdBefore] = React.useState(
    findInitialUnitIdBefore()
  );
  const handleUnitIdBeforeChange = (e: any) => {
    setUnitIdBefore(e.target.value);
  };
  const [animalCount, setAnimalCount] = React.useState(props.unit.animalCount);
  const [animalBoxCount, setAnimalBoxCount] = React.useState(
    props.unit.animalBoxCount
  );
  const [isStrikeResultPossible, setIsStrikeResultPossible] = React.useState(
    props.unit.isStrikeResultPossible
  );
  const [hasHarmonyPoints, setHasHarmonyPoints] = React.useState(
    props.unit.hasHarmonyPoints
  );
  const [isMixed, setIsMixed] = React.useState(!!props.unit.mixed);
  const [isMaleFirst, setIsMaleFirst] = React.useState(props.unit.isMaleFirst);
  const [unitCodes, setUnitCodes] = React.useState(props.unit.unitCodes);
  const captionFieldDescriptors = [
    {
      value: (caption: any) => caption?.unitName || "",
      onChange: (language: string, newValue: string) => {
        setCaptions((old) => {
          const itemForLanguage = old.find((i) => i.language === language);
          return itemForLanguage
            ? [
                ...old.filter((i) => i.language !== language),
                { ...itemForLanguage, unitName: newValue },
              ]
            : [...old, { language, unitName: newValue }];
        });
      },
    },
  ];
  const [allowedCodes, setAllowedCodes] = React.useState<SpecificOrAny>(
    props.unit.unitCodes.length ? "specific" : "any"
  );
  const [
    allowedColorCombinations,
    setAllowedColorCombinations,
  ] = React.useState<SpecificOrAny>(
    props.unit.mixed?.isAnyColorCombinationAllowed ? "any" : "specific"
  );
  const hasDefaultLanguage = !!inDefaultLanguage(captions)?.unitName;
  const valid =
    hasDefaultLanguage &&
    animalCount !== 0 &&
    animalBoxCount <= animalCount &&
    unitIdBefore &&
    (allowedCodes === "any" || unitCodes.length !== 0);

  const collectInputs = () => ({
    unitId: props.unit.unitId,
    animalCount,
    animalBoxCount,
    captions,
    hasHarmonyPoints,
    mixed: isMixed
      ? { isAnyColorCombinationAllowed: allowedColorCombinations === "any" }
      : null,
    unitCodes: allowedCodes === "any" ? [] : unitCodes,
    isMaleFirst,
    isStrikeResultPossible,
    unitIdBefore: unitIdBefore === insertAtHead ? null : unitIdBefore,
  });
  return (
    <BottomBarPage
      title={props.strategy.title}
      subtitle={isNewUnit ? "Neue Einheit" : "Einheit bearbeiten"}
      bottomBarContent={
        isNewUnit ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSaveNew}
            disabled={!valid || loading}
          >
            Speichern
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSaveExisting}
            disabled={!valid || loading}
          >
            Änderungen speichern
          </Button>
        )
      }
    >
      <Grid container spacing={spacing3}>
        <Grid item xs={12}>
          <FormSectionTitle title="Name" />
          <CaptionEditor
            disableHeader
            captions={captions}
            fieldDescriptors={captionFieldDescriptors}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <FormSectionTitle title="Einsortieren nach" />
          <Select
            fullWidth
            value={unitIdBefore}
            onChange={handleUnitIdBeforeChange}
          >
            <MenuItem value={insertAtHead}>Am Anfang</MenuItem>
            <Divider />
            {props.animalStandard.units
              .filter((u) => u.unitId !== props.unit.unitId)
              .sort(byNumber((u) => u.sort))
              .map((u) => (
                <MenuItem key={u.unitId} value={u.unitId}>
                  {translator(u.captions)?.unitName}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <FormSectionTitle title="Anzahl Tiere/Boxenbelegung" />
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            value={animalCount}
            label="Anzahl Tiere"
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setAnimalCount(isNaN(parsed) ? 0 : parsed);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="number"
            value={animalBoxCount}
            label="Tiere in einer Box"
            onChange={(e) => {
              const parsed = parseInt(e.target.value);
              setAnimalBoxCount(isNaN(parsed) ? 0 : parsed);
            }}
          />
        </Grid>
        <FormSectionTitle title="Harmoniepunkte" />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasHarmonyPoints}
                onChange={(e) => setHasHarmonyPoints(e.target.checked)}
              />
            }
            label="Harmoniepunkte bei Bewertung erfassen"
          />
        </Grid>
        <FormSectionTitle title="Streichresultat" />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isStrikeResultPossible}
                onChange={(e) => setIsStrikeResultPossible(e.target.checked)}
              />
            }
            label="Streichresultat möglich"
          />
        </Grid>
        <FormSectionTitle title="Zulässige Codes" />
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="allowedCodes"
              value={allowedCodes}
              onChange={(_, value) => setAllowedCodes(value as SpecificOrAny)}
            >
              <FormControlLabel
                value="any"
                control={<Radio />}
                label="Beliebige Codes zulässig"
              />
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Bestimmte Codes"
              />
            </RadioGroup>
          </FormControl>
          <GenderCodeTable
            animalCount={animalCount}
            disabled={allowedCodes === "any"}
            genderCodes={unitCodes}
            onChange={setUnitCodes}
          />
        </Grid>
        <FormSectionTitle title="Männliche Tiere zuerst" />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMaleFirst}
                onChange={(e) => setIsMaleFirst(e.target.checked)}
              />
            }
            label="Männliche Tiere müssen innerhalb einer Anmeldung in den Boxen mit den tiefsten Nummern eingeliefert werden"
          />
        </Grid>
        <FormSectionTitle title="Gemischte Anmeldungen" />
        {props.animalStandard.hasColorLevel && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMixed}
                    onChange={(e) => setIsMixed(e.target.checked)}
                  />
                }
                label="Gemischte Anmeldungen"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="allowedColorCombinations"
                  value={allowedColorCombinations}
                  onChange={(_, value) =>
                    setAllowedColorCombinations(value as SpecificOrAny)
                  }
                >
                  <FormControlLabel
                    value="any"
                    disabled={!isMixed}
                    control={<Radio />}
                    label="Beliebige Zusammenstellungen zulässig"
                  />
                  <FormControlLabel
                    value="specific"
                    disabled={!isMixed}
                    control={<Radio />}
                    label="Bestimmte Zusammenstellungen gem. Rasse"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </BottomBarPage>
  );
};

export default function SupplyData(props: {
  strategy: {
    title: string;
    getUnitPath: (animalStandardId: string, unitId: string) => string;
    getAnimalStandardPath: (animalStandardId: string) => string;
  } & UnitEditPageStrategy;
}) {
  const { animalStandardId, unitId } = useParams<{
    animalStandardId: string;
    unitId?: string;
  }>();
  const [animalStandardState, refreshAnimalStandard] = useFetch2<
    AnimalStandard
  >(props.strategy.getAnimalStandardPath(animalStandardId));

  const pageProps = mapState(animalStandardState, (animalStandard) => ({
    animalStandard,
    unit: unitId
      ? animalStandard.units.find((u) => u.unitId === unitId)
      : unitTemplate,
  }));

  return pageProps.isRunning() ? (
    <LoadingIndicator />
  ) : pageProps.isError() ? (
    <>Error</>
  ) : pageProps.data.unit ? (
    <UnitEditPage
      strategy={props.strategy}
      {...(pageProps.data as { unit: Unit; animalStandard: AnimalStandard })}
      refreshAnimalStandard={refreshAnimalStandard}
    />
  ) : null;
}

const unitTemplate = {
  unitId: "",
  captions: [],
  animalCount: 0,
  animalBoxCount: 0,
  isMaleFirst: false,
  mixed: null as { isAnyColorCombinationAllowed: boolean } | null,
  hasHarmonyPoints: false,
  unitCodes: [],
  isStrikeResultPossible: true,
};
