import * as React from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  Theme,
} from "@material-ui/core";
import { spacing3 } from "./layoutConstants";

export interface EditToolbarProps {}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginTop: theme.spacing(spacing3),
    marginBottom: theme.spacing(spacing3),
    "& > *+*": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const createToolbarTheme = (baseTheme: Theme) =>
  createMuiTheme({
    ...baseTheme,
    props: {
      MuiButton: {
        size: "medium",
        color: "primary",
        variant: "outlined",
      },
      MuiIconButton: {
        size: "medium",
      },
    },
  });

const EditToolbar: React.FunctionComponent<EditToolbarProps> = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={createToolbarTheme}>
      <div className={classes.toolbar}>
        {React.Children.toArray(props.children)}
      </div>
    </ThemeProvider>
  );
};

export default EditToolbar;
