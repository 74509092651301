import * as React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

import { Maybe } from "../maybe";
import { DeleteIcon } from "../icons";
import EditingButtons from "./EditingButtons";
import EditButton from "./EditButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  selectableRow: {
    cursor: "pointer",
  },
  actionCell: {
    textAlign: "right",
  },
  disabledCell: {
    color: theme.palette.text.disabled,
  },
}));

export default function EditTable<T>(props: EditTableProps<T>) {
  const classes = useStyles();

  return (
    <Table>
      {props.showHeader && (
        <TableHead>
          <TableRow>
            {!!props.selectionMode && <TableCell />}
            {props.columns.map((column) => (
              <TableCell
                className={clsx(props.disabled && classes.disabledCell)}
                key={column.columnKey}
              >
                {column.header}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {props.editState === "AddNew" && (
          <TableRow>
            {!!props.selectionMode && <TableCell />}
            {props.columns.map((column) => {
              return (
                <TableCell key={column.columnKey}>
                  {column.editor && column.editor}
                </TableCell>
              );
            })}
            <TableCell className={classes.actionCell}>
              <EditingButtons
                onSave={props.onSaveNew}
                onCancel={props.onCancel}
                saving={props.saving}
              />
            </TableCell>
          </TableRow>
        )}
        {props.data.map((item) => {
          const editing =
            typeof props.editState === "function" && props.editState(item);

          const { tableRowProps, selectionCell } = selectionProps<T>(
            props,
            item,
            classes.selectableRow
          );

          return (
            <TableRow key={props.rowKey(item)} {...tableRowProps}>
              {selectionCell}
              {props.columns.map((column) => (
                <TableCell
                  className={clsx(props.disabled && classes.disabledCell)}
                  key={column.columnKey}
                >
                  {editing && column.editor && !column.disableUpdate
                    ? column.editor
                    : column.view(item)}
                </TableCell>
              ))}
              <ActionCell
                editing={editing}
                onEdit={() => props.onEditExisting(item)}
                onSave={props.onSaveExisting}
                onCancel={props.onCancel}
                onDelete={() => props.onDelete(item)}
                disabled={props.disabled || props.saving}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function selectionProps<T>(
  props: EditTableProps<T>,
  item: T,
  selectableRow: string
) {
  if (props.selectionMode) {
    const selected = props.isSelected(item);
    const selectionDisabled = props.editState !== "NotEditing";
    const selectionCell = (
      <CheckboxCell
        checked={selected}
        disabled={props.disabled || selectionDisabled}
        onCheckedChange={(checked) =>
          props.selectionChanged(checked ? item : null)
        }
      />
    );
    const handleClick = selectionDisabled
      ? undefined
      : () => props.selectionChanged(selected ? null : item);
    const tableRowProps = {
      selected,
      className: selectionDisabled ? "" : selectableRow,
      onClick: handleClick,
    };
    return { tableRowProps, selectionCell };
  }
  return { tableRowProps: null, selectionCell: null };
}

function ActionCell(props: ActionCellProps) {
  const classes = useStyles();

  return (
    <TableCell className={classes.actionCell}>
      {props.editing ? (
        <EditingButtons
          onSave={props.onSave}
          onCancel={props.onCancel}
          saving={props.disabled}
        />
      ) : (
        <>
          <EditButton onClick={props.onEdit} disabled={props.disabled} />
          <DisableOrTooltip title="Löschen" disabled={props.disabled}>
            <IconButton
              disabled={props.disabled}
              edge="end"
              aria-label="löschen"
              onClick={(e) => {
                e.stopPropagation();
                props.onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </DisableOrTooltip>
        </>
      )}
    </TableCell>
  );
}

function DisableOrTooltip(props: any) {
  return props.disabled ? (
    props.children
  ) : (
    <Tooltip title={props.title}>{props.children}</Tooltip>
  );
}

function CheckboxCell({
  disabled,
  checked,
  onCheckedChange,
}: {
  disabled?: boolean;
  checked: boolean;
  onCheckedChange(checked: boolean): void;
}) {
  return (
    <TableCell>
      <Checkbox
        edge="start"
        disabled={disabled}
        checked={checked}
        tabIndex={-1}
        disableRipple
        onChange={(e) => onCheckedChange(e.target.checked)}
      />
    </TableCell>
  );
}

type EditState<T> = "NotEditing" | "AddNew" | { (item: T): boolean };

interface Column<T> {
  header: React.ReactNode;
  view(item: T): React.ReactNode;
  editor?: React.ReactNode;
  columnKey: string | number | undefined;
  disableUpdate?: boolean;
}

type EditTableProps<T> =
  | (EditTablePropsBase<T> & SelectableTableProps<T>)
  | (EditTablePropsBase<T> & NotSelectableTableProps);

interface EditTablePropsBase<T> {
  editState: EditState<T>;
  showHeader?: boolean;
  columns: Column<T>[];
  onSaveNew(): void;
  onSaveExisting(): void;
  onEditExisting(item: T): void;
  onDelete(item: T): void;
  onCancel: (() => void) | undefined;
  rowKey(item: T): string | number | undefined;
  data: T[];
  saving?: boolean;
  disabled?: boolean;
}

interface NotSelectableTableProps {
  selectionMode?: undefined;
}

interface SelectableTableProps<T> {
  selectionMode: "Single";
  selectionChanged: (item: Maybe<T>) => void;
  isSelected: (item: T) => boolean;
}

interface ActionCellProps {
  editing: boolean;
  onSave(): void;
  onCancel: (() => void) | undefined;
  onEdit(): void;
  onDelete(): void;
  disabled?: boolean;
}
