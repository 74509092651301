import * as React from "react";

export interface PieProps {
  slices: Slice[];
}

const Pie: React.FunctionComponent<PieProps> = ({ slices }) => {
  return (
    <svg
      viewBox="-1 -1 2 2"
      style={{ transform: "rotate(-90deg)" }}
      width="100%"
    >
      {
        slices.reduce(
          (result, slice) => {
            const slicePath = (
              <SlicePath
                key={slice.color}
                slice={slice}
                cumulativePercent={result.cumulativePercent}
              />
            );
            return {
              cumulativePercent: result.cumulativePercent + slice.percent,
              slicePaths: [...result.slicePaths, slicePath],
            };
          },
          { cumulativePercent: 0, slicePaths: [] as any[] }
        ).slicePaths
      }
    </svg>
  );
};

export interface Slice {
  percent: number;
  color: string;
}
export default Pie;

function getCoordinatesForPercent(percent: number) {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);
  return [x, y];
}

function SlicePath({
  slice,
  cumulativePercent,
}: {
  slice: Slice;
  cumulativePercent: number;
}) {
  const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

  const [endX, endY] = getCoordinatesForPercent(
    cumulativePercent + slice.percent
  );

  // if the slice is more than 50%, take the large arc (the long way around)
  const largeArcFlag = slice.percent > 0.5 ? 1 : 0;

  // create an array and join it just for code readability
  const pathData = [
    `M ${startX} ${startY}`, // Move
    `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
    `L 0 0`, // Line
  ].join(" ");

  return <path d={pathData} fill={slice.color} />;
}
