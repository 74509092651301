import * as React from "react";
import { TextField, Tooltip, IconButton } from "@material-ui/core";
import { SearchIcon, CloseIcon } from "./icons";
import { useTranslation } from "../i18n/i18n";

export interface SearchBoxProps {
  /**
   * Only fires on Enter or when the search button is clicked.
   */
  onSearch: (text: string) => void;
  /**
   * Fires on every key stroke.
   */
  onChange?: (text: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string;
}

const SearchBox: React.FunctionComponent<SearchBoxProps> = (props) => {
  const size = "small";
  const [tmpSearchText, setTmpSearchText] = React.useState(
    props.defaultValue || ""
  );
  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      props.onSearch(tmpSearchText);
    } else if (e.key === "Escape") {
      clear();
    }
  };
  const clear = () => {
    setTmpSearchText("");
    props.onSearch("");
  };
  const handleOnChange = (e: any) => {
    setTmpSearchText(e.target.value);
    props.onChange && props.onChange(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <TextField
      label={t("search")}
      size={size}
      variant="outlined"
      fullWidth
      placeholder={props.placeholder}
      value={tmpSearchText}
      onChange={handleOnChange}
      autoFocus={props.autoFocus}
      InputProps={{
        startAdornment: (
          <Tooltip title="Suchen">
            <IconButton size={size}>
              <SearchIcon titleAccess="Search" />
            </IconButton>
          </Tooltip>
        ),
        endAdornment: tmpSearchText && (
          <Tooltip title="Suchen">
            <IconButton size={size} onClick={clear}>
              <CloseIcon titleAccess="Search" />
            </IconButton>
          </Tooltip>
        ),
        onKeyUp: handleKeyUp,
      }}
    />
  );
};

export default SearchBox;
