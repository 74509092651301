import { ExhibitionId } from "../../exhibitions/types";

export function checklistApiPath(exhibitionId: ExhibitionId) {
  return `exhibitions/${exhibitionId}/checklist`;
}

export interface Checklist {
  competitionsDone: boolean;
  membersDone: boolean;
  publication: {
    canPublish: boolean;
    isPublished: boolean;
  };
}
