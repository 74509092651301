import * as React from "react";
import AutocompletePicker from "../../../crossCutting/AutocompletePicker";
import AddIcon from "@material-ui/icons/GroupAdd";
import { SelectorProps } from "../../../crossCutting/selectorProps";
import { ExhibitionId } from "../types";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { Organization } from "../apiTypes";
import { sameOrga } from "./organization";
import { useTranslation } from "../../../i18n/i18n";

function OrganizationPicker(
  props: SelectorProps<Organization[]> & {
    exhibitionId: ExhibitionId;
    buttonLabel: string;
  }
) {
  const fetcher = useFetchClient();
  const search = React.useCallback(
    (searchText: string) =>
      fetcher(
        `exhibitions/${
          props.exhibitionId
        }/organizations?search=${encodeURIComponent(searchText)}`,
        { method: "GET" }
      ).then((r: any) => r.organizations) as Promise<Organization[]>,
    [fetcher, props.exhibitionId]
  );
  const { t } = useTranslation();

  return (
    <AutocompletePicker
      value={props.value}
      onChange={props.onChange}
      comparer={sameOrga}
      fetchOptions={search}
      buttonLabel={props.buttonLabel}
      getOptionLabel={(o) => o.organizationName}
      noOptionsText={t("noOrganizationsFound")}
      startIcon={<AddIcon />}
      placeholder={t("nameNumberCode")}
      color="primary"
      variant="outlined"
      multiple
    />
  );
}
export default OrganizationPicker;
