import { Route } from "react-router-dom";
import React from "react";
import Page from "./ExhibitionsPage";

const path = "/exhibitions";
export const exhibitionsPageRoute = () => (
  <Route path={path}>
    <Page />
  </Route>
);

export const exhibitionsPagePath = () => path;
