import { Maybe } from "./crossCutting/maybe";

const config = {
  apiBaseUrl: devOrTestAndProd(
    process?.env?.REACT_APP_API_BASE_URL_OVERRIDE,
    `${window.location.origin}/api`
  ),
  authAuthority: devOrTestAndProd(
    process?.env?.REACT_APP_AUTH_AUTHORITY_OVERRIDE,
    `${window.location.origin}/auth`
  ),
  appinsightsInstrumentationKey: devOrTestAndProd(
    "",
    process?.env?.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY ?? ""
  ),
};

export default config;

function devOrTestAndProd(devValue: Maybe<string>, testAndProdValue: string) {
  return process.env.NODE_ENV === "development" && devValue
    ? devValue
    : testAndProdValue;
}
