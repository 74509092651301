import * as React from "react";

export default function useSaveShortcut(save: () => void) {
  const handler = (e: KeyboardEvent) => {
    if (e.metaKey && e.key === "s") {
      e.preventDefault();
      save();
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  });
}
