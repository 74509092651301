import * as React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../../crossCutting/Snackbars";
import { FetchOptions } from "../../../fetching/fetchClient";
import { ExhibitionId } from "../../exhibitions/types";
import { AnimalStandard } from "./animalStandardTypes";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useAnimalStandards(exhibitionId?: ExhibitionId) {
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [animalStandards, setAnimalStandards] = React.useState(
    [] as AnimalStandard[]
  );
  const urlPrefix = exhibitionId ? `exhibitions/${exhibitionId}/` : "";
  const refresh = React.useCallback(
    () =>
      fetcher(`${urlPrefix}masterdata/animalstandards`, {
        method: "GET",
      }).then(({ animalStandards }) => {
        setAnimalStandards(animalStandards);
        setLoading(false);
      }),
    [urlPrefix, fetcher]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then(() =>
          refresh().then(() => {
            if (messages?.success) {
              showAlert(messages?.success, "success");
            }
          })
        )
        .catch((e) => {
          showAlert(
            e?.friendlyMessage ||
              messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createAnimalStandard = updater<{
    animalStandardName: string;
    animalStandardId: string;
  }>(
    () => `exhibitions/${exhibitionId}/animalStandards`,
    POST,
    {
      error: "Fehler beim Speichern des Standards.",
      success: "Der Standard wurde erstellt.",
    },
    (data) => data
  );
  const moveAnimalStandard = updater<{
    idOfAnimalStandardToMove: string;
    idOfNewPredecessor: string | undefined;
  }>(
    (data) =>
      `exhibitions/${exhibitionId}/masterdata/animalStandards/${
        data.idOfAnimalStandardToMove
      }/move${
        data.idOfNewPredecessor ? `?before=${data.idOfNewPredecessor}` : ""
      }`,
    { method: "PUT" },
    { error: "Fehler beim Verschieben des Standards." }
  );
  const updateAnimalStandard = updater<{
    animalStandardId: string;
    animalStandardName: string;
  }>(
    (data) =>
      `exhibitions/${exhibitionId}/masterdata/animalStandards/${data.animalStandardId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren der Standards.",
      success: "Die Änderungen wurden gespeichert.",
    },
    (data) => ({ animalStandardName: data.animalStandardName })
  );
  const deleteAnimalStandard = updater<{ animalStandardId: string }>(
    (data) =>
      `exhibitions/${exhibitionId}/masterdata/animalStandards/${data.animalStandardId}`,
    { method: "DELETE" },
    {
      error: "Fehler beim Löschen des Standards.",
      success: "Der Standard wurde gelöscht",
    }
  );
  const updateActivation = React.useCallback(
    (animalStandardIds: string[], isActive) => {
      setLoading(true);
      fetcher(`${urlPrefix}masterdata/animalstandards/updateactivation`, {
        method: "PUT",
        body: JSON.stringify({ animalStandardIds, isActive }),
        headers: { "Content-Type": "application/json" },
      })
        .then(() => refresh().catch(() => {}))
        .then(() => {
          showAlert(
            `Die Standards wurden ${isActive ? "aktiviert" : "deaktiviert"}`,
            "success"
          );
        })
        .catch((e) => {
          showAlert(
            `Fehler beim ${
              isActive ? "Aktivieren" : "Deaktivieren"
            } der Standards`,
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [fetcher, refresh, urlPrefix, showAlert]
  );

  return {
    animalStandards,
    loading,
    refresh,
    moveAnimalStandard,
    createAnimalStandard,
    updateAnimalStandard,
    deleteAnimalStandard,
    updateActivation,
  };
}
