import { Container, Divider, makeStyles } from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "../layoutConstants";
import PaperPage from "./PaperPage";
import { PageProps } from "./Page";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  page: {
    /* flexGrow:1 to have the bottomBar at the bottom even if the page doesn't
      need the full height.*/
    flexGrow: 1,
  },
  // bottomBarContainer is needed to give the bottoBar some space from the right
  // because of the scrollbar in the form
  bottomBarContainer: {
    marginRight: "15px", //seems to be the scrollbar width
  },
  bottomBar: {
    paddingLeft: theme.spacing(spacing3 * 2),
    paddingRight: theme.spacing(spacing3 * 2),
    paddingTop: theme.spacing(spacing3),
    paddingBottom: theme.spacing(spacing3),
    "& *+*": {
      marginLeft: theme.spacing(1),
    },
  },
}));
const BottomBarPage: React.FunctionComponent<
  PageProps & { bottomBarContent: React.ReactElement }
> = (props) => {
  const classes = useStyles();
  return (
    <>
      <PaperPage
        title={props.title}
        subtitle={props.subtitle}
        className={clsx(props.className, classes.page)}
      >
        {props.children}
      </PaperPage>
      <Divider />
      <div className={classes.bottomBarContainer}>
        <Container className={classes.bottomBar} maxWidth="lg">
          {props.bottomBarContent}
        </Container>
      </div>
    </>
  );
};

export default BottomBarPage;
