import React from "react";
import { useFetchClient } from "../../../fetching/fetchProvider";

const MasterDataLockContext = React.createContext<{
  masterDataLockInfo: MasterDataLockInfo;
  refresh: () => void;
}>(undefined!);

const NotLocked = "NotLocked";

export const MasterDataLockProvider: React.FunctionComponent<{}> = ({
  children,
}) => {
  const getLock = useGetLock();
  const [value, setValue] = React.useState<MasterDataLockInfo>();

  const refresh = React.useCallback(() => {
    getLock().then(setValue);
  }, [getLock]);

  React.useEffect(refresh, []);

  return value ? (
    <MasterDataLockContext.Provider
      value={{ masterDataLockInfo: value, refresh }}
    >
      {children}
    </MasterDataLockContext.Provider>
  ) : null;
};

function useGetLock() {
  const klexpoFetch = useFetchClient();

  return React.useCallback(
    () =>
      klexpoFetch("masterdata/lock", { method: "GET" }).then(
        (d: "" | MasterDataLockInfo) => (d === "" ? NotLocked : d)
      ) as Promise<MasterDataLockInfo>,
    [klexpoFetch]
  );
}

export const useMasterDataLock = () => React.useContext(MasterDataLockContext);

type MasterDataLockInfo = MasterDataLockDto | typeof NotLocked;

interface MasterDataLockDto {
  lockedByUserName: string;
  isLockedByCurrentUser: boolean;
  lockedUtc: string;
}
