import * as React from "react";
import { makeStyles, Paper } from "@material-ui/core";
import { spacing3 } from "../layoutConstants";
import Page, { PageProps } from "./Page";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(spacing3),
    marginTop: theme.spacing(spacing3),
  },
}));
const PaperPage: React.FunctionComponent<PageProps> = ({
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Page {...rest}>
      <Paper className={classes.paper}>{children}</Paper>
    </Page>
  );
};

export default PaperPage;
