import * as React from "react";
import { PointCalculation, pointCalculations } from "./pointCalcultation";
import Radios from "../../crossCutting/Radios";

export default function PointCalculationRadios(props: {
  value: PointCalculation;
  onChange: (pointCalculation: PointCalculation) => void;
}) {
  return (
    <Radios
      label="Punktberechnung"
      fieldName="pointCalculation"
      items={pointCalculations}
      value={props.value}
      onChange={(v) => props.onChange(v as PointCalculation)}
    />
  );
}
