import { Route } from "react-router-dom";
import React from "react";
import Page, { MemberEditExhibition } from "./MemberEditPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
import { ExhibitionId } from "../types";
import { uuidRegex } from "../../../crossCutting/uuid";

const suffix = "members";

export const memberEditPageRoute = (
  pathPrefix: string,
  exhibition: MemberEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:memberId(${uuidRegex})/edit`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const memberEditPagePath = (
  exhibitionId: ExhibitionId,
  memberId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${memberId}/edit`;

export const LinkToMemberEdit: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; memberId: string }
> = ({ exhibitionId, memberId, ...rest }) => (
  <AppLink {...rest} to={memberEditPagePath(exhibitionId, memberId)} />
);

export const memberNewPageRoute = (
  pathPrefix: string,
  exhibition: MemberEditExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/new`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const memberNewPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}/${suffix}/new`;

export const LinkToMemberNew: React.FunctionComponent<
  AppLinkProps & { exhibition: MemberEditExhibition }
> = ({ exhibition, ...rest }) => (
  <AppLink {...rest} to={memberNewPagePath(exhibition.exhibitionId)} />
);
