import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";

export function DeleteConfirmation({
  message,
  open,
  onClose,
}: {
  message: string;
  open: boolean;
  onClose: (decision: boolean) => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Nicht Löschen
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const ConfirmationDialog: React.FunctionComponent<{
  yesButtonText: string;
  noButtonText: string;
  open: boolean;
  onClose: (result: boolean) => void;
  title?: string;
  text?: string;
}> = (props) => (
  <Dialog
    open={props.open}
    onClose={() => props.onClose(false)}
    aria-labelledby="alert-dialog-description"
  >
    {props.title && <DialogTitle>{props.title}</DialogTitle>}
    <DialogContent>
      {props.text && (
        <DialogContentText id="alert-dialog-description">
          {props.text}
        </DialogContentText>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.onClose(false)} color="primary">
        {props.noButtonText}
      </Button>
      <Button onClick={() => props.onClose(true)} color="primary" autoFocus>
        {props.yesButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);
