import { Route } from "react-router-dom";
import React from "react";
import Page from "./BreedEditPage";
import { ExhibitionId, Exhibition } from "../../exhibitions/types";
import { uuidRegex } from "../../../crossCutting/uuid";
import { useBreedSave } from "../apiClient/useBreeds";

type BreedEditExhibition = Pick<Exhibition, "exhibitionId" | "exhibitionName">;

export const exhibitionBreedEditPageRoute = (
  pathPrefix: string,
  exhibition: BreedEditExhibition
) => (
  <Route
    path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/breeds/:breedId(${uuidRegex})/edit`}
  >
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        getBreedPath: (animalStandardId: string, breedId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/breeds/${breedId}`,
        getAnimalStandardPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}`,
        getNodesPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/nodes`,
        getBoxTypesPath: () =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/boxtypes/`,
        getBreedsPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/breeds`,
        makeBreedSaveDataHook: () => (animalStandardId: string) =>
          useBreedSave(animalStandardId, exhibition.exhibitionId),
        editBreedPath: (animalStandardId: string, breedId: string) =>
          exhibitionBreedEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            breedId
          ),
      }}
    />
  </Route>
);

export const exhibitionBreedCreatePageRoute = (
  pathPrefix: string,
  exhibition: BreedEditExhibition
) => (
  <Route
    path={`${pathPrefix}/masterdata/standards/:animalStandardId(${uuidRegex})/breeds/new`}
  >
    <Page
      strategy={{
        title: exhibition.exhibitionName,
        getAnimalStandardPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}`,
        getNodesPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/nodes`,
        getBoxTypesPath: () =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/boxtypes/`,
        getBreedsPath: (animalStandardId: string) =>
          `exhibitions/${exhibition.exhibitionId}/masterdata/animalstandards/${animalStandardId}/breeds`,
        makeBreedSaveDataHook: () => (animalStandardId: string) =>
          useBreedSave(animalStandardId, exhibition.exhibitionId),
        editBreedPath: (animalStandardId: string, breedId: string) =>
          exhibitionBreedEditPagePath(
            exhibition.exhibitionId,
            animalStandardId,
            breedId
          ),
      }}
    />
  </Route>
);

export const exhibitionBreedEditPagePath = (
  exhibitionId: ExhibitionId,
  animalStandardId: string,
  breedId: string
) =>
  `/exhibitions/${exhibitionId}/masterdata/standards/${animalStandardId}/breeds/${breedId}/edit`;

export const exhibitionBreedCreatePagePath = (
  exhibitionId: ExhibitionId,
  animalStandardId: string
) =>
  `/exhibitions/${exhibitionId}/masterdata/standards/${animalStandardId}/breeds/new`;
