import { Route } from "react-router-dom";
import React from "react";
import Page from "./ExhibitionPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";
import { ExhibitionId, Exhibition } from "../types";

export const exhibitionPageRoute = (
  pathPrefix: string,
  exhibition: Exhibition
) => (
  <Route path={`${pathPrefix}`}>
    <Page exhibition={exhibition} />
  </Route>
);

export const exhibitionPagePath = (exhibitionId: ExhibitionId) =>
  `/exhibitions/${exhibitionId}`;

export const LinkToExhibition: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId }
> = ({ exhibitionId, ...rest }) => (
  <AppLink {...rest} to={exhibitionPagePath(exhibitionId)} />
);
