import * as React from "react";

export const RolesContext = React.createContext<RolesState<string>>({
  roles: [],
});

export function useRoles<TRole>() {
  return (React.useContext(RolesContext) as any) as RolesState<TRole>;
}

export function ForRoles<TRole>({
  roles,
  exclude,
  ...rest
}: React.PropsWithChildren<{
  roles?: TRole[] | TRole;
  exclude?: TRole[] | TRole;
}>) {
  const usersRoles = useRoles<TRole>().roles;
  return evaluateRoles(usersRoles, roles || [], exclude) ? (
    <>{rest.children}</>
  ) : null;
}

function evaluateRoles<TRole>(
  usersRoles: TRole[],
  roles: TRole[] | TRole,
  exclude?: TRole[] | TRole
) {
  const requiredRoles = Array.isArray(roles) ? roles : [roles];
  const excludedRoles = exclude
    ? Array.isArray(exclude)
      ? exclude
      : [exclude]
    : [];

  return (
    !usersRoles.some((u) => excludedRoles.includes(u)) &&
    (requiredRoles.length === 0 ||
      usersRoles.some((u) => requiredRoles.includes(u)))
  );
}

type RolesState<T> = { roles: T[] };
