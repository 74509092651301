import * as React from "react";
import EditList from "../../../../crossCutting/EditList";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import JumpToButton from "../../../../crossCutting/JumpToButton";
import useHoverActionCell from "../../../../crossCutting/hooks/useHoverActionCell";
import { AddIcon, ChangeOrderIcon } from "../../../../crossCutting/icons";
import { byNumber } from "../../../../crossCutting/sorting";
import AgeClassDialog from "./AgeClassDialog";
import ChangeOrderDialog from "../../ChangeOrderDialog";
import {
  AgeClass,
  UpdateAgeClassInput,
  CreateAgeClassInput,
  ChangeAgeClassOrderInput,
} from "../../apiClient/ageClassTypes";
import { Origin } from "../../apiClient/commonTypes";
import { useTranslatorForUser } from "../../translation";

export type MakeAgeClassesDataHook = () => (
  animalStandardId: string
) => {
  ageClasses: AgeClass[];
  loading: boolean;
  refresh: () => Promise<void>;
  createAgeClass: (input: CreateAgeClassInput) => Promise<void>;
  updateAgeClass: (
    input: UpdateAgeClassInput & { ageClassId: string }
  ) => Promise<void>;
  changeAgeClassOrder: (input: ChangeAgeClassOrderInput) => Promise<void>;
};

export interface AgeClassesTabPanelStrategy {
  makeAgeClassesDataHook: MakeAgeClassesDataHook;
}

export interface AgeClassesTabPanelProps {
  strategy: AgeClassesTabPanelStrategy;
  animalStandard: {
    animalStandardId: string;
  };
}

const templateAgeClass = {
  ageClassId: "",
  captions: [],
  origin: Origin.Exhibition,
  isActive: true,
  sort: 0,
};

const AgeClassesTabPanel: React.FunctionComponent<AgeClassesTabPanelProps> = (
  props
) => {
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const translator = useTranslatorForUser();
  const useAgeClasses = props.strategy.makeAgeClassesDataHook();
  const {
    ageClasses,
    loading,
    createAgeClass,
    updateAgeClass,
    changeAgeClassOrder,
  } = useAgeClasses(props.animalStandard.animalStandardId);
  const [editedAgeClass, setEditedAgeClass] = React.useState<AgeClass>();

  const handleNewClick = () => {
    setEditedAgeClass(templateAgeClass);
  };
  const handleClick = (ageClass: AgeClass) => {
    setEditedAgeClass(ageClass);
  };

  const handleCancelEdit = () => {
    setEditedAgeClass(undefined);
  };
  const handleSave = (ageClass: AgeClass) => {
    if (editedAgeClass?.ageClassId) {
      updateAgeClass({
        captions: ageClass.captions,
        ageClassId: editedAgeClass?.ageClassId,
      }).then(() => handleCancelEdit());
    } else if (editedAgeClass) {
      createAgeClass({ captions: ageClass.captions }).then(() =>
        handleCancelEdit()
      );
    }
  };

  const [changeOrderAgeClasses, setChangeOrderAgeClasses] = React.useState<
    AgeClass[]
  >();
  const handleCancelChangeOrder = () => setChangeOrderAgeClasses(undefined);
  const handleSaveChangeOrder = (reorderedAgeClassIds: string[]) => {
    changeAgeClassOrder({ ageClassIds: reorderedAgeClassIds }).then(() =>
      setChangeOrderAgeClasses(undefined)
    );
  };
  const handleChangeOrderClick = () => {
    setChangeOrderAgeClasses(ageClasses);
  };

  return (
    <EditList
      toolbarItems={
        <>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleNewClick}
          >
            Neue Alterskategorie
          </Button>
          <Button
            startIcon={<ChangeOrderIcon />}
            onClick={handleChangeOrderClick}
          >
            Reihenfolge ändern
          </Button>
        </>
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Herkunft</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ageClasses.sort(byNumber((ac) => ac.sort)).map((ac) => (
            <TableRow
              className={rowClass}
              key={ac.ageClassId}
              hover
              onClick={() => handleClick(ac)}
            >
              <TableCell>{translator(ac.captions)?.ageClassName}</TableCell>
              <TableCell>{ac.origin ? "Ausstellung" : "Global"}</TableCell>
              <HoverActionCell>
                <JumpToButton>Bearbeiten</JumpToButton>
              </HoverActionCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editedAgeClass && (
        <AgeClassDialog
          ageClass={editedAgeClass}
          open={!!editedAgeClass}
          onCancel={handleCancelEdit}
          onSave={handleSave}
          loading={loading}
        />
      )}
      {changeOrderAgeClasses && (
        <ChangeOrderDialog
          open={!!changeOrderAgeClasses}
          onCancel={handleCancelChangeOrder}
          onSave={handleSaveChangeOrder}
          title={"Reihenfolge ändern"}
          loading={loading}
          items={ageClasses.map((ac) => ({
            key: ac.ageClassId,
            orderable: translator(ac.captions)?.ageClassName,
          }))}
        />
      )}
    </EditList>
  );
};

export default AgeClassesTabPanel;
