import * as React from "react";
import { useExhibitionCached } from "./ExhibitionContext";
import { useFetchClient } from "../../fetching/fetchProvider";
import { useGlobalAlertSnackbar } from "../../crossCutting/Snackbars";
import { FetchOptions } from "../../fetching/fetchClient";

const PUT: FetchOptions = {
  method: "PUT",
  headers: { "Content-Type": "application/json" },
};

const POST: FetchOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};

export function useCategories() {
  const { exhibitionId } = useExhibitionCached();
  const fetcher = useFetchClient();
  const showAlert = useGlobalAlertSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([] as Category[]);
  const refresh = React.useCallback(
    () =>
      fetcher(`exhibitions/${exhibitionId}/categories`, {
        method: "GET",
      }).then(({ categories }) => setCategories(categories)),
    [exhibitionId, fetcher]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  function updater<T>(
    pathFn: (args: T) => string,
    options: FetchOptions,
    messages?: {
      error?: string;
      success?: string;
    },
    bodyFn?: (args: T) => {}
  ) {
    return (data: T) => {
      setLoading(true);
      return fetcher(
        pathFn(data),
        bodyFn ? { ...options, body: JSON.stringify(bodyFn(data)) } : options
      )
        .then(() =>
          refresh().then(() => {
            if (messages?.success) {
              showAlert(messages?.success, "success");
            }
          })
        )
        .catch((e) => {
          showAlert(
            messages?.error ||
              "Fehler beim Übermitteln der Daten an den Server.",
            "error"
          );
          throw e;
        })
        .finally(() => setLoading(false));
    };
  }

  const createCategory = updater<{
    categoryName: string;
    animalStandardId: string;
  }>(
    () => `exhibitions/${exhibitionId}/categories`,
    POST,
    {
      error: "Fehler beim Speichern der Kategorie.",
      success: "Die Kategorie wurde erstellt.",
    },
    (data) => data
  );
  const moveCategory = updater<{
    idOfCategoryToMove: string;
    idOfNewPredecessor: string | undefined;
  }>(
    (data) =>
      `exhibitions/${exhibitionId}/categories/${data.idOfCategoryToMove}/move${
        data.idOfNewPredecessor ? `?before=${data.idOfNewPredecessor}` : ""
      }`,
    { method: "PUT" },
    { error: "Fehler beim Verschieben der Kategorie." }
  );
  const updateCategory = updater<{ categoryId: string; categoryName: string }>(
    (data) => `exhibitions/${exhibitionId}/categories/${data.categoryId}`,
    PUT,
    {
      error: "Fehler beim Aktualisieren der Kategorie.",
      success: "Die Änderungen wurden gespeichert.",
    },
    (data) => ({ categoryName: data.categoryName })
  );
  const deleteCategory = updater<{ categoryId: string }>(
    (data) => `exhibitions/${exhibitionId}/categories/${data.categoryId}`,
    { method: "DELETE" },
    {
      error: "Fehler beim Löschen der Kategorie.",
      success: "Die Kategorie wurde gelöscht",
    }
  );

  return {
    categories,
    loading,
    moveCategory,
    createCategory,
    updateCategory,
    deleteCategory,
  };
}

export interface Category {
  categoryId: string;
  categoryName: string;
  sort: number;
  animalStandardId: string;
}
