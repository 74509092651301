import { Route } from "react-router-dom";
import React from "react";
import { ExhibitionId } from "../types";
import { uuidRegex } from "../../../crossCutting/uuid";
import RegistrationPage, {
  RegistrationDetailsExhibition,
} from "./RegistrationPage";
import AppLink, { AppLinkProps } from "../../../crossCutting/AppLink";

const suffix = "registrations";

export const registrationCreatedPagePath = (
  exhibitionId: ExhibitionId,
  registrationId: string,
  registrationCount?: number
) =>
  `/exhibitions/${exhibitionId}/${suffix}/${registrationId}/created${
    (registrationCount ?? 0) > 1
      ? `?registrationCount=${registrationCount}`
      : ""
  }`;

export const registrationCreatedPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationDetailsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:registrationId(${uuidRegex})/created`}>
    <RegistrationPage exhibition={exhibition} />
  </Route>
);

export const registrationPagePath = (
  exhibitionId: ExhibitionId,
  registrationId: string
) => `/exhibitions/${exhibitionId}/${suffix}/${registrationId}`;

export const registrationPageRoute = (
  pathPrefix: string,
  exhibition: RegistrationDetailsExhibition
) => (
  <Route path={`${pathPrefix}/${suffix}/:registrationId(${uuidRegex})`}>
    <RegistrationPage exhibition={exhibition} />
  </Route>
);

export const LinkToRegistration: React.FunctionComponent<
  AppLinkProps & { exhibitionId: ExhibitionId; registrationId: string }
> = ({ exhibitionId, registrationId, ...rest }) => (
  <AppLink {...rest} to={registrationPagePath(exhibitionId, registrationId)} />
);
