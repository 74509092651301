import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { useAuthentication } from "../../../authentication/AuthContext";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import { byString } from "../../../crossCutting/sorting";
import {
  inLanguage,
  inLanguageOrDefault,
  languagesPrio,
} from "../../../language";
import { PickerBreedColor } from "./BreedPicker";
import NameCell from "./NameCell";
import { useTranslation } from "../../../i18n/i18n";

export type ColorSelection = "mixed" | { colorId: string } | null;

const useStyles = makeStyles((theme) => ({
  controlsContainer: {
    display: "flex",
  },
  select: {
    flexGrow: 1,
  },
  mixedControl: {
    marginLeft: theme.spacing(spacing3),
  },
  list: {
    display: "table",
    width: "100%",
  },
  menuItem: {
    display: "table-row",
  },
}));

export default function ColorSelector(props: {
  isMixed: boolean;
  colors: PickerBreedColor[];
  value: ColorSelection;
  onChange: (value: ColorSelection) => void;
  error?: boolean;
  helperText?: string;
}) {
  const { t } = useTranslation();
  const handleMixedCheckboxChange = (_: any, checked: boolean) => {
    props.onChange(checked ? "mixed" : null);
  };
  const colorById = (id: string) => props.colors.find((c) => c.colorId === id);
  const handleSelectChange = (e: any) => {
    props.onChange(colorById(e.target.value) || null);
  };
  const { language } = useAuthentication();
  const languages = languagesPrio(language);
  const classes = useStyles();
  return (
    <FormControl fullWidth error={props.error}>
      <div className={classes.controlsContainer}>
        <Select
          className={classes.select}
          value={
            props.value && typeof props.value === "object"
              ? props.value.colorId
              : ""
          }
          fullWidth
          MenuProps={{ classes: { list: classes.list } }}
          renderValue={(colorId: any) => (
            <div>
              {
                inLanguageOrDefault(language, colorById(colorId)?.captions)
                  ?.colorName
              }
            </div>
          )}
          onChange={handleSelectChange}
        >
          {props.colors
            .sort(
              byString(
                (c) =>
                  inLanguageOrDefault(language, c.captions)?.colorName ?? ""
              )
            )
            .map((color) => (
              <MenuItem
                key={color.colorId}
                value={color.colorId}
                className={classes.menuItem}
              >
                {languages
                  .map(
                    (l) =>
                      inLanguage(l, color.captions) ?? {
                        language: l,
                        colorName: null,
                      }
                  )
                  .map((i) => (
                    <NameCell key={i.language} language={i.language}>
                      {i.colorName}
                    </NameCell>
                  ))}
              </MenuItem>
            ))}
        </Select>
        {props.isMixed ? (
          <FormControlLabel
            className={classes.mixedControl}
            control={
              <Checkbox
                checked={props.value === "mixed"}
                onChange={handleMixedCheckboxChange}
                name="mixed"
                color="primary"
              />
            }
            label={t("mixed")}
          />
        ) : null}
      </div>
      <FormHelperText>{props.helperText ?? " "}</FormHelperText>
    </FormControl>
  );
}
