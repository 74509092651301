import * as React from "react";
import { Grid, Card, makeStyles, withStyles } from "@material-ui/core";

interface GridCardProps {
  xs?: ItemWidth;
  sm?: ItemWidth;
  md?: ItemWidth;
  lg?: ItemWidth;
  devNote?: string | React.ReactNode;
}

const useStyles = makeStyles({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dev: { position: "relative" },
  devNotes: {
    position: "absolute",
    top: "-0.5rem",
    right: "-0.5rem",
    zIndex: 999,
    backgroundColor: "white",
    maxWidth: "10rem",
    fontSize: "0.75rem",
    padding: "0.75rem",
    opacity: 0.8,
  },
});

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiCardActions-root": {
      marginTop: "auto",
    },
  },
})(() => null);

const GridCard: React.FunctionComponent<GridCardProps> = ({
  devNote,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid item {...rest} className={classes.dev}>
      <Card className={classes.card}>
        <GlobalCss />
        {React.Children.toArray(rest.children)}
      </Card>
      {devNote ? <div className={classes.devNotes}>{devNote}</div> : null}
    </Grid>
  );
};

type ItemWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export default GridCard;
