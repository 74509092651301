import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useLocation } from "react-router-dom";
import AppRole from "../../../authorization/appRoles";
import { ForRoles } from "../../../authorization/RolesContext";
import EditList from "../../../crossCutting/EditList";
import { mapState } from "../../../crossCutting/hooks/usePromiseStates";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import Page from "../../../crossCutting/pages/Page";
import SearchBox from "../../../crossCutting/SearchBox";
import TableOrEmptyMessage from "../../../crossCutting/TableOrEmptyMessage";
import { parseQuery } from "../../../crossCutting/urls";
import { useLazyFetch } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import {
  RegistrationInList,
  registrationsApiPath,
} from "../../api/exhibitions/registrations";
import { Exhibition, Pagination } from "../apiTypes";
import {
  IfCanRegisterOthersInContext,
  IfCanRegisterSelfInContext,
} from "../IfCanRegisterInContext";
import PickMemberAndRegister from "../PickMemberAndRegister";
import { LinkToRegistrationNew } from "../registrationEditPage/routing";
import RegistrationsExcelDownload from "./RegistrationsExcelDownload";
import RegistrationTable, { Registration } from "./RegistrationTable";

export type RegistrationsListExhibition = Pick<
  Exhibition,
  "exhibitionId" | "exhibitionName"
>;

export interface RegistrationsPageProps {
  exhibition: RegistrationsListExhibition;
}

const useStyles = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    flexGrow: 1,
  },
  search: {
    marginBottom: theme.spacing(spacing3),
  },
}));

const RegistrationsPage: React.FunctionComponent<RegistrationsPageProps> = (
  props
) => {
  const classes = useStyles();
  const location = useLocation();
  const memberIdRegistered = parseQuery(location.search).memberIdRegistered;
  const [search, setSearch] = React.useState("");
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(100);
  const { t } = useTranslation();

  const { execute, state: stateRaw } = useLazyFetch<{
    registrations: RegistrationInList[];
    pagination: Pagination;
  }>();
  const refresh = React.useCallback(
    (search: string, memberId: string, skip: number, take: number) => {
      const query = {
        search,
        memberIdRegistered: memberId,
        skip,
        take,
      };
      const url = registrationsApiPath(props.exhibition.exhibitionId, query);
      return execute(url);
    },
    [execute, props.exhibition.exhibitionId]
  );

  React.useEffect(() => refresh(search, memberIdRegistered, skip, take), [
    refresh,
    props.exhibition.exhibitionId,
    search,
    memberIdRegistered,
    skip,
    take,
  ]);
  const state = mapState(stateRaw, (s) => ({
    ...s,
    registrations: s.registrations.map(
      (r) => ({ ...r, createdDate: new Date(r.createdUtc) } as Registration)
    ),
  }));
  const handleSearch = (search: string) => {
    setSkip(0);
    setSearch(search);
  };
  const handlePageChanged = (rowsPerPage: number, page: number) => {
    setSkip(page * rowsPerPage);
    setTake(rowsPerPage);
  };
  return (
    <Page
      title={props.exhibition.exhibitionName}
      subtitle={t("registrations")}
      noscroll
      className={classes.page}
      maxWidth="xl"
    >
      <EditList
        toolbarItems={
          <>
            <ForRoles roles="Member">
              <IfCanRegisterSelfInContext>
                <LinkToRegistrationNew
                  button
                  variant="contained"
                  exhibitionId={props.exhibition.exhibitionId}
                >
                  {t("registerYourself")}
                </LinkToRegistrationNew>
              </IfCanRegisterSelfInContext>
            </ForRoles>
            <IfCanRegisterOthersInContext>
              <PickMemberAndRegister
                exhibitionId={props.exhibition.exhibitionId}
              />
            </IfCanRegisterOthersInContext>
            <ForRoles roles={["Organizer", AppRole.ExhibitionAdmin]}>
              <RegistrationsExcelDownload exhibition={props.exhibition} />
            </ForRoles>
          </>
        }
      >
        {state.isRunning() ? (
          <LoadingIndicator />
        ) : state.isSuccess() ? (
          <>
            <div className={classes.search}>
              <SearchBox
                placeholder={t("registrationSearchboxPlaceholder")}
                onSearch={handleSearch}
                autoFocus
                defaultValue={search}
              />
            </div>
            <TableOrEmptyMessage
              data={state.data.registrations}
              emptyMessage={t("noRegistrationsFound")}
            >
              <RegistrationTable
                registrations={state.data.registrations}
                exhibitionId={props.exhibition.exhibitionId}
                rowsPerPage={take}
                page={state.data.pagination.page}
                totalCount={state.data.pagination.totalCount}
                pageChanged={handlePageChanged}
              />
            </TableOrEmptyMessage>
          </>
        ) : null}
      </EditList>
    </Page>
  );
};

export default RegistrationsPage;
