import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { spacing3 } from "../../../crossCutting/layoutConstants";
import LoadingIndicator from "../../../crossCutting/LoadingIndicator";
import { useFetch2, useSendJson } from "../../../fetching/fetchProvider";
import { useTranslation } from "../../../i18n/i18n";
import { Checklist, checklistApiPath } from "../../api/exhibitions/checklist";
import {
  publishExhibitionApiPath,
  unpublishExhibitionApiPath,
} from "../../api/exhibitions/publication";
import { ExhibitionId } from "../types";

const useStyles = makeStyles((theme) => ({
  publicationPrerequisites: {
    marginTop: "0",
    marginBottom: "0",
  },
  publishButton: {
    marginTop: theme.spacing(spacing3),
  },
}));

export default function PublicationTabPanel({
  exhibitionId,
}: {
  exhibitionId: ExhibitionId;
}) {
  const { t } = useTranslation();
  const [checklistState, refreshChecklist] = useFetch2<Checklist>(
    checklistApiPath(exhibitionId)
  );
  const classes = useStyles();

  const { sendJson: publish, loading: publishing } = useSendJson({
    success: t("publishSuccess"),
    error: t("publishError"),
  });

  const handlePublishClick = () => {
    publish({
      path: publishExhibitionApiPath(exhibitionId),
      method: "POST",
      onSuccess: () => refreshChecklist(),
    });
  };

  const { sendJson: unpublish, loading: unpublishing } = useSendJson({
    success: t("unpublishSuccess"),
    error: t("unpublishError"),
  });

  const handleUnpublishClick = () => {
    unpublish({
      path: unpublishExhibitionApiPath(exhibitionId),
      method: "POST",
      onSuccess: () => refreshChecklist(),
    });
  };

  return checklistState.isSuccess() ? (
    <>
      {checklistState.data.publication.isPublished ? (
        <>
          <Typography variant="body1">{t("exhibitionIsPublished")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("publishedExhibitionExplanationText")}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleUnpublishClick}
            className={classes.publishButton}
            disabled={unpublishing}
          >
            {t("unpublish")}
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body1">
            {t("exhibitionIsNotPublished")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("unpublishedExhibitionExplanationText")}
          </Typography>
          {checklistState.data.publication.canPublish ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.publishButton}
              onClick={handlePublishClick}
              disabled={publishing}
            >
              {t("publish")}
            </Button>
          ) : (
            <Box marginTop={3}>
              <Typography component="h3" variant="h6">
                {t("publish")}
              </Typography>
              <Typography variant="body1" color="error">
                {t("exhibitionCantBePublishedBecauseOfPreconditions")}
              </Typography>
              <Box marginTop={1}>
                <Typography component="h4" variant="body1">
                  {`${t("preconditions")}:`}
                </Typography>
                <ul className={classes.publicationPrerequisites}>
                  <li>{t("competitionsRequiredPublicationPrecondition")}</li>
                  <li>{t("membersRequiredPublicationPrecondition")}</li>
                </ul>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  ) : checklistState.isRunning() ? (
    <LoadingIndicator />
  ) : (
    <div>Error</div>
  );
}
