import * as React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export interface ImageEditorProps {
  className?: string;
  onImageChange: (image: any) => void;
  image: any;
}

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  imageWrapper: { flexGrow: 1, backgroundColor: theme.palette.grey[100] },
  image: {
    height: "100%",
    maxHeight: "180px",
  },
  inputWrapper: { flexShrink: 0 },
}));

const ImageEditor: React.FunctionComponent<ImageEditorProps> = (props) => {
  const [imagePreview, setImagePreview] = React.useState<any>();
  const classes = useStyles();

  const handleChange = (e: any) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    props.onImageChange(e.target.files[0]);
  };
  return (
    <div className={clsx(props.className, classes.root)}>
      <div className={classes.imageWrapper}>
        <img
          className={classes.image}
          src={imagePreview || props.image}
          alt=""
        />
      </div>
      <div className={classes.inputWrapper}>
        <input type="file" onChange={handleChange} />
      </div>
    </div>
  );
};

export default ImageEditor;
