import { parseQuery } from "../../../crossCutting/urls";
import { useFetch } from "../../../fetching/fetchProvider";
import { ExhibitionDates, ListExhibition } from "../apiTypes";

export const useExhibitionList = (search?: string) => {
  const { data, path, ...rest } = useFetch<{ exhibitions: RawApiExhibition[] }>(
    createPath(search)
  );
  const query = parseQuery(path?.split("?")[1] ?? "");

  return {
    ...rest,
    search: query.search ? decodeURIComponent(query.search) : undefined,
    exhibitions: data?.exhibitions.map(parseExhibitionJson),
  };
};

function createPath(search: string | undefined) {
  return search
    ? `exhibitions?search=${encodeURIComponent(search)}`
    : "exhibitions";
}
type RawApiExhibition = ListExhibition & ExhibitionDates<string>;

function parseExhibitionJson(data: RawApiExhibition) {
  return {
    ...data,
    startDate: new Date(data.startDate),
    endDate: new Date(data.endDate),
    deadline: new Date(data.deadline),
  };
}
