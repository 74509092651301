import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import {
  AddIcon,
  ChangeOrderIcon,
  EditIcon,
} from "../../../../crossCutting/icons";
import { useTranslatorForUser } from "../../translation";

const useTreeStyles = makeStyles((theme) => ({
  actions: {
    "& > *": {
      visibility: "hidden",
    },
  },
  itemContainer: {
    "&:hover $actions > *": {
      visibility: "visible",
    },
    "&:hover $item": {
      backgroundColor: theme.palette.grey[200],
      cursor: "pointer",
    },
  },
  item: {},
}));

export default function NodeTree<T extends TreeNode<T>>(
  props: NodeTreeProps<T>
) {
  const classes = useTreeStyles();
  const depth = props.depth ?? 0;
  const theme = useTheme();
  const translator = useTranslatorForUser();
  return (
    <List component="div" disablePadding>
      {props.nodes.map((n) => {
        return (
          <React.Fragment key={n.nodeId}>
            <ListItem
              className={classes.item}
              key={n.nodeId}
              classes={{ container: classes.itemContainer }}
              ContainerComponent="div"
              onClick={() => props.onEdit(n)}
            >
              <ListItemText style={{ paddingLeft: theme.spacing(3 * depth) }}>
                {translator(n.captions)?.nodeName}
              </ListItemText>
              <ListItemSecondaryAction className={classes.actions}>
                {!!n.children?.length && (
                  <Tooltip title="Reihenfolge innerhalb ändern">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onChangeChildrenOrder(n);
                      }}
                    >
                      <ChangeOrderIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Neu">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onAdd(e.target, n);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Bearbeiten">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onEdit(n);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            {!!n.children?.length && (
              <NodeTree
                nodes={n.children}
                depth={depth + 1}
                onEdit={props.onEdit}
                onAdd={props.onAdd}
                onToggleActive={props.onToggleActive}
                onChangeChildrenOrder={props.onChangeChildrenOrder}
              />
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
}
export interface NodeTreeProps<T extends TreeNode<T>> {
  nodes: T[];
  depth?: number;
  onEdit: (node: T) => void;
  onToggleActive: (node: T) => void;
  onAdd: (anchorElem: any, node: T) => void;
  onChangeChildrenOrder: (parent: T) => void;
}

export interface TreeNode<T> {
  nodeId: string;
  captions: NodeCaption[];
  children: T[] | null;
  // isActive: boolean;
}

export interface NodeCaption {
  language: string;
  nodeName: string;
}
