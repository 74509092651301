import React from "react";
import AppRole from "../../authorization/appRoles";
import { RolesContext, useRoles } from "../../authorization/RolesContext";
import { Maybe } from "../../crossCutting/maybe";
import { makeUrl } from "../../crossCutting/urls";
import { useFetch2 } from "../../fetching/fetchProvider";

const ExhibitionUserContext = React.createContext<ExhibitionUser>({
  exhibitionRoles: [],
  memberId: undefined,
});

export const ExhibitionUserProvider: React.FunctionComponent<{
  exhibitionId: string;
}> = ({ exhibitionId, children }) => {
  const [state] = useFetch2<ExhibitionUser>(
    makeUrl`exhibitions/${exhibitionId}/user`
  );

  return state.isSuccess() ? (
    <ExhibitionUserContext.Provider value={state.data}>
      {children}
    </ExhibitionUserContext.Provider>
  ) : state.isError() ? (
    <>Error</>
  ) : null;
};

export const useCurrentUserMember = () => {
  const user = React.useContext(ExhibitionUserContext);
  return { memberId: user.memberId };
};

const useExhibitionRoles = () => {
  const user = React.useContext(ExhibitionUserContext);
  return { roles: user.exhibitionRoles };
};

export const useRolesCombined = () => {
  const exhibitionRoles = useExhibitionRoles();
  const appRoles = useRoles<AppRole>();

  return { roles: [...exhibitionRoles.roles, ...appRoles.roles] as Role[] };
};

export const ExhibitionRolesProvider: React.FunctionComponent<{}> = ({
  children,
}) => {
  const rolesCombined = useRolesCombined();
  return (
    <RolesContext.Provider value={rolesCombined}>
      {children}
    </RolesContext.Provider>
  );
};

export type Role = AppRole | ExhibitionRole;
export type ExhibitionRole = "Organizer" | "Member";
export interface ExhibitionUser {
  memberId: Maybe<string>;
  exhibitionRoles: ExhibitionRole[];
}
