import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../crossCutting/date";
import useHoverActionCell from "../../../crossCutting/hooks/useHoverActionCell";
import { Pageable } from "../../../crossCutting/Pagination";
import { useTranslation } from "../../../i18n/i18n";
import { RegistrationInList } from "../../api/exhibitions/registrations";
import { formatGender } from "../genders";
import {
  LinkToRegistration,
  registrationPagePath,
} from "../registrationPage/routing";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  right: {
    textAlign: "right",
  },
  tableContainer: {
    flexGrow: 1,
    overflowY: "auto",
  },
  tablePagination: {
    flexShrink: 0,
  },
}));

const RegistrationTable: React.FunctionComponent<RegistrationTableProps> = ({
  registrations,
  exhibitionId,
  ...props
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const classes = useStyles();
  const { rowClass, HoverActionCell } = useHoverActionCell();
  const handleChangePage = (_: any, page: number) => {
    props.pageChanged(props.rowsPerPage, page);
  };
  const handleChangeRowsPerPage = (e: any) => {
    const parsed = parseInt(e.target.value);
    props.pageChanged(parsed, 0);
  };
  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("name")}</TableCell>
              <TableCell className={classes.right}>
                {t("memberNumber")}
              </TableCell>
              <TableCell>{t("unit")}</TableCell>
              <TableCell>{t("breed")}</TableCell>
              <TableCell>{t("color")}</TableCell>
              <TableCell>{t("gender")}</TableCell>
              <TableCell className={classes.right}>
                {t("registeredOn")}
              </TableCell>
              <TableCell className={classes.right}>
                {t("selfRegistration")}
              </TableCell>
              <TableCell className={classes.right}>
                {t("organizationNumber")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registrations.map((reg) => (
              <TableRow
                className={rowClass}
                key={reg.registrationId}
                hover
                onClick={() =>
                  history.push(
                    registrationPagePath(exhibitionId, reg.registrationId)
                  )
                }
              >
                <TableCell>{`${reg.memberRegistered.lastName} ${reg.memberRegistered.firstName}`}</TableCell>
                <TableCell className={classes.right}>
                  {reg.memberRegistered.memberNumber}
                </TableCell>
                <TableCell>{reg.unit.unitName}</TableCell>
                <TableCell>{reg.breed.breedName}</TableCell>
                <TableCell>{reg.color?.colorName}</TableCell>
                <TableCell>
                  {reg.singleAnimalGender
                    ? formatGender(reg.singleAnimalGender)
                    : null}
                </TableCell>
                <TableCell className={classes.right}>
                  {formatDate(reg.createdDate)}
                </TableCell>
                <TableCell className={classes.right}>
                  {reg.selfRegistration ? t("yes") : t("no")}
                </TableCell>
                <TableCell className={classes.right}>
                  {reg.organizationNumber}
                </TableCell>
                <HoverActionCell>
                  <LinkToRegistration
                    exhibitionId={exhibitionId}
                    registrationId={reg.registrationId}
                    button
                    color="primary"
                    size="small"
                  >
                    {t("goToRegistration")}
                  </LinkToRegistration>
                </HoverActionCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[100, 1000]}
        component="div"
        count={props.totalCount}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        SelectProps={{
          inputProps: { "aria-label": "Zeilen pro Seite" },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RegistrationTable;

export type Registration = Omit<RegistrationInList, "createdUtc"> & {
  createdDate: Date;
};

interface RegistrationTableProps extends Pageable {
  registrations: Registration[];
  exhibitionId: string;
}
