import * as React from "react";
import { Button, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: "none",
  },
}));

const UploadButton: React.FunctionComponent<{
  onFile: (file: File) => void;
  disabled?: boolean;
  className?: string;
}> = (props) => {
  const classes = useStyles();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;
    props.onFile(e.target.files[0]);
  };
  return (
    <div className={props.className}>
      <input
        accept=".csv"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
        disabled={props.disabled}
      />
      <label htmlFor="contained-button-file">
        {/* NOTE about the Button below: It's using component="span" instead of 
        "button" because a button must not be the child of a label. */}
        <Button
          variant="contained"
          color="secondary"
          component="span"
          disabled={props.disabled}
        >
          {props.children}
        </Button>
      </label>
    </div>
  );
};

export default UploadButton;
