import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import useConfirmation from "../../../crossCutting/hooks/useConfirmation";
import { CheckIcon, TodoIcon } from "../../../crossCutting/icons";
import { useTranslation } from "../../../i18n/i18n";
import { LinkToCompetitionsPage } from "../competitionsPage/routing";
import { LinkToMemberImport } from "../memberImportPage/routing";
import { ExhibitionId } from "../types";

export interface OrganizerChecklistProps {
  exhibitionId: ExhibitionId;
  membersDone: boolean;
  competitionsDone: boolean;
  publication: { canPublish: boolean; isPublished: boolean };
  onPublish: () => void;
  publishing: boolean;
}

const OrganizerChecklist: React.SFC<OrganizerChecklistProps> = ({
  exhibitionId,
  membersDone,
  competitionsDone,
  publication,
  onPublish,
  publishing,
}) => {
  const { t } = useTranslation();
  const { Confirmation, executeWithConfirmation } = useConfirmation(
    t("publish"),
    t("notYet"),
    onPublish
  );
  const handlePublishClicked = () => {
    if (publication.canPublish) {
      executeWithConfirmation();
    }
  };
  return (
    <>
      <List>
        <Item
          done={competitionsDone}
          text={t("checklistCreateCompetition")}
          action={
            <LinkToCompetitionsPage
              button
              exhibitionId={exhibitionId}
              color="primary"
            >
              {t("createCompetitionNow")}
            </LinkToCompetitionsPage>
          }
        />
        <Item
          done={membersDone}
          text={t("checklistUnlockMembers")}
          action={
            <LinkToMemberImport
              button
              color="primary"
              exhibitionId={exhibitionId}
            >
              {t("unlockMembersNow")}
            </LinkToMemberImport>
          }
        />
        <Item
          done={publication.isPublished}
          text={t("checklistPublishExhibition")}
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handlePublishClicked}
              disabled={!publication.canPublish || publishing}
            >
              {t("publishExhibitionNow")}
            </Button>
          }
        />
      </List>
      <Confirmation
        title={t("publishConfirmationTitle")}
        text={t("publishConfirmationText")}
      />
    </>
  );
};

export default OrganizerChecklist;

const Item: React.FunctionComponent<{
  done?: boolean;
  text: string;
  action: React.ReactNode;
}> = (props) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <ListItem>
      <ListItemIcon>{props.done ? <CheckIcon /> : <TodoIcon />}</ListItemIcon>
      {(props.done || !xsDown) && <ListItemText>{props.text}</ListItemText>}
      {!props.done && (
        <ListItemSecondaryAction>{props.action}</ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
