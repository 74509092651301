import * as React from "react";
import AutocompletePicker from "../../../../crossCutting/AutocompletePicker";
import { ExhibitionId } from "../../types";
import { useFetchClient } from "../../../../fetching/fetchProvider";
import { AddIcon } from "../../../../crossCutting/icons";
import { useTranslatorForUser } from "../../../masterdata/translation";
import { searchBreedsOfStandards } from "./breedApi";
export interface Breed {
  breedId: string;
  captions: { language: string; breedName: string }[];
}

const minLength = 2;

export default function BreedPicker(props: {
  exhibitionId: ExhibitionId;
  animalStandardIds: string[];
  onSelect: (selectedBreedIds: string[]) => void;
  breedIdsToExclude: string[];
}) {
  const fetcher = useFetchClient();
  const animalStandardIds = props.animalStandardIds;
  const exhibitionId = props.exhibitionId;
  const breedIdsToExclude = props.breedIdsToExclude;
  const search = React.useCallback(
    (searchText: string) =>
      searchText.length >= minLength
        ? searchBreedsOfStandards(
            fetcher,
            exhibitionId,
            animalStandardIds,
            searchText
          ).then((breeds) =>
            breeds.filter((b) => !breedIdsToExclude.includes(b.breedId))
          )
        : Promise.resolve([]),
    [fetcher, exhibitionId, animalStandardIds, breedIdsToExclude]
  );
  const masterDataTranslator = useTranslatorForUser();
  return (
    <AutocompletePicker
      value={[]}
      onChange={(v) => props.onSelect(v.map((b) => b.breedId))}
      comparer={(a, b) => a.breedId === b.breedId}
      fetchOptions={search}
      buttonLabel="Rasse hinzufügen"
      getOptionLabel={(b) =>
        masterDataTranslator(b.captions)?.breedName ?? "n/a"
      }
      noOptionsText="Keine Rassen gefunden"
      startIcon={<AddIcon />}
      placeholder="Name"
      color="primary"
      variant="outlined"
      size="small"
      multiple
    />
  );
}
